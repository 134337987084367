import { useQueryClient } from "react-query"

// customHook used to access to useQuery keys to avoid props drill
// received an array of strings(key name)
export const useQueryKeys = (keys = []) => {
  const queryList = [];
  const queryKeys = useQueryClient();
  // Retrieve all queries stored in the query cache
  const store = queryKeys.getQueryCache().getAll();
  // Iterate over each key in the provided keys array
  keys.forEach(key => {
    // For each key, iterate over store queries
    return store.map(query => {
      // Check if the first element of the queryKey matches the current key
      // If it matches, push the first element of the query's data state into queryList
      return query.queryKey[0] === key && queryList.push(query.state.data);
    });
  });
  // Return the list of filtered query data
  // To get access for your props, destructure in same order defined as keys
  return queryList;
}