import React from 'react';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { HelpersLinks } from './HelpersLinks';
import { RegionExecScoreByTimeframe } from './RegionExecScoreByTimeframe';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
export const Region = ({ execScoreData }) => {
  const { fpToggle } = useShrinkContext();
  const { level } = useShrinkQueries({ fpToggle });

  const levelData = execScoreData.filter(({METRIC_LEVEL}) => METRIC_LEVEL === METRIC_CONSTANTS[level].METRIC_LEVEL);

  return (
    <>
      <HelpersLinks level={level} />
      <RegionExecScoreByTimeframe scoresData={levelData} />
    </>
  );
};
