import { useState } from 'react';
import { useEnhancedQueries } from '../../../hooks/useEnhancedQueries';
import { useSpecialtyProRentalServicesContext } from '../hooks/useSpecialtyProRentalServicesContext';
import { ALL_PROGRAMS, FOCUS_6 } from '../utils/specialtyProRentalServices';
import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { Header, Loader, Menu, Segment } from 'semantic-ui-react';
import { LeadsMeasuresTable } from '../components/LeadsMeasuresTable';
import { Link } from '../../Common/Link';
import { ServicesSales } from '../components/ServicesSales';
import { TabMenu } from '../../Common/TabMenu';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { ServicesPipeline } from '../components/ServicesPipeline';

export const SpecialtyProRentalServicesPage = () => {
  const [focus6AllPrograms, setFocus6AllPrograms] = useState(FOCUS_6);
  const [pipelineFilter, setPipelineFilter] = useState('TOTAL');
  const { currentLevelName, fpToggle, level, locNbr, timeframe, settimeframe } = useSpecialtyProRentalServicesContext();
  const { isLoading } = useEnhancedQueries(['queryServicesDtlByLevel', 'queryServicesDtlByMetricLevel'], fpToggle);
  const paramByLevel = getParamByLevel(level);

  if (isLoading) return <Loader active>Loading...</Loader>;

  const menuItems = [FOCUS_6, ALL_PROGRAMS];

  const onChangePipeline = (_, { value }) => {
    setPipelineFilter(value);
  };

  return (
    <div>
      <Header className='text-capitalize' textAlign='center'>
        Services - {level} #{locNbr}, {currentLevelName}
      </Header>

      <div className='d-flex justify-content-between'>
        <Link to={`/${level}/specialtyprorental?${paramByLevel}=${locNbr}`}>Back to Specialty/Pro/Rental</Link>

        <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />
      </div>

      <Menu attached='top' color='orange' size='small' tabular widths={2}>
        {menuItems.map(item => (
          <Menu.Item
            active={focus6AllPrograms === item}
            content={item}
            onClick={() => setFocus6AllPrograms(item)}
            name={item}
          />
        ))}
      </Menu>

      <Segment attached='bottom'>
        <TabMenu
          className='mb-4'
          color='orange'
          panes={[
            {
              menuItem: 'Sales',
              render: () => <ServicesSales focus6AllPrograms={focus6AllPrograms} pipelineFilter={pipelineFilter} />
            },
            {
              menuItem: 'Pipeline',
              render: () => (
                <ServicesPipeline
                  focus6AllPrograms={focus6AllPrograms}
                  onChangePipeline={onChangePipeline}
                  pipelineFilter={pipelineFilter}
                />
              )
            }
          ]}
        />

        <LeadsMeasuresTable focus6AllPrograms={focus6AllPrograms} pipelineFilter={pipelineFilter} />
      </Segment>
    </div>
  );
};
