import { DataView } from "../../Common/DataView";
import { useShrinkContext } from "../hooks/useShrinkContext";
import { useShrinkQueries } from "../hooks/useShrinkQueries";
import { monitorMetricsData } from "../utils/dataObjectBuilder";

export const MonitorMetrics = ({ shrinkMetricsData }) => {

  const { timeframe, fpToggle } = useShrinkContext();
  const { locNbr, level } = useShrinkQueries({ fpToggle })

  return (
    <DataView className='mt-4'>
      <DataView.Title>
        Monitor Metrics
      </DataView.Title>
      <DataView.DataTable data={monitorMetricsData({ level, locNbr, shrinkMetricsData, timeframe })} />
    </DataView>
  );
}