import { useContext, useState } from 'react'
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext'
import { DataView } from '../../../../../../components/Common/DataView'
import { Dropdown } from 'semantic-ui-react'
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel'
import { capitalizeFirstLetter } from '../../../../../../utils/string'
import { getPercentString } from '../../../../../../components/Common/TableCellUtils'

export default function OnTimeDelivery() {
  const { orderFulfillmentOnTimePickupData, level, timeframe } = useContext(OrderFulfillmentContext)
  const [view, setView] = useState('Box')
  
  //filter by truck type
  const dataFilter  = orderFulfillmentOnTimePickupData.DELIVERY.filter((data) =>  data.TRUCK_TYPE === view)
  
  const dataObj = {
    headers: [
      { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
      { name: 'Pick On Time %' },
      { name: 'Delivery Misses' },
      { name: 'Delivery Misses %' }
    ],
    data: dataFilter.map(data => [
      {
        stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
        link: {
          to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
          }
        }
      },
      { stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']) },
      { numValue: data[timeframe + '_DLV_MISSES'] },
      { stringValue: getPercentString(data[timeframe + '_DLV_MISSES_PCT']) },
    ])
  }
  
  return (
    <DataView className='mt-2'>
      <DataView.Controls>
        <div>Select Truck Type</div>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={[
            { key: 'Box', text: 'Box', value: 'Box' },
            { key: 'Car', text: 'Car', value: 'Car' },
            { key: 'Flatbed', text: 'Flatbed', value: 'Flatbed' },
            { key: 'Van', text: 'Van', value: 'Van' },
            { key: 'Other', text: 'Other', value: 'Other' },
            { key: 'Total', text: 'Total', value: 'Total' },

          ]}
          onChange={(_, { value }) => {
            setView(value);
          }}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Delivery Details by {view}</DataView.Title>
      <DataView.DataTable data={dataObj} />
    </DataView>
  )
}
