import { DataView } from '../../Common/DataView';
import { useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { footerTotal, headers, keyMetricsFooterRow, keyMetricsRows } from '../utils/specialtyProRentalKeyMetrics';

export const SpecialtyProRentalKeyMetrics = ({ specOpsPlusOneData = [] }) => {
  const { timeframe } = useSpecialtyProRentalContext();
  const { level, locNbr } = useSpecialtyProRentalContext();
  const dataFiltered = useMemo(() => specOpsPlusOneData.filter(row => row[level.propToRender]), [specOpsPlusOneData, level]);
  const footer = useMemo(() => footerTotal(dataFiltered, timeframe), [dataFiltered, timeframe]);

  if (!specOpsPlusOneData.length) return <Header textAlign='center'>No data to show in Specialty Key Metrics.</Header>;

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers: headers(level, locNbr),
    footer: [keyMetricsFooterRow(footer, timeframe)],
    data: dataFiltered.map(row => keyMetricsRows(row, timeframe, level))
  };

  return (
    <DataView className='my-4'>
      <DataView.Title position='center'>Specialty Key Metrics</DataView.Title>

      <DataView.DataTable data={data} />
    </DataView>
  );
};
