import { useState } from 'react';
import { useShrinkContext } from '../../hooks/useShrinkContext';
import { useShrinkQueries } from '../../hooks/useShrinkQueries';
import { buildBaseShrinkUrl } from '../../utils/url';
import { formatOptions } from '../../../../utils/dropdown';
import { getParamByLevel } from '../../../../utils/timeFrameUtils';
import { uniqueValues } from '../../../../utils/array';
import { UNIT_CHAR } from '../../utils/overview';
import { Dropdown, Tab } from 'semantic-ui-react';
import { DataView } from '../../../Common/DataView';
import { UnitMenu } from '../../../Common/UnitMenu';

export const SubClassesSummaryTab = () => {
  const { fpToggle } = useShrinkContext();
  const {
    level,
    locNbr,
    results: [queryShrinkActualsSubCls]
  } = useShrinkQueries({
    fpToggle,
    queries: ['query/queryShrinkActualsSubCls']
  });
  const [unit, setUnit] = useState('NOM');
  const [deptNbr, setDeptNbr] = useState('All');
  const paramByLevel = getParamByLevel(level);
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const url = `/${baseLevelShrinkUrl}/subclass`;
  const isUnitNom = unit === 'NOM';

  const departmentNumbers = uniqueValues(queryShrinkActualsSubCls.data?.map(dept => dept.DEPT_NBR)).sort();
  const departmentOptions = formatOptions(['All', ...departmentNumbers]);

  const classSumDataFilter = queryShrinkActualsSubCls.data?.filter(
    row => deptNbr === 'All' || row?.DEPT_NBR?.toString() === deptNbr.toString()
  );

  const dataObj = {
    data: classSumDataFilter.map(row => {
      const shrinkDollar = row.TOTAL_SHRINK_AMT;
      const shrinkPct = row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT;

      const vlyDollar = row.TOTAL_SHRINK_AMT - row.LY_TOTAL_SHRINK_AMT;
      const vlyPct = row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT - row.LY_TOTAL_SHRINK_AMT / row.LY_TOTAL_SALES_AMT;

      const openShrinkDollar = row.OPS_SHRINK_AMT;
      const openShrinkPct = row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT;
      
      return [
      {
        link: {
          to: url,
          params: {
            [paramByLevel]: locNbr,
            subClassNbr: row.EXT_SUB_CLASS_NBR
          }
        },
        stringValue: `${row.EXT_SUB_CLASS_NBR} - ${row.SUB_CLASS_DESC?.replaceAll('/', '/\u200b')}`
      },
      {
        dollarValue: isUnitNom ? shrinkDollar : undefined,
        orderBy: isUnitNom ? shrinkDollar : shrinkPct,
        pctValue: isUnitNom ? undefined : shrinkPct
      },
      {
        dollarValue: isUnitNom ? vlyDollar : undefined,
        orderBy: isUnitNom ? vlyDollar : vlyPct,
        pctValue: isUnitNom ? undefined : vlyPct
      },
      {
        dollarValue: isUnitNom ? openShrinkDollar : undefined,
        orderBy: isUnitNom ? openShrinkDollar : openShrinkPct,
        pctValue: isUnitNom ? undefined : openShrinkPct
      }
    ]
  }),
    headers: [
      { name: 'Subclass ' },
      { name: `Shrink ${UNIT_CHAR[unit]}` },
      { name: `vLY ${UNIT_CHAR[unit]}` },
      { name: `Ops Shrink ${UNIT_CHAR[unit]}` }
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Title>Subclasses Summary</DataView.Title>

        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />

          <Dropdown
            className='mx-3'
            compact
            onChange={(_, { value }) => setDeptNbr(value)}
            options={departmentOptions}
            placeholder='Dept'
            selection
            value={deptNbr}
          />
        </DataView.Controls>

        <DataView.DataTable data={dataObj} />
      </DataView>
    </Tab.Pane>
  );
};
