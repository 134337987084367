import React from 'react'
import OrderFulfillmentContextProvider from '../../../context/OrderFulfillmentContext';
import { Grid, Header, Tab, Segment } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import Complete from '../components/complete/Complete';
import OnTime from '../components/ontime/OnTime';
import Summary from '../components/Summary';
import { useFetchLevelName } from '../../../../../hooks/useFetchLevelName';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { Loader } from 'semantic-ui-react';

export const OrderFulfillmentPage = ({ timeframe, settimeframe, fpToggle, level, ...props }) => {
  const { dstNbr = 0 } = useQueryParams();
  const { currentLevelName, isLoadingName } = useFetchLevelName({ fpToggle, level: 'district', locNbr: dstNbr })

  const date = '2021-01-01';

  const panes = [
    {
      menuItem: 'ON TIME',
      render: () => <OnTime />
    },
    {
      menuItem: 'COMPLETE',
      render: () => <Complete />
    }
  ];

  if (isLoadingName) {
    return <Loader active>Loading...</Loader>;
  }

  const propsToShare = {
    level,
    timeframe,
    ...props
  }

  return (
    <OrderFulfillmentContextProvider {...propsToShare}>
      <Segment fluid>
        <Header textAlign='left' style={{ marginTop: '1rem' }}>
          Order Fulfillment - {level} {dstNbr}, {currentLevelName}
          <Header.Subheader>Data as of {date}</Header.Subheader>
        </Header>

        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              Timeframe:
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Summary */}
        <Summary />

        {/* On Time and Complete Tabs component. This might hold all the other components?*/}
        {/* If we structure everything to be in the On Time and Complete components, then the rest of this would be in those */}
        <Tab menu={{ secondary: true, pointing: true, color: 'orange' }} panes={panes} />

        {/* ON TIME */}
        {/* Order Picked on Time header */}
        {/* PICKUP DELIVERY tabs */}

        {/* PICKUP */}
        {/* Pickup Details by Method */}
        {/* Pickup Details for Curbside */}
        {/* More Details */}



      </Segment>
    </OrderFulfillmentContextProvider >
  );
};
