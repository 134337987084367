import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../utils/string';
import { SPEC_PRO_RENTAL_PATH } from './consts';

const ACT_HRS = 'ACT_HRS';
const HRS_VF = 'HRS_VF';
const HRS_VS = 'HRS_VS';
const HRS_VF_PCT = 'HRS_VF_PCT';
const HRS_VS_PCT = 'HRS_VS_PCT';

export const headers = (level, unit) => {
  const isPct = unit === 'PCT' ? ' %' : '';
  return [
    { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)} # ` },
    { name: 'Act' },
    { name: `AvF${isPct}` },
    { name: `AvS${isPct}` }
  ];
};

export const footerTotal = (laborData, timeframe) => {
  const ACT = `${timeframe}_${ACT_HRS}`;
  const AVF_NOM = `${timeframe}_${HRS_VF}`;
  const AVS_NOM = `${timeframe}_${HRS_VS}`;
  const AVF_PCT = `${timeframe}_${HRS_VF_PCT}`;
  const AVS_PCT = `${timeframe}_${HRS_VS_PCT}`;

  return laborData.reduce(
    (acc, curr) => ({
      [ACT]: (acc[ACT] || 0) + curr[ACT],
      [AVF_NOM]: (acc[AVF_NOM] || 0) + curr[AVF_NOM],
      [AVS_NOM]: (acc[AVS_NOM] || 0) + curr[AVS_NOM],
      [AVF_PCT]: (acc[AVF_PCT] || 0) + curr[AVF_PCT] / laborData.length,
      [AVS_PCT]: (acc[AVS_PCT] || 0) + curr[AVS_PCT] / laborData.length
    }),
    {}
  );
};

const formatColor = value => ({
  color: value > 0.01 || value < -0.01 ? 'red' : 'green'
});

const formatData = value => value.toLocaleString('en-US', { maximumFractionDigits: 0 });

export const laborDataRow = (laborData, timeframe, level, unit) => {
  const firstColumn = {
    stringValue: laborData[METRIC_CONSTANTS[level]?.propToRender],
    link: {
      to: `/${METRIC_CONSTANTS[level]?.urlLinkLevel}/${SPEC_PRO_RENTAL_PATH}`,
      params: {
        [METRIC_CONSTANTS[level].urlSubLevelParam]: laborData[METRIC_CONSTANTS[level]?.propToRender]
      }
    }
  };
  return laborRows(laborData, timeframe, unit, firstColumn);
};

export const laborFooterRow = (laborData, timeframe, unit) => {
  const firstColumn = { stringValue: 'Total' };
  return laborRows(laborData, timeframe, unit, firstColumn);
};

const laborRows = (laborData, timeframe, unit, firstColumn) => {
  const secondColumn = {
    stringValue: formatData(laborData[`${timeframe}_ACT_HRS`])
  };
  switch (unit) {
    case 'NOM':
      return [
        firstColumn,
        secondColumn,
        { stringValue: formatData(laborData[`${timeframe}_HRS_VF`]) },
        { stringValue: formatData(laborData[`${timeframe}_HRS_VS`]) }
      ];
    case 'PCT':
      return [
        firstColumn,
        secondColumn,
        {
          pctValue: laborData[`${timeframe}_HRS_VF_PCT`],
          customStyle: formatColor(laborData[`${timeframe}_HRS_VF_PCT`])
        },
        {
          pctValue: laborData[`${timeframe}_HRS_VS_PCT`],
          customStyle: formatColor(laborData[`${timeframe}_HRS_VS_PCT`])
        }
      ];

    default:
      return [];
  }
};
