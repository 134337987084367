import { useContext, useState } from "react";
import { DataView } from "../../../../../../components/Common/DataView";
import { OrderFulfillmentContext } from "../../../../context/OrderFulfillmentContext";
import { Dropdown } from "semantic-ui-react";
import { METRIC_CONSTANTS } from "../../../../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from "../../../../../../utils/string";
import { getPercentString } from "../../../../../../components/Common/TableCellUtils";

export const OnTimePickUp = () => {

  const { orderFulfillmentOnTimePickupData, level, timeframe } = useContext(OrderFulfillmentContext)

  const [view, setView] = useState('BOSS')
  const getData = () => {
    switch (view) {
      case 'BOSS':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Pick On Time %' },
            { name: '# of Orders Missed SLA' },
            { name: 'Avg Min to Pick' },
            { name: 'DS Compliance %' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            { numValue: data[timeframe + '_RLSD_ORDERS'] },
            {
              numValue: data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-',
              orderBy: data[timeframe + '_AVG_MINS_TO_PICK']
            },
            {
              pctValue: data[timeframe + '_DS_COMP'],
              orderBy: data[timeframe + '_DS_COMP']
            }
          ])
        };
      case 'Total':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Pick On Time %' },
            { name: '# of Orders Missed SLA' },
            { name: 'Avg Min to Pick' },
            { name: 'DS Compliance %' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            { numValue: data[timeframe + '_RLSD_ORDERS'] },
            {
              numValue: data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-',
              orderBy: data[timeframe + '_AVG_MINS_TO_PICK']
            },
            {
              pctValue: data[timeframe + '_DS_COMP'],
              orderBy: data[timeframe + '_DS_COMP']
            }
          ])
        };
      case 'Will Call':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Pick On Time %' },
            { name: '# of Orders Missed SLA' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            { numValue: data[timeframe + '_RLSD_ORDERS'] },
          ])
        };
      case 'Curbside':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Avg Customer Wait Time' },
            { name: '# Of Orders Missed SLA' },
            { name: '% of Orders Missed SLA' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            { numValue: data[timeframe + '_RLSD_ORDERS'] },
            { numValue: data[timeframe + '_RLSD_ORDERS'] },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
          ])
        };
      case 'BOPIS':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Pick On Time %' },
            { name: '# of Orders Missed SLA' },
            { name: 'Avg Min to Pick' },
            { name: 'DS Compliance %' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            { numValue: data[timeframe + '_RLSD_ORDERS'] },
            {
              numValue: data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-',
              orderBy: data[timeframe + '_AVG_MINS_TO_PICK']
            },
            {
              pctValue: data[timeframe + '_DS_COMP'],
              orderBy: data[timeframe + '_DS_COMP']
            }
          ])
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <DataView className='mt-2'>
      <DataView.Controls>
        <div>Select Method</div>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={[
            { key: 'Total', text: 'Total', value: 'Total' },
            { key: 'BOPIS', text: 'BOPIS', value: 'BOPIS' },
            { key: 'BOSS', text: 'BOSS', value: 'BOSS' },
            { key: 'Will Call', text: 'Will Call', value: 'Will Call' },
            { key: 'Curbside', text: 'Curbside', value: 'Curbside' },
          ]}
          onChange={(_, { value }) => {
            setView(value);
          }}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Pickup Details by Method {view}</DataView.Title>
      <DataView.DataTable data={getData()} />
    </DataView>
  );
}