import React from 'react'
import { Tab, Header } from 'semantic-ui-react';
import { OnTimePickUp } from './OnTimePickUp';
import OnTimeDelivery from './OnTimeDelivery';

export default function OnTime() {
  const tabs = [
    {
      menuItem: 'PICKUP',
      render: () => <OnTimePickUp />
    },
    {
      menuItem: 'DELIVERY',
      render: () => <OnTimeDelivery />
    }
  ]

  return (<>
    <Header as='h3'>Order Picked on Time</Header>
    <Tab
      menu={{ compact: true, size: 'small' }}
      panes={tabs}
    />
  </>);
}
