import { getParamByLevel } from "../../../utils/timeFrameUtils";
import { getCalloutStyle, getCompareStyle } from "../../Common/TableCellUtils";

export const buildFooter = (focusSix, totalData, timeframe, isFocusSix) => {
  if (!isFocusSix) return []

  return [
    [
      {
        name: 'total',
        stringValue: 'Total Focus 6 Programs'
      },
      {
        name: 'actual',
        dollarValue: focusSix[timeframe + '_TY_SLS_AMT']
      },
      {
        name: 'vp_dollar',
        dollarValue: focusSix[timeframe + '_TY_SLS_AMT'] - focusSix[timeframe + '_SLS_PLN_AMT']
      },
      {
        name: 'comp',
        pctValue:
          (focusSix[timeframe + '_TY_COMP_SLS_AMT'] - focusSix[timeframe + '_LY_COMP_SLS_AMT']) /
          focusSix[timeframe + '_LY_COMP_SLS_AMT']
      }
    ],
    [
      {
        name: 'total_programs',
        stringValue: 'Total Programs'
      },
      {
        name: 'actual',
        dollarValue: totalData[timeframe + '_TY_SLS_AMT']
      },
      {
        name: 'vp_dollar',
        dollarValue: totalData[timeframe + '_TY_SLS_AMT'] - totalData[timeframe + '_SLS_PLN_AMT']
      },
      {
        name: 'comp',
        pctValue:
          (totalData[timeframe + '_TY_COMP_SLS_AMT'] - totalData[timeframe + '_LY_COMP_SLS_AMT']) /
          totalData[timeframe + '_LY_COMP_SLS_AMT']
      }
    ]
  ]
}

export const buildHeaders = () => [{ name: 'Program' }, { name: 'Actual' }, { name: 'vP $' }, { name: 'Comp %' }]

export const buildRows = (data, isAllPrograms, level, locNbr, timeframe) => {
  const paramByLevel = getParamByLevel(level);

  return data.map(row => {
    const programName = isAllPrograms ? `${row.MER_DEPT_CD} - ${row.SUMMARY_PROGRAM_NAME}` : row.SUMMARY_PROGRAM_NAME;

    return [
      {
        link: {
          to: `/${level}/new-services/program`,
          params: {
            [paramByLevel]: locNbr,
            smryPrgmNm: row.SUMMARY_PROGRAM_NAME
          }
        },
        stringValue: programName
      },
      {
        customStyle: getCalloutStyle(row[timeframe + '_TY_SLS_AMT']),
        dollarValue: row[timeframe + '_TY_SLS_AMT']
      },
      {
        customStyle: getCompareStyle(row[timeframe + '_TY_SLS_AMT'] - row[timeframe + '_SLS_PLN_AMT']),
        dollarValue: row[timeframe + '_TY_SLS_AMT'] - row[timeframe + '_SLS_PLN_AMT']
      },
      {
        customStyle: getCompareStyle(row[timeframe + '_TY_COMP_SLS_AMT'] - row[timeframe + '_LY_COMP_SLS_AMT']),
        pctValue:
          (row[timeframe + '_TY_COMP_SLS_AMT'] - row[timeframe + '_LY_COMP_SLS_AMT']) / row[timeframe + '_LY_COMP_SLS_AMT']
      }
    ];
  })
}
