import { buildBaseDrillLink } from "../../../../../components/ShrinkContainer/utils/url";
import { getPercentString } from "../../../../../components/Common/TableCellUtils"
import { METRIC_CONSTANTS } from "../../../../../utils/constantsByLevel"
import { formatLevelNumber } from "../../../../../utils/level"

export const onHandAdjustment = ({ shrinkData, timeframe }) => ({
  headers: [
    { name: 'Division # ' },
    { name: 'Value ' },
  ],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink('core');

    return [
      {
        stringValue: row[METRIC_CONSTANTS['core']['propToRender']],
        link: {
          to: `/${baseLevelShrinkUrl}/ohemptpkg`,
          params: {
            [METRIC_CONSTANTS['core']['urlSubLevelParam']]: formatLevelNumber(row[METRIC_CONSTANTS['core']['propToRender']])
          }
        }
      },
      {
        decimals: 2,
        pctValue: row[timeframe + '_OH_EMPT_PKG_NUM'] / row[timeframe + '_OH_EMPT_PKG_DEN']
      },
    ]
  })
})