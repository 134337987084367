import { Breadcrumb } from '../../../../../components/Common/Breadcrumb';
import { DataView } from '../../../../../components/Common/DataView';
import { TimeframeDropdownShrink } from '../../../../../components/Common/TimeframeDropdownShrink';
import { Header, Segment } from 'semantic-ui-react';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';

export const CoreMonitorMetricsDrill = ({ data, title, breadcrumb, dataAsOf = null }) => {
  const { timeframe, settimeframe } = useShrinkCoreContext();

  if (data.length) return <Header textAlign='center'>No data to show.</Header>;

  return (
    <Segment className='mb-4'>
      <Breadcrumb items={breadcrumb} />

      <DataView className='my-4'>
        <section style={{ textAlign: 'center' }}>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </section>

        <DataView.Title className='mt-2'>
          {title}
          {dataAsOf && <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>}
        </DataView.Title>

        <DataView.DataTable data={data} />
      </DataView>
    </Segment>
  );
};
