import { Header, Tab } from "semantic-ui-react";
import { DeliveryPush } from "./delivery/DeliveryPush";
import { TruckChanges } from "./delivery/TruckChanges";
import { DeliveryPull } from "./delivery/DeliveryPull";

export const DeliveryTabs = () => {
  const tabs = [
    {
      menuItem: 'Fulfillment',
      render: () => <div>Fulfillment</div>
    },
    {
      menuItem: 'Delivery Push',
      render: () => <DeliveryPush />
    },
    {
      menuItem: 'Delivery Pull',
      render: () => <DeliveryPull />
    },
    {
      menuItem: 'Truck Changes',
      render: () => <TruckChanges />
    },
  ]

  return (<>
    <Header>Delivery Complete</Header>
    <Tab
      menu={{ compact: true, size: 'small' }}
      panes={tabs}
    />
  </>);
}