import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { getAdjustableDollarString, getPercentString } from '../../../../../components/Common/TableCellUtils';

export const coreDepartmentSummaryBuilder = (departmentData, units) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');
  const filteredData = departmentData?.filter(row => row.DEPT_NBR && row.DEPT_NM);

  return {
    headers: [
      { name: 'Dept' },
      { name: units === 'NOM' ? 'Shrink $' : 'Shrink %' },
      { name: units === 'NOM' ? 'vLY $' : 'vLY %' },
      { name: units === 'NOM' ? 'Ops Shrink $' : 'Ops Shrink %' }
    ],
    data: filteredData?.map(row => [
      {
        link: {
          params: {
            deptNbr: row.DEPT_NBR
          },
          to: `/${baseLevelShrinkUrl}/dept`
        },
        stringValue: `${row['DEPT_NBR']}-${row['DEPT_NM']?.toUpperCase()?.replace(/\//g, ' ')}`
      },
      {
        stringValue:
          units === 'NOM'
            ? getAdjustableDollarString(row.TOTAL_SHRINK_AMT, 2)
            : getPercentString(row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT, 2, 2)
      },
      {
        stringValue:
          units === 'NOM'
            ? getAdjustableDollarString(row.TOTAL_SHRINK_AMT - row.LY_TOTAL_SHRINK_AMT, 2)
            : getPercentString(
              row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT - row.LY_TOTAL_SHRINK_AMT / row.LY_TOTAL_SALES_AMT,
              2,
              2
            )
      },
      {
        stringValue:
          units === 'NOM'
            ? getAdjustableDollarString(row.OPS_SHRINK_AMT, 2)
            : getPercentString(row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT, 2)
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };
};
