import { focus6Builder } from '../../utils/focus6Builder';
import { DataView } from '../../../../../../components/Common/DataView';
import { leadsMeasuresBuilder } from '../../utils/leadsMeasuresBuilder';
import { DataTable } from '../../../../../../components/Common/DataTable';

export const CoreFocus6SalesTab = ({ focus6Data = [], leadsMeasuresData = [], timeframe }) => {
  const coreFocus6Data = focus6Builder(focus6Data, timeframe);
  const coreLeadsMeasuresData = leadsMeasuresBuilder(leadsMeasuresData, timeframe);

  return (
    <>
      <DataView className='mt-4'>
        <DataView.Title textAlign='center'>Focus 6</DataView.Title>
        <DataTable data={coreFocus6Data} />
      </DataView>

      <DataView className='mt-4'>
        <DataView.Title textAlign='center'>Leads & Measures Performance</DataView.Title>
        <DataTable data={coreLeadsMeasuresData} />
      </DataView>
    </>
  );
};
