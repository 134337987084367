import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { MonitorMetricsDrill } from '../../../../../components/ShrinkContainer/components/MonitorMetricsDrill';
import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { getShrinkBossCancelDataTable } from '../../../../../components/ShrinkContainer/utils/shrinkMonitorMetrics';
import { useShrinkContext } from '../../../../../components/ShrinkContainer/hooks/useShrinkContext';
import { CORE } from '../../../../../utils/constantsByLevel';
import { TRNSFM_DT } from '../../../../../utils/constants';

const { levelName: level } = CORE;

const getBreadcrumb = ({ level, title }) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);

  return [{ to: `/${baseLevelShrinkUrl}`, text: 'Back to Shrink Overview' }, { text: title }];
};
export const ShrinkBossCancelPage = () => {
  const { fpToggle, timeframe } = useShrinkContext();
  const queryParams = { fpToggle, isCore: true };

  const {
    data: shrinkMetricsByLevelData,
    isError,
    isLoading
  } = useQueryApi(`query/queryShrinkMetricsByLevelPlusOne/${level}`, queryParams);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const titleSingle = 'Boss RTV %';
  const breadcrumb = getBreadcrumb({ level, title: titleSingle });
  const title = `${titleSingle} - ${level}`;
  const dataAsOf = shrinkMetricsByLevelData?.[0]?.[TRNSFM_DT];
  const dataTable = getShrinkBossCancelDataTable({ level, shrinkMetricsByLevelData, timeframe });

  return <MonitorMetricsDrill breadcrumb={breadcrumb} title={title} dataAsOf={dataAsOf} data={dataTable} />;
};
