import React from 'react';
import { Header } from 'semantic-ui-react';
import { DeliveryTabs } from './DeliveryTabs';

export const CompleteDelivery = () => {
    return (<>
        <Header as='h1'>Delivery</Header>
        <div>TODO: Delivery table goes here</div>
        <DeliveryTabs />
    </>);
};



