import useQueryApi from '../../../hooks/useQueryApi';

export const useOverviewQueries = fpToggle => {
  const defaultParams = { fpToggle, isCore: true };

  const {
    data: salesClassesData = [],
    isError: isErrorSalesClasses,
    isLoading: isLoadingSalesClasses
  } = useQueryApi('querySlsClsByCore', defaultParams);
  const {
    data: salesSkusData = [],
    isError: isErrorSalesSkus,
    isLoading: isLoadingSalesSkus
  } = useQueryApi('querySlsSkuByCore', defaultParams);

  const {
    data: salesSafetyByDivisionData = [],
    isError: isErrorSalesSafetyByDivision,
    isLoading: isLoadingSalesSafetyByDivision
  } = useQueryApi('querySafetyIncOshaLtGlbiByCore', defaultParams);

  const {
    data: salesMetricsData = [],
    isError: isErrorSalesMetricsData,
    isLoading: isLoadingSalesMetricsData
  } = useQueryApi('querySwmRgnMetricsByCore', defaultParams);
  const {
    data: salesSubClassesData = [],
    isError: isErrorSalesSubClasses,
    isLoading: isLoadingSalesSubClasses
  } = useQueryApi('querySlsSclsByCore', defaultParams);

  const {
    data: salesDeptData = [],
    isError: isErrorSalesDept,
    isLoading: isLoadingSalesDept
  } = useQueryApi(`query/deptSales/core/0000`, defaultParams);

  const {
    data: swmDetailsData = [],
    isError: isErrorSwmDetails,
    isLoading: isLoadingSwmDetails
  } = useQueryApi('querySwmCore', defaultParams);

  const {
    data: SafetyIncOshaLtGlbiData = [],
    isError: isErrorSafetyIncOshaLtGlbi,
    isLoading: isLoadingSafetyIncOshaLtGlbi
  } = useQueryApi('querySafetyIncOshaLtGlbiByCore', defaultParams);
  const {
    data: InvDepByCore = [],
    isError: isErrorInvDepByCore,
    isLoading: isLoadingInvDepByCore
  } = useQueryApi('queryInvDeptByCore', defaultParams);

  const isError =
    isErrorSalesDept ||
    isErrorSalesClasses ||
    isErrorSalesSkus ||
    isErrorSalesSafetyByDivision ||
    isErrorSalesMetricsData ||
    isErrorSalesSubClasses ||
    isErrorSwmDetails ||
    isErrorSafetyIncOshaLtGlbi;
  const isLoading =
    isLoadingSalesDept ||
    isLoadingSalesClasses ||
    isLoadingSalesSkus ||
    isLoadingSalesSafetyByDivision ||
    isLoadingSalesMetricsData ||
    isLoadingSalesSubClasses ||
    isLoadingSwmDetails ||
    isLoadingSafetyIncOshaLtGlbi;

  return {
    isError,
    isLoading,
    salesClassesData,
    salesDeptData,
    salesMetricsData,
    salesSafetyByDivisionData,
    salesSkusData,
    salesSubClassesData,
    swmDetailsData,
    SafetyIncOshaLtGlbiData,
    InvDepByCore,
    isErrorInvDepByCore,
    isLoadingInvDepByCore
  };
};
