import { useState } from 'react';
import { UnitMenu } from '../../../Common/UnitMenu';
import { DataView } from '../../../Common/DataView';
import { SkuDetailsModal } from '../../../Sku/SkuDetailsModal';
import { NOM } from '../../../../utils/constants';
import { getSkusDataTable } from '../../utils/shrinkDept';

export const ShrinkDeptSkusTab = props => {
  const { shrinkActualsSkuDeptData } = props;
  const [unit, setUnit] = useState(NOM);
  const [dataModal, setDataModal] = useState(null);
  const isOpenModal = Boolean(dataModal);
  const onCloseModal = () => setDataModal(null);
  const skusDataTable = getSkusDataTable({ shrinkActualsSkuDeptData, unit, setDataModal });

  return (
    <>
      <DataView.Controls className='my-3'>
        <UnitMenu unit={unit} setUnit={setUnit} />
      </DataView.Controls>
      <DataView.DataTable data={skusDataTable} />
      {isOpenModal && <SkuDetailsModal isOpen={isOpenModal} onClose={onCloseModal} skuData={dataModal} />}
    </>
  );
};
