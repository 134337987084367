import { useMemo, useState } from 'react';
import { useShrinkContext } from '../../hooks/useShrinkContext';
import { useShrinkQueries } from '../../hooks/useShrinkQueries';
import { buildSkuTableHeaders, buildSkuTableRow } from '../../utils/sku';
import { DataTable } from '../../../Common/DataTable';
import { SkuDetailsModal } from '../../../Sku/SkuDetailsModal';
import { Tab } from 'semantic-ui-react';
import { UnitMenu } from '../../../Common/UnitMenu';

export const ShrinkSkusTab = () => {
  const [skuData, setSkuData] = useState(null);
  const [unit, setUnit] = useState('NOM');
  const { fpToggle } = useShrinkContext();
  const {
    results: [queryShrinkSkuByCls]
  } = useShrinkQueries({ fpToggle, queries: ['query/queryShrinkSkuByCls'] });
  const data = useMemo(() => {
    return queryShrinkSkuByCls.data?.map(row => buildSkuTableRow(row, unit, () => setSkuData(row))) || [];
  }, [queryShrinkSkuByCls.data, unit]);

  const isModalOpen = !!skuData;
  const dataObj = {
    data,
    headers: buildSkuTableHeaders(unit),
    pagination: true,
    sortable: true,
    sortableDefault: 0
  };

  return (
    <Tab.Pane attached={false}>
      <UnitMenu unit={unit} setUnit={setUnit} />

      <DataTable data={dataObj} />

      <SkuDetailsModal isOpen={isModalOpen} onClose={() => setSkuData(null)} skuData={skuData} />
    </Tab.Pane>
  );
};
