export const focus6Builder = (data = [], timeframe = '') => {
  const extractSalesData = row => {
    const actualSales = row[`${timeframe}_TY_SLS_AMT`] || 0;
    const plannedSales = row[`${timeframe}_SLS_PLN_AMT`] || 0;
    return { actualSales, plannedSales };
  };

  const top6Data = data.filter(row => row.TOP_6_CATEGORY <= 6);

  const formattedData = top6Data.map(row => {
    const { actualSales, plannedSales } = extractSalesData(row);
    const variance = actualSales - plannedSales;
    return [
      {
        link: {
          to: '/core/services/program',
          params: { smryPrgmNm: row.SUMMARY_PROGRAM_NAME }
        },
        stringValue: row.SUMMARY_PROGRAM_NAME || 'Unknown Program'
      },
      { dollarValue: actualSales },
      { dollarValue: variance },
      { pctValue: plannedSales > 0 ? variance / plannedSales : 0 }
    ];
  });

  const totalFocus6Programs = top6Data.reduce(
    (acc, row) => {
      const { actualSales, plannedSales } = extractSalesData(row);
      acc.actualSales += actualSales;
      acc.plannedSales += plannedSales;
      return acc;
    },
    { actualSales: 0, plannedSales: 0 }
  );

  const varianceFocus6 = totalFocus6Programs.actualSales - totalFocus6Programs.plannedSales;
  const compFocus6 = totalFocus6Programs.plannedSales > 0 ? varianceFocus6 / totalFocus6Programs.plannedSales : 0;

  const totalPrograms = data.reduce(
    (acc, row) => {
      const { actualSales, plannedSales } = extractSalesData(row);
      acc.actualSales += actualSales;
      acc.plannedSales += plannedSales;
      return acc;
    },
    { actualSales: 0, plannedSales: 0 }
  );

  const varianceTotalPrograms = totalPrograms.actualSales - totalPrograms.plannedSales;
  const compTotalPrograms = totalPrograms.plannedSales > 0 ? varianceTotalPrograms / totalPrograms.plannedSales : 0;

  const footerData = [
    [
      { stringValue: 'Total Focus 6 Programs' },
      { dollarValue: totalFocus6Programs.actualSales },
      { dollarValue: varianceFocus6 },
      { pctValue: compFocus6 }
    ],
    [
      { stringValue: 'Total Programs' },
      { dollarValue: totalPrograms.actualSales },
      { dollarValue: varianceTotalPrograms },
      { pctValue: compTotalPrograms }
    ]
  ];

  return {
    headers: [{ name: 'Program' }, { name: 'Actual ' }, { name: 'vP $ ' }, { name: 'Comp % ' }],
    data: formattedData,
    sortable: true,
    sortableDefault: 0,
    footer: footerData
  };
};
