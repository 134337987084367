import React from 'react';
import { Header, Tab } from 'semantic-ui-react';
import { OnTimePickup } from './OnTimePickup';
import { OnTimeDelivery } from './OnTimeDelivery';

export const OnTime = () => {
    const tabs = [
        {
            menuItem: 'PICKUP',
            render: () => <OnTimePickup />
        },
        {
            menuItem: 'DELIVERY',
            render: () => <OnTimeDelivery />
        }
    ]

    return (<>
        <Header as='h1'>Order Picked on Time</Header>
        <Tab 
            menu={{ compact: true, size: 'small' }}
            panes={tabs} 
        />
    </>);
};
