import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Grid, Loader, Segment } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../../../../../../components/Common/TimeframeDropdownMon';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { LeadsBySummaryProgram } from '../../components/leads/LeadsBySummaryProgram';
import { SumProNMView } from '../../components/leads/SumProNMView';
import { TotalLeadsCore } from '../../components/leads/TotalLeadsCore';
import { coreLeadsAdapter, isMonday, leadsBySumProgramAdapter, totalLeadsDataObjAdapter } from '../../utils/leads/lead';

export const CoreLeads = ({ timeframe, settimeframe, fpToggle }) => {
  const coreLead = useQueryApi('queryLeadsDtlByCore', { isCore: true, fpToggle });
  const [unit, setUnit] = useState('NOM');
  const [allWebStore, setAllWebStore] = useState(''); // All = empty string, Web = '_WEB', Store = '_STR'

  if (coreLead.isLoading) {
    return <Loader active>Loading...</Loader>;
  }
  const sumProgNmFilter = coreLead.data.filter(row => {
    return row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL';
  });
  const coreFilter = coreLead.data.filter(row => {
    return row && row['METRIC_LEVEL'] === 'CORE';
  });

  const totalLeadsDataObj = totalLeadsDataObjAdapter(timeframe, unit, sumProgNmFilter, allWebStore);
  const leadsBySumProgram = leadsBySumProgramAdapter(timeframe, coreFilter, allWebStore, unit);

  return (
    <>
      <>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/core/overview`} data-test='b2o-link'>
                Back to List
              </Link>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {isMonday ? (
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
              ) : (
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
              )}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>

        <Segment attached='bottom'>
          {
            <>
              <SumProNMView unit={unit} setUnit={setUnit} allWebStore={allWebStore} setAllWebStore={setAllWebStore} />
              <TotalLeadsCore totalRecords={coreLead.data} totalLeadsDataObj={totalLeadsDataObj} />
              <LeadsBySummaryProgram leadsBySumProgram={leadsBySumProgram} />
            </>
          }
        </Segment>
      </>
    </>
  );
};
