import { useQuery } from 'react-query';
import React, { useState } from 'react';
import { DistrictTab } from './DistrictTab';
import { StoreListTab } from './StoreListTab';
import { withRouter } from 'react-router-dom';
import { getData } from '../../service/DataService';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Grid, Header, Loader, Menu, Tab } from 'semantic-ui-react';

export const DstOverview = withRouter(
  ({ timeframe, settimeframe, userEmplCtgryNm, metric, setMetric, fpToggle, location }) => {
    const { showList } = useQueryParams();

    const [overviewMenu, setOverviewMenu] = useState(showList ? 'Stores' : 'District');
    const [sortColumn, setSortColumn] = useState('STR_NBR');
    const [sortDirection, setSortDirection] = useState('ascending');
    const [sortColumnDaysSafe, setSortColumnDaysSafe] = useState('STR_NBR');
    const [sortDirectionDaysSafe, setSortDirectionDaysSafe] = useState('ascending');

    const search = new URLSearchParams(location.search);
    const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0;

    const dstOverviewQuery = useQuery(['querySwmDstMetrics', { dstNbr, fpToggle }], getData);
    const slsDeptByDstQuery = useQuery(['querySlsDeptByDst', { dstNbr, fpToggle }], getData);
    const strMetricsByDstQuery = useQuery(['querySwmStrMetricsByDst', { dstNbr, fpToggle }], getData);
    const slsClsByDstQuery = useQuery(['querySlsClsByDst', { dstNbr, fpToggle }], getData);
    const slsSclsByDstQuery = useQuery(['querySlsSclsByDst', { dstNbr, fpToggle }], getData);
    const deptInvQuery = useQuery(['queryInvDeptByDst', { dstNbr, fpToggle }], getData);
    const daysSafeQuery = useQuery(['querySafetyIncOshaLtGlbiByDst', { dstNbr, fpToggle }], getData);
    const Top40Btm40Query = useQuery(['querySlsSkuByDst', { dstNbr, fpToggle }], getData);

    const metric2PathExtn = {
      Sales: 'overview',
      Units: 'overview',
      Transactions: 'overview',
      'Average Ticket': 'overview',
      'Units per Basket': 'overview',
      SMD: 'smd',
      Labor: 'labor',
      GET: 'ltsa',
      LTSA: 'ltsa',
      LTPA: 'voc',
      Leads: 'leads',
      Measures: 'meas',
      'HDPP - MA UA': 'overview',
      'HDPP - GM UA': 'overview'
    };

    // Mapping of metric to array of up to 5 possible column names
    const metric2SortColumn = {
      sales: ['_SLS_AMT_ACT', '_SLS_VP', '_SLS_AMT_VP_PCT', '_SLS_AMT_VLY', '_SLS_AMT_VLY_PCT'],
      units: ['_UNTS_ACT', '_UNTS_VLY', '_UNTS_VLY_PCT', '_UNTS_VLY', '_UNTS_VLY_PCT'],
      transactions: ['_TRNS_ACT', '_TRNS_VLY', '_TRNS_VLY_PCT', '_TRNS_VLY', '_TRNS_VLY_PCT'],
      average_ticket: ['_AVG_TCKT_ACT', '_AVG_TCKT_VLY', '_AVG_TCKT_VLY_PCT', '_AVG_TCKT_VLY', '_AVG_TCKT_VLY_PCT'],
      units_per_basket: ['_UPB', '_UPB_VLY', '_UPB_VLY_PCT', '_UPB_VLY', '_UPB_VLY_PCT'],
      smd: ['_SMD_ACT', '_SMD_VP', '_SMD_VP', '_SMD_VLY', '_SMD_VLY'],
      labor: ['_LBR_ACT', '_LBR_VF', '_LBR_VF_PCT', '_LBR_VF', '_LBR_VF_PCT'],
      get: ['_GET_ACT', '_GET_ACT', '_GET_ACT', '_GET_ACT', '_GET_ACT'],
      ltsa: ['_LTSA_ACT', '_LTSA_VLY', '_LTSA_VLY', '_LTSA_VLY', '_LTSA_VLY'],
      ltpa: ['_ONLINE_SAT_ACT', '_ONLINE_SAT_VLY', '_ONLINE_SAT_VLY', '_ONLINE_SAT_VLY', '_ONLINE_SAT_VLY'],
      leads: ['_LEADS_ACT', '_LEADS_VLY', '_LEADS_VLY_PCT', '_LEADS_VLY', '_LEADS_VLY_PCT'],
      measures: ['_MEAS_ACT', '_MEAS_VLY', '_MEAS_VLY_PCT', '_MEAS_VLY', '_MEAS_VLY_PCT'],
      'HDPP - MA UA': ['_MA_HDPP', '_MA_HDPP_VG', '_MA_HDPP_VG', '_MA_HDPP_VLY', '_MA_HDPP_VLY'],
      'HDPP - GM UA': ['_GM_HDPP', '_GM_HDPP_VG', '_GM_HDPP_VG', '_GM_HDPP_VLY', '_GM_HDPP_VLY']
    };

    if (
      dstOverviewQuery.isLoading ||
      slsDeptByDstQuery.isLoading ||
      strMetricsByDstQuery.isLoading ||
      slsClsByDstQuery.isLoading ||
      slsSclsByDstQuery.isLoading ||
      deptInvQuery.isLoading ||
      daysSafeQuery.isLoading ||
      Top40Btm40Query.isLoading
    ) {
      return <Loader active>Loading</Loader>;
    }
    if (!dstOverviewQuery.data[0]) {
      return <Header textAlign='center'>Invalid or Unauthorized District</Header>;
    }

    const dstMetrics = dstOverviewQuery.data[0];
    const dataSlsDeptByDst = slsDeptByDstQuery.data;
    const strMetricsByDst = strMetricsByDstQuery.data;
    const dataSlsClsByDst = slsClsByDstQuery.data;
    const dataSlsSclsByDst = slsSclsByDstQuery.data;
    const deptInventory = deptInvQuery.data;
    const daysSafe = daysSafeQuery.data;
    const districtNumber = String(dstMetrics['DST_NBR']).padStart(4, '0');
    const dstNm = dstMetrics['DST_NM'];

    let strMetricsSorted = strMetricsByDst.sort((left, right) => {
      let result = left[sortColumn] - right[sortColumn];
      if (sortColumn === timeframe + '_UPB') {
        result =
          left['TY_' + timeframe + '_UNT_SLS'] / left['TY_' + timeframe + '_TXN_CNT'] -
          right['TY_' + timeframe + '_UNT_SLS'] / right['TY_' + timeframe + '_TXN_CNT'];
      }
      if (sortColumn === timeframe + '_UPB_VLY') {
        result =
          left['TY_' + timeframe + '_COMP_UNT_SLS'] / left['TY_' + timeframe + '_COMP_TXN_CNT'] -
          left['LY_' + timeframe + '_COMP_UNT_SLS'] / left['LY_' + timeframe + '_COMP_TXN_CNT'] -
          (right['TY_' + timeframe + '_COMP_UNT_SLS'] / right['TY_' + timeframe + '_COMP_TXN_CNT'] -
            right['LY_' + timeframe + '_COMP_UNT_SLS'] / right['LY_' + timeframe + '_COMP_TXN_CNT']);
      }
      if (sortColumn === timeframe + '_UPB_VLY_PCT') {
        result =
          (left['TY_' + timeframe + '_COMP_UNT_SLS'] / left['TY_' + timeframe + '_COMP_TXN_CNT'] -
            left['LY_' + timeframe + '_COMP_UNT_SLS'] / left['LY_' + timeframe + '_COMP_TXN_CNT']) /
            (left['LY_' + timeframe + '_COMP_UNT_SLS'] / left['LY_' + timeframe + '_COMP_TXN_CNT']) -
          (right['TY_' + timeframe + '_COMP_UNT_SLS'] / right['TY_' + timeframe + '_COMP_TXN_CNT'] -
            right['LY_' + timeframe + '_COMP_UNT_SLS'] / right['LY_' + timeframe + '_COMP_TXN_CNT']) /
            right['LY_' + timeframe + '_COMP_UNT_SLS'] /
            right['LY_' + timeframe + '_COMP_TXN_CNT'];
      }
      if (sortDirection === 'descending') {
        result *= -1;
      }
      return result;
    });

    let daysSafeDstFilter = daysSafe.filter(row => {
      return row && row.METRIC_LEVEL === 'DST';
    });

    let daysSafeStrFilter = daysSafe.filter(row => {
      return row && row.METRIC_LEVEL === 'STR';
    });

    let daysSafeStrSorted = daysSafeStrFilter.sort((left, right) => {
      let result = left[sortColumnDaysSafe] - right[sortColumnDaysSafe];
      if (sortDirectionDaysSafe === 'descending') {
        result *= -1;
      }
      return result;
    });

    const panes = [
      {
        menuItem: 'District',
        render: () => (
          <DistrictTab
            dstNm={dstNm}
            dstNbr={dstNbr}
            timeframe={timeframe}
            dstMetrics={dstMetrics}
            settimeframe={settimeframe}
            deptInventory={deptInventory}
            districtNumber={districtNumber}
            userEmplCtgryNm={userEmplCtgryNm}
            dataSlsClsByDst={dataSlsClsByDst}
            dataSlsDeptByDst={dataSlsDeptByDst}
            dataSlsSclsByDst={dataSlsSclsByDst}
            daysSafeDstFilter={daysSafeDstFilter}
          />
        )
      },
      {
        menuItem: 'Store list',
        render: () => (
          <StoreListTab
            dstNbr={dstNbr}
            metric={metric}
            setMetric={setMetric}
            timeframe={timeframe}
            settimeframe={settimeframe}
            metric2PathExtn={metric2PathExtn}
            strMetricsSorted={strMetricsSorted}
            daysSafeStrSorted={daysSafeStrSorted}
            metric2SortColumn={metric2SortColumn}
          />
        )
      }
    ];

    return <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }}></Tab>;
  }
);
