import { DataView } from '../../Common/DataView';
import { useMemo, useState } from 'react';
import { UnitMenu } from '../../Common/UnitMenu';
import { Select } from '../../Common/Select';
import { footerTotal, headers, laborDataRow, laborFooterRow } from '../utils/specialityProRentalLabor';
import { Header } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';

export const SpecialtyProRentalLabor = ({ proSpecTrLbrData = [], queryProSpecTrLbrPlusOneData = [] }) => {
  const [unit, setUnit] = useState('NOM');
  const { timeframe } = useSpecialtyProRentalContext();
  const {
    level: { levelName }
  } = useSpecialtyProRentalContext();

  const items = useMemo(
    () =>
      proSpecTrLbrData
        .filter(dept => dept?.DEPT_NM)
        .map(dept => ({
          value: dept.DEPT_NM,
          text: dept.DEPT_NM
        }))
        .sort((a, b) => b.text.localeCompare(a.text)),
    [proSpecTrLbrData]
  );
  const [deptSelected, setDeptSelected] = useState('TOTAL');
  const deptFilteredList = useMemo(
    () => queryProSpecTrLbrPlusOneData.filter(row => row['DEPT_NM'] === deptSelected),
    [queryProSpecTrLbrPlusOneData, deptSelected]
  );
  const footer = useMemo(() => footerTotal(deptFilteredList, timeframe), [deptFilteredList, timeframe]);

  if (!proSpecTrLbrData.length || !queryProSpecTrLbrPlusOneData.length)
    return <Header textAlign='center'>No data to show in Labor.</Header>;

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers: headers(levelName, unit),
    footer: [laborFooterRow(footer, timeframe, unit)],
    data: deptFilteredList.map(row => laborDataRow(row, timeframe, levelName, unit))
  };

  return (
    <DataView className='my-4'>
      <DataView.Title position='center'>Labor</DataView.Title>

      <DataView.Controls className='d-flex justify-content-between mb-4'>
        <section className='select-section'>
          <label>Units:</label>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </section>
        <section style={{ textAlign: 'right' }}>
          <Select label='Dept:' name='dept' value={deptSelected} items={items} onChange={setDeptSelected} />
        </section>
      </DataView.Controls>

      <DataView.DataTable data={data} />
    </DataView>
  );
};
