import { useState } from 'react';
import useQueryApi  from '../../../hooks/useQueryApi';
import { useSpecialtyProRentalServicesContext } from '../hooks/useSpecialtyProRentalServicesContext';
import { ALL_PROGRAMS, FOCUS_6 } from '../utils/specialtyProRentalServices';
import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { Header, Loader, Grid, Segment } from 'semantic-ui-react';
import { LeadsMeasuresTable } from '../components/LeadsMeasuresTable';
import { Link } from '../../Common/Link';
import { ServicesPrgmSales } from '../components/ServicesPrgmSales';
import { TabMenu } from '../../Common/TabMenu';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { DataView } from '../../Common/DataView';
import { columnName, METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from '../../../utils/string';
import { ServicesPrgmPipeline } from '../components/ServicesPrgmPipeline';


export const SpecialtyProRentalServicesPrgmPage = () => {
  const { currentLevelName, fpToggle, level, locNbr, timeframe, settimeframe, smryPrgmNm } = useSpecialtyProRentalServicesContext();
  const queryParams = { locNbr,smryPrgmNm, fpToggle };
  const {
    data,
    isLoading,
    isError
  } = useQueryApi(`queryServicesDtlPrgmByLevel/${level}`,queryParams);
  const paramByLevel = getParamByLevel(level);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError || !data[0]) {
    return (<Header textAlign='center'>{`Invalid or Unauthorized ${capitalizeFirstLetter(level)} Number`}</Header>)
  }
  // Process data at district level
  let dataFilter = data.find(row => row.METRIC_LEVEL === METRIC_CONSTANTS[level]['METRIC_LEVEL'])

  return (
    <>
      <DataView className='mt-4'>
        <DataView.Title textAlign='center'>{dataFilter.MER_DEPT_CD}-{dataFilter.SUMMARY_PROGRAM_NAME} - {capitalizeFirstLetter(level)} #{locNbr}</DataView.Title>
        <DataView.Controls>
          <Grid>
            <Grid.Column width={8}>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Link to={`/${level}/new-services?${paramByLevel}=${locNbr}`}>Back to Services</Link>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={8} textAlign='right'>
              Timeframe:
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
              &nbsp;
            </Grid.Column>
          </Grid>
        </DataView.Controls>
      </DataView>
      <TabMenu
        className='mb-4'
        color='orange'
        panes={[
          {
            menuItem: 'Sales',
            render: () => <ServicesPrgmSales data={data} />
          },
          {
            menuItem: 'Pipeline',
            render: () => <ServicesPrgmPipeline data={data} />
          }
        ]}
      />

    </>
  );
};
