import { useContext, useState } from "react";
import { DataView } from "../../../../../../components/Common/DataView";
import { OrderFulfillmentContext } from "../../../../context/OrderFulfillmentContext";
import { Dropdown } from "semantic-ui-react";
import { METRIC_CONSTANTS } from "../../../../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from "../../../../../../utils/string";
import { getPercentString } from "../../../../../../components/Common/TableCellUtils";

export const CompletePickUp = () => {

  const { orderFulfillmentOnTimePickupData, level, timeframe } = useContext(OrderFulfillmentContext)

  const [view, setView] = useState('Total')
  const getData = () => {
    switch (view) {
      case 'Total':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Fulfillment %' },
            { name: 'Units Unfilfilled' },
            { name: 'NIS Units' },
            { name: '$ Unfulfilled' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            { numValue: data[timeframe + '_TRANS_CNT'] },
            {
              numValue: data[timeframe + '_TRANS_CNT']
            },
            {
              dollarValue: data[timeframe + '_UNT_SLS_AMT'],
              orderBy: data[timeframe + '_UNT_SLS_AMT']
            }
          ])
        };
      case 'Will Call':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Fulfillment %' },
            { name: 'Units Unfulfilled' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            { stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']) },
            { numValue: data[timeframe + '_RLSD_ORDERS'] },
          ])
        };
      case 'BOPIS':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Fulfillment %' },
            { name: 'Units Unfilfilled' },
            { name: 'NIS Units' },
            { name: '$ Unfulfilled' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            { numValue: data[timeframe + '_TRANS_CNT'] },
            {
              numValue: data[timeframe + '_TRANS_CNT']
            },
            {
              dollarValue: data[timeframe + '_UNT_SLS_AMT'],
              orderBy: data[timeframe + '_UNT_SLS_AMT']
            }
          ])
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <DataView className='mt-2'>
      <DataView.Controls>
        <div>Select Method</div>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={[
            { key: 'Total', text: 'Total', value: 'Total' },
            { key: 'BOPIS', text: 'BOPIS', value: 'BOPIS' },
            { key: 'Will Call', text: 'Will Call', value: 'Will Call' },
          ]}
          onChange={(_, { value }) => {
            setView(value);
          }}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Pickup Details by Method {view}</DataView.Title>
      <DataView.DataTable data={getData()} />
    </DataView>
  );
}