import { useContext } from 'react'
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext'
import { DataTable } from '../../../../../../components/Common/DataTable'
import { CustomDropDown } from '../CustomDropdown';

export default function OnTimeDeliveryMissed() {
  const { OnTimeMissedData } = useContext(StoreOrderFulfillmentContext);

  const dataObj = {
    headers: [
      { name: 'Truck Type' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'Delivery Miss Bucket' },
      { name: 'Miss Code' },
      { name: 'Miss Date' },
    ],
    data: OnTimeMissedData.map(data => [
      { stringValue: data.TRUCK },
      { numValue: data.ORDER_NOM },
      { numValue: data.WRK_ORDER_NOM },
      { stringValue: data.DLV_MISS_BUCKET },
      { stringValue: data.MISS_CODE },
      { stringValue: data.MISS_DATE },
    ])
  };

  return (
    <>
      <CustomDropDown
        view='ShowDropDown'
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' },
        ]}
        showCustomTimeframe={['ShowDropDown']}
      />
      <DataTable data={dataObj} />
    </>
  );
}
