import { getThousandDollarString } from '../../../../../../components/Common/TableCellUtils';

export const coreInventorySubClassDrillBuilder = drillSubClasses => {
  const dataObj = {
    data: drillSubClasses.map(data => [
      { stringValue: data['SKU_NBR'], orderBy: data['SKU_NBR'] },
      { stringValue: data['SKU_DESC'], orderBy: data['SKU_DESC'] },
      { stringValue: getThousandDollarString(data['INV_AMT']), orderBy: data['INV_AMT'] },
      { stringValue: getThousandDollarString(data['ASW']), orderBy: data['ASW'] },
      { stringValue: data['WOS'].toLocaleString(undefined, { maximumFractionDigits: 1 }), orderBy: data['WOS'] },
      { stringValue: getThousandDollarString(data['ICE_INV_AMT']), orderBy: data['ICE_INV_AMT'] }
    ]),
    headers: [
      { name: 'SKU # ' },
      { name: 'SKU Desc ' },
      { name: 'Inv $ ' },
      { name: 'ASW ' },
      { name: 'WOS ' },
      { name: 'ICE $ ' }
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };
  return { dataObj };
};
