import React, { useState, useEffect } from 'react'
import {
  Table,
  Loader,
  Header,
  Segment,
  Grid,
  Icon,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { UnitMenu } from '../Common/UnitMenu'
import { getPercentString } from '../Common/TableCellUtils'
import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

export const LaborDetailMgrDeptByDst = withRouter(({ timeframe, settimeframe, fpToggle, location }) => {

    const search = new URLSearchParams(location.search)
    const deptNbrInt = search.has("deptNbr") ? search.get("deptNbr") : 0;
    const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
    const [unit, setUnit] = useState('NOM')
    const [unitCXM, setUnitCXM] = useState('NOM')
    const [unitNRM, setUnitNRM] = useState('NOM')
    const [sortColumnCXM, setSortColumnCXM] = useState("STR_NBR")
    const [sortDirectionCXM, setSortDirectionCXM] = useState("ascending")
    const [sortColumnNRM, setSortColumnNRM] = useState("STR_NBR")
    const [sortDirectionNRM, setSortDirectionNRM] = useState("ascending")
    const [flagVal, setFlagVal] = useState("WTD")
    
    const FlagSwitch = (timeframe) => {
      switch(timeframe) {
        case "WTD":
          return "WTD_FLG";
        case "LW":
          return "LW_FLG";
        case "LM":
          return "LM_FLG";
        case "MTD":
          return "MTD_FLG";
        case "QTD":
          return "QTD_FLG";
        case "HTD":
          return "HTD_FLG";
        case "YTD":
         return "YTD_FLG";
        case "L12":
          return "L12_FLG";
        default:
          return "WTD_FLG";
      }
    }

    const laborDetailDept = useQuery(['queryLaborDtlByMgrDeptStrDsts', { dstNbr, deptNbrInt, fpToggle }], getData)

    useEffect(() => {
      setFlagVal(FlagSwitch(timeframe))
    },[timeframe])

    if (laborDetailDept.isLoading) {
      return <Loader active>Loading...</Loader>
    }
  
    const deptNm = laborDetailDept.data[0].DEPT_NM

    let laborDetailDeptFilter = laborDetailDept.data.filter((row) => {
      return row[flagVal] === 1
    })

    let updatedResultStr = laborDetailDeptFilter.reduce((acc, curr) =>{
      let item = acc.find(item => item.STR_NBR === curr.STR_NBR && item.classification === curr.CLASSIFICATION);
      if (item) {
        item.ACTUAL_HOURS += curr.ACTUAL_HOURS
        item.OVERTIME_HOURS += curr.OVERTIME_HOURS
        item.SCHEDULED_HOURS += curr.SCHEDULED_HOURS
        item.AvS = item.ACTUAL_HOURS - item.SCHEDULED_HOURS
        item.AvSPCT = (item.ACTUAL_HOURS - item.SCHEDULED_HOURS)/item.SCHEDULED_HOURS
        item.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS
      } else {
        acc.push({
          STR_NBR: curr.STR_NBR,
          ACTUAL_HOURS: curr.ACTUAL_HOURS,
          OVERTIME_HOURS: curr.OVERTIME_HOURS,
          SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
          AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
          classification: curr.CLASSIFICATION,
          AvSPCT : (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS)/curr.SCHEDULED_HOURS,
          PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS,
        });
      }
      return acc;
    },[]);

    let updatedResultMgrOverView = laborDetailDeptFilter    
    .reduce((acc, curr) => {
      const classification = curr.CLASSIFICATION === 'Customer Experience Manager' ? 'CXM' : 'NRM'
      let item = acc.find(item => item.CLASSIFICATION === classification);
 
      if(item) {
        item.ACTUAL_HOURS += curr.ACTUAL_HOURS
        item.OVERTIME_HOURS += curr.OVERTIME_HOURS
        item.SCHEDULED_HOURS += curr.SCHEDULED_HOURS
        item.AvS = item.ACTUAL_HOURS - item.SCHEDULED_HOURS
        item.AvSPCT = (item.ACTUAL_HOURS - item.SCHEDULED_HOURS)/item.SCHEDULED_HOURS
        item.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS
      } else {
        acc.push({
          CLASSIFICATION: classification,
          ACTUAL_HOURS: curr.ACTUAL_HOURS,
          OVERTIME_HOURS: curr.OVERTIME_HOURS,
          SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
          AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
          AvSPCT : (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS)/curr.SCHEDULED_HOURS,
          PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS,
        })
      }

      item = acc.find(item => item.CLASSIFICATION === 'Total' );
      if(item) {
        item.ACTUAL_HOURS += curr.ACTUAL_HOURS
        item.OVERTIME_HOURS += curr.OVERTIME_HOURS
        item.SCHEDULED_HOURS += curr.SCHEDULED_HOURS
        item.AvS = item.ACTUAL_HOURS - item.SCHEDULED_HOURS
        item.AvSPCT = (item.ACTUAL_HOURS - item.SCHEDULED_HOURS)/item.SCHEDULED_HOURS
        item.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS
      } else {
        acc.push({
          CLASSIFICATION: 'Total',
          ACTUAL_HOURS: curr.ACTUAL_HOURS,
          OVERTIME_HOURS: curr.OVERTIME_HOURS,
          SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
          AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
          AvSPCT : (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS)/curr.SCHEDULED_HOURS,
          PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS,
        })
      }
      return acc
    }, [])

    var classificationEnum = {
        'CXM': 0,
        'NRM': 1,
        'Total': 2,
      }

    let laborOverviewDataSort = updatedResultMgrOverView.sort((left, right) => {
        let result = classificationEnum[left['CLASSIFICATION']] > classificationEnum[right['CLASSIFICATION']] ? 1 : -1
        return result
    })

    let laborDetailCXMFilter = updatedResultStr.filter((row) => {
        return row['classification'] === "Customer Experience Manager"
    })

    let laborDetailNRMFilter = updatedResultStr.filter((row) => {
        return row['classification'] === "Night Replenishment Manager"
    })

    let laborDetailCXM = laborDetailCXMFilter.sort((left, right) => {
        let result = left[sortColumnCXM] - right[sortColumnCXM]
        if (sortColumnCXM === 'STR_NBR') {
          result = left.STR_NBR > right.STR_NBR ? 1 : -1
        } 
        if (sortDirectionCXM === 'descending') {
          result *= -1
        }
        return result
      })

      let laborDetailNRM = laborDetailNRMFilter.sort((left, right) => {
        let result = left[sortColumnNRM] - right[sortColumnNRM]
        if (sortColumnNRM === 'STR_NBR') {
          result = left.STR_NBR > right.STR_NBR ? 1 : -1
        } 
        if (sortDirectionNRM === 'descending') {
          result *= -1
        }
        return result
      })

    return (
        <Segment>
        {/*////////////////////////////  Labor Overview         /////////*/}
         <Grid>
        <Grid.Column width={7}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/district/overview?dstNbr=${deptNbrInt}`} >Back to Overview</Link>
            {' / '}
        <Link style={{ color: '#EE7125' }} to={`/district/labor?dstNbr=${dstNbr}`} >Labor Overview</Link>
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={9}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {new Date().getDay() === 1 ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>Labor Overview - {deptNm} </Header>
          <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell />
            <Table.HeaderCell>Hours</Table.HeaderCell>
            {timeframe === "WTD" && new Date().getDay() !== 1 && <Table.HeaderCell>PROJ O/T</Table.HeaderCell>}
            {timeframe === "WTD" && new Date().getDay() === 1 && <Table.HeaderCell>O/T</Table.HeaderCell>}
            {timeframe !== "WTD" &&<Table.HeaderCell>O/T</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell>AvS</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>AvS %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {laborOverviewDataSort.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data.CLASSIFICATION}</Table.Cell>
                <Table.Cell>{(data.ACTUAL_HOURS ? data.ACTUAL_HOURS: 0).toLocaleString(undefined, { maximumFractionDigits: 0})}</Table.Cell>
                {timeframe !== "WTD" && <Table.Cell>{(data.OVERTIME_HOURS ? data.OVERTIME_HOURS : 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Table.Cell>}
                {timeframe === "WTD" && new Date().getDay() === 1 &&<Table.Cell>{(data.OVERTIME_HOURS ? data.OVERTIME_HOURS : 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Table.Cell>}
                {timeframe === "WTD" && new Date().getDay() !== 1 &&<Table.Cell>{(data.PROJECTED_OVERTIME_HOURS ? data.PROJECTED_OVERTIME_HOURS : 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Table.Cell>}
                {unit === 'NOM' && <Table.Cell>{(data.AvS ? data.AvS : 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Table.Cell>}
                {unit === 'PCT' && <Table.Cell>{getPercentString((data.AvSPCT ? data.AvSPCT : ''), 2)}</Table.Cell>}
              </Table.Row>
            )
          })}  
        </Table.Body>
      </Table>


    {/*////////////////////////////  Labor Details CXM         /////////*/}

    <Grid>
        <Grid.Column width={7}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unitCXM} setUnit={setUnitCXM} />
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>Labor Details - {deptNm} - CXM </Header>
          <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
         <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnCXM === 'STR_NBR' ? sortDirectionCXM : null}
              onClick={() => {
                if (sortColumnCXM !== 'STR_NBR') {
                  setSortColumnCXM('STR_NBR')
                  setSortDirectionCXM('ascending')
                } else {
                    sortDirectionCXM === 'ascending' ? setSortDirectionCXM('descending') : setSortDirectionCXM('ascending')
                }
              }}>Store {sortColumnCXM !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell>Hours</Table.HeaderCell>
            {timeframe === "WTD" && new Date().getDay() !== 1 && <Table.HeaderCell>PROJ O/T</Table.HeaderCell>}
            {timeframe === "WTD" && new Date().getDay() === 1 && <Table.HeaderCell>O/T</Table.HeaderCell>}
            {timeframe !== "WTD" &&<Table.HeaderCell>O/T</Table.HeaderCell>}
            {unitCXM === 'NOM' && <Table.HeaderCell>AvS</Table.HeaderCell>}
            {unitCXM === 'PCT' && <Table.HeaderCell>AvS %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {laborDetailCXM.map((data) => {
            return (
              <Table.Row>
                <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/labor?strNbr=${data['STR_NBR']}`}>{data.STR_NBR}</Link></Table.Cell>
                <Table.Cell>{(data.ACTUAL_HOURS ? data.ACTUAL_HOURS : 0).toLocaleString(undefined, { maximumFractionDigits: 0})}</Table.Cell>
                {timeframe !== "WTD" && <Table.Cell>{(data.OVERTIME_HOURS ? data.OVERTIME_HOURS : 0).toLocaleString(undefined, { maximumFractionDigits: 2})}</Table.Cell>}
                {timeframe === "WTD" && new Date().getDay() === 1 && <Table.Cell>{(data.OVERTIME_HOURS ? data.OVERTIME_HOURS : 0).toLocaleString(undefined, { maximumFractionDigits: 2})}</Table.Cell>}
                {timeframe === "WTD" && new Date().getDay() !== 1 && <Table.Cell>{(data.PROJECTED_OVERTIME_HOURS ? data.PROJECTED_OVERTIME_HOURS : 0).toLocaleString(undefined, { maximumFractionDigits: 2})}</Table.Cell>}
                {unitCXM === 'NOM' && <Table.Cell>{(data.AvS ? data.AvS : 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Table.Cell>}
                {unitCXM === 'PCT' && <Table.Cell>{getPercentString((data.AvSPCT ? data.AvSPCT : ''), 2)}</Table.Cell>}
              </Table.Row>
            )
          })}  
        </Table.Body>
      </Table>

    
    {/*////////////////////////////  Labor Details NRM         /////////*/}

    <Grid>
        <Grid.Column width={7}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unitNRM} setUnit={setUnitNRM} />
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>Labor Details - {deptNm} - NRM </Header>
          <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
         <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnNRM === 'STR_NBR' ? sortDirectionNRM : null}
              onClick={() => {
                if (sortColumnNRM !== 'STR_NBR') {
                  setSortColumnNRM('STR_NBR')
                  setSortDirectionNRM('ascending')
                } else {
                  sortDirectionNRM === 'ascending' ? setSortDirectionNRM('descending') : setSortDirectionNRM('ascending')
                }
              }}>Store {sortColumnNRM !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell>Hours</Table.HeaderCell>
            {timeframe === "WTD" && new Date().getDay() !== 1 && <Table.HeaderCell>PROJ O/T</Table.HeaderCell>}
            {timeframe === "WTD" && new Date().getDay() === 1 && <Table.HeaderCell>O/T</Table.HeaderCell>}
            {timeframe !== "WTD" &&<Table.HeaderCell>O/T</Table.HeaderCell>}
            {unitNRM === 'NOM' && <Table.HeaderCell>AvS</Table.HeaderCell>}
            {unitNRM === 'PCT' && <Table.HeaderCell>AvS %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {laborDetailNRM.map((data) => {
            return (
              <Table.Row>
                <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/labor?strNbr=${data['STR_NBR']}`}>{data.STR_NBR}</Link></Table.Cell>
                <Table.Cell>{(data.ACTUAL_HOURS ? data.ACTUAL_HOURS: 0).toLocaleString(undefined, { maximumFractionDigits: 0})}</Table.Cell>
                {timeframe !== "WTD" && <Table.Cell>{(data.OVERTIME_HOURS ? data.OVERTIME_HOURS: 0).toLocaleString(undefined, { maximumFractionDigits: 2})}</Table.Cell>}
                {timeframe === "WTD" && new Date().getDay() === 1 && <Table.Cell>{(data.OVERTIME_HOURS ? data.OVERTIME_HOURS: 0).toLocaleString(undefined, { maximumFractionDigits: 2})}</Table.Cell>}
                {timeframe === "WTD" && new Date().getDay() !== 1 && <Table.Cell>{(data.PROJECTED_OVERTIME_HOURS ? data.PROJECTED_OVERTIME_HOURS: 0).toLocaleString(undefined, { maximumFractionDigits: 2})}</Table.Cell>}
                {unitNRM === 'NOM' && <Table.Cell>{(data.AvS ? data.AvS : 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}</Table.Cell>}
                {unitNRM === 'PCT' && <Table.Cell>{getPercentString((data.AvSPCT ? data.AvSPCT : ''), 2)}</Table.Cell>}
              </Table.Row>
            )
          })}  
        </Table.Body>
      </Table>
    </Segment>
    )
})


