import { useContext } from 'react';
import { SpecialtyProRentalContext } from '../context/SpecialtyProRentalContext';

export const useSpecialtyProRentalContext = () => {
  const context = useContext(SpecialtyProRentalContext);

  if (context === undefined) {
    throw new Error('useSpecialtyProRentalContext must be used within a SpecialtyProRentalProvider');
  }

  return context;
};
