import React from 'react';
import { Header, Tab } from 'semantic-ui-react';
import { BopisComplete } from './assoc/BopisComplete';
import { WillCallComplete } from './assoc/WillCallComplete';
import { PickUpDetailsMethod } from './PickUpDetailsMethod';

const panes = [
    {
        menuItem: 'BOPIS',
        render: () => <BopisComplete />
    },
    {
        menuItem: 'WILL CALL',
        render: () => <WillCallComplete />
    }
]

export const CompletePickup = () => {
    return (<>
        <PickUpDetailsMethod />
        <Header as='h1'>More Details</Header>
        <Tab
            menu={{ compact: true, size: 'small' }}
            panes={panes}
        />
    </>);
};



