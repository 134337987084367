import { Tab } from 'semantic-ui-react';
import { useMemo, useState } from 'react';
import { coreTop40SkuBuilder } from '../utils/coreTop40SkuBuilder';
import { DataView } from '../../../../../components/Common/DataView';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { SkuDetailsModal } from '../../../../../components/Sku/SkuDetailsModal';

export const CoreTop40Sku = ({ top40SkuData = [] }) => {
  const [unit, setUnit] = useState('NOM');
  const [modalData, setModalData] = useState(null);
  const isUnitNom = unit === 'NOM';

  const dataTableData = useMemo(
    () => coreTop40SkuBuilder(top40SkuData, isUnitNom, unit, setModalData),
    [top40SkuData, isUnitNom, unit, setModalData]
  );

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Title textAlign='center'>Top 40 Shrink Skus</DataView.Title>

        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />
          <p style={{ marginTop: '20px', marginBottom: '14px' }}>
            * Top 40 SKUs are ranked by Shrink rate (vLY $ or vLY % depending on unit selection)
          </p>
        </DataView.Controls>

        <DataView.DataTable data={dataTableData} />
      </DataView>

      <SkuDetailsModal isOpen={!!modalData} onClose={() => setModalData(null)} skuData={modalData} />
    </Tab.Pane>
  );
};
