import { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useFetchLevelName } from '../../../hooks/useFetchLevelName';
import { Loader } from 'semantic-ui-react';

const SpecialtyProRentalServicesContext = createContext();

const SpecialtyProRentalServicesProvider = ({ children, fpToggle, timeframe, settimeframe }) => {
  const { level } = useParams();
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0, smryPrgmNm = '' } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const { currentLevelName, isLoadingName: isLoadingCurrentLevelName } = useFetchLevelName({
    level,
    locNbr,
    fpToggle
  });

  if (isLoadingCurrentLevelName) return <Loader active>Loading...</Loader>;

  const value = {
    currentLevelName,
    fpToggle,
    level,
    locNbr,
    settimeframe,
    timeframe,
    smryPrgmNm
  };

  return <SpecialtyProRentalServicesContext.Provider value={value}>{children}</SpecialtyProRentalServicesContext.Provider>;
};

export { SpecialtyProRentalServicesContext, SpecialtyProRentalServicesProvider };
