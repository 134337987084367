import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';

export const LTPA = 'LTPA';
export const PRODUCT_CONDITION = 'Product Condition';
export const ORDER_ACCURACY = 'Order Accuracy';
export const WAIT_TIME = 'Reasonable Wait Time';

export const SATISFACTION_SCORE = 'SATISFACTION_SCORE';
export const PRODUCT_CONDITION_RATING = 'PRODUCT_CONDITION_RATING';
export const ORDER_FULFILLMENT_ACCURACY = 'ORDER_FULFILLMENT_ACCURACY';
export const WAIT_TIME_5 = 'WAIT_TIME_5';

export const BOSS = 'BOSS';
export const BOPIS = 'BOPIS';
export const MIXED = 'MIXED';
export const TOTAL = 'TOTAL';

export const ProdCond = 'ProdCond';
export const OrderAcc = 'OrderAcc';
export const WaitTm = 'WaitTm';

export const VOC_ROWS = [LTPA, PRODUCT_CONDITION, ORDER_ACCURACY, WAIT_TIME];
export const VOC_PROPS_BY_ROWS = [SATISFACTION_SCORE, PRODUCT_CONDITION_RATING, ORDER_FULFILLMENT_ACCURACY, WAIT_TIME_5];

export const osaImaginBreadcrumbItems = [{ to: '/core/overview', text: 'Overview' }, { text: 'LTPA' }];

export const vocOrdersReducer = dataFilter =>
  dataFilter.reduce((acc, row) => {
    const orderType = row['ORDER_TYPE'];
    if (!acc[orderType]) {
      acc[orderType] = [];
    }
    acc[orderType].push(row);
    return acc;
  }, {});

export const rowByColumn = (column, indexRow) => (column.length > 0 ? column[0][VOC_PROPS_BY_ROWS[indexRow]] : '-');

export const onlineVocDropdownItems = [
  { key: LTPA, text: LTPA, value: LTPA },
  { key: ProdCond, text: PRODUCT_CONDITION, value: ProdCond },
  { key: OrderAcc, text: ORDER_ACCURACY, value: OrderAcc },
  { key: WaitTm, text: WAIT_TIME, value: WaitTm }
];

export const getColumnsByMetric = (bopis, boss, mixed, totalItem, metric, level) => {
  const metricMapping = {
    LTPA: SATISFACTION_SCORE,
    ProdCond: PRODUCT_CONDITION_RATING,
    OrderAcc: ORDER_FULFILLMENT_ACCURACY,
    WaitTm: WAIT_TIME_5
  };

  const columnKey = metricMapping[metric];
  if (!columnKey) return [{ stringValue: '-' }, { stringValue: '-' }, { stringValue: '-' }, { stringValue: '-' }];

  const getMetricValue = dataset => {
    const item = dataset.find(
      row => row[METRIC_CONSTANTS[level]['propToRender']] === totalItem[METRIC_CONSTANTS[level]['propToRender']]
    );
    return item?.[columnKey] || null;
  };

  const bopisValue = getMetricValue(bopis);
  const bossValue = getMetricValue(boss);
  const mixedValue = getMetricValue(mixed);
  const totalValue = totalItem[columnKey];

  return [{ pctValue: bopisValue }, { pctValue: bossValue }, { pctValue: mixedValue }, { pctValue: totalValue }];
};

export const setColumnDrill = ({ total, bopis, boss, mixed, metric, level }) =>
  total.map(totalItem => [
    {
      stringValue: totalItem[METRIC_CONSTANTS[level]['propToRender']],
      link: {
        to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/voc`,
        params: {
          [METRIC_CONSTANTS[level]['urlSubLevelParam']]: String(totalItem[METRIC_CONSTANTS[level]['propToRender']]).padStart(
            4,
            '0'
          )
        }
      }
    },
    ...getColumnsByMetric(bopis, boss, mixed, totalItem, metric, level)
  ]);
