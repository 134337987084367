import { aggregateObjectKeys } from "../../../utils/array";
import { METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from "../../../utils/string";
import { getParamByLevel } from "../../../utils/timeFrameUtils";
import { getCalloutStyle, getCompareStyle } from "../../Common/TableCellUtils";

export const buildFooter = (data, timeframe) => {
  const total = aggregateObjectKeys(data);
  return [
    [
      {
        name: 'total',
        stringValue: 'Total'
      },
      {
        name: 'actual',
        dollarValue: total[timeframe + '_TY_SLS_AMT']
      },
      {
        name: 'vp_dollar',
        dollarValue: total[timeframe + '_TY_SLS_AMT'] - total[timeframe + '_SLS_PLN_AMT']
      },
      {
        name: 'comp',
        pctValue:
          (total[timeframe + '_TY_COMP_SLS_AMT'] - total[timeframe + '_LY_COMP_SLS_AMT']) /
          total[timeframe + '_LY_COMP_SLS_AMT']
      }
    ]
   
  ]
}

export const buildHeaders = (level) => [{ name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel'])} #` }, { name: 'Actual' }, { name: 'vP $' }, { name: 'Comp %' }]

export const buildRows = (data, level, locNbr, timeframe) => {
  const paramByLevel = getParamByLevel(level);

  return data.map(row => {
    const METRIC_LEVEL = METRIC_CONSTANTS[level];
    return [
      {
        link: {
          to: `/${METRIC_LEVEL['urlLinkLevel']}/new-services`,
          params: {
            [METRIC_LEVEL['urlSubLevelParam']]: row[METRIC_LEVEL['propToRender']]
          }
        },
        stringValue: `${METRIC_LEVEL['FILTER_BY_METRIC']} ${row[METRIC_LEVEL['propToRender']]}`
      },
      {
        customStyle: getCalloutStyle(row[timeframe + '_TY_SLS_AMT']),
        dollarValue: row[timeframe + '_TY_SLS_AMT']
      },
      {
        customStyle: getCompareStyle(row[timeframe + '_TY_SLS_AMT'] - row[timeframe + '_SLS_PLN_AMT']),
        dollarValue: row[timeframe + '_TY_SLS_AMT'] - row[timeframe + '_SLS_PLN_AMT']
      },
      {
        customStyle: getCompareStyle(row[timeframe + '_TY_COMP_SLS_AMT'] - row[timeframe + '_LY_COMP_SLS_AMT']),
        pctValue:
          (row[timeframe + '_TY_COMP_SLS_AMT'] - row[timeframe + '_LY_COMP_SLS_AMT']) / row[timeframe + '_LY_COMP_SLS_AMT']
      }
    ];
  })
}
