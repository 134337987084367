import { Grid, Header, Tab } from 'semantic-ui-react';
import { TimeframeDropdownShrink } from '../../../../../components/Common/TimeframeDropdownShrink';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { AccuracyAndReceivingCore } from './AccuracyAndReceivingCore';
import { DivisionList } from './DivisionList';
import { FocusMetricsCore } from './FocusMetricsCore';
import { MainCoreTab } from './MainCoreTab';
import { MonitorMetricsCore } from './MonitorMetricsCore';

export const DriversCoreContainers = ({ shrinkMetricsData, scoresData, queryACCRCVByLevel, headTitle }) => {
  const { timeframe, settimeframe } = useShrinkCoreContext();

  const panes = [
    {
      menuItem: 'CORE',
      render: () => <MainCoreTab execScoreData={scoresData} />
    },
    {
      menuItem: 'DIVISION LIST',
      render: () => <DivisionList />
    }
  ];

  return (
    <>
      <Header className='text-capitalize'>
        {headTitle}
        <Header.Subheader>Data as of {shrinkMetricsData[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />
      <FocusMetricsCore scoresData={scoresData} shrinkMetricsData={shrinkMetricsData[0]} />
      <MonitorMetricsCore shrinkMetricsData={shrinkMetricsData[0]} />
      <AccuracyAndReceivingCore queryACCRCVByLevel={queryACCRCVByLevel} />
    </>
  );
};
