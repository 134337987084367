import { useContext } from 'react';
import { Header } from 'semantic-ui-react';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { OnTimeDeliveryAssoc } from './OnTimeDeliveryAssoc';

export const OnTimeDelivery = () => {

    const { orderDeliveryData } = useContext(StoreOrderFulfillmentContext);

    const dataObj = {
        headers: [
            { name: 'Truck Type' },
            { name: 'Pick on Time %' },
            { name: 'Delivery Misses' },
            { name: 'Delivery Misses %' },
        ],
        data: orderDeliveryData.map(data => [
            { stringValue: data.TRUCK },
            { pctValue: data.PICK_ON_TIME },
            { numValue: data.DLV_MISSES },
            { pctValue: data.DLV_MISSES_PCT }
        ])
    }
    return (<>
        <Header as='h1'>Delivery</Header>
        <DataTable data={dataObj} />
        <Header as='h1'>Delivery On Time</Header>
        <OnTimeDeliveryAssoc />
    </>);
};