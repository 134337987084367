import { useState } from "react";
import { Dropdown, Grid } from "semantic-ui-react";

export const CustomDropDown = ({ viewOptions, showCustomTimeframe, timeframeOptions, view, setView }) => {

  const [timeframe, settimeframe] = useState('WTD')
  const showCustomDropDown = showCustomTimeframe.includes(view)
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          {viewOptions ? (<>
            View < br />
            <Dropdown
              compact
              defaultValue={view}
              selection
              scrolling
              options={viewOptions}
              onChange={(_, { value }) => {
                setView(value);
              }}
            />
          </>) : null}
        </Grid.Column>
        <Grid.Column verticalAlign="bottom" textAlign="right">
          {
            showCustomDropDown
              ?
              <Dropdown
                compact
                defaultValue={timeframe}
                selection
                scrolling
                options={timeframeOptions}
                onChange={(_, { value }) => {
                  settimeframe(value);
                }}
              />
              : null
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
