import { getThousandDollarString } from '../../../../../../components/Common/TableCellUtils';

export const coreInventoryDrillBuilder = (classInventory, isPct, isNom) => {
  const dataObj = {
    data: classInventory.map(row => [
      {
        link: {
          to: `/core/inventory/dept/class?deptNbr=${row['DEPT_NBR']}&classNbr=${row['EXT_CLASS_NBR']}`
        },
        stringValue: row['CLASS_NM']
      },
      {
        orderBy: row['INV_AMT'],
        stringValue: getThousandDollarString(row['INV_AMT'])
      },
      {
        compareStyle: true,
        orderBy: isNom ? row['INV_VP'] : row['INV_VP_PCT'],
        pctValue: isPct ? row['INV_VP_PCT'] : undefined,
        stringValue: isNom ? getThousandDollarString(row['INV_VP']) : undefined
      },
      {
        orderBy: row['ASW'],
        stringValue: getThousandDollarString(row['ASW'])
      },
      {
        stringValue: row['WOS'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }),
        orderBy: row['WOS']
      },
      {
        orderBy: row['ICE_INV_AMT'],
        stringValue: getThousandDollarString(row['ICE_INV_AMT'])
      }
    ]),
    headers: [
      { name: 'Class ' },
      { name: 'Inv $ ' },
      { name: `vP ${isPct ? '% ' : ''}` },
      { name: 'ASF ' },
      { name: 'WOS ' },
      { name: 'ICE $ ' }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return { dataObj };
};
