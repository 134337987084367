import { columnName } from '../../../utils/constantsByLevel';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { MonitorMetricsDrill } from '../components/MonitorMetricsDrill';
import { Header, Loader } from 'semantic-ui-react';
import {
  shrinkMonitorMetricsDataReduced,
  shrinkMonitorMetricsDrillBreadcrumbItems,
  shrinkMonitorMetricsDrillHeaders,
  shrinkReducedTagsRows,
} from '../utils/shrinkMonitorMetrics';

export const ShrinkReducedTagPage = () => {
  const { fpToggle, timeframe } = useShrinkContext();

  const queryBuilder = {
    fpToggle,
    queries: ['query/queryShrinkMetricsByLevelPlusOne']
  };
  const { results, locNbr, level, isError, isLoading } = useShrinkQueries(queryBuilder);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const [queryShrinkMetricsByLevelPlusOne] = results;
  const levelName = queryShrinkMetricsByLevelPlusOne?.data[0][columnName[level]];

  const headers = shrinkMonitorMetricsDrillHeaders(level);
  const metricsData = shrinkMonitorMetricsDataReduced(queryShrinkMetricsByLevelPlusOne?.data, level);

  const titleSingle = 'Reduced Tags';
  const title = `${titleSingle} - ${level} ${locNbr}, ${levelName.toLowerCase()}`;
  const dataAsOf = queryShrinkMetricsByLevelPlusOne.data[0]?.TRNSFM_DT;
  const breadcrumb = shrinkMonitorMetricsDrillBreadcrumbItems(level, locNbr, titleSingle);
  const data = {
    sortable: true,
    sortableDefault: 0,
    headers,
    data: queryShrinkMetricsByLevelPlusOne.data.map(row => shrinkReducedTagsRows(row, metricsData, timeframe, level))
  };

  return <MonitorMetricsDrill data={data} title={title} breadcrumb={breadcrumb} dataAsOf={dataAsOf} />;
};
