import { Tab } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { useCoreOverviewContext } from '../../../../hooks/useCoreOverviewContext';

export const Comp = () => {
  const { salesDeptData, timeframe } = useCoreOverviewContext();

  const dataObj = {
    data: salesDeptData.map(row => [
      {
        link: {
          to: `/core/sales/dept?deptNbr=${row.DEPT_NBR}`
        },
        stringValue: row['MER_DEPT_NM'].replaceAll('/', '/\u200b')
      },
      {
        compareStyle: true,
        orderBy: row[timeframe + '_SLS_AMT_VLY_PCT'],
        pctValue: row[timeframe + '_SLS_AMT_VLY_PCT'],
        decimals: 1
      },
      {
        compareStyle: true,
        orderBy: row[timeframe + '_TXN_CNT_VLY_PCT'],
        pctValue: row[timeframe + '_TXN_CNT_VLY_PCT'],
        decimals: 1
      },
      {
        compareStyle: true,
        orderBy: row[timeframe + '_AVG_TCKT_VLY_PCT'],
        pctValue: row[timeframe + '_AVG_TCKT_VLY_PCT'],
        decimals: 1
      },
      {
        compareStyle: true,
        orderBy: row[timeframe + '_UNTS_VLY_PCT'],
        pctValue: row[timeframe + '_UNTS_VLY_PCT'],
        decimals: 1
      }
    ]),
    headers: [
      { name: 'DEPT ' },
      { name: 'Sales Comp % ' },
      { name: 'Trans Comp % ' },
      { name: 'Avg Tckt Comp % ' },
      { name: 'Unit Comp % ' }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <Tab.Pane attached={false}>
      <DataTable data={dataObj} />
    </Tab.Pane>
  );
};
