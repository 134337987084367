import { columnName } from '../../../utils/constantsByLevel';
import { cullMarkdownsHeaders, shrinkCullMdRows } from '../utils/cullMarkdowns';
import { Header, Loader } from 'semantic-ui-react';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import {
  shrinkMonitorMetricsDataFiltered,
  shrinkMonitorMetricsDataReduced,
  shrinkMonitorMetricsDrillBreadcrumbItems
} from '../utils/shrinkMonitorMetrics';
import { MonitorMetricsDrill } from '../components/MonitorMetricsDrill';

export const ShrinkCullMarkdownsPage = () => {
  const { fpToggle, timeframe } = useShrinkContext();

  const queryBuilder = {
    fpToggle,
    queries: ['query/queryShrinkMetricsByLevelPlusOne', 'query/queryShrinkMetricsScoresByLevel']
  };
  const { isError, isLoading, results, locNbr, level } = useShrinkQueries(queryBuilder);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const [queryShrinkMetricsByLevel, queryShrinkMetricsScoresByLevel] = results;
  const levelName = queryShrinkMetricsByLevel.data[0][columnName[level]];
  
  const headers = cullMarkdownsHeaders(level);
  const metricsScoreDataFiltered = shrinkMonitorMetricsDataFiltered(queryShrinkMetricsScoresByLevel.data, level, timeframe);
  const metricsData = shrinkMonitorMetricsDataReduced(queryShrinkMetricsByLevel.data, level);

  const titleSingle = 'Cull Markdowns';
  const title = `${titleSingle} - ${level} ${locNbr}, ${levelName.toLowerCase()}`;
  const dataAsOf = queryShrinkMetricsByLevel.data[0]?.TRNSFM_DT;
  const breadcrumb = shrinkMonitorMetricsDrillBreadcrumbItems(level, locNbr, titleSingle);
  const data = {
    sortable: true,
    sortableDefault: 0,
    headers,
    data: metricsScoreDataFiltered.map(row => shrinkCullMdRows(row, metricsData, timeframe, level))
  };

  return <MonitorMetricsDrill data={data} title={title} breadcrumb={breadcrumb} dataAsOf={dataAsOf} />;
};
