import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { buildBaseShrinkUrl } from './url';
import { capitalizeFirstLetter } from '../../../utils/string';

export const accuracyandReceivingDrillHeaders = level => [
  { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)} # ` },
  { name: 'Value' },
  { name: 'Goal' }
];

export const ohAutoRejectRows = (metricsScoreData, metricsData, timeframe, level) => {
  const previousLocNbr = metricsScoreData[METRIC_CONSTANTS[level].propToRender];
  const baseLevelShrinkUrl = buildBaseShrinkUrl(METRIC_CONSTANTS[level].urlLinkLevel);
  const total =
    metricsData[previousLocNbr][`${timeframe}_OH_AUTO_RJT_CNT`] /
      metricsData[previousLocNbr][`${timeframe}_OH_NEED_APPRV_CNT`] || 0;

  const linkUrl =
    level === 'region' ? `/${METRIC_CONSTANTS[level].urlLinkLevel}/ohautorej` : `/${baseLevelShrinkUrl}/ohautorej`;
  return [
    {
      stringValue: previousLocNbr,
      prefix: `${METRIC_CONSTANTS[level].subMetric} `,
      link: {
        to: linkUrl,
        params: {
          [METRIC_CONSTANTS[level].urlSubLevelParam]: previousLocNbr
        }
      }
    },
    {
      pctValue: total,
      decimals: 2,
      customStyle: { color: total < 0.03 ? 'green' : 'red' }
    },
    { stringValue: '<3%' }
  ];
};
