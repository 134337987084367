import { useState } from 'react';
import { Link } from 'react-router-dom';
import { UnitMenu } from '../Common/UnitMenu';
import { DstOverviewTabs } from './DstOverviewTabs';
import { Grid, Header, Table } from 'semantic-ui-react';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon';
import { VOC_GET_GOAL, VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../constants/goals';
import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils';

export const DistrictTab = ({
  daysSafeDstFilter,
  dataSlsClsByDst,
  dataSlsDeptByDst,
  dataSlsSclsByDst,
  deptInventory,
  districtNumber,
  dstNm,
  dstNbr,
  dstMetrics,
  settimeframe,
  timeframe,
  Top40Btm40Query,
  userEmplCtgryNm
}) => {
  const [unit, setUnit] = useState('NOM');

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          {new Date().getDay() === 1 ? (
            <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
          ) : (
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          )}
        </Grid.Column>
      </Grid>

      <Header textAlign='center'>
        Overview - District {districtNumber}, {dstNm}
      </Header>

      <div style={{ display: 'flex', justifyContent: 'center' }}>Data as of {dstMetrics['TRNSFM_DT']}</div>

      <Table textAlign='center' celled unstackable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Actual</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell>vP/vF</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vP/vF %</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell>vLY</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vLY %</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body singleLine>
          <Table.Row>
            {userEmplCtgryNm.toUpperCase().includes('SALAR') ||
            userEmplCtgryNm.toUpperCase().includes('OFFICER') ||
            userEmplCtgryNm.toUpperCase().includes('CORP_CNTRC') ? (
              <Table.Cell>
                <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/district/safety?dstNbr=${dstNbr}`}>
                  Days Safe
                </Link>
              </Table.Cell>
            ) : (
              <Table.Cell singleLine>Days Safe</Table.Cell>
            )}
            <Table.Cell>{daysSafeDstFilter[0].DAYS_SAFE}</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell singleLine>Sales</Table.Cell>
            <Table.Cell singleLine>{getDollarString(dstMetrics[timeframe + '_SLS_AMT_ACT'])}</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_VP'])}>
                {getDollarString(dstMetrics[timeframe + '_SLS_VP'])}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_AMT_VP_PCT'])}>
                {getPercentString(dstMetrics[timeframe + '_SLS_AMT_VP_PCT'])}
              </Table.Cell>
            )}
            {unit === 'NOM' && (
              <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_AMT_VLY'])}>
                {getDollarString(dstMetrics[timeframe + '_SLS_AMT_VLY'])}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell singleLine style={getCompareStyle(dstMetrics[timeframe + '_SLS_AMT_VLY_PCT'])}>
                {getPercentString(dstMetrics[timeframe + '_SLS_AMT_VLY_PCT'], 3)}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Units</Table.Cell>
            <Table.Cell>
              {dstMetrics[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_UNTS_VLY'])}>
                {dstMetrics[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_UNTS_VLY_PCT'])}>
                {getPercentString(dstMetrics[timeframe + '_UNTS_VLY_PCT'])}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Transactions</Table.Cell>
            <Table.Cell>
              {dstMetrics[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_TRNS_VLY'])}>
                {dstMetrics[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_TRNS_VLY_PCT'])}>
                {getPercentString(dstMetrics[timeframe + '_TRNS_VLY_PCT'])}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Average Ticket</Table.Cell>
            <Table.Cell>{getDollarString(dstMetrics[timeframe + '_AVG_TCKT_ACT'], 2)}</Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_AVG_TCKT_VLY'])}>
                {getDollarString(dstMetrics[timeframe + '_AVG_TCKT_VLY'], 2)}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_AVG_TCKT_VLY_PCT'])}>
                {getPercentString(dstMetrics[timeframe + '_AVG_TCKT_VLY_PCT'], 3)}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Units per Basket</Table.Cell>
            <Table.Cell>
              {(dstMetrics['TY_' + timeframe + '_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_TXN_CNT']).toLocaleString(
                undefined,
                { maximumFractionDigits: 2 }
              )}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell
                style={getCompareStyle(
                  dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                    dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
                )}>
                {(
                  dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                  dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
                ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell
                style={getCompareStyle(
                  dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                    dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
                )}>
                {getPercentString(
                  (dstMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
                    dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']) /
                    (dstMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / dstMetrics['LY_' + timeframe + '_COMP_TXN_CNT']),
                  2
                )}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {' '}
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/osa?dstNbr=${dstNbr}`}
                data-test='overview-osa-link'>
                On-Shelf Availability
              </Link>
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/district/smd?dstNbr=${dstNbr}`}
                data-test='overview-smd-link'>
                SMD
              </Link>
            </Table.Cell>
            <Table.Cell>{getPercentString(dstMetrics[timeframe + '_SMD_ACT'], 2)}</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(-1 * dstMetrics[timeframe + '_SMD_VP'])}>
                {dstMetrics[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
              </Table.Cell>
            )}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(-1 * dstMetrics[timeframe + '_SMD_VLY'])}>
                {dstMetrics[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}
              </Table.Cell>
            )}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/district/labor?dstNbr=${dstNbr}`}
                data-test='overview-labor-link'>
                Labor
              </Link>
            </Table.Cell>
            <Table.Cell>
              {dstMetrics[timeframe + '_LBR_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
            </Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell>
                {dstMetrics[timeframe + '_LBR_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell
                style={{
                  color:
                    dstMetrics[timeframe + '_LBR_VF_PCT'] > 0.01 || dstMetrics[timeframe + '_LBR_VF_PCT'] < -0.01
                      ? 'red'
                      : 'green'
                }}>
                {getPercentString(dstMetrics[timeframe + '_LBR_VF_PCT'])}
              </Table.Cell>
            )}
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/district/ltsa?dstNbr=${dstNbr}`}
                data-test='overview-get-link'>
                GET
              </Link>
            </Table.Cell>
            <Table.Cell style={{ color: dstMetrics[timeframe + '_GET_ACT'] >= VOC_GET_GOAL ? 'green' : 'red' }}>
              {getPercentString(dstMetrics[timeframe + '_GET_ACT'], 2)}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_GET_VLY'])}>
                {getPercentString(dstMetrics[timeframe + '_GET_VLY'], 2)}
              </Table.Cell>
            )}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/district/ltsa?dstNbr=${dstNbr}`}
                data-test='overview-ltsa-link'>
                LTSA
              </Link>
            </Table.Cell>
            <Table.Cell style={{ color: dstMetrics[timeframe + '_LTSA_ACT'] >= VOC_LTSA_GOAL ? 'green' : 'red' }}>
              {getPercentString(dstMetrics[timeframe + '_LTSA_ACT'], 2)}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_LTSA_VLY'])}>
                {getPercentString(dstMetrics[timeframe + '_LTSA_VLY'], 2)}
              </Table.Cell>
            )}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/district/voc?dstNbr=${dstNbr}`}
                data-test='overview-onlineSAT-link'>
                LTPA
              </Link>
            </Table.Cell>
            <Table.Cell
              style={{
                color:
                  dstMetrics[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL
                    ? 'green'
                    : dstMetrics[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL
                      ? 'red'
                      : 'black'
              }}>
              {dstMetrics[timeframe + '_ONLINE_SAT_ACT'] != null
                ? getPercentString(dstMetrics[timeframe + '_ONLINE_SAT_ACT'])
                : '-'}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_ONLINE_SAT_VLY'])}>
                {dstMetrics[timeframe + '_ONLINE_SAT_VLY'] != null
                  ? dstMetrics[timeframe + '_ONLINE_SAT_VLY'].toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) + '%'
                  : '--'}
              </Table.Cell>
            )}
            {unit === 'PCT' && <Table.Cell>-</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/district/leads?dstNbr=${dstNbr}`}
                data-test='overview-leads-link'>
                Leads
              </Link>
            </Table.Cell>
            <Table.Cell>
              {dstMetrics[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_LEADS_VLY'])}>
                {dstMetrics[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_LEADS_VLY_PCT'])}>
                {getPercentString(dstMetrics[timeframe + '_LEADS_VLY_PCT'])}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/district/meas?dstNbr=${dstNbr}`}
                data-test='overview-meas-link'>
                Measures
              </Link>
            </Table.Cell>
            <Table.Cell>
              {dstMetrics[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
            {unit === 'NOM' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MEAS_VLY'])}>
                {dstMetrics[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </Table.Cell>
            )}
            {unit === 'PCT' && (
              <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MEAS_VLY_PCT'])}>
                {getPercentString(dstMetrics[timeframe + '_MEAS_VLY_PCT'])}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Cashier Metrics</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>HDPP - MA UA</Table.Cell>
            <Table.Cell>{getPercentString(dstMetrics[timeframe + '_MA_HDPP'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MA_HDPP_VG'])}>
              {getPercentString(dstMetrics[timeframe + '_MA_HDPP_VG'])}
            </Table.Cell>
            <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_MA_HDPP_VLY'])}>
              {getPercentString(dstMetrics[timeframe + '_MA_HDPP_VLY'])}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>HDPP - GM UA</Table.Cell>
            <Table.Cell>{getPercentString(dstMetrics[timeframe + '_GM_HDPP'])}</Table.Cell>
            <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_GM_HDPP_VG'])}>
              {getPercentString(dstMetrics[timeframe + '_GM_HDPP_VG'])}
            </Table.Cell>
            <Table.Cell style={getCompareStyle(dstMetrics[timeframe + '_GM_HDPP_VG'])}>
              {getPercentString(dstMetrics[timeframe + '_GM_HDPP_VLY'])}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <DstOverviewTabs
        timeframe={timeframe}
        dstNm={dstMetrics['DST_NM']}
        deptInventory={deptInventory}
        dataSlsClsByDst={dataSlsClsByDst}
        Top40Btm40Query={Top40Btm40Query}
        dataSlsDeptByDst={dataSlsDeptByDst}
        dataSlsSclsByDst={dataSlsSclsByDst}
      />
    </>
  );
};
