import useQueryApi from '../../../../../hooks/useQueryApi';

export const useShrinkDeptQueries = params => {
  const { level, deptNbr, fpToggle } = params;
  const queryParams = { isCore: true, fpToggle };

  const {
    data: shrinkActualsClsDeptData,
    isError: isErrorShrinkActualsClsDept,
    isLoading: isLoadingShrinkActualsClsDept
  } = useQueryApi(`query/queryShrinkActualsClsDept/${level}/dept/${deptNbr}`, queryParams);

  const {
    data: shrinkActualsSkuDeptData,
    isError: isErrorShrinkActualsSkuDept,
    isLoading: isLoadingShrinkActualsSkuDept
  } = useQueryApi(`query/queryShrinkActualsSkuDept/${level}/dept/${deptNbr}`, queryParams);

  const isError = isErrorShrinkActualsClsDept || isErrorShrinkActualsSkuDept;
  const isLoading = isLoadingShrinkActualsClsDept || isLoadingShrinkActualsSkuDept;

  return {
    isError,
    isLoading,
    shrinkActualsClsDeptData,
    shrinkActualsSkuDeptData
  };
};
