import { DataView } from '../../../../../components/Common/DataView';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { focusMetricsDataCore } from '../utils/focusMetricsBuilderCore';

export const FocusMetricsCore = ({ shrinkMetricsData, scoresData }) => {
  const { timeframe } = useShrinkCoreContext();
  const focusMetricsObject = focusMetricsDataCore({ timeframe, scoresData, shrinkMetricsData });

  return (
    <DataView>
      <DataView.Title>Focus Metrics</DataView.Title>
      <DataView.DataTable data={focusMetricsObject} />
    </DataView>
  );
};
