import { Link } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import { CoreSkuDrill } from '../components/CoreSkuDrill';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { TabMenu } from '../../../../../components/Common/TabMenu';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { CoreSubClassesDrill } from '../components/CoreSubClassesDrill';
import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';

export const CoreShrinkClassPage = () => {
  const { classNbr = 0 } = useQueryParams();
  const { fpToggle } = useShrinkCoreContext();

  const { data: subClassesDrillData, isLoading: subclassesLoading } = useQueryApi('queryShrinkSubclassesDrillDataAtCore', {
    classNbr,
    fpToggle,
    isCore: true
  });
  const { data: skuDrillData, isLoading: skuLoading } = useQueryApi('queryShrinkSkuDrillDataAtCore', {
    classNbr,
    fpToggle,
    isCore: true
  });

  if (subclassesLoading || skuLoading) return <Loader active>Loading...</Loader>;

  const panes = [
    {
      menuItem: 'SUBCLASSES',
      render: () => <CoreSubClassesDrill data={subClassesDrillData} />
    },
    {
      menuItem: 'SKUS',
      render: () => <CoreSkuDrill data={skuDrillData} />
    }
  ];

  const deptNbr = classNbr.toString().substring(1, 3);
  const classDesc = subClassesDrillData[0]?.CLASS_DESC;
  const deptNm = subClassesDrillData.length > 0 ? subClassesDrillData[0]?.DEPT_NM : deptNbr;
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');

  return (
    <>
      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/${baseLevelShrinkUrl}`}>
        Shrink
      </Link>
      {' / '}
      <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/${baseLevelShrinkUrl}/dept?deptNbr=${deptNbr}`}>
        {deptNbr}-{deptNm}
      </Link>
      {' / '}
      {classNbr}-{classDesc}
      <Header textAlign='left'>
        {classNbr}-{classDesc}
      </Header>
      <TabMenu panes={panes} />
    </>
  );
};
