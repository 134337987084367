import { Header } from "semantic-ui-react";
import { DataView } from "../../Common/DataView";

export const OnHandAdjustment = ({ dataAsOf, dataObject, level, locNbr }) => {
  return (
    <DataView className='mt-4'>
      <DataView.Title position="center">
        On Hand Adjustments & Empty Packages - {level}, {locNbr}
        <Header.Subheader>
          Data as Of {dataAsOf}
        </Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={dataObject} />
    </DataView>
  );
}