import { useMemo, useState } from 'react';
import { useShrinkContext } from '../../hooks/useShrinkContext';
import { useShrinkQueries } from '../../hooks/useShrinkQueries';
import { formatSku } from '../../../../utils/formatter';
import { UNIT_CHAR } from '../../utils/overview';
import { Tab } from 'semantic-ui-react';
import { DataView } from '../../../Common/DataView';
import { UnitMenu } from '../../../Common/UnitMenu';
import { SkuDetailsModal } from '../../../Sku/SkuDetailsModal';

const SHRINK_AMT_vLY_RANK = 'SHRINK_AMT_vLY_RANK';
const SHRINK_RATE_vLY_RANK = 'SHRINK_RATE_vLY_RANK';

export const SkuSummaryTab = () => {
  const [unit, setUnit] = useState('NOM');
  const [skuData, setSkuData] = useState(null);
  const { fpToggle } = useShrinkContext();
  const {
    level,
    results: [queryShrinkActualsTop40Skus]
  } = useShrinkQueries({ fpToggle, queries: ['query/queryShrinkActualsTop40Skus'] });
  const [shrinkAmtRankField, shrinkRateRankField] = useMemo(() => {
    const shrinkLevelParam = level === 'region' ? 'RGN' : 'DIV';

    return [SHRINK_AMT_vLY_RANK + '_' + shrinkLevelParam, SHRINK_RATE_vLY_RANK + '_' + shrinkLevelParam];
  }, [level]);

  const isUnitNom = unit === 'NOM';
  const skuSumDataFilter = queryShrinkActualsTop40Skus.data.filter(row => {
    const valueToCompare = isUnitNom ? row[shrinkAmtRankField] : row[shrinkRateRankField];

    return valueToCompare <= 40;
  });

  const dataObj = {
    data: skuSumDataFilter.map(row => [
      {
        stringValue: isUnitNom ? row[shrinkAmtRankField] : row[shrinkRateRankField]
      },
      {
        customStyle: { color: '#EE7125', textDecoration: 'underline' },
        onClick: () => setSkuData(row),
        stringValue: formatSku(row.SKU_NBR.toString())
      },
      {
        stringValue: row.SKU_DESC.replaceAll('/', '/\u200b')
      },
      {
        stringValue: row.DEPT_NBR
      },
      {
        decimals: isUnitNom ? undefined : 2,
        dollarValue: isUnitNom ? row.TOTAL_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.SHRINK_RATE
      },
      {
        decimals: isUnitNom ? undefined : 2,
        dollarValue: isUnitNom ? row.SHRINK_AMT_vLY : undefined,
        pctValue: isUnitNom ? undefined : row.SHRINK_RATE_vLY
      },
      {
        decimals: isUnitNom ? undefined : 2,
        dollarValue: isUnitNom ? row.OPS_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT
      }
    ]),
    headers: [
      { name: 'Rank * ' },
      { name: 'SKU # ' },
      { name: 'SKU Desc ' },
      { name: 'Dept ' },
      { name: `Shrink ${UNIT_CHAR[unit]}` },
      { name: `vLY ${UNIT_CHAR[unit]}` },
      { name: `Ops Shrink ${UNIT_CHAR[unit]}` }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Title>Top 40 Shrink Skus</DataView.Title>

        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />

          <p className='mt-4'>* Top 40 SKUs are ranked by Shrink rate (vLY $ or vLY % depending on unit selection)</p>
        </DataView.Controls>

        <DataView.DataTable data={dataObj} />

        <SkuDetailsModal isOpen={!!skuData} onClose={() => setSkuData(null)} skuData={skuData} />
      </DataView>
    </Tab.Pane>
  );
};
