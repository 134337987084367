import React from 'react';
import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import {
  shrinkMonitorCoreMetricsDrillHeaders,
  shrinkMonitorMetricsDataCoreReduced,
  shrinkRtvDeletedTagsCoreRows
} from '../utils/RTVDeletingCoreDataBuilder';
import { MonitorMetricsCoreDrill } from './MonitorMetricsCoreDrill';

export const RTVDeletingCore = () => {
  const { fpToggle, timeframe } = useShrinkCoreContext();

  const defaultParams = {
    fpToggle,
    isCore: true
  };

  const {
    data: shrinkRTVDeletingData = [],
    isLoading: shrinkRTVDeletingDataLoading,
    error: shrinkRTVDeletingError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', defaultParams);

  if (shrinkRTVDeletingDataLoading) return <Loader active>Loading...</Loader>;
  if (shrinkRTVDeletingError) return <Header textAlign='center'>Something went wrong.</Header>;

  const headers = shrinkMonitorCoreMetricsDrillHeaders();
  const metricsData = shrinkMonitorMetricsDataCoreReduced(shrinkRTVDeletingData);

  const title = `RTV Deleted Tags - Core`;
  const dataAsOf = shrinkRTVDeletingData.TRNSFM_DT;

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers,
    data: shrinkRTVDeletingData.map(row => shrinkRtvDeletedTagsCoreRows(row, metricsData, timeframe))
  };

  return <MonitorMetricsCoreDrill data={data} title={title} dataAsOf={dataAsOf} />;
};
