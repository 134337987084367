import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { UNIT_CHAR } from '../../../utils/skus';

export const coreSubClassesSummaryBuilder = (subClassesData, isUnitNom, unit, deptNbr) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');
  const dataFiltered = subClassesData?.filter(row => deptNbr === 'All' || row.DEPT_NBR.toString() === deptNbr.toString());

  return {
    data: dataFiltered?.map(row => [
      {
        link: {
          to: `/${baseLevelShrinkUrl}/subclass`,
          params: {
            subClassNbr: row.EXT_SUB_CLASS_NBR
          }
        },
        stringValue: `${row.EXT_SUB_CLASS_NBR} - ${row.SUB_CLASS_DESC?.replaceAll('/', '/\u200b')}`
      },
      {
        dollarValue: isUnitNom ? row.TOTAL_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT
      },
      {
        dollarValue: isUnitNom ? row.TOTAL_SHRINK_AMT - row.LY_TOTAL_SHRINK_AMT : undefined,
        pctValue: isUnitNom
          ? undefined
          : row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT - row.LY_TOTAL_SHRINK_AMT / row.LY_TOTAL_SALES_AMT
      },
      {
        dollarValue: isUnitNom ? row.OPS_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT
      }
    ]),
    headers: [
      { name: 'Subclasses ' },
      { name: `Shrink ${UNIT_CHAR[unit]}` },
      { name: `vLY ${UNIT_CHAR[unit]}` },
      { name: `Ops Shrink ${UNIT_CHAR[unit]}` }
    ],
    sortable: true,
    pagination: true,
    sortableDefault: 0
  };
};
