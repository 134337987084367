import { DataView } from '../../Common/DataView';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { getQuotesDataTable } from '../utils/specialtyProRental';

export const Quotes = props => {
  const { quotesData } = props;
  const { level, timeframe } = useSpecialtyProRentalContext();
  const quotesDataTable = getQuotesDataTable({ level, timeframe, quotesData });

  return (
    <DataView className='my-4'>
      <DataView.Title position='center'>Quotes</DataView.Title>

      <DataView.DataTable data={quotesDataTable} />
    </DataView>
  );
};
