import { useState } from 'react';
import { UnitMenu } from '../../../Common/UnitMenu';
import { DataView } from '../../../Common/DataView';
import { NOM } from '../../../../utils/constants';
import { getClassesDataTable } from '../../utils/shrinkDept';

export const ShrinkDeptClassesTab = props => {
  const { shrinkActualsClsDeptData, level, locNbr } = props;
  const [unit, setUnit] = useState(NOM);
  const classesDataTable = getClassesDataTable({ shrinkActualsClsDeptData, unit, level, locNbr });

  return (
    <>
      <DataView.Controls className='my-3'>
        <UnitMenu unit={unit} setUnit={setUnit} />
      </DataView.Controls>
      <DataView.DataTable data={classesDataTable} />
    </>
  );
};
