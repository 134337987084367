import { Children, cloneElement } from 'react';
import cnames from 'classnames';
import { DataTable } from './DataTable';
import { Header } from 'semantic-ui-react';

export const DataView = ({ children, className, testId }) => (
  <div className={className} data-testid={testId}>
    {Children.map(children, child => cloneElement(child))}
  </div>
);

const Title = ({ children, position = 'left', ...props }) => {
  return (
    <Header textAlign={position} style={{ textTransform: 'capitalize' }} {...props}>
      {children}
    </Header>
  );
};

DataView.Controls = ({ children, className = '' }) => {
  const classNameWrapper = cnames('data-view-controls', className);

  return <div className={classNameWrapper}>{children}</div>;
};
DataView.DataTable = DataTable;
DataView.Title = Title;
