// List of timeframes and their labels
export const timeframes = [
  { label: 'WTD', display: 'WTD' },
  { label: 'LW', display: 'LW' },
  { label: 'LM', display: 'LM' },
  { label: 'MTD', display: 'MTD' },
  { label: 'QTD', display: 'QTD' },
  { label: 'HTD', display: 'HTD' },
  { label: 'YTD', display: 'YTD' },
  { label: 'R12M', display: 'L12' },
  { label: 'LAST_INV', display: 'Since Last Inv' }
];
