import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { FocusMetricsTimeFrame } from './FocusMetricsTimeFrame';

export const MonitorMetricsCoreDrill = ({ data, title, dataAsOf = null }) => {
  const { timeframe, settimeframe } = useShrinkCoreContext();

  if (data.length) return <Header textAlign='center'>No data to show.</Header>;

  return (
    <>
      <FocusMetricsTimeFrame timeframe={timeframe} settimeframe={settimeframe} />
      <DataView className='my-4'>
        <DataView.Title className='mt-2'>
          {title}
          {dataAsOf && <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>}
        </DataView.Title>

        <DataView.DataTable data={data} />
      </DataView>
    </>
  );
};
