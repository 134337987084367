import { capitalizeFirstLetter } from '../../../utils/string';
import { formatColor } from '../../Common/TableCellUtils';
import { SPEC_PRO_RENTAL_PATH } from './consts';

const PXTRA_SIGNUP = 'PXTRA_SIGNUP';
const PXTRA_SIGNUP_AVG = 'PXTRA_SIGNUP_AVG';
const PRO_MRO_SLS = 'PRO_MRO_SLS';
const PRO_MRO_AVG = 'PRO_MRO_AVG';
const PRO_LTSA_PCT = 'PRO_LTSA_PCT';
const PRO_GET_PCT = 'PRO_GET_PCT';

export const headers = level => [
  { name: `${capitalizeFirstLetter(level.urlLinkLevel)} # ` },
  { name: 'Pro Xtra Signups' },
  { name: 'Pro Xtra Signups Avg' },
  { name: 'Pro MRO Act' },
  { name: 'Pro MRO Avg' },
  { name: 'Pro LTSA' },
  { name: 'Pro GET' }
];

export const footerTotal = (proKeyMetricsData, timeframe) => {
  const PRO_XTRA_SIGNUPS = `${timeframe}_${PXTRA_SIGNUP}`;
  const PRO_XTRA_SIGNUPS_AVG = `${timeframe}_${PXTRA_SIGNUP_AVG}`;
  const PRO_MRO_ACT = `${timeframe}_${PRO_MRO_SLS}`;
  const PRO_MRO_AVG_COL = `${timeframe}_${PRO_MRO_AVG}`;
  const PRO_LTSA = `${timeframe}_${PRO_LTSA_PCT}`;
  const PRO_GET = `${timeframe}_${PRO_GET_PCT}`;

  const nroDivisions = proKeyMetricsData.length;

  const result = proKeyMetricsData.reduce(
    (acc, curr) => ({
      [PRO_XTRA_SIGNUPS]: (acc[PRO_XTRA_SIGNUPS] || 0) + curr[PRO_XTRA_SIGNUPS],
      [PRO_XTRA_SIGNUPS_AVG]: (acc[PRO_XTRA_SIGNUPS_AVG] || 0) + curr[PRO_XTRA_SIGNUPS_AVG],
      [PRO_MRO_ACT]: (acc[PRO_MRO_ACT] || 0) + curr[PRO_MRO_ACT],
      [PRO_MRO_AVG_COL]: (acc[PRO_MRO_AVG_COL] || 0) + curr[PRO_MRO_AVG_COL],
      [PRO_LTSA]: (acc[PRO_LTSA] || 0) + curr[PRO_LTSA],
      [PRO_GET]: (acc[PRO_GET] || 0) + curr[PRO_GET]
    }),
    {}
  );
  result[PRO_XTRA_SIGNUPS_AVG] = result[PRO_XTRA_SIGNUPS_AVG] / nroDivisions;
  result[PRO_LTSA] = result[PRO_LTSA] / nroDivisions;
  result[PRO_GET] = result[PRO_GET] / nroDivisions;
  return result;
};

const isNegativeformatColor = (value, validation) => ({
  color: value < validation ? 'red' : 'black'
});

export const prokeyMetricsDataRows = (proKeyMetricsData, timeframe, level) => {
  const firstColumn = {
    stringValue: proKeyMetricsData[level.propToRender],
    link: {
      to: `/${level.urlLinkLevel}/${SPEC_PRO_RENTAL_PATH}`,
      params: {
        [level.urlSubLevelParam]: proKeyMetricsData[level.propToRender]
      }
    }
  };
  return prokeyMetricsRows(proKeyMetricsData, timeframe, firstColumn);
};

export const prokeyMetricsFooterRow = (proKeyMetricsData, timeframe) => {
  const firstColumn = { stringValue: 'Total' };
  return prokeyMetricsRows(proKeyMetricsData, timeframe, firstColumn);
};

const prokeyMetricsRows = (proKeyMetricsData, timeframe, firstColumn) => {
  const PRO_XTRA_SIGNUPS = `${timeframe}_${PXTRA_SIGNUP}`;
  const PRO_XTRA_SIGNUPS_AVG = `${timeframe}_${PXTRA_SIGNUP_AVG}`;
  const PRO_MRO_ACT = `${timeframe}_${PRO_MRO_SLS}`;
  const PRO_MRO_AVG_COL = `${timeframe}_${PRO_MRO_AVG}`;
  const PRO_LTSA = `${timeframe}_${PRO_LTSA_PCT}`;
  const PRO_GET = `${timeframe}_${PRO_GET_PCT}`;

  return [
    firstColumn,
    { numValue: proKeyMetricsData[PRO_XTRA_SIGNUPS] },
    {
      numValue: proKeyMetricsData[PRO_XTRA_SIGNUPS_AVG],
      customStyle: formatColor(proKeyMetricsData[PRO_XTRA_SIGNUPS_AVG], 8),
      decimals: 2
    },
    {
      dollarValue: proKeyMetricsData[PRO_MRO_ACT],
      decimals: 2,
      customStyle: isNegativeformatColor(proKeyMetricsData[PRO_XTRA_SIGNUPS_AVG], 0)
    },
    {
      dollarValue: proKeyMetricsData[PRO_MRO_AVG_COL],
      customStyle: formatColor(proKeyMetricsData[PRO_MRO_AVG_COL], 1200),
      decimals: 2
    },
    { pctValue: proKeyMetricsData[PRO_LTSA], customStyle: formatColor(proKeyMetricsData[PRO_LTSA], 0.85) },
    { pctValue: proKeyMetricsData[PRO_GET], customStyle: formatColor(proKeyMetricsData[PRO_GET], 0.85) }
  ];
};
