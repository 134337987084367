import { buildBaseShrinkUrl } from './url';
import { getAdjustableDollarString, getPercentString } from '../../Common/TableCellUtils';

export const UNIT_CHAR = { NOM: '$', PCT: '%' };

export const DISTRICT_HEADERS = unit => [
  { name: `Shrink ${UNIT_CHAR[unit]}` },
  { name: 'vGoal' },
  { name: 'vLY' },
  { name: `Ops Shrink ${UNIT_CHAR[unit]}` }
];

export const districtDataBuilder = (row, unit) => {
  switch (unit) {
    case 'NOM':
      return [
        { numValue: getAdjustableDollarString(row.TOTAL_SHRINK_AMT, 2) },
        {
          numValue: getAdjustableDollarString(
            row.TOTAL_SHRINK_AMT - (row.LI_SHRINK_AMT_GOAL / row.LI_SALES_AMT_GOAL) * row.TOTAL_SALES_AMT,
            2
          )
        },
        { numValue: getAdjustableDollarString(row.TOTAL_SHRINK_AMT - row.LY_TOTAL_SHRINK_AMT, 2) },
        { numValue: getAdjustableDollarString(row.OPS_SHRINK_AMT, 2) }
      ];
    case 'PCT':
      return [
        { numValue: getPercentString(row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT, 2, 2) },
        {
          numValue: getPercentString(
            row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT - row.LI_SHRINK_AMT_GOAL / row.LI_SALES_AMT_GOAL,
            2,
            2
          )
        },
        {
          numValue: getPercentString(
            row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT - row.LY_TOTAL_SHRINK_AMT / row.LY_TOTAL_SALES_AMT,
            2,
            2
          )
        },
        { numValue: getPercentString(row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT, 2, 2) }
      ];
    default:
      console.error(`Invalid unit: ${unit}. Unit can only be 'NOM' or 'PCT'.`);
      break;
  }
};

export const DEPARTMENTS_HEADER_TABS = unit => [
  { name: 'Dept' },
  { name: `Shrink ${UNIT_CHAR[unit]} ` },
  { name: `vLY ${UNIT_CHAR[unit]} ` },
  { name: `Ops Shrink ${UNIT_CHAR[unit]} ` }
];

const linkGenerator = (level, data) => {
  const { levelName, urlParam, currentLevel } = level;
  const baseLevelShrinkUrl = buildBaseShrinkUrl(levelName);
  return {
    link: {
      to: `/${baseLevelShrinkUrl}/dept`,
      params: {
        [urlParam]: data[currentLevel],
        deptNbr: data['DEPT_NBR']
      }
    },
    stringValue: `${data['DEPT_NBR']} - ${data['DEPT_NM']}`
  };
};

const TOTAL_SHRINK_AMT = 'TOTAL_SHRINK_AMT';
const LY_TOTAL_SHRINK_AMT = 'LY_TOTAL_SHRINK_AMT';
const OPS_SHRINK_AMT = 'OPS_SHRINK_AMT';
const TOTAL_SALES_AMT = 'TOTAL_SALES_AMT';
const LY_TOTAL_SALES_AMT = 'LY_TOTAL_SALES_AMT';

export const departmentsSummaryRowBuilder = (data, unit, level) => {
  const SHRINK = data[TOTAL_SHRINK_AMT];
  const DATA_LY_TOTAL_SHRINK_AMT = data[LY_TOTAL_SHRINK_AMT];
  const vLY = SHRINK - DATA_LY_TOTAL_SHRINK_AMT;
  const OPS_SHRINK = data[OPS_SHRINK_AMT];

  const SHRINK_PCT = SHRINK / data[TOTAL_SALES_AMT];

  switch (unit) {
    case 'NOM':
      return [
        { ...linkGenerator(level, data) },
        { stringValue: getAdjustableDollarString(SHRINK, 2), orderBy: SHRINK },
        { stringValue: getAdjustableDollarString(vLY, 2), orderBy: vLY },
        { stringValue: getAdjustableDollarString(OPS_SHRINK, 2), orderBy: OPS_SHRINK }
      ];
    case 'PCT':
      return [
        { ...linkGenerator(level, data) },
        { stringValue: getPercentString(SHRINK_PCT, 2, 2) },
        {
          stringValue: getPercentString(SHRINK_PCT - DATA_LY_TOTAL_SHRINK_AMT / data[LY_TOTAL_SALES_AMT], 2, 2)
        },
        { stringValue: getPercentString(data[OPS_SHRINK_AMT] / data[TOTAL_SALES_AMT], 2, 2) }
      ];
    default:
      console.error(`Invalid unit: ${unit}. Unit can only be 'NOM' or 'PCT'.`);
      break;
  }
};
