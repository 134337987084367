import { aggregateObjectKeys } from '../../../utils/array';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../utils/level';
import { capitalizeFirstLetter } from '../../../utils/string';
import { getCompareStyle } from '../../Common/TableCellUtils';
import { calculateComp, calculateConv } from './specialtyProRentalServices';

const AGGREGATE_METRICS = [
  'LEAD_COUNT',
  'MEASURE_COUNT',
  'LY_LEAD_COUNT',
  'LY_MEASURE_COUNT',
  'LEAD_CONV_NUM',
  'MEASURE_CONV_NUM',
  'LEAD_CONV_DEN',
  'MEASURE_CONV_DEN'
];

export const aggregateMetricsFromLevels = (data, level, timeframe, pipelineFilter) => {
  const focus6StrReducedHelper = {};

  return data.reduce((r, o) => {
    const key = o[METRIC_CONSTANTS[level].subLevel.currentLevel];

    if (!focus6StrReducedHelper[key]) {
      focus6StrReducedHelper[key] = Object.assign({}, o);
      r.push(focus6StrReducedHelper[key]);
    } else {
      AGGREGATE_METRICS.forEach(metric => {
        const metricKey = `${timeframe}_${metric}_${pipelineFilter}`;
        focus6StrReducedHelper[key][metricKey] += o[metricKey];
      });
    }
    return r;
  }, []);
};

export const buildFooter = (data, timeframe, pipelineFilter) => {
  const totalObj = aggregateObjectKeys(data);
  const compValue = calculateComp(totalObj, timeframe, pipelineFilter);

  return [
    [
      {
        name: 'total',
        stringValue: 'Total'
      },
      {
        name: 'leadsCount',
        stringValue: totalObj[timeframe + '_LEAD_COUNT_' + pipelineFilter]
      },
      {
        name: 'measureCount',
        stringValue: totalObj[timeframe + '_MEASURE_COUNT_' + pipelineFilter]
      },
      {
        customStyle: getCompareStyle(compValue),
        name: 'compPct',
        pctValue: compValue
      },
      {
        name: 'convPct',
        pctValue: calculateConv(totalObj, timeframe, pipelineFilter)
      }
    ]
  ];
};

export const buildHeaders = (level = 'region') => [
  { name: capitalizeFirstLetter(METRIC_CONSTANTS[level].subLevel.levelName) },
  { name: 'Leads' },
  { name: 'Meas' },
  { name: 'Comp %' },
  { name: 'Conv %' }
];

export const buildRows = (data = [], level = 'region', pipelineFilter, timeframe) => {
  return data.map(row => {
    const compValue = calculateComp(row, timeframe, pipelineFilter);

    return [
      {
        link: {
          to: `/${METRIC_CONSTANTS[level].subLevel.levelName}/new-services`,
          params: {
            [METRIC_CONSTANTS[level].subLevel.urlParam]: formatLevelNumber(
              row[METRIC_CONSTANTS[level].subLevel.currentLevel]
            )
          }
        },
        stringValue: `${METRIC_CONSTANTS[level].subMetric} ${row[METRIC_CONSTANTS[level].subLevel.currentLevel]}`,
        orderBy: row[METRIC_CONSTANTS[level].subLevel.currentLevel]
      },
      {
        stringValue: row[timeframe + '_LEAD_COUNT_' + pipelineFilter]
      },
      {
        stringValue: row[timeframe + '_MEASURE_COUNT_' + pipelineFilter]
      },
      {
        customStyle: getCompareStyle(compValue),
        pctValue: compValue
      },
      {
        pctValue: calculateConv(row, timeframe, pipelineFilter)
      }
    ];
  });
};
