import React, { useContext, useState } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';

export const BopisComplete = () => {
  const { assocCompletePickupData } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const showCustomTimeframe = ['Cancellations', 'Associate View'];

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [{ name: 'Associate' }, { name: 'Units Unfulfilled' }, { name: 'NIIS Units' }, { name: '$ Unfulfilled' }],
          data: assocCompletePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.UNFULFILLED_NOM },
            { numValue: data.NIIS_UNITS },
            {
              dollarValue: data.UNFULFILLED_AMOUNT,
              prefix: '$ '
            }
          ])
        };
      case 'Cancellations':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Cancel Date' },
            { name: 'SKU #' },
            { name: 'Cancel Qty' },
            { name: 'Reason Code' }
          ],
          data: assocCompletePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.WC_ORDER_NOM },
            { numValue: data.BOPIS_CANCELED_DATE },
            { numValue: data.SKU },
            { numValue: data.CANCEL_QTY },
            { numValue: data.REASON_CODE }
          ])
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <div className='mt-4'>
      <div>
        <Header>
          <b>BOPIS: Associate Details</b>
        </Header>
      </div>
      <CustomDropDown
        view={view}
        setView={setView}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: 'Cancellations', text: 'Cancellations', value: 'Cancellations' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataTable data={getData()} />
    </div>
  );
};
