import React, {useState, useEffect} from 'react'
import {
    Grid,
    Header,
    Loader,
    Menu,
    Divider,
  } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from 'react-query'

import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'
import { DataTable } from '../Common/DataTable'

import {
    getData
  } from '../../service/DataService'



export const SidekickSkuBayBayDetail = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location }) => {

  useEffect(() => {
    if (timeframe !== 'WTD' && timeframe !== 'LW') {
      settimeframe('WTD')
    }
  }, [timeframe])

  const search = new URLSearchParams(location.search)
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0
  const bay = search.has('bay') ? search.get('bay') : ''
  const type = search.has('type') ? search.get('type') : ''
  
  const osaSidekickSkuBayDateDtlQuery = useQuery([`queryOSASidekickSkuBayDateDtl`, { strNbr: locNbr, deptNbrInt, fpToggle }], getData)
  if (osaSidekickSkuBayDateDtlQuery.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!osaSidekickSkuBayDateDtlQuery.data || osaSidekickSkuBayDateDtlQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  let title = '';
  switch(type) {
    case 'LOGGED': title = 'Logged Work'; break;
    default:
      title = 'Bay Packdown'
  }


  const bayData = osaSidekickSkuBayDateDtlQuery.data
  .filter(({DEPT_NBR}) => DEPT_NBR !== 38)
  .filter(({WTD_FLG, LW_FLG}) => timeframe === 'WTD' ?  WTD_FLG === 1 : LW_FLG === 1)
  .filter(({AISLE_BAY}) => AISLE_BAY === bay)
  .filter(({TASK_TYPE}) => TASK_TYPE === type)


  let compBy = ''
  let compDate = ''
  const dirTasksSkuData = {
    sortable: true,
    headers: [
      {name: 'SKU'}, {name: 'SKU DES'}, {name: 'OH Qty'}
    ],
    data: bayData
    .map(row => {
      compBy = row.ASSOC
      compDate = row.COMPLETED_DT
      return [
        {stringValue: row.SKU_NBR}, 
        {stringValue: row.SKU_DESC}, 
        {numValue: row.OH_QTY}
      ]
    })
  }


  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa/sidekickskubay/store?strNbr=${locNbr}`} data-test='b2osa-link'>SKU/Bay</Link>
          {` / D${deptNbrInt}`}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
      </Grid.Column>
    </Grid>

    <Divider />

    <Header textAlign='center' >{`${title} ${bay}`}</Header>
    Completed by: {compBy} <br />
    Completion Date: {compDate}
    <DataTable data={dirTasksSkuData} />
    </>
)
})
