import React, { useContext, useState } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';

export const Boss = () => {
  const { assocOnTimePickupData } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const showCustomTimeframe = ['# Missed SLA', 'Non-Compliant Orders'];

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate' },
            { name: 'Pick On Time %' },
            { name: '# Orders Missed' },
            { name: 'Avg Mins to Pick' },
            { name: 'DS Compliance %' }
          ],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE, 5) },
            { pctValue: data.DS_COMPLIANCE_PCT },
            { numValue: data.MISSED_SLA },
            { numValue: (data.MISSED_SLA / data.TOTAL_ORDERS) * 100 },
            { pctValue: data.DS_COMPLIANCE }
          ])
        };
      case '# Missed SLA':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'Order #' }, { name: 'Order Date' }, { name: 'Mins To Pick' }],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.TOTAL_ORDERS },
            { numValue: data.ORDER_DATE },
            { numValue: data.MINS_TO_PICK }
          ])
        };
      case 'Non-Compliant Orders':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Order #' },
            { name: 'Order Date' },
            { name: 'Directed Location' },
            { name: 'Staged Location' }
          ],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.TOTAL_ORDERS },
            { stringValue: data.ORDER_DATE },
            { stringValue: data.DIRECTED_LOCATION },
            { stringValue: data.STAGED_LOCATION }
          ])
        };
      case 'DS Compliance':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'DS Compliance %' }, { name: '# Non-Compliance Orders' }],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { pctValue: data.DS_COMPLIANCE_PCT },
            { numValue: data.NO_ORDER_COMPLIANCE_NOM }
          ])
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' },
          { key: 'Non-Compliant Orders', text: 'Non-Compliant Orders', value: 'Non-Compliant Orders' },
          { key: 'DS Compliance', text: 'DS Compliance', value: 'DS Compliance' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataTable data={getData()} />
    </>
  );
};
