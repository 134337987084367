import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { DataView } from '../../../../../components/Common/DataView';
import { HelpersCoreLinks } from './HelpersCoreLinks';
import { executionScoreByDivisionTableBuilder } from '../utils/coreSummary';

export const DivisionList = () => {
  const { fpToggle, timeframe } = useShrinkCoreContext();
  const { data } = useQueryApi('queryShrinkMetricsScoresCore', {
    fpToggle,
    isCore: true
  });
  const dataFiltered = data.filter(row => row.TIME_FRAME === timeframe);
  const dataObj = executionScoreByDivisionTableBuilder(dataFiltered);

  return (
    <DataView>
      <HelpersCoreLinks level='core' />

      <DataView.Title position='center'>Shrink Exec Score By Division</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
