import { useMemo } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import { buildHeaders, buildRows } from '../../../../../../components/SpecialtyProRentalContainer/utils/servicesSalesTable';
import { FOCUS_6 } from '../../../../../../components/SpecialtyProRentalContainer/utils/specialtyProRentalServices';

export const CoreAllProgramSales = ({ allProgramSalesData, timeframe, focus6AllPrograms }) => {
  const dataFilteredMemo = useMemo(() => {
    if (focus6AllPrograms !== FOCUS_6) return allProgramSalesData;

    return allProgramSalesData?.filter(row => row.TOP_6_CATEGORY <= 6);
  }, [allProgramSalesData, focus6AllPrograms]);

  const dataObj = {
    data: buildRows(dataFilteredMemo, true, 'core', '', timeframe),
    headers: buildHeaders(),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title position='center'>All Programs</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
