import { createContext } from 'react';
import { useShrinkACCRCVQuries } from '../features/shrink/hooks/useShrinkACCRCVQuries';
import { Loader } from 'semantic-ui-react';

export const CoreShrinkACCRCVContext = createContext();

export const CoreShrinkACCRCVProvider = ({ children, fpToggle,timeframe,settimeframe, ...props }) => {

    const {
        isError,
        isLoading,
        queryACCRCVByCore,
    } = useShrinkACCRCVQuries(fpToggle);
    const value = {
        ...props,
        timeframe,
        settimeframe,
        queryACCRCVByCore,
    };

    if (isError) return <p>Something went wrong</p>;
    if (isLoading) return <Loader active>Loading...</Loader>;

    return <CoreShrinkACCRCVContext.Provider fpToggle={fpToggle} value= {value}>{children}</CoreShrinkACCRCVContext.Provider>
}
