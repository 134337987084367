import { Header, Loader, Tab } from 'semantic-ui-react';
import { CoreFocus6SalesTab } from './CoreFocus6SalesTab';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { CoreFocus6PipelineTab } from './CoreFocus6PipelineTab';

export const Focus6Tab = ({ timeframe, settimeframe, fpToggle }) => {
  const {
    data: focus6Data,
    error: focus6Error,
    isLoading: focus6IsLoading
  } = useQueryApi('queryServicesFocus6SalesAtCore', {
    fpToggle,
    isCore: true
  });

  const {
    data: leadsMeasuresData,
    error: leadsMeasuresError,
    isLoading: leadsMeasuresIsLoading
  } = useQueryApi('queryLeadsMeasuresPerformanceAtCore', {
    fpToggle,
    isCore: true
  });

  if (focus6IsLoading || leadsMeasuresIsLoading) return <Loader active>Loading...</Loader>;
  if (focus6Error || leadsMeasuresError) return <Header textAlign='center'>Error fetching Focus 6 data</Header>;
  if (!focus6Data || focus6Data.length === 0 || !leadsMeasuresData || leadsMeasuresData.length === 0) {
    return <Header textAlign='center'>No Focus 6 data available</Header>;
  }

  const panes = [
    {
      menuItem: 'Sales',
      render: () => (
        <CoreFocus6SalesTab focus6Data={focus6Data} leadsMeasuresData={leadsMeasuresData} timeframe={timeframe} />
      )
    },
    {
      menuItem: 'Pipeline',
      render: () => <CoreFocus6PipelineTab focus6Data={focus6Data} timeframe={timeframe} />
    }
  ];

  return <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ padding: '1.5rem', marginTop: '.4rem' }} />;
};
