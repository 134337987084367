import { Link } from 'react-router-dom';
import { Grid, Tab } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import { Focus6Tab } from '../components/focus-6/Focus6Tab';
import { PipelineTab } from '../components/pipeline/PipelineTab';

export const CoreServicesPage = ({ timeframe, settimeframe, fpToggle }) => {
  const panes = [
    {
      menuItem: 'Focus 6',
      render: () => (
        <Tab.Pane>
          <Focus6Tab timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'All Programs',
      render: () => (
        <Tab.Pane>
          <PipelineTab timeframe={timeframe} fpToggle={fpToggle} />
        </Tab.Pane>
      )
    }
  ];

  return (
    <>
      <DataView className='mt-4'>
        <DataView.Title textAlign='center'>Services - Core</DataView.Title>
        <DataView.Controls>
          <Grid>
            <Grid.Column width={8}>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Link style={{ color: '#EE7125' }} to={'/core/specialtyprorental'}>
                  Back to Specialty/Pro/Rental
                </Link>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={8} textAlign='right'>
              Timeframe:
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
              &nbsp;
            </Grid.Column>
          </Grid>
        </DataView.Controls>
      </DataView>
      <Tab panes={panes} style={{ marginBottom: '1em' }} />
    </>
  );
};
