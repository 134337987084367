import React, { useContext } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { OnTimePickupAssoc } from './OnTimePickupAssoc';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { Header } from 'semantic-ui-react';

export const OnTimePickup = () => {
    const { orderFulfillmentPickupData } = useContext(StoreOrderFulfillmentContext);

    const pickupMethodsDataObj = {
        headers: [
            { name: 'Method' },
            { name: 'Pick On Time %' },
            { name: '# of Orders Missed SLA' },
            { name: 'Avg Min to Pick' },
            { name: 'DS Compliance %' }
        ],
        data: orderFulfillmentPickupData.filter(({ METHOD }) => METHOD !== 'Curbside')
            .map(data => [
                {
                    stringValue: data.METHOD + " ",
                    popup: (data.METHOD === 'Will Call')
                        ? {
                            content: 'Will Call includes Stock Will Call or In-Store Stock items only.',
                            icon: 'info circle circleInfoButton',
                            color: 'blue' //Semantic-UI doesn't have Circle Info Icon without background
                        } : undefined
                },
                {
                    pctValue: data.PICK_ON_TIME / data.TOTAL_ORDERS
                },
                { numValue: data.TOTAL_ORDERS - data.PICK_ON_TIME },
                { numValue: data.METHOD !== 'Will Call' ? data.AVG_MIN_TO_PICK : '--' },
                { pctValue: data.METHOD !== 'Will Call' ? 0.9 : '--' } // TODO: Get the real data for this
            ])
    }

    const curbsideDataObj = {
        headers: [
            { name: 'Method' },
            { name: 'Avg Customer Wait Time' },
            { name: '# of Orders Missed SLA' },
            { name: '% of Orders Missed SLA' }
        ],
        data: orderFulfillmentPickupData.filter(({ METHOD }) => METHOD === 'Curbside')
            .map(data => [
                { stringValue: data.METHOD },
                { numValue: data.AVG_MIN_TO_PICK },
                { numValue: data.TOTAL_ORDERS - data.PICK_ON_TIME },
                { pctValue: (data.TOTAL_ORDERS - data.PICK_ON_TIME) / data.TOTAL_ORDERS },
            ])
    };

    return (<>
        <Header as='h1'>Pickup Details by Method</Header>
        <DataTable data={pickupMethodsDataObj} />
        <Header as='h1'>Pickup Details for Curbside</Header>
        <DataTable data={curbsideDataObj} />
        <Header as='h1'>More Details</Header>
        <OnTimePickupAssoc />
    </>);
};



