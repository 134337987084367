import { useState } from 'react';
import { DataTable } from '../../Common/DataTable';
import { DataView } from '../../Common/DataView';
import { DISTRICT_HEADERS, districtDataBuilder } from '../utils/overview';
import { UnitMenu } from '../../Common/UnitMenu';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { columnName } from '../../../utils/constantsByLevel';
import { Header, Tab } from 'semantic-ui-react';
import { ClassesSummaryTab } from './tabs/ClassesSummaryTab';
import { DepartmentsSummaryTab } from './tabs/DepartmentsSummaryTab';
import { SkuSummaryTab } from './tabs/SkuSummaryTab';
import { SubClassesSummaryTab } from './tabs/SubClassesSummaryTab';

export const ShrinkContainerSummary = () => {
  const { fpToggle } = useShrinkContext();
  const { results, level, locNbr } = useShrinkQueries({
    fpToggle,
    queries: ['query/queryShrinkActuals', 'query/queryShrinkActualsDept']
  });
  const [queryShrinkActuals] = results;
  const [unit, setUnit] = useState('NOM');
  const headers = DISTRICT_HEADERS(unit);
  const levelName = queryShrinkActuals.data[0][columnName[level]];
  const dataAsOf = queryShrinkActuals.data[0].TRNSFM_DT;

  const data = {
    headers,
    data: queryShrinkActuals?.data.map(row => districtDataBuilder(row, unit))
  };

  return (
    <section>
      <DataView className='mb-4'>
        <DataView.Title className='mt-4' position='center'>
          Shrink Summary - {level} {locNbr}, {levelName.toLowerCase()}
          <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
        </DataView.Title>
        <DataView.Controls>
          <p>Units:</p>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </DataView.Controls>

        <DataTable data={data} />
      </DataView>

      <Tab menu={{ size: 'small', widths: panes.length }} panes={panes} />
    </section>
  );
};

const panes = [
  {
    menuItem: 'DEPT',
    render: () => <DepartmentsSummaryTab />
  },
  {
    menuItem: 'CLASS',
    render: () => <ClassesSummaryTab />
  },
  {
    menuItem: 'SUBCLASS',
    render: () => <SubClassesSummaryTab />
  },
  {
    menuItem: 'SKUS',
    render: () => <SkuSummaryTab />
  }
];
