import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { buildBaseDrillLink } from '../utils/url';
import { capitalizeFirstLetter } from '../../../utils/string';
import { getCompareStyle } from '../../Common/TableCellUtils';
import { formatLevelNumber } from '../../../utils/level';
import { DataView } from '../../Common/DataView';
import { HelpersLinks } from './HelpersLinks';

export const LevelList = () => {
  const { timeframe, fpToggle } = useShrinkContext();
  const {
    level,
    results: [queryShrinkMetricsScoresByLevel]
  } = useShrinkQueries({
    fpToggle,
    queries: ['query/queryShrinkMetricsScoresByLevel']
  });
  const { propToRender, subMetric } = METRIC_CONSTANTS[level];
  const { levelName: subLevelName, urlParam } = METRIC_CONSTANTS[level].subLevel;

  const data = queryShrinkMetricsScoresByLevel.data.filter(row => {
    return row.TIME_FRAME === timeframe && row.METRIC_LEVEL === subMetric;
  });

  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  const dataObj = {
    data: data.map(row => [
      {
        link: {
          to: `/${baseLevelShrinkUrl}`,
          params: {
            [urlParam]: formatLevelNumber(row[propToRender])
          }
        },
        stringValue: row[propToRender]
      },
      {
        pctValue: row.SHRINK_EXEC_SCORE
      },
      {
        customStyle: getCompareStyle(row.SHRINK_EXEC_SCORE - row.LY_SHRINK_EXEC_SCORE),
        pctValue: row.SHRINK_EXEC_SCORE - row.LY_SHRINK_EXEC_SCORE
      }
    ]),
    headers: [{ name: capitalizeFirstLetter(subLevelName) }, { name: 'Total Score %' }, { name: 'vLY%' }],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView>
      <HelpersLinks level={level} />

      <DataView.Title position='center'>Shrink Exec Score By {subLevelName}</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
