import { useMemo, useState } from 'react';
import { useCoreOverviewContext } from '../../../../../hooks/useCoreOverviewContext';

import { Dropdown, Tab } from 'semantic-ui-react';
import { DataView } from '../../../../../../../components/Common/DataView';
import { UnitMenu } from '../../../../../../../components/Common/UnitMenu';

import {
  buildClassNameColumn,
  buildUnitValue,
  unitCellBuilder,
  CLASSES_DEPARTMENT_OPTIONS,
  CLASSES_METRIC_OPTIONS
} from '../../../../../utils/sales';
import { getCalloutStyle } from '../../../../../../../components/Common/TableCellUtils';
import { timeframeCode } from '../../../../../../../components/Common/timeframeCode';

export const SalesClassesTab = () => {
  const [dept, setDept] = useState('All');
  const [unit, setUnit] = useState('NOM');
  const [metric, setMetric] = useState('SLS');
  const { salesClassesData, timeframe } = useCoreOverviewContext();

  const isUnitNOM = unit === 'NOM';
  const isUnitPCT = unit === 'PCT';
  const isMetricSLS = metric === 'SLS';
  const isMetricUNT = metric === 'UNT';
  const isMetricTXN = metric === 'TXN';

  const dataFiltered = useMemo(() => {
    return salesClassesData.filter(
      row =>
        row &&
        row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] &&
        (dept === 'All' || row['DEPT_NBR'].toString() === dept)
    );
  }, [salesClassesData, timeframe, dept]);

  const dataSLSObj = {
    data: dataFiltered.map(data => [
      {
        ...buildClassNameColumn(data['MER_CLASS_NM'], data['EXT_CLASS_NBR'])
      },
      {
        orderBy: data['TY_SLS_AMT'],
        dollarValue: data['TY_SLS_AMT'],
        customStyle: getCalloutStyle(data['TY_SLS_AMT'])
      },
      {
        orderBy: isUnitPCT ? data['SLS_COMP_PCT'] : data['SLS_COMP_NOM'],
        ...unitCellBuilder(unit, data['SLS_VP_NOM'], data['SLS_VP_PCT']),
        compareStyle: true
      },
      {
        orderBy: isUnitPCT ? data['SLS_COMP_PCT'] : data['SLS_COMP_NOM'],
        ...unitCellBuilder(unit, data['SLS_COMP_NOM'], data['SLS_COMP_PCT']),
        compareStyle: true
      }
    ]),
    headers: [
      { name: 'Class' },
      { name: `Sales Amt ($)` },
      { name: `Sales vP ${isUnitPCT ? '%' : '($)'}` },
      { name: `Sales vLY ${isUnitPCT ? '%' : '($)'}` }
    ],
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  const dataUNTObj = {
    data: dataFiltered.map(data => [
      {
        ...buildClassNameColumn(data['MER_CLASS_NM'], data['EXT_CLASS_NBR'])
      },
      {
        orderBy: data['TY_UNT_SLS'],
        numValue: buildUnitValue(data['TY_UNT_SLS']),
        customStyle: getCalloutStyle(data['TY_UNT_SLS'])
      },
      {
        orderBy: isUnitPCT ? data['UNT_COMP_PCT'] : data['UNT_COMP_NOM'],
        stringValue: isUnitNOM ? buildUnitValue(data['UNT_COMP_NOM']) : undefined,
        pctValue: isUnitPCT ? (data['UNT_COMP_PCT'] == null ? '--' : data['UNT_COMP_PCT']) : undefined,
        compareStyle: true
      }
    ]),
    headers: [{ name: 'Class' }, { name: `Unit Sales (#)` }, { name: `Units vLY ${isUnitPCT ? '%' : '(#)'}` }],
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  const dataTXNObj = {
    data: dataFiltered.map(data => [
      {
        ...buildClassNameColumn(data['MER_CLASS_NM'], data['EXT_CLASS_NBR'])
      },
      {
        orderBy: data['TY_TXN_CNT'],
        stringValue: buildUnitValue(data['TY_TXN_CNT']),
        customStyle: getCalloutStyle(data['TY_TXN_CNT'])
      },
      {
        orderBy: isUnitPCT ? data['TXN_COMP_PCT'] : data['TXN_COMP_NOM'],
        stringValue: isUnitNOM ? buildUnitValue(data['TXN_COMP_NOM']) : undefined,
        pctValue: isUnitPCT ? (data['TXN_COMP_PCT'] == null ? '--' : data['TXN_COMP_PCT']) : undefined,
        compareStyle: true
      }
    ]),
    headers: [{ name: 'Class' }, { name: `Trans Cnt (#)` }, { name: `Trans vLY ${isUnitPCT ? '%' : '(#)'}` }],
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  const dataAVG_TCKTObj = {
    data: dataFiltered.map(data => [
      {
        ...buildClassNameColumn(data['MER_CLASS_NM'], data['EXT_CLASS_NBR'])
      },
      {
        orderBy: data['TY_AVG_TCKT'],
        dollarValue: data['TY_AVG_TCKT'],
        customStyle: getCalloutStyle(data['TY_AVG_TCKT'])
      },
      {
        orderBy: isUnitPCT ? data['AVG_TCKT_COMP_PCT'] : data['AVG_TCKT_COMP_NOM'],
        ...unitCellBuilder(unit, data['AVG_TCKT_COMP_NOM'], data['AVG_TCKT_COMP_PCT']),
        compareStyle: true
      }
    ]),
    headers: [{ name: 'Class' }, { name: `Avg Ticket ($)` }, { name: `Avg Ticket vLY ${isUnitPCT ? '%' : '($)'}` }],
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  const dataTableData = isMetricSLS ? dataSLSObj : isMetricUNT ? dataUNTObj : isMetricTXN ? dataTXNObj : dataAVG_TCKTObj;

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />

          <Dropdown
            className='mx-3'
            compact
            defaultValue='SLS'
            options={CLASSES_METRIC_OPTIONS}
            onChange={(_, { value }) => setMetric(value)}
            selection
            scrolling
            size='tiny'
          />

          <Dropdown
            compact
            defaultValue='All'
            onChange={(_, { value }) => setDept(value)}
            options={CLASSES_DEPARTMENT_OPTIONS}
            selection
            size='tiny'
          />
        </DataView.Controls>

        <DataView.Title>Sales by Classes</DataView.Title>

        <DataView.DataTable data={dataTableData} />
      </DataView>
    </Tab.Pane>
  );
};
