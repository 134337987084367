import { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { UnitMenu } from '../../../../../../components/Common/UnitMenu';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { getPrevAndNextElement } from '../../../../../../utils/array';
import { coreInventoryDrillBuilder } from '../../utils/inventory/CoreInventoryDrillBuilder';

export const CoreInventoryDrill = ({ fpToggle }) => {
  const { deptNbr } = useQueryParams();
  const [unit, setUnit] = useState('NOM');
  const isNom = unit === 'NOM';
  const isPct = unit === 'PCT';

  const { data: inventoryDrillData = [], isLoading: isInventoryDrillData } = useQueryApi(
    `queryInvClsByCoreDept/${deptNbr}`,
    {
      fpToggle,
      isCore: true
    }
  );
  const { data: deptList = [], isLoading: isDeptList } = useQueryApi(`deptSalesByCore/${deptNbr}`, {
    fpToggle,
    isCore: true
  });

  const { dataObj } = coreInventoryDrillBuilder(inventoryDrillData, isPct, isNom);

  const deptDataSorted = deptList.sort((left, right) => {
    const result = left.DEPT_NBR - right.DEPT_NBR;
    return result;
  });
  const currentIndex = deptDataSorted.findIndex(dept => dept['DEPT_NBR'] === parseInt(deptNbr));
  const [previousDept, nextDept] = getPrevAndNextElement(deptDataSorted, currentIndex);

  if (isInventoryDrillData || isDeptList) return <Loader active>Loading...</Loader>;

  return (
    <>
      <Header data-test='department-header' textAlign='center'>
        {deptDataSorted[currentIndex]?.MER_DEPT_NM}
      </Header>
      <Grid className='adjacent-departments'>
        <Grid.Row>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/core/inventory/dept?deptNbr=${previousDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {previousDept?.MER_DEPT_NM}
            </Link>
          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/core/inventory/dept?deptNbr=${nextDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {nextDept?.MER_DEPT_NM}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/core/overview`} data-test='b2o-link'>
                Back to Overview
              </Link>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Inventory Detail</Header>

      <DataTable data={dataObj} />
    </>
  );
};
