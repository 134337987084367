import React from 'react';
import { Loader, Segment, Tab } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { DriversCoreContainers } from '../components/DriversCoreContainer';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { CoreSummaryTab } from '../components/CoreSummaryTab';

const ShrinkPageCore = () => {
  const { fpToggle } = useShrinkCoreContext();
  const queryParams = { fpToggle, isCore: true };

  const { data: shrinkMetricsDataCore, isLoading } = useQueryApi('query/queryShrinkMetricsByLevel/core/0000', queryParams);
  const { data: scoresDataCore, isLoading: loading } = useQueryApi('queryShrinkMetricsScoresByScore', queryParams);
  const { isLoading: isShrinkMetricsLoading } = useQueryApi('queryShrinkMetricsScoresCore', queryParams);
  const { data: queryACCRCVByLevel, isLoading: isACCRCVByLevelLoading } = useQueryApi('queryACCRCVByCore', queryParams);

  // CoreSummaryTabs Queries
  const { data: summaryData, isLoading: loadingSummary } = useQueryApi('queryShrinkSummaryAtCore', queryParams);
  const { data: departmentData, isLoading: loadingDept } = useQueryApi('queryShrinkDepartmentSummaryAtCore', queryParams);
  const { data: classesData, isLoading: loadingClasses } = useQueryApi('queryShrinkClassesSummaryAtCore', queryParams);
  const { data: subClassesData, isLoading: loadingSubClasses } = useQueryApi(
    'queryShrinkSubClassesSummaryAtCore',
    queryParams
  );
  const { data: top40SkuData, isLoading: loadingTop40Sku } = useQueryApi('queryShrinkTop40SkuAtCore', queryParams);

  const panes = [
    {
      menuItem: 'DRIVERS',
      render: () => (
        <DriversCoreContainers
          shrinkMetricsData={shrinkMetricsDataCore}
          scoresData={scoresDataCore}
          queryACCRCVByLevel={queryACCRCVByLevel}
          headTitle='Shrink Metrics - CORE'
        />
      )
    },
    {
      menuItem: 'SUMMARY',
      render: () => (
        <CoreSummaryTab
          summaryData={summaryData}
          classesData={classesData}
          top40SkuData={top40SkuData}
          departmentData={departmentData}
          subClassesData={subClassesData}
        />
      )
    }
  ];

  if (
    isLoading ||
    loading ||
    scoresDataCore.length === 0 ||
    shrinkMetricsDataCore.length === 0 ||
    isACCRCVByLevelLoading ||
    loadingSummary ||
    loadingDept ||
    loadingClasses ||
    loadingSubClasses ||
    loadingTop40Sku ||
    isShrinkMetricsLoading ||
    queryACCRCVByLevel.length === 0
  )
    return <Loader active>Loading...</Loader>;

  return (
    <Segment>
      <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />
    </Segment>
  );
};

export default ShrinkPageCore;
