import { Grid, Header, Loader } from "semantic-ui-react";
import { TimeframeDropdownShrink } from "../../Common/TimeframeDropdownShrink";
import { Link } from "react-router-dom";
import { useShrinkQueries } from "../hooks/useShrinkQueries";
import { columnName, METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { AgedWithCalls } from "../components/AgedWithCalls";
import { buildBaseShrinkUrl } from "../utils/url";
import { agedWithCallsData } from "../utils/dataObjectBuilder";

export const AgedWithCallsPage = ({ timeframe, fpToggle, settimeframe }) => {
  const queries = ['query/queryShrinkMetricsScoresByLevel', 'query/queryShrinkMetricsBySubLevel']

  const { isError, isLoading, level, locNbr, results } = useShrinkQueries({ fpToggle, queries })

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const [shrinkScoreData, shrinkData] = results

  const recordsByLevel = shrinkScoreData.data.filter(row => row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['FILTER_BY_METRIC'] && row['TIME_FRAME'] === timeframe)
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${baseLevelShrinkUrl}?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`} data-test='b2o-link'>
              Back to Shrink Overview
            </Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <AgedWithCalls
        locNbr={locNbr}
        level={level}
        dataAsOf={recordsByLevel[0]?.TRNSFM_DT}
        levelName={String(recordsByLevel[0][columnName[level]]).toLowerCase()}
        dataObject={agedWithCallsData({ level, shrinkData: shrinkData.data, shrinkScoreData: recordsByLevel, timeframe })}
      />
    </>
  );
}