import { DataView } from "../../Common/DataView";
import { useShrinkContext } from "../hooks/useShrinkContext";
import { useShrinkQueries } from "../hooks/useShrinkQueries";
import { accuracyAndReceivingData } from "../utils/dataObjectBuilder";

export const AccuracyAndReceiving = ({ queryACCRCVByLevel }) => {
  const { timeframe, fpToggle } = useShrinkContext();
  const { locNbr, level } = useShrinkQueries({ fpToggle })

  const accuracyDataObj = accuracyAndReceivingData({ level, locNbr, timeframe, queryACCRCVByLevel })

  return (
    <DataView className='mt-4'>
      <DataView.Title>
        Accuracy & Receiving
      </DataView.Title>
      <DataView.DataTable data={accuracyDataObj} />
    </DataView>
  );
}