import React from 'react';
import { Header, Icon, Popup, Table } from 'semantic-ui-react';
import { getCompareStyle, getPercentString } from '../../Common/TableCellUtils';

// Utility function to get the score data for a specific timeframe
const getTimeframeData = (timeframe, scoresData) => {
  return scoresData.find(row => row && row['TIME_FRAME'] === timeframe);
};

const getTimeframeLabel = (label) => {
  // Get the current day of the week (used to determine WTD or LW)
  const currentDay = new Date().getDay();
  const isMonday = currentDay === 1;

  if (isMonday === false) {
    return label;
  }
  if (label === 'WTD') {
    return 'LW';
  } else if (label === 'LW') {
    return 'LLW';
  } else {
    return label;
  }
}

// List of timeframes and their labels
const timeframes = [
  { label: 'WTD', display: 'WTD' },
  { label: 'LW', display: 'LW' },
  { label: 'LM', display: 'LM' },
  { label: 'MTD', display: 'MTD' },
  { label: 'QTD', display: 'QTD' },
  { label: 'HTD', display: 'HTD' },
  { label: 'YTD', display: 'YTD' },
  { label: 'R12M', display: 'L12' },
  { label: 'LAST_INV', display: 'Since Last Inv' }
];

export const RegionExecScoreByTimeframe = ({ scoresData }) => {

  return (
    <div>
      <Header textAlign='center'>Shrink Exec Score by Timeframe</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timeframe</Table.HeaderCell>
            <Table.HeaderCell>Total Score</Table.HeaderCell>
            <Table.HeaderCell>
              vLY{' '}
              <Popup
                on='click'
                position='bottom left'
                content='For the total Operational Shrink Execution Score, always try to perform better year-over-year.'
                trigger={<Icon name='info circle' color='blue' />}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {timeframes.map(({ label, display }) => {
            const currentData = getTimeframeData(label, scoresData);
            const lastYearData = getTimeframeData(label, scoresData);
            const currentScore = currentData ? currentData['SHRINK_EXEC_SCORE'] : 0;
            const lastYearScore = lastYearData ? lastYearData['LY_SHRINK_EXEC_SCORE'] : 0;
            const compareStyle = getCompareStyle(currentScore - lastYearScore);

            return (
              <Table.Row key={label}>
                <Table.Cell>{ getTimeframeLabel(display) }</Table.Cell>
                <Table.Cell>{currentData ? getPercentString(currentScore) : '-'}</Table.Cell>
                <Table.Cell style={compareStyle}>
                  {currentData ? getPercentString(currentScore - lastYearScore) : '-'}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
