import React from 'react';
import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { CoreMonitorMetricsDrill } from '../components/CoreMonitorMetricsDrill';
import { accuracyandReceivingDrillHeaders, ohAutoRejectRows } from '../utils/accuracyAndReceivingBuilderCore';
import { shrinkMonitorMetricsDataReduced } from '../../../../../components/ShrinkContainer/utils/shrinkMonitorMetrics';
import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';

export const OhAutoRejectCorePage = () => {
  const { timeframe, fpToggle } = useShrinkCoreContext();

  const defaultParams = {
    fpToggle,
    isCore: true
  };

  const {
    data: queryShrinkMetricsData = [],
    isLoading: queryShrinkMetricsLoading,
    error: queryShrinkMetricsError
  } = useQueryApi('query/queryShrinkMetricsByLevelPlusOne/core', defaultParams);

  if (queryShrinkMetricsLoading) return <Loader active>Loading...</Loader>;
  if (queryShrinkMetricsError) return <Header textAlign='center'>Something went wrong.</Header>;

  const level = 'core';
  const headers = accuracyandReceivingDrillHeaders(level);
  const metricsData = shrinkMonitorMetricsDataReduced(queryShrinkMetricsData, level);

  const titleSingle = 'OH-Auto Rej Rate';
  const title = `${titleSingle} - ${level}`;
  const dataAsOf = queryShrinkMetricsData[0]?.TRNSFM_DT;
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const breadcrumb = [{ to: `/${baseLevelShrinkUrl}`, text: 'Back to Shrink Overview' }, { text: titleSingle }];

  const data = {
    sortable: true,
    sortableColumns: [0, 1],
    sortableDefault: 0,
    headers,
    data: queryShrinkMetricsData.map(row => ohAutoRejectRows(row, metricsData, timeframe, level))
  };

  return <CoreMonitorMetricsDrill data={data} title={title} breadcrumb={breadcrumb} dataAsOf={dataAsOf} />;
};
