import { capitalizeFirstLetter } from '../../../utils/string';
import { formatColor, getThousandDollarString } from '../../Common/TableCellUtils';
import {
  SPEC_PRO_RENTAL_PATH,
  OPEN_QUOTE_AMT,
  OPEN_QUOTE_CNT,
  QUOTE_SOLD_AMT,
  QUOTE_SOLD_CNT,
  TOT_QUOTE_CNT,
  DMG_WVR_PCT,
  TTL_CAD_WO,
  CAD_NO_PARTS,
  LTRA_PCT,
  GET_PCT,
  LTRA_GOAL,
  RENTAL_GET_GOAL
} from '../utils/consts';

export const getTitle = params => {
  const { level, locNbr, currentLevelName } = params;
  const { levelName } = level;
  const baseTitle = 'Specialty, Pro, Rental';

  const title = `${baseTitle} - ${capitalizeFirstLetter(levelName)}`;
  const titleSufix = locNbr && locNbr ? `${locNbr}, ${currentLevelName}` : '';

  return `${title} ${titleSufix}`;
};

// Quotes Table

const getQuotesTableHeaders = params => {
  const { level } = params;
  const {
    subLevel: { levelName: subLevelName }
  } = level;

  return [
    { name: `${capitalizeFirstLetter(subLevelName)} #` },
    { name: 'Open Opportunity' },
    { name: 'Open Count' },
    { name: 'Sold $' },
    { name: 'Sold Count' },
    { name: 'Sold Quote % *' }
  ];
};

const getQuotesRowValues = params => {
  const { row, timeframe } = params;

  const openOpportunity = row[`${timeframe}_${OPEN_QUOTE_AMT}`];
  const openCount = row[`${timeframe}_${OPEN_QUOTE_CNT}`];
  const sold = row[`${timeframe}_${QUOTE_SOLD_AMT}`];
  const soldCount = row[`${timeframe}_${QUOTE_SOLD_CNT}`];
  const soldQuotePct = soldCount / row[`${timeframe}_${TOT_QUOTE_CNT}`];

  return { openOpportunity, openCount, sold, soldCount, soldQuotePct };
};

const getQuotesRow = params => {
  const { openOpportunity, openCount, sold, soldCount, soldQuotePct } = params;

  return [
    {
      stringValue: getThousandDollarString(openOpportunity, 1),
      orderBy: openOpportunity
    },
    {
      stringValue: openCount.toLocaleString(undefined, { maximumFractionDigits: 1 }),
      orderBy: openCount
    },
    {
      stringValue: getThousandDollarString(sold, 1),
      orderBy: sold
    },
    {
      stringValue: soldCount.toLocaleString(undefined, { maximumFractionDigits: 1 }),
      orderBy: soldCount
    },
    {
      pctValue: soldQuotePct
    }
  ];
};

const getQuotesTableData = params => {
  const { level, timeframe, quotesData } = params;
  const {
    subLevel: { levelName: subLevelName, urlParam: subUrlParam, currentLevel: subCurrentLevel }
  } = level;

  return quotesData.map(row => {
    const subLevelNbr = row[subCurrentLevel];
    const { openOpportunity, openCount, sold, soldCount, soldQuotePct } = getQuotesRowValues({ row, timeframe });

    return [
      {
        stringValue: subLevelNbr,
        link: {
          to: `/${subLevelName}/${SPEC_PRO_RENTAL_PATH}`,
          params: {
            [subUrlParam]: subLevelNbr
          }
        }
      },
      ...getQuotesRow({ openOpportunity, openCount, sold, soldCount, soldQuotePct })
    ];
  });
};

const getQuotesTableFooter = params => {
  const { timeframe, quotesData } = params;

  const quotesTotalData = quotesData.reduce((acc, row) => {
    const { openOpportunity, openCount, sold, soldCount, soldQuotePct } = getQuotesRowValues({ row, timeframe });

    return {
      openOpportunity: (acc.openOpportunity || 0) + openOpportunity,
      openCount: (acc.openCount || 0) + openCount,
      sold: (acc.sold || 0) + sold,
      soldCount: (acc.soldCount || 0) + soldCount,
      soldQuotePct: (acc.soldQuotePct || 0) + soldQuotePct
    };
  }, {});

  const quotesTotalDataWithPct = {
    ...quotesTotalData,
    soldQuotePct: quotesTotalData.soldQuotePct / quotesData.length
  };

  return [[{ stringValue: 'Total' }, ...getQuotesRow(quotesTotalDataWithPct)]];
};

export const getQuotesDataTable = params => {
  const { level, timeframe, quotesData } = params;

  return {
    headers: getQuotesTableHeaders({ level }),
    data: getQuotesTableData({ level, timeframe, quotesData }),
    footer: getQuotesTableFooter({ timeframe, quotesData }),
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending'
  };
};

// Rental Key Metrics Table

const getRentalKeyMetricsTableHeaders = params => {
  const { level } = params;
  const {
    subLevel: { levelName: subLevelName }
  } = level;

  return [
    { name: `${capitalizeFirstLetter(subLevelName)} #` },
    { name: 'Damage Waiver %' },
    { name: 'Critical Down Tools' },
    { name: 'Critical Down Tools - No Parts' },
    { name: 'LTRA' },
    { name: 'Rental GET' }
  ];
};

const getRentalKeyMetricsRowValues = params => {
  const { row, timeframe } = params;

  const damageWaiverPct = row[`${timeframe}_${DMG_WVR_PCT}`];
  const criticalDownTools = row[TTL_CAD_WO];
  const criticalDownToolsNoParts = row[CAD_NO_PARTS];
  const ltra = row[`${timeframe}_${LTRA_PCT}`];
  const rentalGet = row[`${timeframe}_${GET_PCT}`];

  return { damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet };
};

const getRentalKeyMetricsRow = params => {
  const { damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet } = params;

  return [
    { pctValue: damageWaiverPct, orderBy: damageWaiverPct },
    { numValue: criticalDownTools, orderBy: criticalDownTools },
    { numValue: criticalDownToolsNoParts, orderBy: criticalDownToolsNoParts },
    { pctValue: ltra, orderBy: ltra, customStyle: formatColor(ltra, LTRA_GOAL) },
    { pctValue: rentalGet, orderBy: rentalGet, customStyle: formatColor(rentalGet, RENTAL_GET_GOAL) }
  ];
};

const getRentalKeyMetricsTableData = params => {
  const { level, timeframe, rentalKeyMetricsData } = params;
  const {
    subLevel: { levelName: subLevelName, urlParam: subUrlParam, currentLevel: subCurrentLevel }
  } = level;

  return rentalKeyMetricsData.map(row => {
    const subLevelNbr = row[subCurrentLevel];
    const { damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet } = getRentalKeyMetricsRowValues({
      row,
      timeframe
    });

    return [
      {
        stringValue: subLevelNbr,
        orderBy: subLevelNbr,
        link: {
          to: `/${subLevelName}/${SPEC_PRO_RENTAL_PATH}`,
          params: {
            [subUrlParam]: subLevelNbr
          }
        }
      },
      ...getRentalKeyMetricsRow({ damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet })
    ];
  });
};

const getRentalKeyMetricsTableFooter = params => {
  const { timeframe, rentalKeyMetricsData } = params;

  const rentalKeyMetricsTotalData = rentalKeyMetricsData.reduce((acc, row) => {
    const { damageWaiverPct, criticalDownTools, criticalDownToolsNoParts, ltra, rentalGet } = getRentalKeyMetricsRowValues({
      row,
      timeframe
    });

    return {
      damageWaiverPct: (acc.damageWaiverPct || 0) + damageWaiverPct,
      criticalDownTools: (acc.criticalDownTools || 0) + criticalDownTools,
      criticalDownToolsNoParts: (acc.criticalDownToolsNoParts || 0) + criticalDownToolsNoParts,
      ltra: (acc.ltra || 0) + ltra,
      rentalGet: (acc.rentalGet || 0) + rentalGet
    };
  }, {});

  const rentalKeyMetricsTotalDataWithPct = {
    ...rentalKeyMetricsTotalData,
    damageWaiverPct: rentalKeyMetricsTotalData.damageWaiverPct / rentalKeyMetricsData.length,
    ltra: rentalKeyMetricsTotalData.ltra / rentalKeyMetricsData.length,
    rentalGet: rentalKeyMetricsTotalData.rentalGet / rentalKeyMetricsData.length
  };

  return [[{ stringValue: 'Total' }, ...getRentalKeyMetricsRow(rentalKeyMetricsTotalDataWithPct)]];
};

export const getRentalKeyMetricsDataTable = params => {
  const { level, timeframe, rentalKeyMetricsData } = params;

  return {
    headers: getRentalKeyMetricsTableHeaders({ level }),
    data: getRentalKeyMetricsTableData({ level, timeframe, rentalKeyMetricsData }),
    footer: getRentalKeyMetricsTableFooter({ timeframe, rentalKeyMetricsData }),
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending'
  };
};
