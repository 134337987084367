import { Header, Loader, Segment, Tab } from 'semantic-ui-react';
import { DriversContainers } from '../components/DriversContainer';
import { ShrinkContainerSummary } from '../components/ShrinkContainerSummary';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';

export const ShrinkPage = () => {
  const { fpToggle } = useShrinkContext();
  // Add new endpoint into queries array
  const queryBuilder = {
    fpToggle,
    queries: [
      'query/queryShrinkActuals',
      'query/queryShrinkActualsDept',
      'query/queryShrinkMetricsByLevel',
      'query/queryShrinkMetricsScoresByLevel',
      // 'query/queryShrinkExecScoreByLevel',
      'query/queryACCRCVByLevel',
      'query/queryShrinkActualsCls',
      'query/queryShrinkActualsTop40Skus',
      'query/queryShrinkActualsSubCls'
    ]
  };
  const { isError, isLoading } = useShrinkQueries(queryBuilder);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  return (
    <Segment>
      <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />
    </Segment>
  );
};

const panes = [
  {
    menuItem: 'DRIVERS',
    render: () => <DriversContainers />
  },
  {
    menuItem: 'SUMMARY',
    render: () => <ShrinkContainerSummary />
  }
];
