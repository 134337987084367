import { Header } from "semantic-ui-react";
import { DataView } from "../../Common/DataView";
import { invalidScandData } from "../utils/dataObjectBuilder";

export const InvalidScans = ({ level, locNbr, shrinkData, shrinkScoreData, levelName, dataAsOf, timeframe }) => {

  return (
    <DataView className='mt-4'>
      <DataView.Title position="center">
        Invalid Scans - {level} {locNbr}, {levelName}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={invalidScandData({ shrinkData, shrinkScoreData, timeframe, level })} />
    </DataView>
  );
}