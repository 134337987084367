import React, { useState } from 'react'
import {
  Table,
  Tab,
  Header
} from 'semantic-ui-react'
import {
  Link
} from "react-router-dom"


import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'

export const DstChannelView = ({ chnlData, chnlDtlData, delvDtlData, timeframe, dstNbr }) => {
  const [bopisSortColumn, setBopisSortColumn] = useState('STR_NBR')
  const [bopisSortDirection, setBopisSortDirection] = useState('ascending')
  const [bossSortColumn, setBossSortColumn] = useState('STR_NBR')
  const [bossSortDirection, setBossSortDirection] = useState('ascending')
  const [delvSortColumn, setDelvSortColumn] = useState('STR_NBR')
  const [delvSortDirection, setDelvSortDirection] = useState('ascending')

  let bopisDataFiltered = chnlData.filter((row) => {
    return row && row['CHANNEL'] === 'BOPIS'
  })

  let bossDataFiltered = chnlData.filter((row) => {
    return row && row['CHANNEL'] === 'BOSS'
  })

  let bopisDtlDataFiltered = chnlDtlData.filter((row) => {
    return row && row['CHANNEL'] === 'BOPIS'
  })

  let bopisDtlDataSorted = bopisDtlDataFiltered.sort((left, right) => {
    let result = left[bopisSortColumn] - right[bopisSortColumn]
    if (bopisSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let bossDtlDataFiltered = chnlDtlData.filter((row) => {
    return row && row['CHANNEL'] === 'BOSS'
  })

  let bossDtlDataSorted = bossDtlDataFiltered.sort((left, right) => {
    let result = left[bossSortColumn] - right[bossSortColumn]
    if (bossSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let delvDtlDataSorted = delvDtlData.sort((left, right) => {
    let result = left[delvSortColumn] - right[delvSortColumn]
    if (delvSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  console.log({ bopisDtlDataSorted, bossDtlDataSorted, delvDtlData })

  const bopisView = <Tab.Pane>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Avg Mins to Pick</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pick on Time %</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}># Missed SLA</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>DS Compliance %</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OF App %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body singleLine>
        {bopisDataFiltered.map((data) => {
          return (
            <Table.Row>
              <Table.Cell>{data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-'}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_PCT_ON_TM'])}</Table.Cell>
              <Table.Cell>{data[timeframe + '_OVER_SLA_CNT']}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_DS_COMP'])}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_OF_APP'])}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    <Header textAlign='center'>More Details - BOPIS</Header>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bopisSortColumn === 'STR_NBR' ? bopisSortDirection : null}
            onClick={() => {
              if (bopisSortColumn !== 'STR_NBR') {
                setBopisSortColumn('STR_NBR')
                setBopisSortDirection('ascending')
              } else {
                bopisSortDirection === 'ascending' ? setBopisSortDirection('descending') : setBopisSortDirection('ascending')
              }
            }}
          >Store #</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bopisSortColumn === timeframe + '_RLSD_ORDERS' ? bopisSortDirection : null}
            onClick={() => {
              if (bopisSortColumn !== timeframe + '_RLSD_ORDERS') {
                setBopisSortColumn(timeframe + '_RLSD_ORDERS')
                setBopisSortDirection('ascending')
              } else {
                bopisSortDirection === 'ascending' ? setBopisSortDirection('descending') : setBopisSortDirection('ascending')
              }
            }}
          >Fulfilled Orders</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bopisSortColumn === timeframe + '_AVG_MINS_TO_PICK' ? bopisSortDirection : null}
            onClick={() => {
              if (bopisSortColumn !== timeframe + '_AVG_MINS_TO_PICK') {
                setBopisSortColumn(timeframe + '_AVG_MINS_TO_PICK')
                setBopisSortDirection('ascending')
              } else {
                bopisSortDirection === 'ascending' ? setBopisSortDirection('descending') : setBopisSortDirection('ascending')
              }
            }}
          >Avg Mins to Pick</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bopisSortColumn === timeframe + '_PCT_ON_TM' ? bopisSortDirection : null}
            onClick={() => {
              if (bopisSortColumn !== timeframe + '_PCT_ON_TM') {
                setBopisSortColumn(timeframe + '_PCT_ON_TM')
                setBopisSortDirection('ascending')
              } else {
                bopisSortDirection === 'ascending' ? setBopisSortDirection('descending') : setBopisSortDirection('ascending')
              }
            }}
          >Pick on Time %</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bopisSortColumn === timeframe + '_DS_COMP' ? bopisSortDirection : null}
            onClick={() => {
              if (bopisSortColumn !== timeframe + '_DS_COMP') {
                setBopisSortColumn(timeframe + '_DS_COMP')
                setBopisSortDirection('ascending')
              } else {
                bopisSortDirection === 'ascending' ? setBopisSortDirection('descending') : setBopisSortDirection('ascending')
              }
            }}
          >DS Compliance %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body singleLine>
        {bopisDtlDataSorted.map((data) => {
          return (
            <Table.Row>
              <Table.Cell><Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/store/orderfulfillment?strNbr=${data['STR_NBR']}`}
              >{'STR ' + data['STR_NBR']}</Link></Table.Cell>
              <Table.Cell>{data[timeframe + '_RLSD_ORDERS']}</Table.Cell>
              <Table.Cell>{data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-'}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_PCT_ON_TM'])}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_DS_COMP'])}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  </Tab.Pane>

  const bossView = <Tab.Pane>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Avg Mins to Pick</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Pick on Time %</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}># Missed SLA</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>DS Compliance %</Table.HeaderCell>
          <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OF App %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body singleLine>
        {bossDataFiltered.map((data) => {
          return (
            <Table.Row>
              <Table.Cell>{data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-'}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_PCT_ON_TM'])}</Table.Cell>
              <Table.Cell>{data[timeframe + '_OVER_SLA_CNT']}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_DS_COMP'])}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_OF_APP'])}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    <Header textAlign='center'>More Details - BOSS</Header>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bossSortColumn === 'STR_NBR' ? bossSortDirection : null}
            onClick={() => {
              if (bossSortColumn !== 'STR_NBR') {
                setBossSortColumn('STR_NBR')
                setBossSortDirection('ascending')
              } else {
                bossSortDirection === 'ascending' ? setBossSortDirection('descending') : setBossSortDirection('ascending')
              }
            }}
          >Store #</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bossSortColumn === timeframe + '_RLSD_ORDERS' ? bossSortDirection : null}
            onClick={() => {
              if (bossSortColumn !== timeframe + '_RLSD_ORDERS') {
                setBossSortColumn(timeframe + '_RLSD_ORDERS')
                setBossSortDirection('ascending')
              } else {
                bossSortDirection === 'ascending' ? setBossSortDirection('descending') : setBossSortDirection('ascending')
              }
            }}
          >Fulfilled Orders</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bossSortColumn === timeframe + '_AVG_MINS_TO_PICK' ? bossSortDirection : null}
            onClick={() => {
              if (bossSortColumn !== timeframe + '_AVG_MINS_TO_PICK') {
                setBossSortColumn(timeframe + '_AVG_MINS_TO_PICK')
                setBossSortDirection('ascending')
              } else {
                bossSortDirection === 'ascending' ? setBossSortDirection('descending') : setBossSortDirection('ascending')
              }
            }}
          >Avg Mins to Pick</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bossSortColumn === timeframe + '_PCT_ON_TM' ? bossSortDirection : null}
            onClick={() => {
              if (bossSortColumn !== timeframe + '_PCT_ON_TM') {
                setBossSortColumn(timeframe + '_PCT_ON_TM')
                setBossSortDirection('ascending')
              } else {
                bossSortDirection === 'ascending' ? setBossSortDirection('descending') : setBossSortDirection('ascending')
              }
            }}
          >Pick on Time %</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={bossSortColumn === timeframe + '_DS_COMP' ? bossSortDirection : null}
            onClick={() => {
              if (bossSortColumn !== timeframe + '_DS_COMP') {
                setBossSortColumn(timeframe + '_DS_COMP')
                setBossSortDirection('ascending')
              } else {
                bossSortDirection === 'ascending' ? setBossSortDirection('descending') : setBossSortDirection('ascending')
              }
            }}
          >DS Compliance %</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body singleLine>
        {bossDtlDataSorted.map((data) => {
          return (
            <Table.Row>
              <Table.Cell><Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/store/orderfulfillment?strNbr=${data['STR_NBR']}`}
              >{'STR ' + data['STR_NBR']}</Link></Table.Cell>
              <Table.Cell>{data[timeframe + '_RLSD_ORDERS']}</Table.Cell>
              <Table.Cell>{data[timeframe + '_AVG_MINS_TO_PICK'] !== null ?
                data[timeframe + '_AVG_MINS_TO_PICK'].toLocaleString(undefined, { maximumFractionDigits: 2 }) :
                '-'}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_PCT_ON_TM'])}</Table.Cell>
              <Table.Cell>{getPercentString(data[timeframe + '_DS_COMP'])}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  </Tab.Pane>

  const deliveriesView = <Tab.Pane>
    <Header textAlign='center'>More Details - Deliveries</Header>
    <Table textAlign='center' celled sortable unstackable size='small' style={{ fontSize: 10 }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={delvSortColumn === 'STR_NBR' ? delvSortDirection : null}
            onClick={() => {
              if (delvSortColumn !== 'STR_NBR') {
                setDelvSortColumn('STR_NBR')
                setDelvSortDirection('ascending')
              } else {
                delvSortDirection === 'ascending' ? setDelvSortDirection('descending') : setDelvSortDirection('ascending')
              }
            }}
          >Store #</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={delvSortColumn === timeframe + '_SLS_AMT' ? delvSortDirection : null}
            onClick={() => {
              if (delvSortColumn !== timeframe + '_SLS_AMT') {
                setDelvSortColumn(timeframe + '_SLS_AMT')
                setDelvSortDirection('ascending')
              } else {
                delvSortDirection === 'ascending' ? setDelvSortDirection('descending') : setDelvSortDirection('ascending')
              }
            }}
          >Act Sales</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={delvSortColumn === timeframe + '_FULFL_ORD' ? delvSortDirection : null}
            onClick={() => {
              if (delvSortColumn !== timeframe + '_FULFL_ORD') {
                setDelvSortColumn(timeframe + '_FULFL_ORD')
                setDelvSortDirection('ascending')
              } else {
                delvSortDirection === 'ascending' ? setDelvSortDirection('descending') : setDelvSortDirection('ascending')
              }
            }}
          >Orders Fulfilled</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={delvSortColumn === timeframe + '_FULFL_ORD_COMP_PCT' ? delvSortDirection : null}
            onClick={() => {
              if (delvSortColumn !== timeframe + '_FULFL_ORD_COMP_PCT') {
                setDelvSortColumn(timeframe + '_FULFL_ORD_COMP_PCT')
                setDelvSortDirection('ascending')
              } else {
                delvSortDirection === 'ascending' ? setDelvSortDirection('descending') : setDelvSortDirection('ascending')
              }
            }}
          >Orders Fulfilled Comp %</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={delvSortColumn === timeframe + '_CSAT' ? delvSortDirection : null}
            onClick={() => {
              if (delvSortColumn !== timeframe + '_CSAT') {
                setDelvSortColumn(timeframe + '_CSAT')
                setDelvSortDirection('ascending')
              } else {
                delvSortDirection === 'ascending' ? setDelvSortDirection('descending') : setDelvSortDirection('ascending')
              }
            }}
          >CSAT</Table.HeaderCell>
          <Table.HeaderCell
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            sorted={delvSortColumn === timeframe + '_MISS_COUNT' ? delvSortDirection : null}
            onClick={() => {
              if (delvSortColumn !== timeframe + '_MISS_COUNT') {
                setDelvSortColumn(timeframe + '_MISS_COUNT')
                setDelvSortDirection('ascending')
              } else {
                delvSortDirection === 'ascending' ? setDelvSortDirection('descending') : setDelvSortDirection('ascending')
              }
            }}
          >Misses</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {delvDtlDataSorted.map((data) => {
          return (
            <Table.Row>
              <Table.Cell><Link
                style={{ color: '#EE7125', textDecoration: 'underline' }}
                to={`/store/orderfulfillment?strNbr=${data['STR_NBR']}`}
              >{'STR ' + data['STR_NBR']}</Link></Table.Cell>
              <Table.Cell>{getDollarString(data[timeframe + '_SLS_AMT'])}</Table.Cell>
              <Table.Cell>{data[timeframe + '_FULFL_ORD']}</Table.Cell>
              <Table.Cell style={getCompareStyle(data[timeframe + '_FULFL_ORD_COMP_PCT'])}>{getPercentString(data[timeframe + '_FULFL_ORD_COMP_PCT'])}</Table.Cell>
              <Table.Cell>{data[timeframe + '_SURVEY_COUNT'] !== 0 ?
                data[timeframe + '_CSAT'].toLocaleString(undefined, { maximumFractionDigits: 1 }) :
                '-'}</Table.Cell>
              <Table.Cell>{data[timeframe + '_MISS_COUNT']}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  </Tab.Pane>
  const curbsideView = <Tab.Pane>curbside deliveries placeholder</Tab.Pane>

  const options = [
    {
      menuItem: 'BOPIS',
      render: () => bopisView
    },
    {
      menuItem: 'BOSS',
      render: () => bossView
    },
    {
      menuItem: 'Deliveries',
      render: () => deliveriesView
    },
    {
      menuItem: 'Curbside',
      render: () => curbsideView
    }
  ]

  return <Tab panes={options} />
}