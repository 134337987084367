import { DataView } from '../../../../../components/Common/DataView';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { accuracyAndReceivingDataCore } from '../utils/accuracyAndReceivingBuilderCore';

export const AccuracyAndReceivingCore = ({ queryACCRCVByLevel }) => {
  const { timeframe } = useShrinkCoreContext();
  const accuracyDataObj = accuracyAndReceivingDataCore({ timeframe, queryACCRCVByLevel });

  return (
    <DataView className='mt-4'>
      <DataView.Title>Accuracy & Receiving</DataView.Title>
      <DataView.DataTable data={accuracyDataObj} />
    </DataView>
  );
};
