import { DataView } from "../../../../../../components/Common/DataView";

export const PickUpDetailsMethod = () => {

  const DATA_BY__METHOD = {
    BOPIS: {
      FULFILLMENT_PCT: 0.65,
      UNITS_UNFULFILLED: 10,
      NIS_UNITS: 15,
      AMOUNT_UNFULFILLED: 210
    },
    WILLCALL: {
      FULFILLMENT_PCT: 0.8,
      UNITS_UNFULFILLED: 20,
      NIS_UNITS: 5,
      AMOUNT_UNFULFILLED: 150
    }
  }

  const dataObj = {
    headers: [
      { name: 'Method' },
      { name: 'Fulfillment %' },
      { name: 'Units Unfulfilled' },
      { name: 'NIS Units' },
      { name: '$ Unfulfilled' },
    ],
    data: [[
      {
        stringValue: 'BOPIS'
      },
      {
        pctValue: DATA_BY__METHOD.BOPIS.FULFILLMENT_PCT
      },
      {
        numValue: DATA_BY__METHOD.BOPIS.UNITS_UNFULFILLED
      },
      {
        numValue: DATA_BY__METHOD.BOPIS.NIS_UNITS
      },
      {
        dollarValue: DATA_BY__METHOD.BOPIS.AMOUNT_UNFULFILLED
      }
    ],
    [
      {
        stringValue: 'Will Call ',
        popup: {
          content: 'TBD',
          icon: 'info circle',
          color: 'blue' //Semantic-UI doesn't have Circle Info Icon without background
        }
      },
      {
        pctValue: DATA_BY__METHOD.WILLCALL.FULFILLMENT_PCT
      },
      {
        numValue: DATA_BY__METHOD.WILLCALL.UNITS_UNFULFILLED
      },
      {
        numValue: DATA_BY__METHOD.WILLCALL.NIS_UNITS
      },
      {
        dollarValue: DATA_BY__METHOD.WILLCALL.AMOUNT_UNFULFILLED
      }
    ],
    [
      {
        stringValue: 'Total'
      },
      {
        pctValue: ((DATA_BY__METHOD.BOPIS.FULFILLMENT_PCT) + (DATA_BY__METHOD.WILLCALL.FULFILLMENT_PCT)) / 2
      },
      {
        numValue: DATA_BY__METHOD.BOPIS.UNITS_UNFULFILLED + DATA_BY__METHOD.WILLCALL.UNITS_UNFULFILLED
      },
      {
        numValue: DATA_BY__METHOD.BOPIS.NIS_UNITS + DATA_BY__METHOD.WILLCALL.NIS_UNITS
      },
      {
        dollarValue: DATA_BY__METHOD.BOPIS.AMOUNT_UNFULFILLED + DATA_BY__METHOD.WILLCALL.AMOUNT_UNFULFILLED
      }
    ]
    ]
  }

  return (
    <DataView className='mt-4'>
      <DataView.Title>Pickup Details by Method</DataView.Title>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
}
