import { useMemo } from 'react';
import { useEnhancedQueries } from '../../../hooks/useEnhancedQueries';
import { useSpecialtyProRentalServicesContext } from '../hooks/useSpecialtyProRentalServicesContext';
import { FOCUS_6 } from '../utils/specialtyProRentalServices';
import { aggregateMetricsFromLevels, buildFooter, buildHeaders, buildRows } from '../utils/leadsMeasuresTable';
import { DataView } from '../../Common/DataView';

export const LeadsMeasuresTable = ({ focus6AllPrograms, pipelineFilter }) => {
  const { fpToggle, level, timeframe } = useSpecialtyProRentalServicesContext();
  const {
    results: [{ data = [] }]
  } = useEnhancedQueries(['queryServicesDtlByLevel'], fpToggle);
  const dataFilteredMemo = useMemo(() => {
    if (focus6AllPrograms !== FOCUS_6) return data;

    return data.filter(row => row.TOP_6_CATEGORY <= 6);
  }, [data, focus6AllPrograms]);

  const dataReducedMemo = useMemo(() => {
    return aggregateMetricsFromLevels(dataFilteredMemo, level, timeframe, pipelineFilter);
  }, [dataFilteredMemo, level, pipelineFilter, timeframe]);

  const dataObj = {
    data: buildRows(dataReducedMemo, level, pipelineFilter, timeframe),
    footer: buildFooter(dataFilteredMemo, timeframe, pipelineFilter),
    headers: buildHeaders(level),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView>
      <DataView.Title position='center'>Leads & Measures Performance</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
