import { useState } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { TabMenu } from '../../../../../../components/Common/TabMenu';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { LeadsAndMeasuresPerformanceTable } from '../leadsAndMeasures/LeadsAndMeasuresPerformanceTable';
import { CoreAllProgramPipeline } from './CoreAllProgramPipeline';
import { CoreAllProgramSales } from './CoreAllProgramSales';

export const PipelineTab = ({ timeframe, fpToggle }) => {
  const [pipelineFilter, setPipelineFilter] = useState('TOTAL');

  const {
    data: allProgramSalesData,
    error: allProgramSalesDataError,
    isLoading: allProgramSalesDataLoading
  } = useQueryApi('query/queryServicesDtlByMetricLevel/core/0000', {
    fpToggle,
    isCore: true
  });
  const {
    data: allProgramPipelineData,
    error: allProgramPipelineDataError,
    isLoading: allProgramPipelineDataLoading
  } = useQueryApi('query/queryServicesPipelineByMetricLevel/core/0000', {
    fpToggle,
    isCore: true
  });
  const {
    data: allProgramLeadsMeasureData,
    error: allProgramLeadsMeasureDataError,
    isLoading: allProgramLeadsMeasureDataLoading
  } = useQueryApi('queryLeadsMeasuresPerformanceAtCore', {
    fpToggle,
    isCore: true
  });

  const isError = allProgramSalesDataError || allProgramLeadsMeasureDataError || allProgramPipelineDataError;
  const isLoading = allProgramSalesDataLoading || allProgramLeadsMeasureDataLoading || allProgramPipelineDataLoading;
  const noData =
    !allProgramSalesData ||
    allProgramSalesData.length === 0 ||
    !allProgramLeadsMeasureData ||
    allProgramLeadsMeasureData.length === 0 ||
    !allProgramPipelineData ||
    allProgramPipelineData.length === 0;

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Error fetching Pipeline data</Header>;
  if (noData) {
    return <Header textAlign='center'>No Pipeline data available</Header>;
  }

  const onChangePipeline = (_, { value }) => {
    setPipelineFilter(value);
  };

  const panes = [
    {
      menuItem: 'Sales',
      render: () => (
        <CoreAllProgramSales
          allProgramSalesData={allProgramSalesData}
          timeframe={timeframe}
          focus6AllPrograms='ALL_PROGRAMS'
        />
      )
    },
    {
      menuItem: 'Pipeline',
      render: () => (
        <CoreAllProgramPipeline
          allProgramPipelineData={allProgramPipelineData}
          onChangePipeline={onChangePipeline}
          pipelineFilter={pipelineFilter}
          timeframe={timeframe}
        />
      )
    }
  ];

  return (
    <>
      <TabMenu className='mb-4' color='orange' panes={panes} />
      <LeadsAndMeasuresPerformanceTable
        allProgramLeadsMeasureData={allProgramLeadsMeasureData}
        focus6AllPrograms={'ALL_PROGRAMS'}
        timeframe={timeframe}
        pipelineFilter={pipelineFilter}
      />
    </>
  );
};
