// URL
export const SPEC_PRO_RENTAL_PATH = 'new-specialtyprorental';

// Quotes Table
export const OPEN_QUOTE_AMT = 'OPEN_QUOTE_AMT';
export const OPEN_QUOTE_CNT = 'OPEN_QUOTE_CNT';
export const QUOTE_SOLD_AMT = 'QUOTE_SOLD_AMT';
export const QUOTE_SOLD_CNT = 'QUOTE_SOLD_CNT';
export const TOT_QUOTE_CNT = 'TOT_QUOTE_CNT';

// Rental Key Metrics Table

export const DMG_WVR_PCT = 'DMG_WVR_PCT';
export const TTL_CAD_WO = 'TTL_CAD_WO';
export const CAD_NO_PARTS = 'CAD_NO_PARTS';
export const LTRA_PCT = 'LTRA_PCT';
export const GET_PCT = 'GET_PCT';
export const LTRA_GOAL = '0.85';
export const RENTAL_GET_GOAL = '0.85';
