import { UNIT_CHAR } from './overview';
import { formatSku } from '../../../utils/formatter';
import { NOM } from '../../../utils/constants';

export const buildSkuTableHeaders = unit => [
  { name: 'SKU #' },
  { name: 'SKU Desc' },
  { name: `Shrink ${UNIT_CHAR[unit]}` },
  { name: `vLY ${UNIT_CHAR[unit]}` },
  { name: `Open Shrink ${UNIT_CHAR[unit]}` }
];

export const buildSkuTableRow = (data = [], unit, onClick) => {
  const isUnitNom = unit === NOM;
  const shrinkDollarValue = data.TOTAL_SHRINK_AMT
  const shrinkPctValue = data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT;
  const shrinkValue = isUnitNom ? shrinkDollarValue : shrinkPctValue;

  const vlyDollar = data.TOTAL_SHRINK_AMT - data.LY_TOTAL_SHRINK_AMT
  const vlyPct = data.TOTAL_SHRINK_AMT / data.TOTAL_SALES_AMT - data.LY_TOTAL_SHRINK_AMT / data.LY_TOTAL_SALES_AMT
  const vlyValue = isUnitNom ? vlyDollar : vlyPct;

  const openShrinkPctValue = data.OPS_SHRINK_AMT / data.TOTAL_SALES_AMT;
  const openShrinkValue = isUnitNom ? data.OPS_SHRINK_AMT : data.OPS_SHRINK_AMT / data.TOTAL_SALES_AMT;

  return [
    {
      customStyle: { color: '#EE7125', textDecoration: 'underline' },
      onClick,
      stringValue: formatSku(data.SKU_NBR.toString())
    },
    {
      stringValue: data.SKU_DESC
    },
    {
      decimals: !isUnitNom ? 2 : undefined,
      dollarValue: isUnitNom ? shrinkDollarValue : undefined,
      orderBy: shrinkValue,
      pctValue: !isUnitNom ? shrinkPctValue : undefined,
    },
    {
      decimals: !isUnitNom ? 2 : undefined,
      dollarValue: isUnitNom ? vlyDollar : undefined,
      orderBy: vlyValue,
      pctValue: !isUnitNom ? vlyPct : undefined
    },
    {
      decimals: !isUnitNom ? 2 : undefined,
      dollarValue: isUnitNom ? data.OPS_SHRINK_AMT : undefined,
      orderBy: openShrinkValue,
      pctValue: !isUnitNom ? openShrinkPctValue : undefined
    }
  ];
};
