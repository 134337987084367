import React from 'react';
import { Link } from 'react-router-dom';
import { Loader, Header } from 'semantic-ui-react';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { DataView } from '../../../../../../components/Common/DataView';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { getSmdCompareStyle, getThousandDollarString } from '../../../../../../components/Common/TableCellUtils';

export const SmdDepartmentPage = ({ timeframe, settimeframe, fpToggle }) => {
  const { deptNbr: deptNbrInt = 0, deptName = 0 } = useQueryParams();
  const smdDeptDrill = useQueryApi('querySmdDepartmentDrillAtCore', { isCore: true, fpToggle });

  if (smdDeptDrill.isLoading) return <Loader active>Loading...</Loader>;
  if (smdDeptDrill.isError) return <Header textAlign='center'>Something went wrong fetching data.</Header>;

  const dataObj = {
    headers: [
      { name: 'Division # ' },
      { name: 'SMD $ ' },
      { name: 'SMD % ' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' }
    ],
    data: smdDeptDrill.data.map(row => {
      const smdVpBpsValue = calculateSmdVpBpsValue(row, timeframe);
      const smdVpValue = calculateSmdVpValue(row, timeframe);
      return [
        {
          stringValue: row['DIV_NBR'],
          link: {
            to: '/division/smd/dept',
            params: { divNbr: row['DIV_NBR'], deptNbr: deptNbrInt }
          }
        },
        {
          orderBy: row[timeframe + '_SMD'],
          stringValue: getThousandDollarString(row[timeframe + '_SMD'], 1)
        },
        {
          decimals: 2,
          pctValue: row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT']
        },
        {
          decimals: 0,
          postfix: ' bps',
          compareStyle: true,
          numValue: smdVpBpsValue
        },
        {
          customStyle: getSmdCompareStyle(smdVpValue),
          stringValue: getThousandDollarString(smdVpValue, 1),
          orderBy: smdVpValue
        }
      ];
    }),
    sortable: true,
    sortableDefault: 0
  };

  const dataAsOf = smdDeptDrill.data[0]?.TRNSFM_DT;

  return (
    <>
      <Link style={{ color: '#EE7125' }} to='/core/smd' data-test='b2o-link'>
        Back to SMD Overview
      </Link>

      <Header textAlign='left'>
        SMD - Departments - Core
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>

      <DataView>
        <DataView.Controls>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>
        <DataView.Title>{deptNbrInt + '-' + deptName}</DataView.Title>
        <DataView.DataTable data={dataObj} />
      </DataView>
      <br />
    </>
  );
};

const calculateSmdVpBpsValue = (row, timeframe) => {
  return (
    10000 *
    (row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT'] -
      row[timeframe + '_MKDN_PLN_AMT'] / row[timeframe + '_SLS_PLN_AMT'])
  );
};

const calculateSmdVpValue = (row, timeframe) => {
  return (
    row[timeframe + '_SMD'] -
    (row[timeframe + '_MKDN_PLN_AMT'] * row['TY_' + timeframe + '_SLS_AMT']) / row[timeframe + '_SLS_PLN_AMT']
  );
};
