import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';

export const DamagedMarkdownsCoreTable = ({ dataObject, dataAsOf }) => {
  return (
    <DataView className='my-4'>
      <DataView.Title>
        Damaged Markdowns - Core
        <Header.Subheader>Data as Of {dataAsOf}</Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={dataObject} />
    </DataView>
  );
};
