import { useSpecialtyProRentalServicesContext } from '../hooks/useSpecialtyProRentalServicesContext';
import { ALL_PROGRAMS, FOCUS_6 } from '../utils/specialtyProRentalServices';
import { buildFooter, buildHeaders, buildRows } from '../utils/servicesPrgmPipelineTable';
import { DataView } from '../../Common/DataView';
import { Dropdown } from 'semantic-ui-react';
import { useState } from 'react';


const PIPELINE_OPTIONS = [
  { key: 'TOTAL', text: 'Total', value: 'TOTAL' },
  { key: 'STR', text: 'Store', value: 'STR' },
  { key: 'WEB', text: 'Web', value: 'WEB' },
  { key: 'SP', text: 'Service Provider', value: 'SP' },
  { key: 'OTHER', text: 'Other', value: 'OTHER' }
];

export const ServicesPrgmPipeline = ({ data }) => {
  const { level, locNbr, timeframe } = useSpecialtyProRentalServicesContext();
  const [pipelineFilter, setPipelineFilter] = useState('TOTAL');

  const onChangePipeline = (_, { value }) => {
    setPipelineFilter(value);
  };

  const dataObj = {
    data: buildRows(data, pipelineFilter, level, locNbr, timeframe),
    headers: buildHeaders(level),
    footer: buildFooter(data, timeframe, pipelineFilter),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.Controls className='d-flex justify-content-end'>
        <Dropdown selection options={PIPELINE_OPTIONS} onChange={onChangePipeline} value={pipelineFilter} />
      </DataView.Controls>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};