export const ALL_PROGRAMS = 'All Programs';
export const FOCUS_6 = 'Focus 6';

export const calculateComp = (row, timeframe, pipelineFilter) => {
  return (
    (row[timeframe + '_LEAD_COUNT_' + pipelineFilter] +
      row[timeframe + '_MEASURE_COUNT_' + pipelineFilter] -
      row[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] -
      row[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) /
    (row[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] + row[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])
  );
};

export const calculateConv = (row, timeframe, pipelineFilter) => {
  return (
    (row[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] + row[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter]) /
    (row[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] + row[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter])
  );
};
