import { Header } from "semantic-ui-react";
import { DataView } from "../../Common/DataView";

export const DamagedMarkdowns = ({ level, locNbr, levelName, dataObject, dataAsOf }) => {
  return (
    <DataView className='mt-4'>
      <DataView.Title position="center">
        Damaged Markdowns - {level} {locNbr}, {levelName} 
        <Header.Subheader>
          Data as Of {dataAsOf}
        </Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={dataObject} />
    </DataView>
  );
}
