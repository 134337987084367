import { Header } from 'semantic-ui-react';
import { DataView } from '../../Common/DataView';
import { shrinkRTVCreditsData } from '../utils/dataObjectBuilder';

export const RTVCredits = ({ level, locNbr, shrinkData, shrinkScoreData, levelName, dataAsOf, timeframe }) => {
  return (
    <DataView className='mt-4'>
      <DataView.Title>
        RTV Credits - {level} {locNbr}, {levelName}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </DataView.Title>
      <DataView.DataTable
        data={shrinkRTVCreditsData({
          scoresData: shrinkScoreData,
          shrinkMetricsData: shrinkData,
          timeframe,
          level
        })}
      />
    </DataView>
  );
};
