import { Header } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../utils/string";
import { METRIC_CONSTANTS } from "../../utils/constantsByLevel";
import { useIntradayContext } from "../../hooks/useIntradayContext";
import { getCompareStyle, getPercentString, getThousandDollarString } from "../Common/TableCellUtils";
import { DataView } from "../Common/DataView";

const getPlannedComp = (SLS_PLN_AMT, LY_COMP_SLS_AMT) => {
  if (SLS_PLN_AMT == null || LY_COMP_SLS_AMT == null) return '-';
  return getPercentString(SLS_PLN_AMT / LY_COMP_SLS_AMT - 1, 1, 1);
};

export const IntradayTabList = () => {

  const { level, totalSalesByLevel } = useIntradayContext();
  const compPlanVar =  level === 'core' ? 'SLS_PLN_COMP_AMT' : 'SLS_PLN_AMT';

  const daySalesObj = {
    sortable: true,
    sortableDefault: 0,
    headers: [
      { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " " },
      { name: 'Sales $ (k) ' },
      { name: '$ vPlan (k) ' },
      { name: '% to Daily Plan ' },
      { name: 'Comp % ' },
      { name: 'Planned Comp % ' }
    ],
    data: totalSalesByLevel.map(row => [
      {
        stringValue: METRIC_CONSTANTS[level]['subMetric'] + " " + row[METRIC_CONSTANTS[level]['propToRender']],
        orderBy: row[METRIC_CONSTANTS[level]['propToRender']],
        link: {
          to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/intraday`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubLevelParam']]: String(row[METRIC_CONSTANTS[level]['propToRender']]).padStart(4, '0')
          }
        }
      },
      {
        stringValue: getThousandDollarString(row['CUMMU_SLS'], 1),
        orderBy: +getThousandDollarString(row['CUMMU_SLS'], 1)
      },
      {
        customStyle: getCompareStyle(row['DOL_TO_DAILY_PLN']),
        stringValue: getThousandDollarString(row['DOL_TO_DAILY_PLN'], 1),
        orderBy: row['DOL_TO_DAILY_PLN']
      },
      {
        customStyle: getCompareStyle(row['PCT_TO_DAILY_PLN']),
        stringValue: getPercentString(row['PCT_TO_DAILY_PLN'], 1, 1),
        orderBy: row['PCT_TO_DAILY_PLN']
      },
      {
        customStyle: getCompareStyle(row['CUMMU_COMP_PCT']),
        stringValue: getPercentString(row['CUMMU_COMP_PCT'], 1, 1),
        orderBy: row['CUMMU_COMP_PCT']
      },
      {
        customStyle: getCompareStyle(row[compPlanVar] - row['LY_COMP_SLS_AMT']),
        stringValue: getPlannedComp(row[compPlanVar], row['LY_COMP_SLS_AMT']),
        orderBy: (row[compPlanVar] !== null && row['LY_COMP_SLS_AMT'] !== null) ? row[compPlanVar] / row['LY_COMP_SLS_AMT'] : undefined
      }
    ])
  };


  return (
    <DataView className='mt-4'>
      <DataView.Title position="center">
        Current Day Sales - {level} {capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel'])} Comparison
        <Header.Subheader>
          Data as of {new Date().toISOString().split('T')[0]}
        </Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={daySalesObj} />
    </DataView>
  );
}
