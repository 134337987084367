import { buildBaseDrillLink, buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../../../utils/string';

export const cullMarkdownsHeadersCore = () => [
  { name: `${capitalizeFirstLetter(METRIC_CONSTANTS['core'].urlLinkLevel)} # ` },
  { name: 'Value' },
  { name: 'Goal' },
  { name: '% of Stores Passing ' }
];

export const shrinkCullMdBreadcrumbItemsCore = () => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');

  return [{ to: `/${baseLevelShrinkUrl}`, text: 'Back to Shrink Overview' }, { text: 'Cull Markdowns' }];
};

export const shrinkCullMdRowsCore = (metricsScoreData, metricsData, timeframe) => {
  const baseLevelShrinkUrl = buildBaseDrillLink('core');
  const previousLocNbr = metricsScoreData[METRIC_CONSTANTS['core'].propToRender];
  return [
    {
      stringValue: previousLocNbr,
      link: {
        to: `/${baseLevelShrinkUrl}/cullmd`,
        params: {
          [METRIC_CONSTANTS['core'].urlSubLevelParam]: previousLocNbr
        }
      }
    },
    {
      decimals: 2,
      pctValue: metricsData[previousLocNbr][timeframe + '_CULL_MD_NUM'] / metricsData[previousLocNbr][timeframe + '_CULL_MD_DEN']
    },
    { decimals: 2, pctValue: metricsScoreData['CULL_MD_GOAL'], prefix: '≥ ' },
    { decimals: 2, pctValue: metricsScoreData['CULL_MD_SCORE'] }
  ];
};