import { DataView } from '../../Common/DataView';
import { useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import {
  footerTotal,
  headers,
  prokeyMetricsDataRows,
  prokeyMetricsFooterRow
} from '../utils/specialtyProRentalProKeyMetrics';

export const SpecialtyProRentalProKeyMetrics = ({ queryProOpsPlusOneData = [], proOpsData = [] }) => {
  const { level, locNbr, timeframe } = useSpecialtyProRentalContext();
  const isCore = level.levelName === 'core';
  const dataFiltered = useMemo(
    () => queryProOpsPlusOneData.filter(row => row[level.propToRender]),
    [queryProOpsPlusOneData, level]
  );
  const footer = isCore? footerTotal(dataFiltered, timeframe) : proOpsData[0]

  if (!queryProOpsPlusOneData.length || !footer)
    return <Header textAlign='center'>No data to show in Pro Key Metrics.</Header>;

  const data = {
    sortable: true,
    sortableDefault: 0,
    headers: headers(level, locNbr),
    footer: [prokeyMetricsFooterRow(footer, timeframe)],
    data: dataFiltered.map(row => prokeyMetricsDataRows(row, timeframe, level))
  };

  return (
    <DataView className='my-4'>
      <DataView.Title position='center'>Pro Key Metrics</DataView.Title>

      <DataView.DataTable data={data} />
    </DataView>
  );
};
