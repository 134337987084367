// ShrinkDept tab names
export const CLASSES = 'CLASSES';
export const SKUS = 'SKUS';

//Shrink Data
export const TOTAL_SHRINK_AMT = 'TOTAL_SHRINK_AMT';
export const TOTAL_SALES_AMT = 'TOTAL_SALES_AMT';
export const LY_TOTAL_SHRINK_AMT = 'LY_TOTAL_SHRINK_AMT';
export const LY_TOTAL_SALES_AMT = 'LY_TOTAL_SALES_AMT';
export const OPS_SHRINK_AMT = 'OPS_SHRINK_AMT';
export const BOSS_CNCL_NUM = 'BOSS_CNCL_NUM';
export const BOSS_CNCL_DEN = 'BOSS_CNCL_DEN';
