import { useServiceDtlData } from '../hooks/useServiceDtlData';
import { useSpecialtyProRentalServicesContext } from '../hooks/useSpecialtyProRentalServicesContext';
import { ALL_PROGRAMS, FOCUS_6 } from '../utils/specialtyProRentalServices';
import { buildFooter, buildHeaders, buildRows } from '../utils/servicesSalesTable';
import { DataView } from '../../Common/DataView';

export const ServicesSales = ({ focus6AllPrograms }) => {
  const { level, locNbr, timeframe } = useSpecialtyProRentalServicesContext();
  const { data, focusSixTotal, totalData } = useServiceDtlData(focus6AllPrograms);

  const dataObj = {
    data: buildRows(data, focus6AllPrograms === ALL_PROGRAMS, level, locNbr, timeframe),
    headers: buildHeaders(),
    footer: buildFooter(focusSixTotal, totalData, timeframe, focus6AllPrograms === FOCUS_6),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title position='center'>{focus6AllPrograms}</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
