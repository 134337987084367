import { getThousandDollarString } from '../../../../../../components/Common/TableCellUtils';

export const coreInventoryClassDrillBuilder = drillClasses => {
  const dataObj = {
    headers: [{ name: 'Subclass ' }, { name: 'Inv $ ' }, { name: 'ASW ' }, { name: 'WOS ' }, { name: 'ICE $ ' }],
    data: drillClasses.map(row => [
      {
        link: {
          to: `/core/inventory/dept/class/subclass?deptNbr=${row['DEPT_NBR']}&classNbr=${row['EXT_CLASS_NBR']}&subClassNbr=${row['EXT_SUB_CLASS_NBR']}`
        },
        stringValue: row['SUB_CLASS_NM']
      },
      {
        orderBy: row['INV_AMT'],
        stringValue: getThousandDollarString(row['INV_AMT'])
      },
      {
        orderBy: row['ASW'],
        stringValue: getThousandDollarString(row['ASW'])
      },
      {
        decimals: 1,
        numValue: row['WOS']
      },
      {
        orderBy: row['ICE_INV_AMT'],
        stringValue: getThousandDollarString(row['ICE_INV_AMT'])
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };

  return { dataObj };
};
