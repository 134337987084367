import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { buildBaseShrinkUrl } from './url';
import { getDollarString, getPercentString } from '../../Common/TableCellUtils';
import { NOM, EXT_CLASS_NBR, CLASS_DESC, SKU_NBR, SKU_DESC } from '../../../utils/constants';
import { formatSku } from '../../../utils/formatter';
import {
  TOTAL_SHRINK_AMT,
  TOTAL_SALES_AMT,
  LY_TOTAL_SALES_AMT,
  LY_TOTAL_SHRINK_AMT,
  OPS_SHRINK_AMT
} from '../utils/constants';

// BreadCrumbs

export const getBreadcrumbItems = params => {
  const { level, locNbr, deptNbr, deptNm } = params;
  const { urlParam } = METRIC_CONSTANTS[level];

  const shrinkRouteUrlParam = Boolean(urlParam) ? `?${urlParam}=${locNbr}` : '';
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const shrinkRoute = `/${baseLevelShrinkUrl}${shrinkRouteUrlParam}`;

  return [{ to: shrinkRoute, text: 'Shrink' }, { text: `${deptNbr}-${deptNm}` }];
};

// CLASSES Table

const getClassesData = ({ shrinkActualsClsDeptData, unit, level, locNbr }) => {
  const { levelName, urlParam } = METRIC_CONSTANTS[level];
  const baseLevelShrinkUrl = buildBaseShrinkUrl(levelName);

  return shrinkActualsClsDeptData.map(row => {
    const extClassNbr = row[EXT_CLASS_NBR];
    const classDesc = row[CLASS_DESC];
    const totalShrinkAmt = row[TOTAL_SHRINK_AMT];
    const totalSalesAmt = row[TOTAL_SALES_AMT];
    const lyTotalShrinkAmt = row[LY_TOTAL_SHRINK_AMT];
    const lyTotalSalesAmt = row[LY_TOTAL_SALES_AMT];
    const opsShrinkAmt = row[OPS_SHRINK_AMT];

    const shrinkNom = totalShrinkAmt;
    const shrinkPct = totalShrinkAmt / totalSalesAmt;
    const shrinkValue = unit === NOM ? getDollarString(shrinkNom) : getPercentString(shrinkPct, 2, 2);
    const shrinkOrderBy = unit === NOM ? shrinkNom : shrinkPct;

    const vlyNom = totalShrinkAmt - lyTotalShrinkAmt;
    const vlyPct = totalShrinkAmt / totalSalesAmt - lyTotalShrinkAmt / lyTotalSalesAmt;
    const vlyValue = unit === NOM ? getDollarString(vlyNom) : getPercentString(vlyPct, 2, 2);
    const vlyOrderBy = unit === NOM ? vlyNom : vlyPct;

    const opsShrinkNom = opsShrinkAmt;
    const opsShrinkPct = opsShrinkAmt / totalSalesAmt;
    const opsShrinkValue = unit === NOM ? getDollarString(opsShrinkNom) : getPercentString(opsShrinkPct, 2, 2);
    const opsShrinkOrderBy = unit === NOM ? opsShrinkNom : opsShrinkPct;

    return [
      {
        link: {
          to: `/${baseLevelShrinkUrl}/class`,
          params: {
            ...(urlParam && { [urlParam]: locNbr }),
            classNbr: extClassNbr
          }
        },
        stringValue: `${extClassNbr} - ${classDesc.replaceAll('/', '/\u200b')}`
      },
      { stringValue: shrinkValue, orderBy: shrinkOrderBy },
      { stringValue: vlyValue, orderBy: vlyOrderBy },
      { stringValue: opsShrinkValue, orderBy: opsShrinkOrderBy }
    ];
  });
};

const getClassesHeaders = ({ unit }) => {
  const unitPrefix = unit === NOM ? '$' : '%';

  return [
    { name: 'Class ' },
    { name: `Shrink ${unitPrefix} ` },
    { name: `vLY ${unitPrefix} ` },
    { name: `Ops Shrink ${unitPrefix} ` }
  ];
};

export const getClassesDataTable = ({ shrinkActualsClsDeptData, unit, level, locNbr }) => ({
  headers: getClassesHeaders({ unit }),
  data: getClassesData({ shrinkActualsClsDeptData, unit, level, locNbr }),
  sortable: true,
  sortableDefault: 0,
  sortDirection: 'descending',
  pagination: true
});

// SKUS Table

const getSkusData = ({ shrinkActualsSkuDeptData, unit, setDataModal }) => {
  return shrinkActualsSkuDeptData.map(row => {
    const skuNbr = row[SKU_NBR];
    const skuDesc = row[SKU_DESC];
    const totalShrinkAmt = row[TOTAL_SHRINK_AMT];
    const totalSalesAmt = row[TOTAL_SALES_AMT];
    const lyTotalShrinkAmt = row[LY_TOTAL_SHRINK_AMT];
    const lyTotalSalesAmt = row[LY_TOTAL_SALES_AMT];
    const opsShrinkAmt = row[OPS_SHRINK_AMT];

    const skuNbrValue = formatSku(String(skuNbr));
    const skuNbrOrderBy = skuNbr;

    const skuDescValue = skuDesc.replaceAll('/', '/\u200b');

    const shrinkNom = totalShrinkAmt;
    const shrinkPct = totalShrinkAmt / totalSalesAmt;
    const shrinkValue = unit === NOM ? getDollarString(shrinkNom) : getPercentString(shrinkPct, 2, 2);
    const shrinkOrderBy = unit === NOM ? shrinkNom : shrinkPct;

    const vlyNom = totalShrinkAmt - lyTotalShrinkAmt;
    const vlyPct = totalShrinkAmt / totalSalesAmt - lyTotalShrinkAmt / lyTotalSalesAmt;
    const vlyValue = unit === NOM ? getDollarString(vlyNom) : getPercentString(vlyPct, 2, 2);
    const vlyOrderBy = unit === NOM ? vlyNom : vlyPct;

    const opsShrinkNom = opsShrinkAmt;
    const opsShrinkPct = opsShrinkAmt / totalSalesAmt;
    const opsShrinkValue = unit === NOM ? getDollarString(opsShrinkNom) : getPercentString(opsShrinkPct, 2, 2);
    const opsShrinkOrderBy = unit === NOM ? opsShrinkNom : opsShrinkPct;

    return [
      {
        onClick: () => setDataModal(row),
        stringValue: skuNbrValue,
        orderBy: skuNbrOrderBy,
        customStyle: { cursor: 'pointer', color: '#EE7125', textDecoration: 'underline' }
      },
      { stringValue: skuDescValue },
      { stringValue: shrinkValue, orderBy: shrinkOrderBy },
      { stringValue: vlyValue, orderBy: vlyOrderBy },
      { stringValue: opsShrinkValue, orderBy: opsShrinkOrderBy }
    ];
  });
};

const getSkusHeaders = ({ unit }) => {
  const unitPrefix = unit === NOM ? '$' : '%';

  return [
    { name: 'SKU # ' },
    { name: 'SKU Desc ' },
    { name: `Shrink ${unitPrefix} ` },
    { name: `vLY ${unitPrefix} ` },
    { name: `Ops Shrink ${unitPrefix} ` }
  ];
};

export const getSkusDataTable = ({ shrinkActualsSkuDeptData, unit, setDataModal }) => ({
  headers: getSkusHeaders({ unit }),
  data: getSkusData({ shrinkActualsSkuDeptData, unit, setDataModal }),
  sortable: true,
  sortableDefault: 0,
  sortDirection: 'ascending',
  pagination: true
});
