import { useContext, useState } from "react";
import { StoreOrderFulfillmentContext } from "../../../../../context/StoreOrderFulfillmentContext";
import { formatAssociateName } from "../../../../../utils/utils";
import { DataTable } from "../../../../../../../components/Common/DataTable";
import { CustomDropDown } from "../../CustomDropdown";

export const DeliveryPull = () => {

  const { DeliveryPushData } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Manager View');
  const showCustomTimeframe = ['Pulled Work Orders'];

  const getData = () => {
    switch (view) {
      case 'Manager View':
        return {
          headers: [
            { name: 'Manager Name' },
            { name: 'Work Orders Pulled' },
          ],
          data: DeliveryPushData.map(data => [
            { stringValue: formatAssociateName(data.MANAGER, 5) },
            { numValue: data.WORK_ORDER_PUSHED }
          ])
        };
      case 'Pulled Work Orders':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Manager Name' },
            { name: 'Customer Order #' },
            { name: 'Work Order #' },
            { name: 'Pull $' },
            { name: 'Pull Date' },
          ],
          data: DeliveryPushData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE, 5) },
            { stringValue: formatAssociateName(data.MANAGER, 5) },
            { numValue: data.WC_ORDER_NOM },
            { numValue: data.WORK_ORDER_NOM },
            { dollarValue: data.PUSH_AMOUNT },
            { numValue: data.ORDER_DATE },
          ])
        }
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <div className="mt-2">
      <CustomDropDown
        view={view}
        setView={setView}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Manager View', text: 'Manager View', value: 'Manager View' },
          { key: 'Pulled Work Orders', text: 'Pulled Work Orders', value: 'Pulled Work Orders' },
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataTable
        data={getData()}
      />
    </div>
  );
}