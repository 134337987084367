import React, { useState } from 'react'
import {
  Table,
  Loader,
  Header,
  Segment,
  Grid,
  Icon,
  Menu,
} from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'
import { AvSAvFMenu } from './AvSAvFMenu'
import { useQuery } from 'react-query'
import { ACCESS_LEVELS } from '../Common/AccessChecks';
import {
  getData
} from '../../service/DataService'
import { getPercentString } from '../Common/TableCellUtils'

export const LaborDetail = withRouter(({ timeframe, settimeframe, fpToggle, location, jobProfileNm, userMgtLvlId, userIsCorporate }) => {

  // Department Supervisors can only access their department, so we need to parse their department number and filter the data to it
  const deptSupNbrs = [...jobProfileNm.matchAll(/(\d+)/g)].map(i => i[0]).map(Number)

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [sortColumn, setSortColumn] = useState("DEPT_NBR")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [unit, setUnit] = useState('NOM')
  const [days, setDays] = useState('Total')
  const [unitLaborDetails, setUnitLaborDetails] = useState('NOM')
  const [clf, setClf] = useState("All")
  const [avsOrAvf, setAvsOrAvf] = useState('AVF')
  const [avsOrAvfLaborDeets, setAvsOrAvfLaborDeets ] = useState('AVF')

  let queryStr = 'queryLaborOverviewByStr850'

  if (userMgtLvlId <= ACCESS_LEVELS.STORE_MANAGERS || userIsCorporate) {
    queryStr = 'queryLaborOverviewByStr' 
  } else if (userMgtLvlId === ACCESS_LEVELS.HOURLY_ASM) {
    queryStr = 'queryLaborOverviewByStr830'
  }
  const laborOverviewQuery = useQuery([queryStr, {strNbr, fpToggle}], getData)

  queryStr = 'queryLaborDtlByStr850'
  if (userMgtLvlId <= ACCESS_LEVELS.STORE_MANAGERS || userIsCorporate) {
    queryStr = 'queryLaborDtlByStr' 
  } else if (userMgtLvlId === ACCESS_LEVELS.HOURLY_ASM) {
    queryStr = 'queryLaborDtlByStr830'
  }
  const laborDetailQuery = useQuery([queryStr, {strNbr, fpToggle}], getData)

  if (laborOverviewQuery.isLoading || laborDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  var classificationEnum = {
    'Manager': 0,
    'Core': 1,
    'Operations': 2,
    'Specialty': 3,
    'Tool/Pro': 4,
    'Supervisor': 5,
    'Other': 6,
    'Total': 7
  }

  let laborOverviewDataSort = laborOverviewQuery.data.sort((left, right) => {
    let result = classificationEnum[left['CLASSIFICATION']] > classificationEnum[right['CLASSIFICATION']] ? 1 : -1
    return result
  })


  //For labor overview table - the following filter method filter out the row that has all the value fields equal to zero/null 
  let laborOverviewDataFilter = laborOverviewDataSort.filter((row) => {
    return (
      row[timeframe + "_ACT_HRS"] !== 0 || row[timeframe + "_PROJ_OT_HRS"] !== 0 || row[timeframe + "_VF"] !== 0 || row[timeframe + "_VS"] !== 0 ||
      row[timeframe + "_VF_PCT"] !== 0 || row[timeframe + "_VS_PCT"] !== 0 || 
      row[timeframe + "_VF_WEEKDAY"] !== 0 || row[timeframe + "_VF_WEEKEND"] !== 0 || row[timeframe + "_VF_PCT_WEEKDAY"] !== 0 || row[timeframe + "_VF_PCT_WEEKEND"] !== 0 ||
      row[timeframe + "_VS_WEEKDAY"] !== 0 || row[timeframe + "_VS_WEEKEND"] !== 0  || row[timeframe + "_VS_PCT_WEEKDAY"] !== 0 || row[timeframe + "_VS_PCT_WEEKEND"] !== 0 ||
      row[timeframe + "_FCST_HRS"] !== 0 || row[timeframe + "_SCH_HRS"] !== 0 || row[timeframe + "_ACT_HRS_WEEKDAY"] !== 0 || row[timeframe + "_ACT_HRS_WEEKEND"] !== 0 ||
      row[timeframe + "_FCST_HRS_WEEKDAY"] !== 0 || row[timeframe + "_FCST_HRS_WEEKEND"] !== 0
    );
  })

  const classification = []
  laborOverviewDataSort.forEach(element => {
    if (element['CLASSIFICATION'] !== 'Total' && !classification.includes(element['CLASSIFICATION']) ) {
      classification.push(element['CLASSIFICATION'])
    }
  });

  //For labor Detail table - the following filter method filter out the row that has all the value fields equal to zero/null and show row data based on classification
  let laborDetailsFilter = laborDetailQuery.data
  .filter((row) => {
    return row && (clf === 'All' || row['CLASSIFICATION'] === clf) && (
      row[timeframe + "_ACT_HRS"] !== 0 || row[timeframe + "_PROJ_OT_HRS"] !== 0 || row[timeframe + "_VF"] !== 0  || row[timeframe + "_VS"] !== 0  ||
      row[timeframe + "_VF_PCT"] !== 0 || row[timeframe + "_VS_PCT"] !== 0 || 
      row[timeframe + "_VF_WEEKDAY"] !== 0  || row[timeframe + "_VF_WEEKEND"] !== 0  || row[timeframe + "_VF_PCT_WEEKDAY"] !== 0   || row[timeframe + "_VF_PCT_WEEKEND"] !== 0  ||
      row[timeframe + "_VS_WEEKDAY"] !== 0  || row[timeframe + "_VS_WEEKEND"] !== 0  || row[timeframe + "_VS_PCT_WEEKDAY"] !== 0  || row[timeframe + "_VS_PCT_WEEKEND"] !== 0  ||
      row[timeframe + "_FCST_HRS"] !== 0  || row[timeframe + "_SCH_WRK_HRS"] !== 0  || row[timeframe + "_ACT_HRS_WEEKDAY"] !== 0  || row[timeframe + "_ACT_HRS_WEEKEND"] !== 0  ||
      row[timeframe + "_FCST_HRS_WEEKDAY"] !== 0  || row[timeframe + "_FCST_HRS_WEEKEND"] !== 0 
    )
   
  })

 
  let deptDataSort = laborDetailsFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'DEPT_NBR') {
      result = left.DEPT_NBR > right.DEPT_NBR ? 1 : -1
    }
    if (sortColumn === 'DEPT_NM') {
      result = left.DEPT_NM > right.DEPT_NM ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })
  return (
      <Segment>
        <Grid>
          <Grid.Column width={7}>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link'>Back to Overview</Link>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={9}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Menu compact>
                <Menu.Item
                  name='Total'
                  active={days === 'Total'}
                  content='Total'
                  onClick={() => {
                    setDays('Total')
                  }}
                />
                <Menu.Item
                  name='Wkdy/Wknd'
                  active={days === 'Wkdy/Wknd'}
                  content='Wkdy/Wknd'
                  onClick={() => {
                    setDays('Wkdy/Wknd')
                  }}
                />
                </Menu>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={16} textAlign='right'>
                {days === 'Wkdy/Wknd'&& <Grid.Row>
              <AvSAvFMenu avsOrAvf={avsOrAvf} setAvsOrAvf={setAvsOrAvf} />
            </Grid.Row>}
            </Grid.Column>
        </Grid>

        <Header textAlign='center'>Labor Overview - Store #{strNbr}</Header>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              {days === 'Total' && <Table.HeaderCell>HRS</Table.HeaderCell>}
              {days === 'Total' && timeframe === "WTD" && new Date().getDay() !== 1 && <Table.HeaderCell>PROJ O/T</Table.HeaderCell>}
              {days === 'Total' && timeframe === "WTD" && new Date().getDay() === 1 && <Table.HeaderCell>O/T</Table.HeaderCell>}
              {days === 'Total' && timeframe !== "WTD" &&<Table.HeaderCell>O/T</Table.HeaderCell>}

              {days === 'Total' && unit === 'NOM' && <Table.HeaderCell>AvF</Table.HeaderCell>}
              {days === 'Total' && unit === 'NOM' && <Table.HeaderCell>AvS</Table.HeaderCell>}

              {days === 'Total' && unit === 'PCT' && <Table.HeaderCell>AvF %</Table.HeaderCell>}
              {days === 'Total' && unit === 'PCT' && <Table.HeaderCell>AvS %</Table.HeaderCell>}

              {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVF' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wkdy AvF</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVF' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wknd AvF</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVF' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Total AvF</Table.HeaderCell>}
              
              {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVF' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wkdy AvF %</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVF' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wknd AvF %</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVF' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Total AvF %</Table.HeaderCell>}
             
              {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVS' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wkdy AvS</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVS' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wknd AvS</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVS' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Total AvS</Table.HeaderCell>}
              
              {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVS' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wkdy AvS %</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVS' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Wknd AvS %</Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVS' && <Table.HeaderCell style={{whiteSpace: "normal",wordWrap: 'break-word'}}>Total AvS %</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {laborOverviewDataFilter.map((data) => {
              return (
                <Table.Row>
                  {/* Category Label */}
                  <Table.Cell>{data['CLASSIFICATION']}</Table.Cell>
                  {/* HRS for Total */}
                  {days === 'Total' && <Table.Cell>{data[timeframe+'_ACT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}

                  {/* O/T Requires different values for WTD on Monday/Not Monday, and every other timeframe */}
                  {/* O/T for Total on WTD and Not Monday */}
                  {days === 'Total' && timeframe === "WTD" && new Date().getDay() !== 1 && <Table.Cell>{data[timeframe+'_PROJ_OT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {/* O/T for Total on WTD and Monday */}
                  {days === 'Total' && timeframe === "WTD" && new Date().getDay() === 1 && <Table.Cell>{data[timeframe+'_OT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {/* O/T for Total on Not WTD */}
                  {days === 'Total' && timeframe !== "WTD" &&<Table.Cell>{data[timeframe+'_OT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                 

                  {/* AvF */}
                  {days === 'Total' && unit === 'NOM' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : data[timeframe+'_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {/* AvS */}
                  {days === 'Total' && unit === 'NOM' && <Table.Cell>{(data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_HRS']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                 
                  {/* AvF % */}
                  {days === 'Total' && unit === 'PCT' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT'] - 1 < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(data[timeframe+'_VF_PCT'] - 1,2)}</Table.Cell>}
                  {/* AvS % */}
                  {days === 'Total' && unit === 'PCT' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_HRS'])/data[timeframe+'_SCH_HRS']),2)}</Table.Cell>}
                 
                  {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVF' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : data[timeframe+'_VF_WEEKDAY'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVF' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : data[timeframe+'_VF_WEEKEND'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVF' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : data[timeframe+'_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  
                  {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVF' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT_WEEKDAY'] < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(((data[timeframe+'_VF_WEEKDAY'])/data[timeframe+'_FCST_HRS_WEEKDAY']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVF' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT_WEEKEND'] < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(((data[timeframe+'_VF_WEEKEND'])/data[timeframe+'_FCST_HRS_WEEKEND']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVF' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT'] < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(((data[timeframe+'_VF'])/data[timeframe+'_FCST_HRS']),2)}</Table.Cell>}
                  
                  {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVS' && <Table.Cell>{(data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_SCH_HRS_WEEKDAY']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVS' && <Table.Cell>{(data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_SCH_HRS_WEEKEND']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'NOM' && avsOrAvf==='AVS' && <Table.Cell>{(data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_HRS']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                 
                  {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVS' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT_WEEKDAY'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_SCH_HRS_WEEKDAY'])/data[timeframe+'_SCH_HRS_WEEKDAY']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVS' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT_WEEKEND'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_SCH_HRS_WEEKEND'])/data[timeframe+'_SCH_HRS_WEEKEND']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unit === 'PCT' && avsOrAvf==='AVS' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_HRS'])/data[timeframe+'_SCH_HRS']),2)}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <Header textAlign='center'>Labor Details</Header>
        <Grid>
          <Grid.Column width={7} textAlign='left'>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unitLaborDetails} setUnit={setUnitLaborDetails} />
            </Grid.Row>
          </Grid.Column>
        <Grid.Column textAlign='right' width={9}>
          {days === 'Wkdy/Wknd'&& <Grid.Row>
            <AvSAvFMenu avsOrAvf={avsOrAvfLaborDeets} setAvsOrAvf={setAvsOrAvfLaborDeets} />
            </Grid.Row>}
          <Grid.Row>
            Labor Categories
            <div>
              <select
                name="classification"
                onChange={e => {
                  setClf(e.target.value)
                }}
                value={clf}
                style={{ padding: 10 }}
              >
                <option value="All" selected>All</option>
                {classification.map((row) => {
                  return <option value={row}>{row}</option>
                })}
              </select>
            </div>
            </Grid.Row>
         </Grid.Column>
         <Grid.Row>&nbsp;</Grid.Row>
        </Grid>
        <Table textAlign='center' unstackable celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === 'DEPT_NBR' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== 'DEPT_NBR') {
                    setSortColumn('DEPT_NBR')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Dept {sortColumn !== 'DEPT_NBR' && <Icon fitted name='sort' />}
              </Table.HeaderCell>
              {days === 'Total' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_ACT_HRS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_ACT_HRS') {
                    setSortColumn(timeframe + '_ACT_HRS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                HRS {sortColumn !== timeframe + '_ACT_HRS' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Total' && timeframe === 'WTD' && new Date().getDay() !== 1 && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_PROJ_OT_HRS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_PROJ_OT_HRS') {
                    setSortColumn(timeframe + '_PROJ_OT_HRS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                PROJ O/T {sortColumn !== timeframe + '_PROJ_OT_HRS' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Total' && timeframe === 'WTD' && new Date().getDay() === 1 && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_OT_HRS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_OT_HRS') {
                    setSortColumn(timeframe + '_OT_HRS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
               
                O/T {sortColumn !== timeframe + '_OT_HRS' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Total' && timeframe !== 'WTD' &&<Table.HeaderCell
                sorted={sortColumn === timeframe + '_OT_HRS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_OT_HRS') {
                    setSortColumn(timeframe + '_OT_HRS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
              O/T {sortColumn !== timeframe + '_OT_HRS' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
               {days === 'Total' && unitLaborDetails === 'NOM' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_VF' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF') {
                    setSortColumn(timeframe + '_VF')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                AvF {sortColumn !== timeframe + '_VF' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Total' && unitLaborDetails === 'NOM' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_VS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS') {
                    setSortColumn(timeframe + '_VS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                AvS {sortColumn !== timeframe + '_VS' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Total' && unitLaborDetails === 'PCT' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_VF_PCT' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF_PCT') {
                    setSortColumn(timeframe + '_VF_PCT')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                AvF % {sortColumn !== timeframe + '_VF_PCT' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Total' && unitLaborDetails === 'PCT' && <Table.HeaderCell
                sorted={sortColumn === timeframe + '_VS_PCT' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS_PCT') {
                    setSortColumn(timeframe + '_VS_PCT')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                AvS % {sortColumn !== timeframe + '_VS_PCT' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVF' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VF_WEEKDAY' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF_WEEKDAY') {
                    setSortColumn(timeframe + '_VF_WEEKDAY')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wkdy AvF {sortColumn !== timeframe + '_VF_WEEKDAY' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVF' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VF_WEEKEND' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF_WEEKEND') {
                    setSortColumn(timeframe + '_VF_WEEKEND')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wknd AvF {sortColumn !== timeframe + '_VF_WEEKEND' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVF' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VF' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF') {
                    setSortColumn(timeframe + '_VF')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Total AvF {sortColumn !== timeframe + '_VF' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVF' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VF_PCT_WEEKDAY' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF_PCT_WEEKDAY') {
                    setSortColumn(timeframe + '_VF_PCT_WEEKDAY')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wkdy AvF % {sortColumn !== timeframe + '_VF_PCT_WEEKDAY' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVF' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VF_PCT_WEEKEND' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF_PCT_WEEKEND') {
                    setSortColumn(timeframe + '_VF_PCT_WEEKEND')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wknd AvF % {sortColumn !== timeframe + '_VF_PCT_WEEKEND' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVF' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VF_PCT' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VF_PCT') {
                    setSortColumn(timeframe + '_VF_PCT')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Total AvF % {sortColumn !== timeframe + '_VF_PCT' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVS' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VS_WEEKDAY' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS_WEEKDAY') {
                    setSortColumn(timeframe + '_VS_WEEKDAY')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wkdy AvS {sortColumn !== timeframe + '_VS_WEEKDAY' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVS' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VS_WEEKEND' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS_WEEKEND') {
                    setSortColumn(timeframe + '_VS_WEEKEND')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wknd AvS {sortColumn !== timeframe + '_VS_WEEKEND' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVS' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VS' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS') {
                    setSortColumn(timeframe + '_VS')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Total AvS {sortColumn !== timeframe + '_VS' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVS' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VS_PCT_WEEKDAY' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS_PCT_WEEKDAY') {
                    setSortColumn(timeframe + '_VS_PCT_WEEKDAY')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wkdy AvS % {sortColumn !== timeframe + '_VS_PCT_WEEKDAY' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVS' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VS_PCT_WEEKEND' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS_PCT_WEEKEND') {
                    setSortColumn(timeframe + '_VS_PCT_WEEKEND')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Wknd AvS % {sortColumn !== timeframe + '_VS_PCT_WEEKEND' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
              {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVS' && <Table.HeaderCell
                style={{whiteSpace: "normal",wordWrap: 'break-word'}}
                sorted={sortColumn === timeframe + '_VS_PCT' ? sortDirection : null}
                onClick={() => {
                  if (sortColumn !== timeframe + '_VS_PCT') {
                    setSortColumn(timeframe + '_VS_PCT')
                    setSortDirection('ascending')
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                  }
                }}>
                Total AvS % {sortColumn !== timeframe + '_VS_PCT' && <Icon fitted name='sort' />}
              </Table.HeaderCell>}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {deptDataSort.map((data) => {
              return (
                <Table.Row>
                  {days === 'Total' && <Table.Cell>
                    { // If the user is higher level than a department supervisor or the department is one they supervisor, give them a link to the labor detail page
                      (
                          userMgtLvlId < ACCESS_LEVELS.DEPT_SUP || 
                          (jobProfileNm.includes('DEPT SUP') && deptSupNbrs.includes(data['DEPT_NBR'])) || 
                          userIsCorporate
                      ) ?
                        <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/laborDetailAssoc?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}`}>
                          {data['DEPT_NBR']}-{data['DEPT_NM'] ? data['DEPT_NM'].replaceAll('/', '/\u200b') : 'Other'}
                        </Link>
                        : <>{data['DEPT_NBR']}-{data['DEPT_NM'] ? data['DEPT_NM'].replaceAll('/', '/\u200b') : 'Other'}</>

                    }
                  </Table.Cell>} 

                  {days === 'Wkdy/Wknd' && <Table.Cell>{data['DEPT_NBR']}-{data['DEPT_NM'] ? data['DEPT_NM'].replaceAll('/', '/\u200b') : 'Other'}</Table.Cell>}
                  
                  {days === 'Total' && <Table.Cell>{data[timeframe+'_ACT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                 
                  {days === 'Total' && timeframe === "WTD" && new Date().getDay() !== 1 && <Table.Cell>{data[timeframe+'_PROJ_OT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Total' && timeframe === "WTD" && new Date().getDay() === 1 && <Table.Cell>{data[timeframe+'_OT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Total' && timeframe !== "WTD" &&<Table.Cell>{data[timeframe+'_OT_HRS'].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  
                  {days === 'Total' && unitLaborDetails === 'NOM' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : (data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Total' && unitLaborDetails === 'NOM' && <Table.Cell>{(data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  
                  {days === 'Total' && unitLaborDetails === 'PCT' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT'] < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(((data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'])/data[timeframe+'_FCST_HRS']),2)}</Table.Cell>}
                  {days === 'Total' && unitLaborDetails === 'PCT' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS'])/data[timeframe+'_SCH_WRK_HRS']),2)}</Table.Cell>}
                 
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVF' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : (data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_FCST_HRS_WEEKDAY']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVF' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : (data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_FCST_HRS_WEEKEND']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVF' && <Table.Cell>{data.CLASSIFICATION === "Manager" ? "-" : (data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVF' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT_WEEKDAY'] < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(((data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_FCST_HRS_WEEKDAY'])/data[timeframe+'_FCST_HRS_WEEKDAY']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVF' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT_WEEKEND'] < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(((data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_FCST_HRS_WEEKEND'])/data[timeframe+'_FCST_HRS_WEEKEND']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVF' && <Table.Cell style={{ color: data[timeframe+'_VF_PCT'] < -0.001 ? 'red' : 'green'}}>{data.CLASSIFICATION === "Manager" ? "-" : getPercentString(((data[timeframe+'_ACT_HRS']-data[timeframe+'_FCST_HRS'])/data[timeframe+'_FCST_HRS']),2)}</Table.Cell>}
                 
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVS' && <Table.Cell>{(data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_SCH_WRK_HRS_WEEKDAY']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVS' && <Table.Cell>{(data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_SCH_WRK_HRS_WEEKEND']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'NOM' && avsOrAvfLaborDeets==='AVS' && <Table.Cell>{(data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS']).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>}
                  
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVS' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT_WEEKDAY'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS_WEEKDAY']-data[timeframe+'_SCH_WRK_HRS_WEEKDAY'])/data[timeframe+'_SCH_WRK_HRS_WEEKDAY']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVS' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT_WEEKEND'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS_WEEKEND']-data[timeframe+'_SCH_WRK_HRS_WEEKEND'])/data[timeframe+'_SCH_WRK_HRS_WEEKEND']),2)}</Table.Cell>}
                  {days === 'Wkdy/Wknd' && unitLaborDetails === 'PCT' && avsOrAvfLaborDeets==='AVS' && <Table.Cell style={{ color: data[timeframe+'_VS_PCT'] < -0.001 ? 'red' : 'green'}}>{getPercentString(((data[timeframe+'_ACT_HRS']-data[timeframe+'_SCH_WRK_HRS'])/data[timeframe+'_SCH_WRK_HRS']),2)}</Table.Cell>}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Segment>
  )
})