import { createContext } from 'react';
import { useOrderFulfillmentQueries } from '../hooks/useOrderFulfillmentQueries';

export const StoreOrderFulfillmentContext = createContext();

const StoreOrderFulfillmentContextProvider = ({ children, fpToggle, ...props }) => {
  const queryProps = useOrderFulfillmentQueries({ fpToggle });

  const value = { 
    ...props, 
    ...queryProps,
  };

  return (
    <StoreOrderFulfillmentContext.Provider value={value}>
      {children}
    </StoreOrderFulfillmentContext.Provider>
  );
};

export default StoreOrderFulfillmentContextProvider;
