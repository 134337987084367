import { CoreTop40Sku } from './CoreTop40Sku';
import { Tab, Segment } from 'semantic-ui-react';
import { CoreSummaryTable } from './CoreSummaryTable';
import { CoreClassesSummary } from './CoreClassesSummary';
import { CoreSubClassesSummary } from './CoreSubClassesSummary';
import { CoreDepartmentSummary } from './CoreDepartmentSummary';

export const CoreSummaryTab = ({ summaryData, departmentData, classesData, subClassesData, top40SkuData }) => {
  const tabs = [
    {
      menuItem: 'DEPT',
      render: () => <CoreDepartmentSummary departmentData={departmentData} />
    },
    {
      menuItem: 'CLASS',
      render: () => <CoreClassesSummary classesData={classesData} />
    },
    {
      menuItem: 'SUBCLASS',
      render: () => <CoreSubClassesSummary subClassesData={subClassesData} />
    },
    {
      menuItem: 'SKU',
      render: () => <CoreTop40Sku top40SkuData={top40SkuData} />
    }
  ];

  return (
    <>
      <CoreSummaryTable summaryData={summaryData} />

      <Segment style={{ marginTop: '20px' }}>
        <Tab
          menu={{
            widths: 4,
            size: 'small',
            compact: true,
            attached: false,
            pointing: false,
            secondary: false,
            borderless: false
          }}
          panes={tabs}
        />
      </Segment>
    </>
  );
};
