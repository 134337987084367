import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { formatNumberWithCommas } from '../../../utils/formatter';
import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { getCompareStyle, getPercentString, getThousandDollarString } from '../../Common/TableCellUtils';

const sortDataTableValues = { sortable: true, sortableDefault: 0, sortDirection: 'descending' };

export const aggregateByDivNbr = (data, currentForCore) => {
  return data.reduce((acc, curr) => {
    const levelNbr = curr[currentForCore];
    if (!acc[levelNbr]) {
      acc[levelNbr] = { ...curr };
    } else {
      Object.keys(curr).forEach(key => {
        if (key !== currentForCore && typeof curr[key] === 'number') {
          acc[levelNbr][key] += curr[key];
        }
      });
    }
    return acc;
  }, {});
};

export const slsDepts = salesData =>
  salesData
    .sort((left, right) => left['MER_DEPT_NM'].localeCompare(right['MER_DEPT_NM']))
    .reduce((acc, data) => {
      if (!acc.includes(data['MER_DEPT_NM'])) {
        acc.push(data['MER_DEPT_NM']);
      }
      return acc;
    }, []);

export const salesRentalDataBuilder = (salesData, timeframe, subMetric, unit, levelName) => {
  const urlParam = getParamByLevel(levelName);
  const paramBuilder = sale => (levelName === 'core' ? {} : { [urlParam]: sale[`${subMetric}_NBR`] });

  const dataSalesRentalDataBuilder = {
    headers: [{ name: '' }, { name: 'Act' }, { name: 'vP' }, { name: 'vLY' }],
    data: salesData.map(sale => [
      {
        customStyle: sale['MER_DEPT_NM'] === 'Total' && { fontWeight: 'bold' },
        stringValue: sale['MER_DEPT_NM'],
        link: sale['MER_DEPT_NM'] !== 'Total' && {
          to: `/${levelName}/sales/dept`,
          params: {
            ...paramBuilder(sale),
            deptNbr: sale['DEPT_NBR']
          }
        }
      },
      {
        customStyle:
          sale['MER_DEPT_NM'] !== 'Total' ? getCompareStyle(sale[timeframe + '_TY_SLS_AMT']) : { fontWeight: 'bold' },
        stringValue: formatNumberWithCommas(sale[timeframe + '_TY_SLS_AMT'])
      },
      {
        customStyle: sale['MER_DEPT_NM'] !== 'Total' ? getCompareStyle(sale[timeframe + '_SLS_VP']) : { fontWeight: 'bold' },
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VP'], 2)
            : getPercentString(sale[timeframe + '_SLS_VP_PCT'], 2)
      },
      {
        customStyle:
          sale['MER_DEPT_NM'] !== 'Total' ? getCompareStyle(sale[timeframe + '_SLS_VLY']) : { fontWeight: 'bold' },
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VLY'], 2)
            : getPercentString(sale[timeframe + '_SLS_VLY_PCT'], 2)
      }
    ]),
    ...sortDataTableValues
  };
  return { dataSalesRentalDataBuilder };
};

export const salesRentalSubLevelDataBuilder = (
  subMetric,
  slsSubLevel,
  subLevelSalesData,
  timeframe,
  unit,
  levelName
) => {
  const totals = subLevelSalesData.reduce(
    (acc, sale) => {
      acc.TY_SLS_AMT += sale[timeframe + '_TY_SLS_AMT'];
      acc.SLS_VP += sale[timeframe + '_SLS_VP'];
      acc.SLS_VLY += sale[timeframe + '_SLS_VLY'];
      return acc;
    },
    { TY_SLS_AMT: 0, SLS_VP: 0, SLS_VLY: 0 }
  );

  const dataSalesRentalSubLevelDataBuilder = {
    headers: [{ name: `${slsSubLevel} #` }, { name: 'Act' }, { name: 'vP' }, { name: 'vLY' }],
    data: subLevelSalesData.map(sale => [
      {
        stringValue: sale[`${subMetric}_NBR`],
        link: {
          to: `/${slsSubLevel}/new-specialtyprorental`,
          params: { [METRIC_CONSTANTS[levelName].urlSubLevelParam]: sale[METRIC_CONSTANTS[levelName]?.propToRender] }
        }
      },
      {
        customStyle: getCompareStyle(sale[timeframe + '_TY_SLS_AMT']),
        stringValue: formatNumberWithCommas(sale[timeframe + '_TY_SLS_AMT'])
      },
      {
        customStyle: getCompareStyle(sale[timeframe + '_SLS_VP']),
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VP'], 2)
            : getPercentString(sale[timeframe + '_SLS_VP_PCT'], 2)
      },
      {
        customStyle: getCompareStyle(sale[timeframe + '_SLS_VLY']),
        stringValue:
          unit === 'NOM'
            ? getThousandDollarString(sale[timeframe + '_SLS_VLY'], 2)
            : getPercentString(sale[timeframe + '_SLS_VLY_PCT'], 2)
      }
    ]),
    footer: [
      [
        { stringValue: 'Total' },
        { stringValue: formatNumberWithCommas(totals.TY_SLS_AMT) },
        {
          stringValue:
            unit === 'NOM'
              ? getThousandDollarString(totals.SLS_VP, 2)
              : getPercentString(totals.SLS_VP / subLevelSalesData.length, 2)
        },
        {
          stringValue:
            unit === 'NOM'
              ? getThousandDollarString(totals.SLS_VLY, 2)
              : getPercentString(totals.SLS_VLY / subLevelSalesData.length, 2)
        }
      ]
    ],
    ...sortDataTableValues
  };

  return { dataSalesRentalSubLevelDataBuilder };
};
