import { HelpersCoreLinks } from './HelpersCoreLinks';
import { RegionCoreExecScoreByTimeframe } from './RegionCoreExecScoreByTimeFrame';

export const MainCoreTab = ({ execScoreData }) => {
  return (
    <>
      <HelpersCoreLinks level='core' />
      <RegionCoreExecScoreByTimeframe scoresData={execScoreData} />
    </>
  );
};
