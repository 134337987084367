import { DataView } from '../../../../../../components/Common/DataView';
import { METRIC_CONSTANTS } from '../../../../../../utils/constantsByLevel';
import { buildBaseDrillLink } from '../../../../../../components/ShrinkContainer/utils/url';
import { getCompareStyle } from '../../../../../../components/Common/TableCellUtils';

export const AutoKeyRecsTable = ({ autoKeyRecData, timeframe }) => {
  const level = 'core';
  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  const url = `/${baseLevelShrinkUrl}/autokr`;

  const dataObj = {
    sortable: true,
    sortableColumns: [0, 1],
    sortableDefault: 0,
    headers: [{ name: 'Division # ' }, { name: 'Value ' }, { name: 'Goal ' }],
    data: autoKeyRecData.map(data => [
      {
        stringValue: data[METRIC_CONSTANTS[level].propToRender],
        prefix: `${METRIC_CONSTANTS[level].subMetric} `,
        link: {
          to: url,
          params: {
            [METRIC_CONSTANTS[level].urlSubLevelParam]: data[METRIC_CONSTANTS[level].propToRender]
          }
        }
      },
      {
        stringValue:
          data[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'] > 0
            ? data[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT']
            : 0,
        customStyle: getCompareStyle(data[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'])
      },
      {
        stringValue: '0'
      }
    ])
  };

  return <DataView.DataTable data={dataObj} />;
};
