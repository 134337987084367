import React, { useContext, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';

export const WillCall = () => {
  const { assocOnTimePickupData } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const showCustomTimeframe = ['# Missed SLA']

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate' },
            { name: 'Pick On Time %' },
            { name: ' # of Orders Missed ' },
          ],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { pctValue: data.WC_PICK_ON_TIME_PCT },
            { numValue: data.WC_NO_ORDER_COMPLIANCE_NOM }
          ])
        };
      case '# Missed SLA':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Order Date' },
            { name: 'Mins To Pick' },
          ],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.WC_ORDER_NOM },
            { numValue: data.WC_ORDER_DATE },
            { numValue: data.MINS_TO_PICK },
          ])
        }
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' },
        ]}
      />
      <DataTable
        data={getData()}
      />
    </>
  );
};
