export const onTimeDistrictDataSource = {
  BOPIS: [
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 5824.519999999999,
      "WTD_LY_NET_SLS_AMT": 6849.169999999998,
      "LW_NET_SLS_AMT": 15101.57,
      "LW_LY_NET_SLS_AMT": 15139.779999999993,
      "LM_NET_SLS_AMT": 61823.349999999984,
      "LM_LY_NET_SLS_AMT": 74841.70999999998,
      "MTD_NET_SLS_AMT": 70718.08,
      "MTD_LY_NET_SLS_AMT": 69749.65,
      "QTD_NET_SLS_AMT": 208162.05999999997,
      "QTD_LY_NET_SLS_AMT": 210467.27000000005,
      "HTD_NET_SLS_AMT": 408177.75000000006,
      "HTD_LY_NET_SLS_AMT": 408179.6199999998,
      "YTD_NET_SLS_AMT": 408177.75000000006,
      "YTD_LY_NET_SLS_AMT": 408179.6199999998,
      "L12_NET_SLS_AMT": 867162.3299999996,
      "L12_LY_NET_SLS_AMT": 849607.5899999997,
      "YST_NET_SLS_AMT": 1849.48,
      "YST_LY_NET_SLS_AMT": 4386.539999999999,
      "LQ_NET_SLS_AMT": 200015.6899999999,
      "LQ_LY_NET_SLS_AMT": 197712.35,
      "LH_NET_SLS_AMT": 451640.72,
      "LH_LY_NET_SLS_AMT": 431001.18,
      "WTD_UNT_SLS_AMT": 253,
      "WTD_LY_UNT_SLS_AMT": 183,
      "LW_UNT_SLS_AMT": 648,
      "LW_LY_UNT_SLS_AMT": 503,
      "LM_UNT_SLS_AMT": 3675,
      "LM_LY_UNT_SLS_AMT": 3855,
      "MTD_UNT_SLS_AMT": 2848,
      "MTD_LY_UNT_SLS_AMT": 2627,
      "QTD_UNT_SLS_AMT": 10506,
      "QTD_LY_UNT_SLS_AMT": 9480,
      "HTD_UNT_SLS_AMT": 19991,
      "HTD_LY_UNT_SLS_AMT": 20979,
      "YTD_UNT_SLS_AMT": 19991,
      "YTD_LY_UNT_SLS_AMT": 20979,
      "L12_UNT_SLS_AMT": 41691,
      "L12_LY_UNT_SLS_AMT": 41039,
      "YST_UNT_SLS_AMT": 127,
      "YST_LY_UNT_SLS_AMT": 96,
      "LQ_UNT_SLS_AMT": 9485,
      "LQ_LY_UNT_SLS_AMT": 11499,
      "LH_UNT_SLS_AMT": 21434,
      "LH_LY_UNT_SLS_AMT": 17472,
      "WTD_TRANS_CNT": 47,
      "WTD_LY_TRANS_CNT": 56,
      "LW_TRANS_CNT": 129,
      "LW_LY_TRANS_CNT": 194,
      "LM_TRANS_CNT": 691,
      "LM_LY_TRANS_CNT": 779,
      "MTD_TRANS_CNT": 656,
      "MTD_LY_TRANS_CNT": 767,
      "QTD_TRANS_CNT": 2081,
      "QTD_LY_TRANS_CNT": 2278,
      "HTD_TRANS_CNT": 4038,
      "HTD_LY_TRANS_CNT": 4498,
      "YTD_TRANS_CNT": 4038,
      "YTD_LY_TRANS_CNT": 4498,
      "L12_TRANS_CNT": 8854,
      "L12_LY_TRANS_CNT": 10118,
      "YST_TRANS_CNT": 23,
      "YST_LY_TRANS_CNT": 31,
      "LQ_TRANS_CNT": 1957,
      "LQ_LY_TRANS_CNT": 2220,
      "LH_TRANS_CNT": 4587,
      "LH_LY_TRANS_CNT": 5289,
      "WTD_RLSD_UNITS": 294,
      "WTD_RLSD_ORDERS": 29,
      "LW_RLSD_UNITS": 647,
      "LW_RLSD_ORDERS": 76,
      "LM_RLSD_UNITS": 3964,
      "LM_RLSD_ORDERS": 447,
      "MTD_RLSD_UNITS": 3166,
      "MTD_RLSD_ORDERS": 441,
      "QTD_RLSD_UNITS": 11740,
      "QTD_RLSD_ORDERS": 1387,
      "HTD_RLSD_UNITS": 22079,
      "HTD_RLSD_ORDERS": 2731,
      "YTD_RLSD_UNITS": 22079,
      "YTD_RLSD_ORDERS": 2731,
      "L12_RLSD_UNITS": 46151,
      "L12_RLSD_ORDERS": 5961,
      "YST_RLSD_UNITS": 152,
      "YST_RLSD_ORDERS": 17,
      "LQ_RLSD_UNITS": 10339,
      "LQ_RLSD_ORDERS": 1349,
      "LH_RLSD_UNITS": 23691,
      "LH_RLSD_ORDERS": 3104,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 1,
      "LW_PCT_ON_TM": 0.926829268292683,
      "LM_PCT_ON_TM": 0.8720173535791758,
      "MTD_PCT_ON_TM": 0.899103139013453,
      "QTD_PCT_ON_TM": 0.9000708717221828,
      "HTD_PCT_ON_TM": 0.911134903640257,
      "YTD_PCT_ON_TM": 0.911134903640257,
      "L12_PCT_ON_TM": 0.886565166471474,
      "YST_PCT_ON_TM": 1,
      "LQ_PCT_ON_TM": 0.9223580158159598,
      "LH_PCT_ON_TM": 0.865472312703583,
      "WTD_AVG_MINS_TO_PICK": 35.72727272727272,
      "LW_AVG_MINS_TO_PICK": 40.81707317073171,
      "LM_AVG_MINS_TO_PICK": 68.93058568329715,
      "MTD_AVG_MINS_TO_PICK": 53.95964125560536,
      "QTD_AVG_MINS_TO_PICK": 58.76966690290573,
      "HTD_AVG_MINS_TO_PICK": 54.6395431834404,
      "YTD_AVG_MINS_TO_PICK": 54.6395431834404,
      "L12_AVG_MINS_TO_PICK": 64.4512297139033,
      "YST_AVG_MINS_TO_PICK": 40.733333333333334,
      "LQ_AVG_MINS_TO_PICK": 50.45003594536302,
      "LH_AVG_MINS_TO_PICK": 71.34169381107492,
      "WTD_OVER_SLA_CNT": 0,
      "LW_OVER_SLA_CNT": 6,
      "LM_OVER_SLA_CNT": 59,
      "MTD_OVER_SLA_CNT": 45,
      "QTD_OVER_SLA_CNT": 141,
      "HTD_OVER_SLA_CNT": 249,
      "YTD_OVER_SLA_CNT": 249,
      "L12_OVER_SLA_CNT": 678,
      "YST_OVER_SLA_CNT": 0,
      "LQ_OVER_SLA_CNT": 108,
      "LH_OVER_SLA_CNT": 413,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9921259842519685,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.991869918699187,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9870801033591732,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9724655819774718,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9724655819774718,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9531149646756584,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 4217.35,
      "WTD_LY_NET_SLS_AMT": 9709.539999999995,
      "LW_NET_SLS_AMT": 23022.66,
      "LW_LY_NET_SLS_AMT": 20925.249999999993,
      "LM_NET_SLS_AMT": 99293.98,
      "LM_LY_NET_SLS_AMT": 90859.87000000001,
      "MTD_NET_SLS_AMT": 94382.56999999998,
      "MTD_LY_NET_SLS_AMT": 111261.17000000001,
      "QTD_NET_SLS_AMT": 279321.98000000004,
      "QTD_LY_NET_SLS_AMT": 298738.6600000001,
      "HTD_NET_SLS_AMT": 555989.5799999998,
      "HTD_LY_NET_SLS_AMT": 581181.6799999998,
      "YTD_NET_SLS_AMT": 555989.5799999998,
      "YTD_LY_NET_SLS_AMT": 581181.6799999998,
      "L12_NET_SLS_AMT": 1156309.86,
      "L12_LY_NET_SLS_AMT": 1172770.6199999992,
      "YST_NET_SLS_AMT": 1194.62,
      "YST_LY_NET_SLS_AMT": 4565.7300000000005,
      "LQ_NET_SLS_AMT": 276667.5999999999,
      "LQ_LY_NET_SLS_AMT": 282443.02,
      "LH_NET_SLS_AMT": 575750.5599999997,
      "LH_LY_NET_SLS_AMT": 578729.29,
      "WTD_UNT_SLS_AMT": 151,
      "WTD_LY_UNT_SLS_AMT": 777,
      "LW_UNT_SLS_AMT": 1179,
      "LW_LY_UNT_SLS_AMT": 1002,
      "LM_UNT_SLS_AMT": 4792,
      "LM_LY_UNT_SLS_AMT": 4395,
      "MTD_UNT_SLS_AMT": 4994,
      "MTD_LY_UNT_SLS_AMT": 5943,
      "QTD_UNT_SLS_AMT": 15011,
      "QTD_LY_UNT_SLS_AMT": 15847,
      "HTD_UNT_SLS_AMT": 33693.5,
      "HTD_LY_UNT_SLS_AMT": 32100,
      "YTD_UNT_SLS_AMT": 33693.5,
      "YTD_LY_UNT_SLS_AMT": 32100,
      "L12_UNT_SLS_AMT": 59050.5,
      "L12_LY_UNT_SLS_AMT": 56381,
      "YST_UNT_SLS_AMT": 48,
      "YST_LY_UNT_SLS_AMT": 73,
      "LQ_UNT_SLS_AMT": 18682.5,
      "LQ_LY_UNT_SLS_AMT": 16253,
      "LH_UNT_SLS_AMT": 24053,
      "LH_LY_UNT_SLS_AMT": 24089,
      "WTD_TRANS_CNT": 80,
      "WTD_LY_TRANS_CNT": 88,
      "LW_TRANS_CNT": 279,
      "LW_LY_TRANS_CNT": 303,
      "LM_TRANS_CNT": 1346,
      "LM_LY_TRANS_CNT": 1215,
      "MTD_TRANS_CNT": 1202,
      "MTD_LY_TRANS_CNT": 1362,
      "QTD_TRANS_CNT": 3718,
      "QTD_LY_TRANS_CNT": 3700,
      "HTD_TRANS_CNT": 7258,
      "HTD_LY_TRANS_CNT": 6896,
      "YTD_TRANS_CNT": 7258,
      "YTD_LY_TRANS_CNT": 6896,
      "L12_TRANS_CNT": 14957,
      "L12_LY_TRANS_CNT": 15115,
      "YST_TRANS_CNT": 37,
      "YST_LY_TRANS_CNT": 35,
      "LQ_TRANS_CNT": 3540,
      "LQ_LY_TRANS_CNT": 3196,
      "LH_TRANS_CNT": 7345,
      "LH_LY_TRANS_CNT": 7937,
      "WTD_RLSD_UNITS": 350,
      "WTD_RLSD_ORDERS": 53,
      "LW_RLSD_UNITS": 1538,
      "LW_RLSD_ORDERS": 202,
      "LM_RLSD_UNITS": 5178,
      "LM_RLSD_ORDERS": 910,
      "MTD_RLSD_UNITS": 5401,
      "MTD_RLSD_ORDERS": 829,
      "QTD_RLSD_UNITS": 16525,
      "QTD_RLSD_ORDERS": 2543,
      "HTD_RLSD_UNITS": 36670.5,
      "HTD_RLSD_ORDERS": 4969,
      "YTD_RLSD_UNITS": 36670.5,
      "YTD_RLSD_ORDERS": 4969,
      "L12_RLSD_UNITS": 67176.5,
      "L12_RLSD_ORDERS": 10173,
      "YST_RLSD_UNITS": 249,
      "YST_RLSD_ORDERS": 29,
      "LQ_RLSD_UNITS": 20145.5,
      "LQ_RLSD_ORDERS": 2429,
      "LH_RLSD_UNITS": 28239,
      "LH_RLSD_ORDERS": 4991,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.8245614035087719,
      "LW_PCT_ON_TM": 0.7218934911242604,
      "LM_PCT_ON_TM": 0.8340807174887892,
      "MTD_PCT_ON_TM": 0.7455012853470437,
      "QTD_PCT_ON_TM": 0.8195062727640632,
      "HTD_PCT_ON_TM": 0.8552443263136373,
      "YTD_PCT_ON_TM": 0.8552443263136373,
      "L12_PCT_ON_TM": 0.8988430732720261,
      "YST_PCT_ON_TM": 0.8620689655172413,
      "LQ_PCT_ON_TM": 0.8917355371900827,
      "LH_PCT_ON_TM": 0.9145161290322581,
      "WTD_AVG_MINS_TO_PICK": 114.66666666666669,
      "LW_AVG_MINS_TO_PICK": 132.82248520710058,
      "LM_AVG_MINS_TO_PICK": 70.51008968609862,
      "MTD_AVG_MINS_TO_PICK": 112.45501285347046,
      "QTD_AVG_MINS_TO_PICK": 81.59085390530153,
      "HTD_AVG_MINS_TO_PICK": 69.57718258024944,
      "YTD_AVG_MINS_TO_PICK": 69.57718258024944,
      "L12_AVG_MINS_TO_PICK": 57.47206565806387,
      "YST_AVG_MINS_TO_PICK": 164.96551724137933,
      "LQ_AVG_MINS_TO_PICK": 57.310330578512385,
      "LH_AVG_MINS_TO_PICK": 53.4695564516129,
      "WTD_OVER_SLA_CNT": 10,
      "LW_OVER_SLA_CNT": 47,
      "LM_OVER_SLA_CNT": 148,
      "MTD_OVER_SLA_CNT": 198,
      "QTD_OVER_SLA_CNT": 446,
      "HTD_OVER_SLA_CNT": 708,
      "YTD_OVER_SLA_CNT": 708,
      "L12_OVER_SLA_CNT": 1023,
      "YST_OVER_SLA_CNT": 4,
      "LQ_OVER_SLA_CNT": 262,
      "LH_OVER_SLA_CNT": 424,
      "WTD_DS_COMP": 0.9333333333333333,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9914529914529915,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.986784140969163,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9864661654135338,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9718844984802432,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9718844984802432,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9453363914373089,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 9947.92,
      "WTD_LY_NET_SLS_AMT": 5535.330000000001,
      "LW_NET_SLS_AMT": 24899.14,
      "LW_LY_NET_SLS_AMT": 16433.750000000004,
      "LM_NET_SLS_AMT": 106241.58,
      "LM_LY_NET_SLS_AMT": 111439.38999999997,
      "MTD_NET_SLS_AMT": 95633.95999999998,
      "MTD_LY_NET_SLS_AMT": 93201.1,
      "QTD_NET_SLS_AMT": 298230.86,
      "QTD_LY_NET_SLS_AMT": 316296.3999999999,
      "HTD_NET_SLS_AMT": 584513.8899999999,
      "HTD_LY_NET_SLS_AMT": 611892.5800000001,
      "YTD_NET_SLS_AMT": 584513.8899999999,
      "YTD_LY_NET_SLS_AMT": 611892.5800000001,
      "L12_NET_SLS_AMT": 1149434.8299999996,
      "L12_LY_NET_SLS_AMT": 1236209.7300000002,
      "YST_NET_SLS_AMT": 3891.3099999999986,
      "YST_LY_NET_SLS_AMT": 3419.9300000000003,
      "LQ_NET_SLS_AMT": 286283.03000000014,
      "LQ_LY_NET_SLS_AMT": 295596.18000000017,
      "LH_NET_SLS_AMT": 551087.4099999999,
      "LH_LY_NET_SLS_AMT": 606360.1999999998,
      "WTD_UNT_SLS_AMT": 502,
      "WTD_LY_UNT_SLS_AMT": 366,
      "LW_UNT_SLS_AMT": 1495,
      "LW_LY_UNT_SLS_AMT": 1181.67,
      "LM_UNT_SLS_AMT": 6568,
      "LM_LY_UNT_SLS_AMT": 4853,
      "MTD_UNT_SLS_AMT": 5640,
      "MTD_LY_UNT_SLS_AMT": 5334.67,
      "QTD_UNT_SLS_AMT": 16800,
      "QTD_LY_UNT_SLS_AMT": 15852.67,
      "HTD_UNT_SLS_AMT": 31850,
      "HTD_LY_UNT_SLS_AMT": 32026.67,
      "YTD_UNT_SLS_AMT": 31850,
      "YTD_LY_UNT_SLS_AMT": 32026.67,
      "L12_UNT_SLS_AMT": 55451.67,
      "L12_LY_UNT_SLS_AMT": 57093,
      "YST_UNT_SLS_AMT": 147,
      "YST_LY_UNT_SLS_AMT": 195,
      "LQ_UNT_SLS_AMT": 15050,
      "LQ_LY_UNT_SLS_AMT": 16174,
      "LH_UNT_SLS_AMT": 23146,
      "LH_LY_UNT_SLS_AMT": 24909,
      "WTD_TRANS_CNT": 89,
      "WTD_LY_TRANS_CNT": 78,
      "LW_TRANS_CNT": 232,
      "LW_LY_TRANS_CNT": 244,
      "LM_TRANS_CNT": 1092,
      "LM_LY_TRANS_CNT": 1164,
      "MTD_TRANS_CNT": 1099,
      "MTD_LY_TRANS_CNT": 1121,
      "QTD_TRANS_CNT": 3182,
      "QTD_LY_TRANS_CNT": 3373,
      "HTD_TRANS_CNT": 5924,
      "HTD_LY_TRANS_CNT": 6194,
      "YTD_TRANS_CNT": 5924,
      "YTD_LY_TRANS_CNT": 6194,
      "L12_TRANS_CNT": 12111,
      "L12_LY_TRANS_CNT": 13170,
      "YST_TRANS_CNT": 50,
      "YST_LY_TRANS_CNT": 45,
      "LQ_TRANS_CNT": 2742,
      "LQ_LY_TRANS_CNT": 2821,
      "LH_TRANS_CNT": 6013,
      "LH_LY_TRANS_CNT": 6751,
      "WTD_RLSD_UNITS": 419,
      "WTD_RLSD_ORDERS": 52,
      "LW_RLSD_UNITS": 1368,
      "LW_RLSD_ORDERS": 166,
      "LM_RLSD_UNITS": 7150,
      "LM_RLSD_ORDERS": 737,
      "MTD_RLSD_UNITS": 5714,
      "MTD_RLSD_ORDERS": 715,
      "QTD_RLSD_UNITS": 17980,
      "QTD_RLSD_ORDERS": 2126,
      "HTD_RLSD_UNITS": 34814,
      "HTD_RLSD_ORDERS": 4041,
      "YTD_RLSD_UNITS": 34814,
      "YTD_RLSD_ORDERS": 4041,
      "L12_RLSD_UNITS": 61807,
      "L12_RLSD_ORDERS": 8461,
      "YST_RLSD_UNITS": 121,
      "YST_RLSD_ORDERS": 20,
      "LQ_RLSD_UNITS": 16834,
      "LQ_RLSD_ORDERS": 1916,
      "LH_RLSD_UNITS": 25613,
      "LH_RLSD_ORDERS": 4250,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 89,
      "L12_ONLINE_SURVEY_COUNT": 1,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.7761194029850746,
      "LW_PCT_ON_TM": 0.7105263157894737,
      "LM_PCT_ON_TM": 0.8265162200282088,
      "MTD_PCT_ON_TM": 0.8257790368271954,
      "QTD_PCT_ON_TM": 0.8291746641074856,
      "HTD_PCT_ON_TM": 0.8608520292412403,
      "YTD_PCT_ON_TM": 0.8608520292412403,
      "L12_PCT_ON_TM": 0.8689579791110031,
      "YST_PCT_ON_TM": 0.7352941176470589,
      "LQ_PCT_ON_TM": 0.895910780669145,
      "LH_PCT_ON_TM": 0.8801063572637177,
      "WTD_AVG_MINS_TO_PICK": 88.02985074626869,
      "LW_AVG_MINS_TO_PICK": 126.97368421052639,
      "LM_AVG_MINS_TO_PICK": 80.08180535966152,
      "MTD_AVG_MINS_TO_PICK": 80.157223796034,
      "QTD_AVG_MINS_TO_PICK": 81.50815738963529,
      "HTD_AVG_MINS_TO_PICK": 73.09200907486763,
      "YTD_AVG_MINS_TO_PICK": 73.09200907486763,
      "L12_AVG_MINS_TO_PICK": 69.33580276900659,
      "YST_AVG_MINS_TO_PICK": 110.17647058823529,
      "LQ_AVG_MINS_TO_PICK": 63.777482740308024,
      "LH_AVG_MINS_TO_PICK": 63.005317863185894,
      "WTD_OVER_SLA_CNT": 15,
      "LW_OVER_SLA_CNT": 44,
      "LM_OVER_SLA_CNT": 123,
      "MTD_OVER_SLA_CNT": 123,
      "QTD_OVER_SLA_CNT": 356,
      "HTD_OVER_SLA_CNT": 552,
      "YTD_OVER_SLA_CNT": 552,
      "L12_OVER_SLA_CNT": 1079,
      "YST_OVER_SLA_CNT": 9,
      "LQ_OVER_SLA_CNT": 196,
      "LH_OVER_SLA_CNT": 496,
      "WTD_DS_COMP": 0.875,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.9210526315789473,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9868421052631579,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9190751445086706,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9447852760736196,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9596174282678002,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9596174282678002,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9388803287108372,
      "L12_OF_APP": 0.9992575176339562
    },
    {
      "STR_NBR": 149,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 8483.679999999998,
      "WTD_LY_NET_SLS_AMT": 7237.299999999997,
      "LW_NET_SLS_AMT": 25175.489999999987,
      "LW_LY_NET_SLS_AMT": 35719.26,
      "LM_NET_SLS_AMT": 119128.66,
      "LM_LY_NET_SLS_AMT": 122207.50000000001,
      "MTD_NET_SLS_AMT": 129149.15999999999,
      "MTD_LY_NET_SLS_AMT": 126648.03999999996,
      "QTD_NET_SLS_AMT": 364191.02,
      "QTD_LY_NET_SLS_AMT": 372370.87999999995,
      "HTD_NET_SLS_AMT": 732533.0999999999,
      "HTD_LY_NET_SLS_AMT": 792604.5399999997,
      "YTD_NET_SLS_AMT": 732533.0999999999,
      "YTD_LY_NET_SLS_AMT": 792604.5399999997,
      "L12_NET_SLS_AMT": 1541491.4999999988,
      "L12_LY_NET_SLS_AMT": 1589110.5699999994,
      "YST_NET_SLS_AMT": 3994.26,
      "YST_LY_NET_SLS_AMT": 4139.29,
      "LQ_NET_SLS_AMT": 368342.07999999984,
      "LQ_LY_NET_SLS_AMT": 420233.66000000015,
      "LH_NET_SLS_AMT": 792811.4299999996,
      "LH_LY_NET_SLS_AMT": 754666.0099999997,
      "WTD_UNT_SLS_AMT": 382,
      "WTD_LY_UNT_SLS_AMT": 384,
      "LW_UNT_SLS_AMT": 1325,
      "LW_LY_UNT_SLS_AMT": 1159,
      "LM_UNT_SLS_AMT": 5823,
      "LM_LY_UNT_SLS_AMT": 6737,
      "MTD_UNT_SLS_AMT": 7346.5,
      "MTD_LY_UNT_SLS_AMT": 5568,
      "QTD_UNT_SLS_AMT": 19404.5,
      "QTD_LY_UNT_SLS_AMT": 18142,
      "HTD_UNT_SLS_AMT": 38710.5,
      "HTD_LY_UNT_SLS_AMT": 43190.25,
      "YTD_UNT_SLS_AMT": 38710.5,
      "YTD_LY_UNT_SLS_AMT": 43190.25,
      "L12_UNT_SLS_AMT": 70746.48,
      "L12_LY_UNT_SLS_AMT": 76844.63,
      "YST_UNT_SLS_AMT": 174,
      "YST_LY_UNT_SLS_AMT": 261,
      "LQ_UNT_SLS_AMT": 19306,
      "LQ_LY_UNT_SLS_AMT": 25048.25,
      "LH_UNT_SLS_AMT": 33277.479999999996,
      "LH_LY_UNT_SLS_AMT": 31637.379999999997,
      "WTD_TRANS_CNT": 79,
      "WTD_LY_TRANS_CNT": 105,
      "LW_TRANS_CNT": 310,
      "LW_LY_TRANS_CNT": 394,
      "LM_TRANS_CNT": 1450,
      "LM_LY_TRANS_CNT": 1521,
      "MTD_TRANS_CNT": 1427,
      "MTD_LY_TRANS_CNT": 1582,
      "QTD_TRANS_CNT": 4310,
      "QTD_LY_TRANS_CNT": 4542,
      "HTD_TRANS_CNT": 8230,
      "HTD_LY_TRANS_CNT": 8836,
      "YTD_TRANS_CNT": 8230,
      "YTD_LY_TRANS_CNT": 8836,
      "L12_TRANS_CNT": 17656,
      "L12_LY_TRANS_CNT": 19780,
      "YST_TRANS_CNT": 37,
      "YST_LY_TRANS_CNT": 66,
      "LQ_TRANS_CNT": 3920,
      "LQ_LY_TRANS_CNT": 4294,
      "LH_TRANS_CNT": 9029,
      "LH_LY_TRANS_CNT": 10380,
      "WTD_RLSD_UNITS": 292,
      "WTD_RLSD_ORDERS": 62,
      "LW_RLSD_UNITS": 1998,
      "LW_RLSD_ORDERS": 205,
      "LM_RLSD_UNITS": 6773,
      "LM_RLSD_ORDERS": 963,
      "MTD_RLSD_UNITS": 8723,
      "MTD_RLSD_ORDERS": 992,
      "QTD_RLSD_UNITS": 22944,
      "QTD_RLSD_ORDERS": 2941,
      "HTD_RLSD_UNITS": 45841,
      "HTD_RLSD_ORDERS": 5588,
      "YTD_RLSD_UNITS": 45841,
      "YTD_RLSD_ORDERS": 5588,
      "L12_RLSD_UNITS": 82834,
      "L12_RLSD_ORDERS": 11908,
      "YST_RLSD_UNITS": 193,
      "YST_RLSD_ORDERS": 37,
      "LQ_RLSD_UNITS": 22897,
      "LQ_RLSD_ORDERS": 2648,
      "LH_RLSD_UNITS": 37837,
      "LH_RLSD_ORDERS": 6129,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.9836065573770492,
      "LW_PCT_ON_TM": 0.9310344827586207,
      "LM_PCT_ON_TM": 0.9339430894308943,
      "MTD_PCT_ON_TM": 0.9463019250253293,
      "QTD_PCT_ON_TM": 0.9499159663865546,
      "HTD_PCT_ON_TM": 0.9436201780415431,
      "YTD_PCT_ON_TM": 0.9436201780415431,
      "L12_PCT_ON_TM": 0.8909420887642303,
      "YST_PCT_ON_TM": 0.96,
      "LQ_PCT_ON_TM": 0.9368191721132898,
      "LH_PCT_ON_TM": 0.8798262548262549,
      "WTD_AVG_MINS_TO_PICK": 25.54098360655737,
      "LW_AVG_MINS_TO_PICK": 52.68965517241381,
      "LM_AVG_MINS_TO_PICK": 49.178861788617894,
      "MTD_AVG_MINS_TO_PICK": 48.069908814589645,
      "QTD_AVG_MINS_TO_PICK": 45.07999999999999,
      "HTD_AVG_MINS_TO_PICK": 47.47215919008552,
      "YTD_AVG_MINS_TO_PICK": 47.47215919008552,
      "L12_AVG_MINS_TO_PICK": 61.900758950668234,
      "YST_AVG_MINS_TO_PICK": 25.32,
      "LQ_AVG_MINS_TO_PICK": 50.056281771968024,
      "LH_AVG_MINS_TO_PICK": 65.75691763191767,
      "WTD_OVER_SLA_CNT": 1,
      "LW_OVER_SLA_CNT": 14,
      "LM_OVER_SLA_CNT": 65,
      "MTD_OVER_SLA_CNT": 53,
      "QTD_OVER_SLA_CNT": 149,
      "HTD_OVER_SLA_CNT": 323,
      "YTD_OVER_SLA_CNT": 323,
      "L12_OVER_SLA_CNT": 1322,
      "YST_OVER_SLA_CNT": 1,
      "LQ_OVER_SLA_CNT": 174,
      "LH_OVER_SLA_CNT": 747,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.9827586206896551,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9620253164556962,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9683794466403162,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9662522202486679,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9673405909797823,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9673405909797823,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9555796316359697,
      "L12_OF_APP": 0.9995803961060759
    },
    {
      "STR_NBR": 161,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 6882.480000000002,
      "WTD_LY_NET_SLS_AMT": 5967.989999999999,
      "LW_NET_SLS_AMT": 19760.24,
      "LW_LY_NET_SLS_AMT": 31492.210000000017,
      "LM_NET_SLS_AMT": 127882.86,
      "LM_LY_NET_SLS_AMT": 125116.73999999999,
      "MTD_NET_SLS_AMT": 128493.54999999996,
      "MTD_LY_NET_SLS_AMT": 128981.19000000003,
      "QTD_NET_SLS_AMT": 406022.42999999993,
      "QTD_LY_NET_SLS_AMT": 387777.9899999998,
      "HTD_NET_SLS_AMT": 726104.1799999998,
      "HTD_LY_NET_SLS_AMT": 786746.2499999998,
      "YTD_NET_SLS_AMT": 726104.1799999998,
      "YTD_LY_NET_SLS_AMT": 786746.2499999998,
      "L12_NET_SLS_AMT": 1485357.4999999998,
      "L12_LY_NET_SLS_AMT": 1567592.6599999992,
      "YST_NET_SLS_AMT": 4196.32,
      "YST_LY_NET_SLS_AMT": 2374.3,
      "LQ_NET_SLS_AMT": 320081.75000000006,
      "LQ_LY_NET_SLS_AMT": 398968.2600000001,
      "LH_NET_SLS_AMT": 738646.14,
      "LH_LY_NET_SLS_AMT": 740305.5899999999,
      "WTD_UNT_SLS_AMT": 371,
      "WTD_LY_UNT_SLS_AMT": 246,
      "LW_UNT_SLS_AMT": 1464,
      "LW_LY_UNT_SLS_AMT": 1428,
      "LM_UNT_SLS_AMT": 7748,
      "LM_LY_UNT_SLS_AMT": 5905,
      "MTD_UNT_SLS_AMT": 9314,
      "MTD_LY_UNT_SLS_AMT": 6227,
      "QTD_UNT_SLS_AMT": 25123,
      "QTD_LY_UNT_SLS_AMT": 18758,
      "HTD_UNT_SLS_AMT": 47458,
      "HTD_LY_UNT_SLS_AMT": 45595,
      "YTD_UNT_SLS_AMT": 47458,
      "YTD_LY_UNT_SLS_AMT": 45595,
      "L12_UNT_SLS_AMT": 79853,
      "L12_LY_UNT_SLS_AMT": 86577,
      "YST_UNT_SLS_AMT": 211,
      "YST_LY_UNT_SLS_AMT": 93,
      "LQ_UNT_SLS_AMT": 22335,
      "LQ_LY_UNT_SLS_AMT": 26837,
      "LH_UNT_SLS_AMT": 34614,
      "LH_LY_UNT_SLS_AMT": 38016,
      "WTD_TRANS_CNT": 108,
      "WTD_LY_TRANS_CNT": 98,
      "LW_TRANS_CNT": 255,
      "LW_LY_TRANS_CNT": 335,
      "LM_TRANS_CNT": 1314,
      "LM_LY_TRANS_CNT": 1364,
      "MTD_TRANS_CNT": 1310,
      "MTD_LY_TRANS_CNT": 1453,
      "QTD_TRANS_CNT": 3931,
      "QTD_LY_TRANS_CNT": 4143,
      "HTD_TRANS_CNT": 7490,
      "HTD_LY_TRANS_CNT": 7729,
      "YTD_TRANS_CNT": 7490,
      "YTD_LY_TRANS_CNT": 7729,
      "L12_TRANS_CNT": 15400,
      "L12_LY_TRANS_CNT": 16415,
      "YST_TRANS_CNT": 56,
      "YST_LY_TRANS_CNT": 47,
      "LQ_TRANS_CNT": 3559,
      "LQ_LY_TRANS_CNT": 3586,
      "LH_TRANS_CNT": 7554,
      "LH_LY_TRANS_CNT": 8347,
      "WTD_RLSD_UNITS": 353,
      "WTD_RLSD_ORDERS": 58,
      "LW_RLSD_UNITS": 1729,
      "LW_RLSD_ORDERS": 168,
      "LM_RLSD_UNITS": 9689,
      "LM_RLSD_ORDERS": 973,
      "MTD_RLSD_UNITS": 10535,
      "MTD_RLSD_ORDERS": 891,
      "QTD_RLSD_UNITS": 29170,
      "QTD_RLSD_ORDERS": 2817,
      "HTD_RLSD_UNITS": 53533,
      "HTD_RLSD_ORDERS": 5293,
      "YTD_RLSD_UNITS": 53533,
      "YTD_RLSD_ORDERS": 5293,
      "L12_RLSD_UNITS": 89370,
      "L12_RLSD_ORDERS": 10949,
      "YST_RLSD_UNITS": 122,
      "YST_RLSD_ORDERS": 25,
      "LQ_RLSD_UNITS": 24363,
      "LQ_RLSD_ORDERS": 2486,
      "LH_RLSD_UNITS": 38400,
      "LH_RLSD_ORDERS": 5363,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.9841269841269841,
      "LW_PCT_ON_TM": 0.9314285714285714,
      "LM_PCT_ON_TM": 0.8619246861924686,
      "MTD_PCT_ON_TM": 0.9229074889867841,
      "QTD_PCT_ON_TM": 0.8250180766449747,
      "HTD_PCT_ON_TM": 0.7910073330760324,
      "YTD_PCT_ON_TM": 0.7910073330760324,
      "L12_PCT_ON_TM": 0.7782653347026421,
      "YST_PCT_ON_TM": 1,
      "LQ_PCT_ON_TM": 0.7520695364238411,
      "LH_PCT_ON_TM": 0.7913669064748201,
      "WTD_AVG_MINS_TO_PICK": 36.98412698412699,
      "LW_AVG_MINS_TO_PICK": 54.88571428571429,
      "LM_AVG_MINS_TO_PICK": 74.56485355648536,
      "MTD_AVG_MINS_TO_PICK": 56.31057268722468,
      "QTD_AVG_MINS_TO_PICK": 83.77404193781634,
      "HTD_AVG_MINS_TO_PICK": 90.76302585874177,
      "YTD_AVG_MINS_TO_PICK": 90.76302585874177,
      "L12_AVG_MINS_TO_PICK": 92.376528802166,
      "YST_AVG_MINS_TO_PICK": 31.741935483870968,
      "LQ_AVG_MINS_TO_PICK": 98.76448675496687,
      "LH_AVG_MINS_TO_PICK": 87.05395683453241,
      "WTD_OVER_SLA_CNT": 1,
      "LW_OVER_SLA_CNT": 12,
      "LM_OVER_SLA_CNT": 132,
      "MTD_OVER_SLA_CNT": 70,
      "QTD_OVER_SLA_CNT": 484,
      "HTD_OVER_SLA_CNT": 1083,
      "YTD_OVER_SLA_CNT": 1083,
      "L12_OVER_SLA_CNT": 2375,
      "YST_OVER_SLA_CNT": 0,
      "LQ_OVER_SLA_CNT": 599,
      "LH_OVER_SLA_CNT": 1102,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9719101123595506,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9826589595375722,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9721669980119284,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9711751662971175,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9711751662971175,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9305555555555556,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 175,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 4879.249999999998,
      "WTD_LY_NET_SLS_AMT": 5047.41,
      "LW_NET_SLS_AMT": 28160.370000000006,
      "LW_LY_NET_SLS_AMT": 19504.33,
      "LM_NET_SLS_AMT": 90919.09000000003,
      "LM_LY_NET_SLS_AMT": 104856.39000000001,
      "MTD_NET_SLS_AMT": 107282.70999999999,
      "MTD_LY_NET_SLS_AMT": 91261.06,
      "QTD_NET_SLS_AMT": 308332.47000000003,
      "QTD_LY_NET_SLS_AMT": 314124.72000000003,
      "HTD_NET_SLS_AMT": 625241.07,
      "HTD_LY_NET_SLS_AMT": 662951.6899999998,
      "YTD_NET_SLS_AMT": 625241.07,
      "YTD_LY_NET_SLS_AMT": 662951.6899999998,
      "L12_NET_SLS_AMT": 1166252.5499999996,
      "L12_LY_NET_SLS_AMT": 1298817.2799999996,
      "YST_NET_SLS_AMT": 2443.6800000000007,
      "YST_LY_NET_SLS_AMT": 3603.9700000000003,
      "LQ_NET_SLS_AMT": 316908.60000000015,
      "LQ_LY_NET_SLS_AMT": 348826.9700000001,
      "LH_NET_SLS_AMT": 544436.3299999998,
      "LH_LY_NET_SLS_AMT": 593242.3200000001,
      "WTD_UNT_SLS_AMT": 215,
      "WTD_LY_UNT_SLS_AMT": 436,
      "LW_UNT_SLS_AMT": 1699,
      "LW_LY_UNT_SLS_AMT": 1046,
      "LM_UNT_SLS_AMT": 4684,
      "LM_LY_UNT_SLS_AMT": 4695,
      "MTD_UNT_SLS_AMT": 7105,
      "MTD_LY_UNT_SLS_AMT": 4275,
      "QTD_UNT_SLS_AMT": 17059,
      "QTD_LY_UNT_SLS_AMT": 14462,
      "HTD_UNT_SLS_AMT": 33410,
      "HTD_LY_UNT_SLS_AMT": 38081,
      "YTD_UNT_SLS_AMT": 33410,
      "YTD_LY_UNT_SLS_AMT": 38081,
      "L12_UNT_SLS_AMT": 57186,
      "L12_LY_UNT_SLS_AMT": 67541,
      "YST_UNT_SLS_AMT": 100,
      "YST_LY_UNT_SLS_AMT": 350,
      "LQ_UNT_SLS_AMT": 16351,
      "LQ_LY_UNT_SLS_AMT": 23619,
      "LH_UNT_SLS_AMT": 25841,
      "LH_LY_UNT_SLS_AMT": 26426,
      "WTD_TRANS_CNT": 78,
      "WTD_LY_TRANS_CNT": 68,
      "LW_TRANS_CNT": 238,
      "LW_LY_TRANS_CNT": 234,
      "LM_TRANS_CNT": 1030,
      "LM_LY_TRANS_CNT": 1141,
      "MTD_TRANS_CNT": 1099,
      "MTD_LY_TRANS_CNT": 1143,
      "QTD_TRANS_CNT": 3084,
      "QTD_LY_TRANS_CNT": 3210,
      "HTD_TRANS_CNT": 6081,
      "HTD_LY_TRANS_CNT": 6232,
      "YTD_TRANS_CNT": 6081,
      "YTD_LY_TRANS_CNT": 6232,
      "L12_TRANS_CNT": 12173,
      "L12_LY_TRANS_CNT": 12827,
      "YST_TRANS_CNT": 46,
      "YST_LY_TRANS_CNT": 33,
      "LQ_TRANS_CNT": 2997,
      "LQ_LY_TRANS_CNT": 3022,
      "LH_TRANS_CNT": 5873,
      "LH_LY_TRANS_CNT": 6405,
      "WTD_RLSD_UNITS": 764,
      "WTD_RLSD_ORDERS": 48,
      "LW_RLSD_UNITS": 1494,
      "LW_RLSD_ORDERS": 165,
      "LM_RLSD_UNITS": 5190,
      "LM_RLSD_ORDERS": 624,
      "MTD_RLSD_UNITS": 8139,
      "MTD_RLSD_ORDERS": 682,
      "QTD_RLSD_UNITS": 19125,
      "QTD_RLSD_ORDERS": 1931,
      "HTD_RLSD_UNITS": 37735,
      "HTD_RLSD_ORDERS": 3842,
      "YTD_RLSD_UNITS": 37735,
      "YTD_RLSD_ORDERS": 3842,
      "L12_RLSD_UNITS": 64563,
      "L12_RLSD_ORDERS": 7710,
      "YST_RLSD_UNITS": 550,
      "YST_RLSD_ORDERS": 31,
      "LQ_RLSD_UNITS": 18610,
      "LQ_RLSD_ORDERS": 1913,
      "LH_RLSD_UNITS": 29533,
      "LH_RLSD_ORDERS": 3785,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.9795918367346939,
      "LW_PCT_ON_TM": 0.7741935483870968,
      "LM_PCT_ON_TM": 0.5966101694915255,
      "MTD_PCT_ON_TM": 0.8239757207890743,
      "QTD_PCT_ON_TM": 0.7561894510226049,
      "HTD_PCT_ON_TM": 0.8211316706891928,
      "YTD_PCT_ON_TM": 0.8211316706891928,
      "L12_PCT_ON_TM": 0.8120624663435649,
      "YST_PCT_ON_TM": 1,
      "LQ_PCT_ON_TM": 0.8856226616782469,
      "LH_PCT_ON_TM": 0.8454545454545455,
      "WTD_AVG_MINS_TO_PICK": 45.44897959183674,
      "LW_AVG_MINS_TO_PICK": 89.54193548387096,
      "LM_AVG_MINS_TO_PICK": 158.31186440677962,
      "MTD_AVG_MINS_TO_PICK": 73.86039453717754,
      "QTD_AVG_MINS_TO_PICK": 98.02152852529605,
      "HTD_AVG_MINS_TO_PICK": 82.14910163582734,
      "YTD_AVG_MINS_TO_PICK": 82.14910163582734,
      "L12_AVG_MINS_TO_PICK": 86.62910608508346,
      "YST_AVG_MINS_TO_PICK": 43.964285714285715,
      "LQ_AVG_MINS_TO_PICK": 66.38695884553717,
      "LH_AVG_MINS_TO_PICK": 72.9928374655647,
      "WTD_OVER_SLA_CNT": 1,
      "LW_OVER_SLA_CNT": 35,
      "LM_OVER_SLA_CNT": 238,
      "MTD_OVER_SLA_CNT": 116,
      "QTD_OVER_SLA_CNT": 453,
      "HTD_OVER_SLA_CNT": 667,
      "YTD_OVER_SLA_CNT": 667,
      "L12_OVER_SLA_CNT": 1396,
      "YST_OVER_SLA_CNT": 0,
      "LQ_OVER_SLA_CNT": 214,
      "LH_OVER_SLA_CNT": 561,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9365079365079365,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.90625,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9458689458689459,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9490358126721763,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9490358126721763,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8954578226387887,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 1754,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 7096.07,
      "WTD_LY_NET_SLS_AMT": 2515.21,
      "LW_NET_SLS_AMT": 27525.019999999997,
      "LW_LY_NET_SLS_AMT": 22642.539999999997,
      "LM_NET_SLS_AMT": 97192.72999999992,
      "LM_LY_NET_SLS_AMT": 103729.25,
      "MTD_NET_SLS_AMT": 116949.17999999998,
      "MTD_LY_NET_SLS_AMT": 111629.01999999997,
      "QTD_NET_SLS_AMT": 318847.7899999999,
      "QTD_LY_NET_SLS_AMT": 332584.1400000002,
      "HTD_NET_SLS_AMT": 591613.7100000001,
      "HTD_LY_NET_SLS_AMT": 653586.7799999998,
      "YTD_NET_SLS_AMT": 591613.7100000001,
      "YTD_LY_NET_SLS_AMT": 653586.7799999998,
      "L12_NET_SLS_AMT": 1161864.6799999992,
      "L12_LY_NET_SLS_AMT": 1284357.4699999997,
      "YST_NET_SLS_AMT": 1810.9900000000002,
      "YST_LY_NET_SLS_AMT": 1814.2,
      "LQ_NET_SLS_AMT": 272765.9199999999,
      "LQ_LY_NET_SLS_AMT": 321002.6399999999,
      "LH_NET_SLS_AMT": 561015.46,
      "LH_LY_NET_SLS_AMT": 598161.4500000002,
      "WTD_UNT_SLS_AMT": 203,
      "WTD_LY_UNT_SLS_AMT": 34,
      "LW_UNT_SLS_AMT": 1481,
      "LW_LY_UNT_SLS_AMT": 1362,
      "LM_UNT_SLS_AMT": 4388,
      "LM_LY_UNT_SLS_AMT": 4993,
      "MTD_UNT_SLS_AMT": 5472,
      "MTD_LY_UNT_SLS_AMT": 4957,
      "QTD_UNT_SLS_AMT": 15075,
      "QTD_LY_UNT_SLS_AMT": 14925,
      "HTD_UNT_SLS_AMT": 28993,
      "HTD_LY_UNT_SLS_AMT": 39025,
      "YTD_UNT_SLS_AMT": 28993,
      "YTD_LY_UNT_SLS_AMT": 39025,
      "L12_UNT_SLS_AMT": 52680,
      "L12_LY_UNT_SLS_AMT": 67622,
      "YST_UNT_SLS_AMT": 22,
      "YST_LY_UNT_SLS_AMT": 33,
      "LQ_UNT_SLS_AMT": 13918,
      "LQ_LY_UNT_SLS_AMT": 24100,
      "LH_UNT_SLS_AMT": 23368,
      "LH_LY_UNT_SLS_AMT": 28055,
      "WTD_TRANS_CNT": 61,
      "WTD_LY_TRANS_CNT": 68,
      "LW_TRANS_CNT": 226,
      "LW_LY_TRANS_CNT": 239,
      "LM_TRANS_CNT": 1023,
      "LM_LY_TRANS_CNT": 1020,
      "MTD_TRANS_CNT": 1016,
      "MTD_LY_TRANS_CNT": 1114,
      "QTD_TRANS_CNT": 2987,
      "QTD_LY_TRANS_CNT": 3212,
      "HTD_TRANS_CNT": 5807,
      "HTD_LY_TRANS_CNT": 6136,
      "YTD_TRANS_CNT": 5807,
      "YTD_LY_TRANS_CNT": 6136,
      "L12_TRANS_CNT": 12292,
      "L12_LY_TRANS_CNT": 12656,
      "YST_TRANS_CNT": 24,
      "YST_LY_TRANS_CNT": 40,
      "LQ_TRANS_CNT": 2820,
      "LQ_LY_TRANS_CNT": 2924,
      "LH_TRANS_CNT": 6230,
      "LH_LY_TRANS_CNT": 6386,
      "WTD_RLSD_UNITS": 295,
      "WTD_RLSD_ORDERS": 55,
      "LW_RLSD_UNITS": 1426,
      "LW_RLSD_ORDERS": 149,
      "LM_RLSD_UNITS": 4450,
      "LM_RLSD_ORDERS": 726,
      "MTD_RLSD_UNITS": 6358,
      "MTD_RLSD_ORDERS": 698,
      "QTD_RLSD_UNITS": 16965,
      "QTD_RLSD_ORDERS": 2073,
      "HTD_RLSD_UNITS": 33503,
      "HTD_RLSD_ORDERS": 4043,
      "YTD_RLSD_UNITS": 33503,
      "YTD_RLSD_ORDERS": 4043,
      "L12_RLSD_UNITS": 59962,
      "L12_RLSD_ORDERS": 8518,
      "YST_RLSD_UNITS": 82,
      "YST_RLSD_ORDERS": 26,
      "LQ_RLSD_UNITS": 16538,
      "LQ_RLSD_ORDERS": 1973,
      "LH_RLSD_UNITS": 26888,
      "LH_RLSD_ORDERS": 4282,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.8333333333333334,
      "LW_PCT_ON_TM": 0.832,
      "LM_PCT_ON_TM": 0.8473609129814551,
      "MTD_PCT_ON_TM": 0.8705357142857143,
      "QTD_PCT_ON_TM": 0.843098311817279,
      "HTD_PCT_ON_TM": 0.861612821165098,
      "YTD_PCT_ON_TM": 0.861612821165098,
      "L12_PCT_ON_TM": 0.8919313618108798,
      "YST_PCT_ON_TM": 0.6818181818181818,
      "LQ_PCT_ON_TM": 0.8810641627543035,
      "LH_PCT_ON_TM": 0.9074255007327797,
      "WTD_AVG_MINS_TO_PICK": 91.87037037037038,
      "LW_AVG_MINS_TO_PICK": 97.41600000000001,
      "LM_AVG_MINS_TO_PICK": 68.48074179743219,
      "MTD_AVG_MINS_TO_PICK": 68.99553571428572,
      "QTD_AVG_MINS_TO_PICK": 72.78798411122142,
      "HTD_AVG_MINS_TO_PICK": 69.03154413635203,
      "YTD_AVG_MINS_TO_PICK": 69.03154413635203,
      "L12_AVG_MINS_TO_PICK": 57.56869903857859,
      "YST_AVG_MINS_TO_PICK": 117.54545454545455,
      "LQ_AVG_MINS_TO_PICK": 65.08502869066248,
      "LH_AVG_MINS_TO_PICK": 51.37176355642406,
      "WTD_OVER_SLA_CNT": 9,
      "LW_OVER_SLA_CNT": 21,
      "LM_OVER_SLA_CNT": 107,
      "MTD_OVER_SLA_CNT": 87,
      "QTD_OVER_SLA_CNT": 316,
      "HTD_OVER_SLA_CNT": 544,
      "YTD_OVER_SLA_CNT": 544,
      "L12_OVER_SLA_CNT": 888,
      "YST_OVER_SLA_CNT": 7,
      "LQ_OVER_SLA_CNT": 228,
      "LH_OVER_SLA_CNT": 379,
      "WTD_DS_COMP": 0.8666666666666667,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.92,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9181286549707602,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.937888198757764,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9194214876033058,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9058577405857741,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9058577405857741,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9085064292779427,
      "L12_OF_APP": 0.9997484909456741
    },
    {
      "STR_NBR": 1755,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 5408.84,
      "WTD_LY_NET_SLS_AMT": 7292.539999999996,
      "LW_NET_SLS_AMT": 17833.610000000004,
      "LW_LY_NET_SLS_AMT": 25360.02999999999,
      "LM_NET_SLS_AMT": 93642.01999999995,
      "LM_LY_NET_SLS_AMT": 86657.27,
      "MTD_NET_SLS_AMT": 85006.48999999999,
      "MTD_LY_NET_SLS_AMT": 106579.34999999998,
      "QTD_NET_SLS_AMT": 261431.8800000001,
      "QTD_LY_NET_SLS_AMT": 281850.63999999996,
      "HTD_NET_SLS_AMT": 516866.1599999999,
      "HTD_LY_NET_SLS_AMT": 539214.9699999999,
      "YTD_NET_SLS_AMT": 516866.1599999999,
      "YTD_LY_NET_SLS_AMT": 539214.9699999999,
      "L12_NET_SLS_AMT": 1082018.5499999998,
      "L12_LY_NET_SLS_AMT": 1083439.1199999994,
      "YST_NET_SLS_AMT": 3874.249999999999,
      "YST_LY_NET_SLS_AMT": 3492.2200000000007,
      "LQ_NET_SLS_AMT": 255434.27999999997,
      "LQ_LY_NET_SLS_AMT": 257364.32999999996,
      "LH_NET_SLS_AMT": 525550.3799999999,
      "LH_LY_NET_SLS_AMT": 523554.9100000001,
      "WTD_UNT_SLS_AMT": 189,
      "WTD_LY_UNT_SLS_AMT": 300,
      "LW_UNT_SLS_AMT": 583,
      "LW_LY_UNT_SLS_AMT": 1473,
      "LM_UNT_SLS_AMT": 5176,
      "LM_LY_UNT_SLS_AMT": 4192,
      "MTD_UNT_SLS_AMT": 3590,
      "MTD_LY_UNT_SLS_AMT": 4307,
      "QTD_UNT_SLS_AMT": 12362,
      "QTD_LY_UNT_SLS_AMT": 12640,
      "HTD_UNT_SLS_AMT": 26250,
      "HTD_LY_UNT_SLS_AMT": 27599,
      "YTD_UNT_SLS_AMT": 26250,
      "YTD_LY_UNT_SLS_AMT": 27599,
      "L12_UNT_SLS_AMT": 53075,
      "L12_LY_UNT_SLS_AMT": 53668,
      "YST_UNT_SLS_AMT": 136,
      "YST_LY_UNT_SLS_AMT": 233,
      "LQ_UNT_SLS_AMT": 13888,
      "LQ_LY_UNT_SLS_AMT": 14959,
      "LH_UNT_SLS_AMT": 25066,
      "LH_LY_UNT_SLS_AMT": 24142,
      "WTD_TRANS_CNT": 66,
      "WTD_LY_TRANS_CNT": 89,
      "LW_TRANS_CNT": 216,
      "LW_LY_TRANS_CNT": 249,
      "LM_TRANS_CNT": 946,
      "LM_LY_TRANS_CNT": 966,
      "MTD_TRANS_CNT": 997,
      "MTD_LY_TRANS_CNT": 1076,
      "QTD_TRANS_CNT": 2904,
      "QTD_LY_TRANS_CNT": 3029,
      "HTD_TRANS_CNT": 5663,
      "HTD_LY_TRANS_CNT": 5857,
      "YTD_TRANS_CNT": 5663,
      "YTD_LY_TRANS_CNT": 5857,
      "L12_TRANS_CNT": 12085,
      "L12_LY_TRANS_CNT": 13299,
      "YST_TRANS_CNT": 41,
      "YST_LY_TRANS_CNT": 42,
      "LQ_TRANS_CNT": 2759,
      "LQ_LY_TRANS_CNT": 2828,
      "LH_TRANS_CNT": 6155,
      "LH_LY_TRANS_CNT": 7112,
      "WTD_RLSD_UNITS": 227,
      "WTD_RLSD_ORDERS": 47,
      "LW_RLSD_UNITS": 814,
      "LW_RLSD_ORDERS": 152,
      "LM_RLSD_UNITS": 5202,
      "LM_RLSD_ORDERS": 673,
      "MTD_RLSD_UNITS": 4202,
      "MTD_RLSD_ORDERS": 658,
      "QTD_RLSD_UNITS": 13430,
      "QTD_RLSD_ORDERS": 2011,
      "HTD_RLSD_UNITS": 28327,
      "HTD_RLSD_ORDERS": 3933,
      "YTD_RLSD_UNITS": 28327,
      "YTD_RLSD_ORDERS": 3933,
      "L12_RLSD_UNITS": 57408,
      "L12_RLSD_ORDERS": 8400,
      "YST_RLSD_UNITS": 185,
      "YST_RLSD_ORDERS": 34,
      "LQ_RLSD_UNITS": 14897,
      "LQ_RLSD_ORDERS": 1924,
      "LH_RLSD_UNITS": 27561,
      "LH_RLSD_ORDERS": 4263,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.96,
      "LW_PCT_ON_TM": 0.7222222222222222,
      "LM_PCT_ON_TM": 0.7921092564491654,
      "MTD_PCT_ON_TM": 0.852017937219731,
      "QTD_PCT_ON_TM": 0.837865055387714,
      "HTD_PCT_ON_TM": 0.8634538152610441,
      "YTD_PCT_ON_TM": 0.8634538152610441,
      "L12_PCT_ON_TM": 0.8662155745489079,
      "YST_PCT_ON_TM": 0.967741935483871,
      "LQ_PCT_ON_TM": 0.8888888888888888,
      "LH_PCT_ON_TM": 0.8633686690223793,
      "WTD_AVG_MINS_TO_PICK": 51.58,
      "LW_AVG_MINS_TO_PICK": 98.38888888888886,
      "LM_AVG_MINS_TO_PICK": 73.36418816388472,
      "MTD_AVG_MINS_TO_PICK": 78.37668161434976,
      "QTD_AVG_MINS_TO_PICK": 73.96525679758308,
      "HTD_AVG_MINS_TO_PICK": 65.8529116465864,
      "YTD_AVG_MINS_TO_PICK": 65.8529116465864,
      "L12_AVG_MINS_TO_PICK": 66.71783000949671,
      "YST_AVG_MINS_TO_PICK": 43.16129032258065,
      "LQ_AVG_MINS_TO_PICK": 57.789289289289286,
      "LH_AVG_MINS_TO_PICK": 68.46148409893992,
      "WTD_OVER_SLA_CNT": 2,
      "LW_OVER_SLA_CNT": 40,
      "LM_OVER_SLA_CNT": 137,
      "MTD_OVER_SLA_CNT": 99,
      "QTD_OVER_SLA_CNT": 322,
      "HTD_OVER_SLA_CNT": 544,
      "YTD_OVER_SLA_CNT": 544,
      "L12_OVER_SLA_CNT": 1127,
      "YST_OVER_SLA_CNT": 1,
      "LQ_OVER_SLA_CNT": 222,
      "LH_OVER_SLA_CNT": 580,
      "WTD_DS_COMP": null,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": null,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.8405797101449275,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.6666666666666666,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9291666666666667,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9245742092457421,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9245742092457421,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9154411764705882,
      "L12_OF_APP": 0.9996392062537582
    },
    {
      "STR_NBR": 1856,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 7246.6,
      "WTD_LY_NET_SLS_AMT": 6210.07,
      "LW_NET_SLS_AMT": 29555.42,
      "LW_LY_NET_SLS_AMT": 36850.280000000006,
      "LM_NET_SLS_AMT": 113057.98000000004,
      "LM_LY_NET_SLS_AMT": 116253.79999999999,
      "MTD_NET_SLS_AMT": 110115.34,
      "MTD_LY_NET_SLS_AMT": 126940.42,
      "QTD_NET_SLS_AMT": 323376.9700000001,
      "QTD_LY_NET_SLS_AMT": 354753.2699999998,
      "HTD_NET_SLS_AMT": 669428.7199999999,
      "HTD_LY_NET_SLS_AMT": 735677.3000000002,
      "YTD_NET_SLS_AMT": 669428.7199999999,
      "YTD_LY_NET_SLS_AMT": 735677.3000000002,
      "L12_NET_SLS_AMT": 1432050.7299999995,
      "L12_LY_NET_SLS_AMT": 1406935.7499999998,
      "YST_NET_SLS_AMT": 4053.8899999999994,
      "YST_LY_NET_SLS_AMT": 2557.61,
      "LQ_NET_SLS_AMT": 346051.7500000001,
      "LQ_LY_NET_SLS_AMT": 380924.02999999997,
      "LH_NET_SLS_AMT": 728641.1399999997,
      "LH_LY_NET_SLS_AMT": 674216.0399999998,
      "WTD_UNT_SLS_AMT": 393,
      "WTD_LY_UNT_SLS_AMT": 306,
      "LW_UNT_SLS_AMT": 1698,
      "LW_LY_UNT_SLS_AMT": 1535,
      "LM_UNT_SLS_AMT": 6376,
      "LM_LY_UNT_SLS_AMT": 7302,
      "MTD_UNT_SLS_AMT": 6399,
      "MTD_LY_UNT_SLS_AMT": 6837,
      "QTD_UNT_SLS_AMT": 19149,
      "QTD_LY_UNT_SLS_AMT": 20988,
      "HTD_UNT_SLS_AMT": 43386,
      "HTD_LY_UNT_SLS_AMT": 49432,
      "YTD_UNT_SLS_AMT": 43386,
      "YTD_LY_UNT_SLS_AMT": 49432,
      "L12_UNT_SLS_AMT": 84726,
      "L12_LY_UNT_SLS_AMT": 88574,
      "YST_UNT_SLS_AMT": 170,
      "YST_LY_UNT_SLS_AMT": 75,
      "LQ_UNT_SLS_AMT": 24237,
      "LQ_LY_UNT_SLS_AMT": 28444,
      "LH_UNT_SLS_AMT": 39866,
      "LH_LY_UNT_SLS_AMT": 36089,
      "WTD_TRANS_CNT": 75,
      "WTD_LY_TRANS_CNT": 78,
      "LW_TRANS_CNT": 289,
      "LW_LY_TRANS_CNT": 325,
      "LM_TRANS_CNT": 1298,
      "LM_LY_TRANS_CNT": 1285,
      "MTD_TRANS_CNT": 1278,
      "MTD_LY_TRANS_CNT": 1322,
      "QTD_TRANS_CNT": 3761,
      "QTD_LY_TRANS_CNT": 3855,
      "HTD_TRANS_CNT": 7171,
      "HTD_LY_TRANS_CNT": 7373,
      "YTD_TRANS_CNT": 7171,
      "YTD_LY_TRANS_CNT": 7373,
      "L12_TRANS_CNT": 14653,
      "L12_LY_TRANS_CNT": 15541,
      "YST_TRANS_CNT": 49,
      "YST_LY_TRANS_CNT": 43,
      "LQ_TRANS_CNT": 3410,
      "LQ_LY_TRANS_CNT": 3518,
      "LH_TRANS_CNT": 7238,
      "LH_LY_TRANS_CNT": 7845,
      "WTD_RLSD_UNITS": 471,
      "WTD_RLSD_ORDERS": 64,
      "LW_RLSD_UNITS": 1693,
      "LW_RLSD_ORDERS": 198,
      "LM_RLSD_UNITS": 7377,
      "LM_RLSD_ORDERS": 898,
      "MTD_RLSD_UNITS": 7226,
      "MTD_RLSD_ORDERS": 879,
      "QTD_RLSD_UNITS": 21290,
      "QTD_RLSD_ORDERS": 2590,
      "HTD_RLSD_UNITS": 46329,
      "HTD_RLSD_ORDERS": 4985,
      "YTD_RLSD_UNITS": 46329,
      "YTD_RLSD_ORDERS": 4985,
      "L12_RLSD_UNITS": 91371,
      "L12_RLSD_ORDERS": 10541,
      "YST_RLSD_UNITS": 250,
      "YST_RLSD_ORDERS": 40,
      "LQ_RLSD_UNITS": 25039,
      "LQ_RLSD_ORDERS": 2401,
      "LH_RLSD_UNITS": 43106,
      "LH_RLSD_ORDERS": 5323,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.8888888888888888,
      "LW_PCT_ON_TM": 0.7923497267759563,
      "LM_PCT_ON_TM": 0.8415492957746479,
      "MTD_PCT_ON_TM": 0.8635838150289017,
      "QTD_PCT_ON_TM": 0.8460323726806158,
      "HTD_PCT_ON_TM": 0.8477861660885534,
      "YTD_PCT_ON_TM": 0.8477861660885534,
      "L12_PCT_ON_TM": 0.8646202282565919,
      "YST_PCT_ON_TM": 0.8536585365853658,
      "LQ_PCT_ON_TM": 0.8496621621621622,
      "LH_PCT_ON_TM": 0.8851457840819543,
      "WTD_AVG_MINS_TO_PICK": 56.761904761904766,
      "LW_AVG_MINS_TO_PICK": 98.72677595628414,
      "LM_AVG_MINS_TO_PICK": 77.3849765258216,
      "MTD_AVG_MINS_TO_PICK": 67.49248554913301,
      "QTD_AVG_MINS_TO_PICK": 74.1247532570075,
      "HTD_AVG_MINS_TO_PICK": 70.56927157722913,
      "YTD_AVG_MINS_TO_PICK": 70.56927157722913,
      "L12_AVG_MINS_TO_PICK": 70.4707792207792,
      "YST_AVG_MINS_TO_PICK": 70.85365853658536,
      "LQ_AVG_MINS_TO_PICK": 66.76604729729725,
      "LH_AVG_MINS_TO_PICK": 65.39539007092202,
      "WTD_OVER_SLA_CNT": 7,
      "LW_OVER_SLA_CNT": 38,
      "LM_OVER_SLA_CNT": 135,
      "MTD_OVER_SLA_CNT": 118,
      "QTD_OVER_SLA_CNT": 390,
      "HTD_OVER_SLA_CNT": 746,
      "YTD_OVER_SLA_CNT": 746,
      "L12_OVER_SLA_CNT": 1376,
      "YST_OVER_SLA_CNT": 6,
      "LQ_OVER_SLA_CNT": 356,
      "LH_OVER_SLA_CNT": 583,
      "WTD_DS_COMP": 0.9545454545454546,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9849246231155779,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9403669724770642,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9487179487179487,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9582947173308619,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9582947173308619,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9680525164113786,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 6978,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOPIS",
      "WTD_NET_SLS_AMT": 6541.829999999999,
      "WTD_LY_NET_SLS_AMT": 2061.0900000000006,
      "LW_NET_SLS_AMT": 14092.779999999993,
      "LW_LY_NET_SLS_AMT": 19284.39,
      "LM_NET_SLS_AMT": 72020.63,
      "LM_LY_NET_SLS_AMT": 77855.24999999997,
      "MTD_NET_SLS_AMT": 68696.18000000002,
      "MTD_LY_NET_SLS_AMT": 84542.43000000002,
      "QTD_NET_SLS_AMT": 202108.47000000006,
      "QTD_LY_NET_SLS_AMT": 249006.00999999995,
      "HTD_NET_SLS_AMT": 428013.23,
      "HTD_LY_NET_SLS_AMT": 465979.1300000002,
      "YTD_NET_SLS_AMT": 428013.23,
      "YTD_LY_NET_SLS_AMT": 465979.1300000002,
      "L12_NET_SLS_AMT": 883630.8899999997,
      "L12_LY_NET_SLS_AMT": 891200.2999999998,
      "YST_NET_SLS_AMT": 2043.56,
      "YST_LY_NET_SLS_AMT": 917.0800000000003,
      "LQ_NET_SLS_AMT": 225904.76000000004,
      "LQ_LY_NET_SLS_AMT": 216973.12,
      "LH_NET_SLS_AMT": 424393.64,
      "LH_LY_NET_SLS_AMT": 426356.7999999999,
      "WTD_UNT_SLS_AMT": 182,
      "WTD_LY_UNT_SLS_AMT": 99,
      "LW_UNT_SLS_AMT": 1074,
      "LW_LY_UNT_SLS_AMT": 965,
      "LM_UNT_SLS_AMT": 4173,
      "LM_LY_UNT_SLS_AMT": 5065,
      "MTD_UNT_SLS_AMT": 4073,
      "MTD_LY_UNT_SLS_AMT": 4115,
      "QTD_UNT_SLS_AMT": 11960,
      "QTD_LY_UNT_SLS_AMT": 13319,
      "HTD_UNT_SLS_AMT": 26215,
      "HTD_LY_UNT_SLS_AMT": 32714,
      "YTD_UNT_SLS_AMT": 26215,
      "YTD_LY_UNT_SLS_AMT": 32714,
      "L12_UNT_SLS_AMT": 44546,
      "L12_LY_UNT_SLS_AMT": 52208,
      "YST_UNT_SLS_AMT": 71,
      "YST_LY_UNT_SLS_AMT": 38,
      "LQ_UNT_SLS_AMT": 14255,
      "LQ_LY_UNT_SLS_AMT": 19395,
      "LH_UNT_SLS_AMT": 17364,
      "LH_LY_UNT_SLS_AMT": 19308,
      "WTD_TRANS_CNT": 79,
      "WTD_LY_TRANS_CNT": 75,
      "LW_TRANS_CNT": 194,
      "LW_LY_TRANS_CNT": 259,
      "LM_TRANS_CNT": 1080,
      "LM_LY_TRANS_CNT": 1198,
      "MTD_TRANS_CNT": 988,
      "MTD_LY_TRANS_CNT": 1156,
      "QTD_TRANS_CNT": 3067,
      "QTD_LY_TRANS_CNT": 3489,
      "HTD_TRANS_CNT": 5938,
      "HTD_LY_TRANS_CNT": 6449,
      "YTD_TRANS_CNT": 5938,
      "YTD_LY_TRANS_CNT": 6449,
      "L12_TRANS_CNT": 13108,
      "L12_LY_TRANS_CNT": 14250,
      "YST_TRANS_CNT": 43,
      "YST_LY_TRANS_CNT": 42,
      "LQ_TRANS_CNT": 2871,
      "LQ_LY_TRANS_CNT": 2960,
      "LH_TRANS_CNT": 6816,
      "LH_LY_TRANS_CNT": 7432,
      "WTD_RLSD_UNITS": 158,
      "WTD_RLSD_ORDERS": 35,
      "LW_RLSD_UNITS": 916,
      "LW_RLSD_ORDERS": 107,
      "LM_RLSD_UNITS": 4712,
      "LM_RLSD_ORDERS": 724,
      "MTD_RLSD_UNITS": 4103,
      "MTD_RLSD_ORDERS": 619,
      "QTD_RLSD_UNITS": 13054,
      "QTD_RLSD_ORDERS": 1985,
      "HTD_RLSD_UNITS": 28447,
      "HTD_RLSD_ORDERS": 3879,
      "YTD_RLSD_UNITS": 28447,
      "YTD_RLSD_ORDERS": 3879,
      "L12_RLSD_UNITS": 51023,
      "L12_RLSD_ORDERS": 8665,
      "YST_RLSD_UNITS": 55,
      "YST_RLSD_ORDERS": 19,
      "LQ_RLSD_UNITS": 15393,
      "LQ_RLSD_ORDERS": 1900,
      "LH_RLSD_UNITS": 21321,
      "LH_RLSD_ORDERS": 4529,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.7916666666666666,
      "LW_PCT_ON_TM": 0.825,
      "LM_PCT_ON_TM": 0.8659217877094972,
      "MTD_PCT_ON_TM": 0.9134328358208955,
      "QTD_PCT_ON_TM": 0.8759159745969711,
      "HTD_PCT_ON_TM": 0.8724630418441494,
      "YTD_PCT_ON_TM": 0.8724630418441494,
      "L12_PCT_ON_TM": 0.8642092746730083,
      "YST_PCT_ON_TM": 0.8461538461538461,
      "LQ_PCT_ON_TM": 0.8688271604938271,
      "LH_PCT_ON_TM": 0.8641829757132752,
      "WTD_AVG_MINS_TO_PICK": 82.6875,
      "LW_AVG_MINS_TO_PICK": 111.49166666666666,
      "LM_AVG_MINS_TO_PICK": 76.26815642458102,
      "MTD_AVG_MINS_TO_PICK": 56.72238805970149,
      "QTD_AVG_MINS_TO_PICK": 68.22667318026379,
      "HTD_AVG_MINS_TO_PICK": 64.48459032823854,
      "YTD_AVG_MINS_TO_PICK": 64.48459032823854,
      "L12_AVG_MINS_TO_PICK": 67.93495838287755,
      "YST_AVG_MINS_TO_PICK": 71.15384615384615,
      "LQ_AVG_MINS_TO_PICK": 60.544238683127595,
      "LH_AVG_MINS_TO_PICK": 68.56236736618722,
      "WTD_OVER_SLA_CNT": 10,
      "LW_OVER_SLA_CNT": 21,
      "LM_OVER_SLA_CNT": 96,
      "MTD_OVER_SLA_CNT": 58,
      "QTD_OVER_SLA_CNT": 254,
      "HTD_OVER_SLA_CNT": 509,
      "YTD_OVER_SLA_CNT": 509,
      "L12_OVER_SLA_CNT": 1142,
      "YST_OVER_SLA_CNT": 4,
      "LQ_OVER_SLA_CNT": 255,
      "LH_OVER_SLA_CNT": 576,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.9705882352941176,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9900497512437811,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9855072463768116,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9677966101694915,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9614035087719298,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9614035087719298,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9388009991673605,
      "L12_OF_APP": 1
    }
  ],
  BOSS: [
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 5919.899999999999,
      "WTD_LY_NET_SLS_AMT": 7484.959999999999,
      "LW_NET_SLS_AMT": 10243.089999999997,
      "LW_LY_NET_SLS_AMT": 23574.02,
      "LM_NET_SLS_AMT": 45294.51999999997,
      "LM_LY_NET_SLS_AMT": 47221.14000000003,
      "MTD_NET_SLS_AMT": 43674.38000000003,
      "MTD_LY_NET_SLS_AMT": 59595.43000000002,
      "QTD_NET_SLS_AMT": 133575.3100000001,
      "QTD_LY_NET_SLS_AMT": 171940.8999999999,
      "HTD_NET_SLS_AMT": 270907.65,
      "HTD_LY_NET_SLS_AMT": 368352.49999999977,
      "YTD_NET_SLS_AMT": 270907.65,
      "YTD_LY_NET_SLS_AMT": 368352.49999999977,
      "L12_NET_SLS_AMT": 633913.7799999998,
      "L12_LY_NET_SLS_AMT": 742292.4499999988,
      "YST_NET_SLS_AMT": 320.91,
      "YST_LY_NET_SLS_AMT": 4519.29,
      "LQ_NET_SLS_AMT": 137332.34000000005,
      "LQ_LY_NET_SLS_AMT": 196411.59999999992,
      "LH_NET_SLS_AMT": 336795.2899999998,
      "LH_LY_NET_SLS_AMT": 365544.46999999945,
      "WTD_UNT_SLS_AMT": 15,
      "WTD_LY_UNT_SLS_AMT": 58,
      "LW_UNT_SLS_AMT": 113,
      "LW_LY_UNT_SLS_AMT": 237,
      "LM_UNT_SLS_AMT": 449,
      "LM_LY_UNT_SLS_AMT": 368,
      "MTD_UNT_SLS_AMT": 369,
      "MTD_LY_UNT_SLS_AMT": 567,
      "QTD_UNT_SLS_AMT": 1182,
      "QTD_LY_UNT_SLS_AMT": 1493,
      "HTD_UNT_SLS_AMT": 2482,
      "HTD_LY_UNT_SLS_AMT": 3000,
      "YTD_UNT_SLS_AMT": 2482,
      "YTD_LY_UNT_SLS_AMT": 3000,
      "L12_UNT_SLS_AMT": 5599,
      "L12_LY_UNT_SLS_AMT": 6347,
      "YST_UNT_SLS_AMT": 9,
      "YST_LY_UNT_SLS_AMT": 43,
      "LQ_UNT_SLS_AMT": 1300,
      "LQ_LY_UNT_SLS_AMT": 1507,
      "LH_UNT_SLS_AMT": 2851,
      "LH_LY_UNT_SLS_AMT": 3163,
      "WTD_TRANS_CNT": 12,
      "WTD_LY_TRANS_CNT": 17,
      "LW_TRANS_CNT": 39,
      "LW_LY_TRANS_CNT": 67,
      "LM_TRANS_CNT": 169,
      "LM_LY_TRANS_CNT": 213,
      "MTD_TRANS_CNT": 168,
      "MTD_LY_TRANS_CNT": 201,
      "QTD_TRANS_CNT": 528,
      "QTD_LY_TRANS_CNT": 663,
      "HTD_TRANS_CNT": 1093,
      "HTD_LY_TRANS_CNT": 1362,
      "YTD_TRANS_CNT": 1093,
      "YTD_LY_TRANS_CNT": 1362,
      "L12_TRANS_CNT": 2375,
      "L12_LY_TRANS_CNT": 2764,
      "YST_TRANS_CNT": 7,
      "YST_LY_TRANS_CNT": 11,
      "LQ_TRANS_CNT": 565,
      "LQ_LY_TRANS_CNT": 699,
      "LH_TRANS_CNT": 1217,
      "LH_LY_TRANS_CNT": 1320,
      "WTD_RLSD_UNITS": 22,
      "WTD_RLSD_ORDERS": 16,
      "LW_RLSD_UNITS": 70,
      "LW_RLSD_ORDERS": 19,
      "LM_RLSD_UNITS": 335,
      "LM_RLSD_ORDERS": 146,
      "MTD_RLSD_UNITS": 447,
      "MTD_RLSD_ORDERS": 138,
      "QTD_RLSD_UNITS": 1141,
      "QTD_RLSD_ORDERS": 424,
      "HTD_RLSD_UNITS": 2512,
      "HTD_RLSD_ORDERS": 903,
      "YTD_RLSD_UNITS": 2512,
      "YTD_RLSD_ORDERS": 903,
      "L12_RLSD_UNITS": 5621,
      "L12_RLSD_ORDERS": 1981,
      "YST_RLSD_UNITS": 6,
      "YST_RLSD_ORDERS": 6,
      "LQ_RLSD_UNITS": 1371,
      "LQ_RLSD_ORDERS": 482,
      "LH_RLSD_UNITS": 2979,
      "LH_RLSD_ORDERS": 1029,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.8947368421052632,
      "LW_PCT_ON_TM": 0.9,
      "LM_PCT_ON_TM": 0.845679012345679,
      "MTD_PCT_ON_TM": 0.910828025477707,
      "QTD_PCT_ON_TM": 0.8962655601659751,
      "HTD_PCT_ON_TM": 0.9031620553359684,
      "YTD_PCT_ON_TM": 0.9031620553359684,
      "L12_PCT_ON_TM": 0.8866120218579235,
      "YST_PCT_ON_TM": 0.8333333333333334,
      "LQ_PCT_ON_TM": 0.909433962264151,
      "LH_PCT_ON_TM": 0.8846495119787046,
      "WTD_AVG_MINS_TO_PICK": 61.47368421052631,
      "LW_AVG_MINS_TO_PICK": 81.8,
      "LM_AVG_MINS_TO_PICK": 131.3518518518518,
      "MTD_AVG_MINS_TO_PICK": 62.547770700636974,
      "QTD_AVG_MINS_TO_PICK": 98.28008298755186,
      "HTD_AVG_MINS_TO_PICK": 81.83399209486167,
      "YTD_AVG_MINS_TO_PICK": 81.83399209486167,
      "L12_AVG_MINS_TO_PICK": 104.47950819672133,
      "YST_AVG_MINS_TO_PICK": 78.91666666666667,
      "LQ_AVG_MINS_TO_PICK": 66.87735849056605,
      "LH_AVG_MINS_TO_PICK": 105.16503992901507,
      "WTD_OVER_SLA_CNT": 2,
      "LW_OVER_SLA_CNT": 2,
      "LM_OVER_SLA_CNT": 25,
      "MTD_OVER_SLA_CNT": 14,
      "QTD_OVER_SLA_CNT": 50,
      "HTD_OVER_SLA_CNT": 98,
      "YTD_OVER_SLA_CNT": 98,
      "L12_OVER_SLA_CNT": 249,
      "YST_OVER_SLA_CNT": 2,
      "LQ_OVER_SLA_CNT": 48,
      "LH_OVER_SLA_CNT": 130,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.8928571428571429,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.926829268292683,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.8979591836734694,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.8493150684931506,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.8493150684931506,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.6561743341404358,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 6715.309999999999,
      "WTD_LY_NET_SLS_AMT": 7206.089999999998,
      "LW_NET_SLS_AMT": 14002.789999999997,
      "LW_LY_NET_SLS_AMT": 28920.889999999996,
      "LM_NET_SLS_AMT": 71807.02,
      "LM_LY_NET_SLS_AMT": 86717.12000000001,
      "MTD_NET_SLS_AMT": 72185.31999999998,
      "MTD_LY_NET_SLS_AMT": 119708.30000000009,
      "QTD_NET_SLS_AMT": 231573.72,
      "QTD_LY_NET_SLS_AMT": 292070.54,
      "HTD_NET_SLS_AMT": 474788.9799999994,
      "HTD_LY_NET_SLS_AMT": 635347.4699999997,
      "YTD_NET_SLS_AMT": 474788.9799999994,
      "YTD_LY_NET_SLS_AMT": 635347.4699999997,
      "L12_NET_SLS_AMT": 968398.9699999997,
      "L12_LY_NET_SLS_AMT": 1243737.6399999987,
      "YST_NET_SLS_AMT": 4538.03,
      "YST_LY_NET_SLS_AMT": 4456.460000000001,
      "LQ_NET_SLS_AMT": 243215.2600000001,
      "LQ_LY_NET_SLS_AMT": 343276.9299999996,
      "LH_NET_SLS_AMT": 433646.7099999994,
      "LH_LY_NET_SLS_AMT": 610745.0999999992,
      "WTD_UNT_SLS_AMT": 54,
      "WTD_LY_UNT_SLS_AMT": 63,
      "LW_UNT_SLS_AMT": 118,
      "LW_LY_UNT_SLS_AMT": 247,
      "LM_UNT_SLS_AMT": 823,
      "LM_LY_UNT_SLS_AMT": 589,
      "MTD_UNT_SLS_AMT": 617,
      "MTD_LY_UNT_SLS_AMT": 909,
      "QTD_UNT_SLS_AMT": 2503,
      "QTD_LY_UNT_SLS_AMT": 2175,
      "HTD_UNT_SLS_AMT": 4480,
      "HTD_LY_UNT_SLS_AMT": 4893,
      "YTD_UNT_SLS_AMT": 4480,
      "YTD_LY_UNT_SLS_AMT": 4893,
      "L12_UNT_SLS_AMT": 8497,
      "L12_LY_UNT_SLS_AMT": 10334,
      "YST_UNT_SLS_AMT": 28,
      "YST_LY_UNT_SLS_AMT": 42,
      "LQ_UNT_SLS_AMT": 1977,
      "LQ_LY_UNT_SLS_AMT": 2718,
      "LH_UNT_SLS_AMT": 3641,
      "LH_LY_UNT_SLS_AMT": 5230,
      "WTD_TRANS_CNT": 35,
      "WTD_LY_TRANS_CNT": 44,
      "LW_TRANS_CNT": 70,
      "LW_LY_TRANS_CNT": 110,
      "LM_TRANS_CNT": 327,
      "LM_LY_TRANS_CNT": 346,
      "MTD_TRANS_CNT": 339,
      "MTD_LY_TRANS_CNT": 435,
      "QTD_TRANS_CNT": 992,
      "QTD_LY_TRANS_CNT": 1127,
      "HTD_TRANS_CNT": 1958,
      "HTD_LY_TRANS_CNT": 2351,
      "YTD_TRANS_CNT": 1958,
      "YTD_LY_TRANS_CNT": 2351,
      "L12_TRANS_CNT": 3914,
      "L12_LY_TRANS_CNT": 4678,
      "YST_TRANS_CNT": 19,
      "YST_LY_TRANS_CNT": 26,
      "LQ_TRANS_CNT": 966,
      "LQ_LY_TRANS_CNT": 1224,
      "LH_TRANS_CNT": 1803,
      "LH_LY_TRANS_CNT": 2265,
      "WTD_RLSD_UNITS": 64,
      "WTD_RLSD_ORDERS": 26,
      "LW_RLSD_UNITS": 128,
      "LW_RLSD_ORDERS": 63,
      "LM_RLSD_UNITS": 885,
      "LM_RLSD_ORDERS": 276,
      "MTD_RLSD_UNITS": 566,
      "MTD_RLSD_ORDERS": 285,
      "QTD_RLSD_UNITS": 2531,
      "QTD_RLSD_ORDERS": 846,
      "HTD_RLSD_UNITS": 4477,
      "HTD_RLSD_ORDERS": 1594,
      "YTD_RLSD_UNITS": 4477,
      "YTD_RLSD_ORDERS": 1594,
      "L12_RLSD_UNITS": 8642,
      "L12_RLSD_ORDERS": 3285,
      "YST_RLSD_UNITS": 37,
      "YST_RLSD_ORDERS": 13,
      "LQ_RLSD_UNITS": 1946,
      "LQ_RLSD_ORDERS": 753,
      "LH_RLSD_UNITS": 3761,
      "LH_RLSD_ORDERS": 1579,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.625,
      "LW_PCT_ON_TM": 0.7037037037037037,
      "LM_PCT_ON_TM": 0.8456375838926175,
      "MTD_PCT_ON_TM": 0.7699680511182109,
      "QTD_PCT_ON_TM": 0.8397849462365592,
      "HTD_PCT_ON_TM": 0.8709497206703911,
      "YTD_PCT_ON_TM": 0.8709497206703911,
      "L12_PCT_ON_TM": 0.8775623268698061,
      "YST_PCT_ON_TM": 0.5652173913043478,
      "LQ_PCT_ON_TM": 0.9046511627906977,
      "LH_PCT_ON_TM": 0.8943329397874853,
      "WTD_AVG_MINS_TO_PICK": 144.8125,
      "LW_AVG_MINS_TO_PICK": 314.9259259259259,
      "LM_AVG_MINS_TO_PICK": 136.78187919463093,
      "MTD_AVG_MINS_TO_PICK": 165.9872204472844,
      "QTD_AVG_MINS_TO_PICK": 129.36774193548382,
      "HTD_AVG_MINS_TO_PICK": 104.7960893854748,
      "YTD_AVG_MINS_TO_PICK": 104.7960893854748,
      "L12_AVG_MINS_TO_PICK": 94.61800554016617,
      "YST_AVG_MINS_TO_PICK": 135.56521739130434,
      "LQ_AVG_MINS_TO_PICK": 78.22441860465118,
      "LH_AVG_MINS_TO_PICK": 80.66351829988191,
      "WTD_OVER_SLA_CNT": 12,
      "LW_OVER_SLA_CNT": 16,
      "LM_OVER_SLA_CNT": 46,
      "MTD_OVER_SLA_CNT": 72,
      "QTD_OVER_SLA_CNT": 149,
      "HTD_OVER_SLA_CNT": 231,
      "YTD_OVER_SLA_CNT": 231,
      "L12_OVER_SLA_CNT": 442,
      "YST_OVER_SLA_CNT": 10,
      "LQ_OVER_SLA_CNT": 82,
      "LH_OVER_SLA_CNT": 179,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9230769230769231,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9322033898305084,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.8908045977011494,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.884180790960452,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.884180790960452,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8157894736842105,
      "L12_OF_APP": 0.9988919667590028
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 8062.369999999999,
      "WTD_LY_NET_SLS_AMT": 14631.39,
      "LW_NET_SLS_AMT": 13506.299999999997,
      "LW_LY_NET_SLS_AMT": 18577.100000000002,
      "LM_NET_SLS_AMT": 81628.57000000007,
      "LM_LY_NET_SLS_AMT": 102922.33000000007,
      "MTD_NET_SLS_AMT": 74522.72000000003,
      "MTD_LY_NET_SLS_AMT": 114469.24000000002,
      "QTD_NET_SLS_AMT": 243492.7100000003,
      "QTD_LY_NET_SLS_AMT": 332525.3099999998,
      "HTD_NET_SLS_AMT": 485668.4499999993,
      "HTD_LY_NET_SLS_AMT": 616634.6999999995,
      "YTD_NET_SLS_AMT": 485668.4499999993,
      "YTD_LY_NET_SLS_AMT": 616634.6999999995,
      "L12_NET_SLS_AMT": 1061203.2299999984,
      "L12_LY_NET_SLS_AMT": 1256225.2399999986,
      "YST_NET_SLS_AMT": 4257.73,
      "YST_LY_NET_SLS_AMT": 3287.7000000000007,
      "LQ_NET_SLS_AMT": 242175.74000000017,
      "LQ_LY_NET_SLS_AMT": 284109.38999999984,
      "LH_NET_SLS_AMT": 526232.729999999,
      "LH_LY_NET_SLS_AMT": 622642.0499999991,
      "WTD_UNT_SLS_AMT": 169,
      "WTD_LY_UNT_SLS_AMT": 91,
      "LW_UNT_SLS_AMT": 175,
      "LW_LY_UNT_SLS_AMT": 110,
      "LM_UNT_SLS_AMT": 697,
      "LM_LY_UNT_SLS_AMT": 658,
      "MTD_UNT_SLS_AMT": 740,
      "MTD_LY_UNT_SLS_AMT": 633,
      "QTD_UNT_SLS_AMT": 2042,
      "QTD_LY_UNT_SLS_AMT": 2198,
      "HTD_UNT_SLS_AMT": 4163,
      "HTD_LY_UNT_SLS_AMT": 4671,
      "YTD_UNT_SLS_AMT": 4163,
      "YTD_LY_UNT_SLS_AMT": 4671,
      "L12_UNT_SLS_AMT": 7930,
      "L12_LY_UNT_SLS_AMT": 9985,
      "YST_UNT_SLS_AMT": 84,
      "YST_LY_UNT_SLS_AMT": 40,
      "LQ_UNT_SLS_AMT": 2121,
      "LQ_LY_UNT_SLS_AMT": 2473,
      "LH_UNT_SLS_AMT": 3818,
      "LH_LY_UNT_SLS_AMT": 4572,
      "WTD_TRANS_CNT": 30,
      "WTD_LY_TRANS_CNT": 44,
      "LW_TRANS_CNT": 64,
      "LW_LY_TRANS_CNT": 73,
      "LM_TRANS_CNT": 274,
      "LM_LY_TRANS_CNT": 318,
      "MTD_TRANS_CNT": 304,
      "MTD_LY_TRANS_CNT": 358,
      "QTD_TRANS_CNT": 870,
      "QTD_LY_TRANS_CNT": 997,
      "HTD_TRANS_CNT": 1662,
      "HTD_LY_TRANS_CNT": 2000,
      "YTD_TRANS_CNT": 1662,
      "YTD_LY_TRANS_CNT": 2000,
      "L12_TRANS_CNT": 3426,
      "L12_LY_TRANS_CNT": 4209,
      "YST_TRANS_CNT": 21,
      "YST_LY_TRANS_CNT": 17,
      "LQ_TRANS_CNT": 792,
      "LQ_LY_TRANS_CNT": 1003,
      "LH_TRANS_CNT": 1664,
      "LH_LY_TRANS_CNT": 2071,
      "WTD_RLSD_UNITS": 32,
      "WTD_RLSD_ORDERS": 24,
      "LW_RLSD_UNITS": 175,
      "LW_RLSD_ORDERS": 53,
      "LM_RLSD_UNITS": 835,
      "LM_RLSD_ORDERS": 247,
      "MTD_RLSD_UNITS": 592,
      "MTD_RLSD_ORDERS": 240,
      "QTD_RLSD_UNITS": 2181,
      "QTD_RLSD_ORDERS": 723,
      "HTD_RLSD_UNITS": 4475,
      "HTD_RLSD_ORDERS": 1380,
      "YTD_RLSD_UNITS": 4475,
      "YTD_RLSD_ORDERS": 1380,
      "L12_RLSD_UNITS": 8246,
      "L12_RLSD_ORDERS": 2885,
      "YST_RLSD_UNITS": 18,
      "YST_RLSD_ORDERS": 14,
      "LQ_RLSD_UNITS": 2294,
      "LQ_RLSD_ORDERS": 664,
      "LH_RLSD_UNITS": 3645,
      "LH_RLSD_ORDERS": 1434,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.9428571428571428,
      "LW_PCT_ON_TM": 0.8478260869565217,
      "LM_PCT_ON_TM": 0.8798449612403101,
      "MTD_PCT_ON_TM": 0.8201438848920863,
      "QTD_PCT_ON_TM": 0.8424317617866005,
      "HTD_PCT_ON_TM": 0.8697342838626053,
      "YTD_PCT_ON_TM": 0.8697342838626053,
      "L12_PCT_ON_TM": 0.8513513513513513,
      "YST_PCT_ON_TM": 0.9615384615384616,
      "LQ_PCT_ON_TM": 0.8995929443690638,
      "LH_PCT_ON_TM": 0.8460051546391752,
      "WTD_AVG_MINS_TO_PICK": 54.77142857142858,
      "LW_AVG_MINS_TO_PICK": 133.80434782608697,
      "LM_AVG_MINS_TO_PICK": 65.74031007751937,
      "MTD_AVG_MINS_TO_PICK": 104.60071942446041,
      "QTD_AVG_MINS_TO_PICK": 85.66873449131513,
      "HTD_AVG_MINS_TO_PICK": 79.19507453013613,
      "YTD_AVG_MINS_TO_PICK": 79.19507453013613,
      "L12_AVG_MINS_TO_PICK": 85.28786926461345,
      "YST_AVG_MINS_TO_PICK": 50.07692307692309,
      "LQ_AVG_MINS_TO_PICK": 72.11533242876523,
      "LH_AVG_MINS_TO_PICK": 89.18105670103095,
      "WTD_OVER_SLA_CNT": 2,
      "LW_OVER_SLA_CNT": 7,
      "LM_OVER_SLA_CNT": 31,
      "MTD_OVER_SLA_CNT": 50,
      "QTD_OVER_SLA_CNT": 127,
      "HTD_OVER_SLA_CNT": 201,
      "YTD_OVER_SLA_CNT": 201,
      "L12_OVER_SLA_CNT": 473,
      "YST_OVER_SLA_CNT": 1,
      "LQ_OVER_SLA_CNT": 74,
      "LH_OVER_SLA_CNT": 239,
      "WTD_DS_COMP": 0.6363636363636364,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 1,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.8979591836734694,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.7647058823529411,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.8652482269503546,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9067164179104478,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9067164179104478,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9109730848861284,
      "L12_OF_APP": 0.9987433239082626
    },
    {
      "STR_NBR": 149,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 10863.119999999999,
      "WTD_LY_NET_SLS_AMT": 9968.560000000001,
      "LW_NET_SLS_AMT": 19209.16,
      "LW_LY_NET_SLS_AMT": 45264.73000000002,
      "LM_NET_SLS_AMT": 128920.28000000004,
      "LM_LY_NET_SLS_AMT": 126055.67000000007,
      "MTD_NET_SLS_AMT": 99915.73999999999,
      "MTD_LY_NET_SLS_AMT": 149219.09,
      "QTD_NET_SLS_AMT": 355696.3299999997,
      "QTD_LY_NET_SLS_AMT": 414747.65000000014,
      "HTD_NET_SLS_AMT": 735301.3399999992,
      "HTD_LY_NET_SLS_AMT": 864612.3200000006,
      "YTD_NET_SLS_AMT": 735301.3399999992,
      "YTD_LY_NET_SLS_AMT": 864612.3200000006,
      "L12_NET_SLS_AMT": 1589387.209999997,
      "L12_LY_NET_SLS_AMT": 1791519.9699999993,
      "YST_NET_SLS_AMT": 3759.8399999999992,
      "YST_LY_NET_SLS_AMT": 2892.4399999999996,
      "LQ_NET_SLS_AMT": 379605.0099999993,
      "LQ_LY_NET_SLS_AMT": 449864.66999999975,
      "LH_NET_SLS_AMT": 785365.3199999993,
      "LH_LY_NET_SLS_AMT": 904886.5300000005,
      "WTD_UNT_SLS_AMT": 183,
      "WTD_LY_UNT_SLS_AMT": 66,
      "LW_UNT_SLS_AMT": 185,
      "LW_LY_UNT_SLS_AMT": 223,
      "LM_UNT_SLS_AMT": 957,
      "LM_LY_UNT_SLS_AMT": 898,
      "MTD_UNT_SLS_AMT": 1008,
      "MTD_LY_UNT_SLS_AMT": 863,
      "QTD_UNT_SLS_AMT": 2960,
      "QTD_LY_UNT_SLS_AMT": 2815,
      "HTD_UNT_SLS_AMT": 6013,
      "HTD_LY_UNT_SLS_AMT": 6276,
      "YTD_UNT_SLS_AMT": 6013,
      "YTD_LY_UNT_SLS_AMT": 6276,
      "L12_UNT_SLS_AMT": 12116,
      "L12_LY_UNT_SLS_AMT": 14384,
      "YST_UNT_SLS_AMT": 37,
      "YST_LY_UNT_SLS_AMT": 37,
      "LQ_UNT_SLS_AMT": 3053,
      "LQ_LY_UNT_SLS_AMT": 3461,
      "LH_UNT_SLS_AMT": 6007,
      "LH_LY_UNT_SLS_AMT": 7588,
      "WTD_TRANS_CNT": 47,
      "WTD_LY_TRANS_CNT": 43,
      "LW_TRANS_CNT": 103,
      "LW_LY_TRANS_CNT": 130,
      "LM_TRANS_CNT": 467,
      "LM_LY_TRANS_CNT": 483,
      "MTD_TRANS_CNT": 443,
      "MTD_LY_TRANS_CNT": 497,
      "QTD_TRANS_CNT": 1398,
      "QTD_LY_TRANS_CNT": 1420,
      "HTD_TRANS_CNT": 2734,
      "HTD_LY_TRANS_CNT": 2985,
      "YTD_TRANS_CNT": 2734,
      "YTD_LY_TRANS_CNT": 2985,
      "L12_TRANS_CNT": 5590,
      "L12_LY_TRANS_CNT": 6484,
      "YST_TRANS_CNT": 27,
      "YST_LY_TRANS_CNT": 22,
      "LQ_TRANS_CNT": 1336,
      "LQ_LY_TRANS_CNT": 1565,
      "LH_TRANS_CNT": 2733,
      "LH_LY_TRANS_CNT": 3331,
      "WTD_RLSD_UNITS": 80,
      "WTD_RLSD_ORDERS": 31,
      "LW_RLSD_UNITS": 134,
      "LW_RLSD_ORDERS": 66,
      "LM_RLSD_UNITS": 902,
      "LM_RLSD_ORDERS": 387,
      "MTD_RLSD_UNITS": 828,
      "MTD_RLSD_ORDERS": 389,
      "QTD_RLSD_UNITS": 2725,
      "QTD_RLSD_ORDERS": 1163,
      "HTD_RLSD_UNITS": 5827,
      "HTD_RLSD_ORDERS": 2235,
      "YTD_RLSD_UNITS": 5827,
      "YTD_RLSD_ORDERS": 2235,
      "L12_RLSD_UNITS": 12198,
      "L12_RLSD_ORDERS": 4637,
      "YST_RLSD_UNITS": 45,
      "YST_RLSD_ORDERS": 22,
      "LQ_RLSD_UNITS": 3102,
      "LQ_RLSD_ORDERS": 1080,
      "LH_RLSD_UNITS": 6169,
      "LH_RLSD_ORDERS": 2305,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.9555555555555556,
      "LW_PCT_ON_TM": 0.9122807017543859,
      "LM_PCT_ON_TM": 0.9395348837209302,
      "MTD_PCT_ON_TM": 0.9534313725490197,
      "QTD_PCT_ON_TM": 0.9447040498442367,
      "HTD_PCT_ON_TM": 0.9415506958250497,
      "YTD_PCT_ON_TM": 0.9415506958250497,
      "L12_PCT_ON_TM": 0.9062200956937799,
      "YST_PCT_ON_TM": 0.9523809523809523,
      "LQ_PCT_ON_TM": 0.9382615759545085,
      "LH_PCT_ON_TM": 0.8817162736760726,
      "WTD_AVG_MINS_TO_PICK": 52.00000000000001,
      "LW_AVG_MINS_TO_PICK": 39.05263157894737,
      "LM_AVG_MINS_TO_PICK": 70.71395348837211,
      "MTD_AVG_MINS_TO_PICK": 61.73774509803918,
      "QTD_AVG_MINS_TO_PICK": 70.4384735202492,
      "HTD_AVG_MINS_TO_PICK": 69.49224652087474,
      "YTD_AVG_MINS_TO_PICK": 69.49224652087474,
      "L12_AVG_MINS_TO_PICK": 88.57205741626797,
      "YST_AVG_MINS_TO_PICK": 53.142857142857146,
      "LQ_AVG_MINS_TO_PICK": 68.50528025995126,
      "LH_AVG_MINS_TO_PICK": 101.90954773869346,
      "WTD_OVER_SLA_CNT": 2,
      "LW_OVER_SLA_CNT": 5,
      "LM_OVER_SLA_CNT": 26,
      "MTD_OVER_SLA_CNT": 19,
      "QTD_OVER_SLA_CNT": 71,
      "HTD_OVER_SLA_CNT": 147,
      "YTD_OVER_SLA_CNT": 147,
      "L12_OVER_SLA_CNT": 490,
      "YST_OVER_SLA_CNT": 1,
      "LQ_OVER_SLA_CNT": 76,
      "LH_OVER_SLA_CNT": 306,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.7,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 1,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9125,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9397590361445783,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9465648854961832,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9465648854961832,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.9251336898395722,
      "L12_OF_APP": 0.9971291866028709
    },
    {
      "STR_NBR": 161,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 11801.979999999998,
      "WTD_LY_NET_SLS_AMT": 7040.579999999998,
      "LW_NET_SLS_AMT": 18541.75,
      "LW_LY_NET_SLS_AMT": 29151.380000000012,
      "LM_NET_SLS_AMT": 100795.48000000003,
      "LM_LY_NET_SLS_AMT": 130294.67000000011,
      "MTD_NET_SLS_AMT": 117005.59000000013,
      "MTD_LY_NET_SLS_AMT": 118825.98000000014,
      "QTD_NET_SLS_AMT": 320927.1799999999,
      "QTD_LY_NET_SLS_AMT": 390178.33000000013,
      "HTD_NET_SLS_AMT": 731465.4399999991,
      "HTD_LY_NET_SLS_AMT": 833489.87,
      "YTD_NET_SLS_AMT": 731465.4399999991,
      "YTD_LY_NET_SLS_AMT": 833489.87,
      "L12_NET_SLS_AMT": 1395596.2200000007,
      "L12_LY_NET_SLS_AMT": 1719569.9800000007,
      "YST_NET_SLS_AMT": 8595.93,
      "YST_LY_NET_SLS_AMT": 3483.02,
      "LQ_NET_SLS_AMT": 410538.2599999998,
      "LQ_LY_NET_SLS_AMT": 443311.5399999998,
      "LH_NET_SLS_AMT": 643942.4199999983,
      "LH_LY_NET_SLS_AMT": 829476.65,
      "WTD_UNT_SLS_AMT": 83,
      "WTD_LY_UNT_SLS_AMT": 63,
      "LW_UNT_SLS_AMT": 138,
      "LW_LY_UNT_SLS_AMT": 222,
      "LM_UNT_SLS_AMT": 917,
      "LM_LY_UNT_SLS_AMT": 911,
      "MTD_UNT_SLS_AMT": 1028,
      "MTD_LY_UNT_SLS_AMT": 977,
      "QTD_UNT_SLS_AMT": 2832,
      "QTD_LY_UNT_SLS_AMT": 2792,
      "HTD_UNT_SLS_AMT": 5869,
      "HTD_LY_UNT_SLS_AMT": 5698,
      "YTD_UNT_SLS_AMT": 5869,
      "YTD_LY_UNT_SLS_AMT": 5698,
      "L12_UNT_SLS_AMT": 11093,
      "L12_LY_UNT_SLS_AMT": 11953,
      "YST_UNT_SLS_AMT": 44,
      "YST_LY_UNT_SLS_AMT": 31,
      "LQ_UNT_SLS_AMT": 3037,
      "LQ_LY_UNT_SLS_AMT": 2906,
      "LH_UNT_SLS_AMT": 5086,
      "LH_LY_UNT_SLS_AMT": 6036,
      "WTD_TRANS_CNT": 39,
      "WTD_LY_TRANS_CNT": 42,
      "LW_TRANS_CNT": 85,
      "LW_LY_TRANS_CNT": 103,
      "LM_TRANS_CNT": 373,
      "LM_LY_TRANS_CNT": 474,
      "MTD_TRANS_CNT": 364,
      "MTD_LY_TRANS_CNT": 429,
      "QTD_TRANS_CNT": 1140,
      "QTD_LY_TRANS_CNT": 1405,
      "HTD_TRANS_CNT": 2350,
      "HTD_LY_TRANS_CNT": 2929,
      "YTD_TRANS_CNT": 2350,
      "YTD_LY_TRANS_CNT": 2929,
      "L12_TRANS_CNT": 4919,
      "L12_LY_TRANS_CNT": 5985,
      "YST_TRANS_CNT": 26,
      "YST_LY_TRANS_CNT": 23,
      "LQ_TRANS_CNT": 1210,
      "LQ_LY_TRANS_CNT": 1524,
      "LH_TRANS_CNT": 2436,
      "LH_LY_TRANS_CNT": 2877,
      "WTD_RLSD_UNITS": 50,
      "WTD_RLSD_ORDERS": 28,
      "LW_RLSD_UNITS": 135,
      "LW_RLSD_ORDERS": 68,
      "LM_RLSD_UNITS": 806,
      "LM_RLSD_ORDERS": 333,
      "MTD_RLSD_UNITS": 1127,
      "MTD_RLSD_ORDERS": 286,
      "QTD_RLSD_UNITS": 2973,
      "QTD_RLSD_ORDERS": 962,
      "HTD_RLSD_UNITS": 6029,
      "HTD_RLSD_ORDERS": 1954,
      "YTD_RLSD_UNITS": 6029,
      "YTD_RLSD_ORDERS": 1954,
      "L12_RLSD_UNITS": 11663,
      "L12_RLSD_ORDERS": 4172,
      "YST_RLSD_UNITS": 32,
      "YST_RLSD_ORDERS": 16,
      "LQ_RLSD_UNITS": 3056,
      "LQ_RLSD_ORDERS": 1002,
      "LH_RLSD_UNITS": 5509,
      "LH_RLSD_ORDERS": 2072,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 1,
      "LW_PCT_ON_TM": 1,
      "LM_PCT_ON_TM": 0.976878612716763,
      "MTD_PCT_ON_TM": 0.9755351681957186,
      "QTD_PCT_ON_TM": 0.975517890772128,
      "HTD_PCT_ON_TM": 0.9697247706422019,
      "YTD_PCT_ON_TM": 0.9697247706422019,
      "L12_PCT_ON_TM": 0.9407423485999565,
      "YST_PCT_ON_TM": 1,
      "LQ_PCT_ON_TM": 0.964221824686941,
      "LH_PCT_ON_TM": 0.9250217959895379,
      "WTD_AVG_MINS_TO_PICK": 17.74358974358974,
      "LW_AVG_MINS_TO_PICK": 18.214285714285705,
      "LM_AVG_MINS_TO_PICK": 28.07803468208092,
      "MTD_AVG_MINS_TO_PICK": 34.89296636085625,
      "QTD_AVG_MINS_TO_PICK": 36.78060263653485,
      "HTD_AVG_MINS_TO_PICK": 42.349082568807354,
      "YTD_AVG_MINS_TO_PICK": 42.349082568807354,
      "L12_AVG_MINS_TO_PICK": 67.06772303017146,
      "YST_AVG_MINS_TO_PICK": 20.17857142857143,
      "LQ_AVG_MINS_TO_PICK": 47.638640429338096,
      "LH_AVG_MINS_TO_PICK": 75.1704446381866,
      "WTD_OVER_SLA_CNT": 0,
      "LW_OVER_SLA_CNT": 0,
      "LM_OVER_SLA_CNT": 8,
      "MTD_OVER_SLA_CNT": 8,
      "QTD_OVER_SLA_CNT": 26,
      "HTD_OVER_SLA_CNT": 66,
      "YTD_OVER_SLA_CNT": 66,
      "L12_OVER_SLA_CNT": 273,
      "YST_OVER_SLA_CNT": 0,
      "LQ_OVER_SLA_CNT": 40,
      "LH_OVER_SLA_CNT": 172,
      "WTD_DS_COMP": 0.8,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.8333333333333334,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9555555555555556,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.8048780487804879,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.8666666666666667,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.8253968253968254,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.8253968253968254,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8541033434650456,
      "L12_OF_APP": 0.9997828918801563
    },
    {
      "STR_NBR": 175,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 5816.900000000001,
      "WTD_LY_NET_SLS_AMT": 5079.78,
      "LW_NET_SLS_AMT": 16180.829999999998,
      "LW_LY_NET_SLS_AMT": 25095.289999999994,
      "LM_NET_SLS_AMT": 67368.64000000001,
      "LM_LY_NET_SLS_AMT": 102252.83000000009,
      "MTD_NET_SLS_AMT": 56089.25,
      "MTD_LY_NET_SLS_AMT": 98085.32000000002,
      "QTD_NET_SLS_AMT": 220685.71999999997,
      "QTD_LY_NET_SLS_AMT": 280023.7399999998,
      "HTD_NET_SLS_AMT": 475049.9999999994,
      "HTD_LY_NET_SLS_AMT": 577413.7299999992,
      "YTD_NET_SLS_AMT": 475049.9999999994,
      "YTD_LY_NET_SLS_AMT": 577413.7299999992,
      "L12_NET_SLS_AMT": 990555.0899999986,
      "L12_LY_NET_SLS_AMT": 1101286.3699999985,
      "YST_NET_SLS_AMT": 3650.49,
      "YST_LY_NET_SLS_AMT": 3163.7,
      "LQ_NET_SLS_AMT": 254364.2799999999,
      "LQ_LY_NET_SLS_AMT": 297389.99000000017,
      "LH_NET_SLS_AMT": 463141.3099999996,
      "LH_LY_NET_SLS_AMT": 527822.8099999996,
      "WTD_UNT_SLS_AMT": 70,
      "WTD_LY_UNT_SLS_AMT": 40,
      "LW_UNT_SLS_AMT": 132,
      "LW_LY_UNT_SLS_AMT": 158,
      "LM_UNT_SLS_AMT": 592,
      "LM_LY_UNT_SLS_AMT": 803,
      "MTD_UNT_SLS_AMT": 528,
      "MTD_LY_UNT_SLS_AMT": 657,
      "QTD_UNT_SLS_AMT": 1820,
      "QTD_LY_UNT_SLS_AMT": 2403,
      "HTD_UNT_SLS_AMT": 4565,
      "HTD_LY_UNT_SLS_AMT": 5459,
      "YTD_UNT_SLS_AMT": 4565,
      "YTD_LY_UNT_SLS_AMT": 5459,
      "L12_UNT_SLS_AMT": 8393,
      "L12_LY_UNT_SLS_AMT": 9801,
      "YST_UNT_SLS_AMT": 39,
      "YST_LY_UNT_SLS_AMT": 23,
      "LQ_UNT_SLS_AMT": 2745,
      "LQ_LY_UNT_SLS_AMT": 3056,
      "LH_UNT_SLS_AMT": 3607,
      "LH_LY_UNT_SLS_AMT": 4063,
      "WTD_TRANS_CNT": 30,
      "WTD_LY_TRANS_CNT": 21,
      "LW_TRANS_CNT": 64,
      "LW_LY_TRANS_CNT": 84,
      "LM_TRANS_CNT": 297,
      "LM_LY_TRANS_CNT": 374,
      "MTD_TRANS_CNT": 288,
      "MTD_LY_TRANS_CNT": 349,
      "QTD_TRANS_CNT": 869,
      "QTD_LY_TRANS_CNT": 1058,
      "HTD_TRANS_CNT": 1789,
      "HTD_LY_TRANS_CNT": 2079,
      "YTD_TRANS_CNT": 1789,
      "YTD_LY_TRANS_CNT": 2079,
      "L12_TRANS_CNT": 3774,
      "L12_LY_TRANS_CNT": 4104,
      "YST_TRANS_CNT": 13,
      "YST_LY_TRANS_CNT": 12,
      "LQ_TRANS_CNT": 920,
      "LQ_LY_TRANS_CNT": 1021,
      "LH_TRANS_CNT": 1879,
      "LH_LY_TRANS_CNT": 1989,
      "WTD_RLSD_UNITS": 61,
      "WTD_RLSD_ORDERS": 25,
      "LW_RLSD_UNITS": 167,
      "LW_RLSD_ORDERS": 57,
      "LM_RLSD_UNITS": 740,
      "LM_RLSD_ORDERS": 238,
      "MTD_RLSD_UNITS": 555,
      "MTD_RLSD_ORDERS": 259,
      "QTD_RLSD_UNITS": 2070,
      "QTD_RLSD_ORDERS": 738,
      "HTD_RLSD_UNITS": 4703,
      "HTD_RLSD_ORDERS": 1475,
      "YTD_RLSD_UNITS": 4703,
      "YTD_RLSD_ORDERS": 1475,
      "L12_RLSD_UNITS": 8426,
      "L12_RLSD_ORDERS": 3095,
      "YST_RLSD_UNITS": 36,
      "YST_RLSD_ORDERS": 16,
      "LQ_RLSD_UNITS": 2633,
      "LQ_RLSD_ORDERS": 743,
      "LH_RLSD_UNITS": 3499,
      "LH_RLSD_ORDERS": 1529,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.78125,
      "LW_PCT_ON_TM": 0.8695652173913043,
      "LM_PCT_ON_TM": 0.9747292418772563,
      "MTD_PCT_ON_TM": 0.9239130434782609,
      "QTD_PCT_ON_TM": 0.937424789410349,
      "HTD_PCT_ON_TM": 0.9370125974805039,
      "YTD_PCT_ON_TM": 0.9370125974805039,
      "L12_PCT_ON_TM": 0.8891111746213204,
      "YST_PCT_ON_TM": 1,
      "LQ_PCT_ON_TM": 0.9366028708133971,
      "LH_PCT_ON_TM": 0.9549132947976878,
      "WTD_AVG_MINS_TO_PICK": 136.71875,
      "LW_AVG_MINS_TO_PICK": 148.91304347826087,
      "LM_AVG_MINS_TO_PICK": 52.335740072202185,
      "MTD_AVG_MINS_TO_PICK": 83.1159420289855,
      "QTD_AVG_MINS_TO_PICK": 78.03730445246698,
      "HTD_AVG_MINS_TO_PICK": 74.81943611277744,
      "YTD_AVG_MINS_TO_PICK": 74.81943611277744,
      "L12_AVG_MINS_TO_PICK": 91.88653901114604,
      "YST_AVG_MINS_TO_PICK": 15.157894736842104,
      "LQ_AVG_MINS_TO_PICK": 71.6208133971292,
      "LH_AVG_MINS_TO_PICK": 58.3722543352601,
      "WTD_OVER_SLA_CNT": 7,
      "LW_OVER_SLA_CNT": 6,
      "LM_OVER_SLA_CNT": 7,
      "MTD_OVER_SLA_CNT": 21,
      "QTD_OVER_SLA_CNT": 52,
      "HTD_OVER_SLA_CNT": 105,
      "YTD_OVER_SLA_CNT": 105,
      "L12_OVER_SLA_CNT": 388,
      "YST_OVER_SLA_CNT": 0,
      "LQ_OVER_SLA_CNT": 53,
      "LH_OVER_SLA_CNT": 78,
      "WTD_DS_COMP": 0.6666666666666666,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.75,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.96,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.8936170212765957,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.935251798561151,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9250814332247557,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9250814332247557,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8227194492254734,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 1754,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 17398.52,
      "WTD_LY_NET_SLS_AMT": 8894.489999999998,
      "LW_NET_SLS_AMT": 21337.030000000002,
      "LW_LY_NET_SLS_AMT": 21427.839999999997,
      "LM_NET_SLS_AMT": 75710.37000000002,
      "LM_LY_NET_SLS_AMT": 75403.75000000006,
      "MTD_NET_SLS_AMT": 99410.13000000005,
      "MTD_LY_NET_SLS_AMT": 87972.40000000001,
      "QTD_NET_SLS_AMT": 255914.44000000012,
      "QTD_LY_NET_SLS_AMT": 251065.38,
      "HTD_NET_SLS_AMT": 540482.8599999994,
      "HTD_LY_NET_SLS_AMT": 591114.0099999995,
      "YTD_NET_SLS_AMT": 540482.8599999994,
      "YTD_LY_NET_SLS_AMT": 591114.0099999995,
      "L12_NET_SLS_AMT": 1122883.8499999987,
      "L12_LY_NET_SLS_AMT": 1246937.519999999,
      "YST_NET_SLS_AMT": 13700.38,
      "YST_LY_NET_SLS_AMT": 5549.76,
      "LQ_NET_SLS_AMT": 284568.4199999999,
      "LQ_LY_NET_SLS_AMT": 340048.62999999983,
      "LH_NET_SLS_AMT": 580582.4800000001,
      "LH_LY_NET_SLS_AMT": 621326.3599999986,
      "WTD_UNT_SLS_AMT": 197,
      "WTD_LY_UNT_SLS_AMT": 126,
      "LW_UNT_SLS_AMT": 101,
      "LW_LY_UNT_SLS_AMT": 143,
      "LM_UNT_SLS_AMT": 633,
      "LM_LY_UNT_SLS_AMT": 593,
      "MTD_UNT_SLS_AMT": 997,
      "MTD_LY_UNT_SLS_AMT": 699,
      "QTD_UNT_SLS_AMT": 2427,
      "QTD_LY_UNT_SLS_AMT": 2093,
      "HTD_UNT_SLS_AMT": 5156,
      "HTD_LY_UNT_SLS_AMT": 4809,
      "YTD_UNT_SLS_AMT": 5156,
      "YTD_LY_UNT_SLS_AMT": 4809,
      "L12_UNT_SLS_AMT": 9297,
      "L12_LY_UNT_SLS_AMT": 10498,
      "YST_UNT_SLS_AMT": 142,
      "YST_LY_UNT_SLS_AMT": 50,
      "LQ_UNT_SLS_AMT": 2729,
      "LQ_LY_UNT_SLS_AMT": 2716,
      "LH_UNT_SLS_AMT": 4313,
      "LH_LY_UNT_SLS_AMT": 5283,
      "WTD_TRANS_CNT": 42,
      "WTD_LY_TRANS_CNT": 38,
      "LW_TRANS_CNT": 65,
      "LW_LY_TRANS_CNT": 91,
      "LM_TRANS_CNT": 289,
      "LM_LY_TRANS_CNT": 315,
      "MTD_TRANS_CNT": 330,
      "MTD_LY_TRANS_CNT": 344,
      "QTD_TRANS_CNT": 934,
      "QTD_LY_TRANS_CNT": 1004,
      "HTD_TRANS_CNT": 1960,
      "HTD_LY_TRANS_CNT": 2165,
      "YTD_TRANS_CNT": 1960,
      "YTD_LY_TRANS_CNT": 2165,
      "L12_TRANS_CNT": 3962,
      "L12_LY_TRANS_CNT": 4486,
      "YST_TRANS_CNT": 28,
      "YST_LY_TRANS_CNT": 21,
      "LQ_TRANS_CNT": 1026,
      "LQ_LY_TRANS_CNT": 1161,
      "LH_TRANS_CNT": 1939,
      "LH_LY_TRANS_CNT": 2215,
      "WTD_RLSD_UNITS": 185,
      "WTD_RLSD_ORDERS": 29,
      "LW_RLSD_UNITS": 104,
      "LW_RLSD_ORDERS": 54,
      "LM_RLSD_UNITS": 489,
      "LM_RLSD_ORDERS": 244,
      "MTD_RLSD_UNITS": 1092,
      "MTD_RLSD_ORDERS": 259,
      "QTD_RLSD_UNITS": 2558,
      "QTD_RLSD_ORDERS": 786,
      "HTD_RLSD_UNITS": 5068,
      "HTD_RLSD_ORDERS": 1574,
      "YTD_RLSD_UNITS": 5068,
      "YTD_RLSD_ORDERS": 1574,
      "L12_RLSD_UNITS": 9235,
      "L12_RLSD_ORDERS": 3300,
      "YST_RLSD_UNITS": 28,
      "YST_RLSD_ORDERS": 18,
      "LQ_RLSD_UNITS": 2510,
      "LQ_RLSD_ORDERS": 794,
      "LH_RLSD_UNITS": 4405,
      "LH_RLSD_ORDERS": 1648,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.7931034482758621,
      "LW_PCT_ON_TM": 0.75,
      "LM_PCT_ON_TM": 0.875,
      "MTD_PCT_ON_TM": 0.835016835016835,
      "QTD_PCT_ON_TM": 0.8495981630309989,
      "HTD_PCT_ON_TM": 0.8624161073825504,
      "YTD_PCT_ON_TM": 0.8624161073825504,
      "L12_PCT_ON_TM": 0.844776119402985,
      "YST_PCT_ON_TM": 0.8333333333333334,
      "LQ_PCT_ON_TM": 0.8745910577971646,
      "LH_PCT_ON_TM": 0.8282275711159738,
      "WTD_AVG_MINS_TO_PICK": 204.7931034482759,
      "LW_AVG_MINS_TO_PICK": 304.05,
      "LM_AVG_MINS_TO_PICK": 111.96484374999997,
      "MTD_AVG_MINS_TO_PICK": 169.92592592592587,
      "QTD_AVG_MINS_TO_PICK": 141.9196326061997,
      "HTD_AVG_MINS_TO_PICK": 122.80033557046988,
      "YTD_AVG_MINS_TO_PICK": 122.80033557046988,
      "L12_AVG_MINS_TO_PICK": 112.37693351424697,
      "YST_AVG_MINS_TO_PICK": 264.1666666666667,
      "LQ_AVG_MINS_TO_PICK": 104.64013086150489,
      "LH_AVG_MINS_TO_PICK": 117.66849015317284,
      "WTD_OVER_SLA_CNT": 6,
      "LW_OVER_SLA_CNT": 15,
      "LM_OVER_SLA_CNT": 32,
      "MTD_OVER_SLA_CNT": 49,
      "QTD_OVER_SLA_CNT": 131,
      "HTD_OVER_SLA_CNT": 246,
      "YTD_OVER_SLA_CNT": 246,
      "L12_OVER_SLA_CNT": 572,
      "YST_OVER_SLA_CNT": 3,
      "LQ_OVER_SLA_CNT": 115,
      "LH_OVER_SLA_CNT": 314,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.75,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9622641509433962,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.8571428571428571,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9285714285714286,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.9080459770114943,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.9080459770114943,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8995695839311334,
      "L12_OF_APP": 1
    },
    {
      "STR_NBR": 1755,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 7636.129999999998,
      "WTD_LY_NET_SLS_AMT": 9691.679999999998,
      "LW_NET_SLS_AMT": 16607.3,
      "LW_LY_NET_SLS_AMT": 26078.070000000007,
      "LM_NET_SLS_AMT": 74168.32999999996,
      "LM_LY_NET_SLS_AMT": 121636.59000000003,
      "MTD_NET_SLS_AMT": 76717.74,
      "MTD_LY_NET_SLS_AMT": 95912.49000000005,
      "QTD_NET_SLS_AMT": 240743.41000000006,
      "QTD_LY_NET_SLS_AMT": 301818.4300000002,
      "HTD_NET_SLS_AMT": 502952.18999999954,
      "HTD_LY_NET_SLS_AMT": 590601.6299999995,
      "YTD_NET_SLS_AMT": 502952.18999999954,
      "YTD_LY_NET_SLS_AMT": 590601.6299999995,
      "L12_NET_SLS_AMT": 1064141.9800000014,
      "L12_LY_NET_SLS_AMT": 1211833.7699999996,
      "YST_NET_SLS_AMT": 4642.409999999999,
      "YST_LY_NET_SLS_AMT": 5709.49,
      "LQ_NET_SLS_AMT": 262208.77999999997,
      "LQ_LY_NET_SLS_AMT": 288783.1999999999,
      "LH_NET_SLS_AMT": 532231.0900000002,
      "LH_LY_NET_SLS_AMT": 590008.3899999994,
      "WTD_UNT_SLS_AMT": 64,
      "WTD_LY_UNT_SLS_AMT": 30,
      "LW_UNT_SLS_AMT": 139,
      "LW_LY_UNT_SLS_AMT": 173,
      "LM_UNT_SLS_AMT": 607,
      "LM_LY_UNT_SLS_AMT": 814,
      "MTD_UNT_SLS_AMT": 679,
      "MTD_LY_UNT_SLS_AMT": 647,
      "QTD_UNT_SLS_AMT": 1890,
      "QTD_LY_UNT_SLS_AMT": 2175,
      "HTD_UNT_SLS_AMT": 4387,
      "HTD_LY_UNT_SLS_AMT": 4460,
      "YTD_UNT_SLS_AMT": 4387,
      "YTD_LY_UNT_SLS_AMT": 4460,
      "L12_UNT_SLS_AMT": 8913,
      "L12_LY_UNT_SLS_AMT": 9774,
      "YST_UNT_SLS_AMT": 38,
      "YST_LY_UNT_SLS_AMT": 15,
      "LQ_UNT_SLS_AMT": 2497,
      "LQ_LY_UNT_SLS_AMT": 2285,
      "LH_UNT_SLS_AMT": 4507,
      "LH_LY_UNT_SLS_AMT": 4722,
      "WTD_TRANS_CNT": 34,
      "WTD_LY_TRANS_CNT": 27,
      "LW_TRANS_CNT": 59,
      "LW_LY_TRANS_CNT": 95,
      "LM_TRANS_CNT": 297,
      "LM_LY_TRANS_CNT": 383,
      "MTD_TRANS_CNT": 295,
      "MTD_LY_TRANS_CNT": 369,
      "QTD_TRANS_CNT": 892,
      "QTD_LY_TRANS_CNT": 1092,
      "HTD_TRANS_CNT": 1905,
      "HTD_LY_TRANS_CNT": 2142,
      "YTD_TRANS_CNT": 1905,
      "YTD_LY_TRANS_CNT": 2142,
      "L12_TRANS_CNT": 3969,
      "L12_LY_TRANS_CNT": 4393,
      "YST_TRANS_CNT": 23,
      "YST_LY_TRANS_CNT": 14,
      "LQ_TRANS_CNT": 1013,
      "LQ_LY_TRANS_CNT": 1050,
      "LH_TRANS_CNT": 1954,
      "LH_LY_TRANS_CNT": 2137,
      "WTD_RLSD_UNITS": 19,
      "WTD_RLSD_ORDERS": 15,
      "LW_RLSD_UNITS": 112,
      "LW_RLSD_ORDERS": 52,
      "LM_RLSD_UNITS": 604,
      "LM_RLSD_ORDERS": 255,
      "MTD_RLSD_UNITS": 689,
      "MTD_RLSD_ORDERS": 252,
      "QTD_RLSD_UNITS": 1887,
      "QTD_RLSD_ORDERS": 771,
      "HTD_RLSD_UNITS": 4433,
      "HTD_RLSD_ORDERS": 1576,
      "YTD_RLSD_UNITS": 4433,
      "YTD_RLSD_ORDERS": 1576,
      "L12_RLSD_UNITS": 8998,
      "L12_RLSD_ORDERS": 3338,
      "YST_RLSD_UNITS": 10,
      "YST_RLSD_ORDERS": 9,
      "LQ_RLSD_UNITS": 2546,
      "LQ_RLSD_ORDERS": 812,
      "LH_RLSD_UNITS": 4495,
      "LH_RLSD_ORDERS": 1663,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.9333333333333333,
      "LW_PCT_ON_TM": 0.8125,
      "LM_PCT_ON_TM": 0.9715302491103203,
      "MTD_PCT_ON_TM": 0.8598484848484849,
      "QTD_PCT_ON_TM": 0.9105011933174224,
      "HTD_PCT_ON_TM": 0.8852459016393442,
      "YTD_PCT_ON_TM": 0.8852459016393442,
      "L12_PCT_ON_TM": 0.7811566657616074,
      "YST_PCT_ON_TM": 0.875,
      "LQ_PCT_ON_TM": 0.8625134264232008,
      "LH_PCT_ON_TM": 0.7038269550748752,
      "WTD_AVG_MINS_TO_PICK": 49.266666666666666,
      "LW_AVG_MINS_TO_PICK": 108.64583333333336,
      "LM_AVG_MINS_TO_PICK": 52.77224199288256,
      "MTD_AVG_MINS_TO_PICK": 113.38636363636363,
      "QTD_AVG_MINS_TO_PICK": 83.15513126491652,
      "HTD_AVG_MINS_TO_PICK": 93.57490107405309,
      "YTD_AVG_MINS_TO_PICK": 93.57490107405309,
      "L12_AVG_MINS_TO_PICK": 153.91555796904694,
      "YST_AVG_MINS_TO_PICK": 58.375,
      "LQ_AVG_MINS_TO_PICK": 102.95381310418897,
      "LH_AVG_MINS_TO_PICK": 196.54797559622855,
      "WTD_OVER_SLA_CNT": 1,
      "LW_OVER_SLA_CNT": 9,
      "LM_OVER_SLA_CNT": 8,
      "MTD_OVER_SLA_CNT": 37,
      "QTD_OVER_SLA_CNT": 75,
      "HTD_OVER_SLA_CNT": 203,
      "YTD_OVER_SLA_CNT": 203,
      "L12_OVER_SLA_CNT": 806,
      "YST_OVER_SLA_CNT": 1,
      "LQ_OVER_SLA_CNT": 128,
      "LH_OVER_SLA_CNT": 534,
      "WTD_DS_COMP": null,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": null,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 1,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 1,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.9285714285714286,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.853448275862069,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.853448275862069,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8485576923076923,
      "L12_OF_APP": 0.9991850040749797
    },
    {
      "STR_NBR": 1856,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 7954.329999999998,
      "WTD_LY_NET_SLS_AMT": 11100.73,
      "LW_NET_SLS_AMT": 21121.78000000001,
      "LW_LY_NET_SLS_AMT": 29179.970000000005,
      "LM_NET_SLS_AMT": 74629.46,
      "LM_LY_NET_SLS_AMT": 117408.36000000013,
      "MTD_NET_SLS_AMT": 86913.15000000001,
      "MTD_LY_NET_SLS_AMT": 155409.49000000008,
      "QTD_NET_SLS_AMT": 247319.22999999992,
      "QTD_LY_NET_SLS_AMT": 385594.16999999975,
      "HTD_NET_SLS_AMT": 538358.4900000001,
      "HTD_LY_NET_SLS_AMT": 707171.7099999998,
      "YTD_NET_SLS_AMT": 538358.4900000001,
      "YTD_LY_NET_SLS_AMT": 707171.7099999998,
      "L12_NET_SLS_AMT": 1250786.110000001,
      "L12_LY_NET_SLS_AMT": 1385009.7799999986,
      "YST_NET_SLS_AMT": 6134.249999999999,
      "YST_LY_NET_SLS_AMT": 6104.61,
      "LQ_NET_SLS_AMT": 291039.26000000007,
      "LQ_LY_NET_SLS_AMT": 321577.54,
      "LH_NET_SLS_AMT": 613456.1999999993,
      "LH_LY_NET_SLS_AMT": 686654.799999999,
      "WTD_UNT_SLS_AMT": 36,
      "WTD_LY_UNT_SLS_AMT": 61,
      "LW_UNT_SLS_AMT": 141,
      "LW_LY_UNT_SLS_AMT": 106,
      "LM_UNT_SLS_AMT": 536,
      "LM_LY_UNT_SLS_AMT": 931,
      "MTD_UNT_SLS_AMT": 661,
      "MTD_LY_UNT_SLS_AMT": 1123,
      "QTD_UNT_SLS_AMT": 2062,
      "QTD_LY_UNT_SLS_AMT": 3092,
      "HTD_UNT_SLS_AMT": 4589,
      "HTD_LY_UNT_SLS_AMT": 5556,
      "YTD_UNT_SLS_AMT": 4589,
      "YTD_LY_UNT_SLS_AMT": 5556,
      "L12_UNT_SLS_AMT": 10095,
      "L12_LY_UNT_SLS_AMT": 10830,
      "YST_UNT_SLS_AMT": 24,
      "YST_LY_UNT_SLS_AMT": 23,
      "LQ_UNT_SLS_AMT": 2527,
      "LQ_LY_UNT_SLS_AMT": 2464,
      "LH_UNT_SLS_AMT": 4885,
      "LH_LY_UNT_SLS_AMT": 5297,
      "WTD_TRANS_CNT": 32,
      "WTD_LY_TRANS_CNT": 34,
      "LW_TRANS_CNT": 81,
      "LW_LY_TRANS_CNT": 85,
      "LM_TRANS_CNT": 279,
      "LM_LY_TRANS_CNT": 442,
      "MTD_TRANS_CNT": 329,
      "MTD_LY_TRANS_CNT": 452,
      "QTD_TRANS_CNT": 967,
      "QTD_LY_TRANS_CNT": 1318,
      "HTD_TRANS_CNT": 2137,
      "HTD_LY_TRANS_CNT": 2598,
      "YTD_TRANS_CNT": 2137,
      "YTD_LY_TRANS_CNT": 2598,
      "L12_TRANS_CNT": 4495,
      "L12_LY_TRANS_CNT": 5151,
      "YST_TRANS_CNT": 21,
      "YST_LY_TRANS_CNT": 12,
      "LQ_TRANS_CNT": 1170,
      "LQ_LY_TRANS_CNT": 1280,
      "LH_TRANS_CNT": 2151,
      "LH_LY_TRANS_CNT": 2498,
      "WTD_RLSD_UNITS": 47,
      "WTD_RLSD_ORDERS": 19,
      "LW_RLSD_UNITS": 157,
      "LW_RLSD_ORDERS": 68,
      "LM_RLSD_UNITS": 594,
      "LM_RLSD_ORDERS": 251,
      "MTD_RLSD_UNITS": 595,
      "MTD_RLSD_ORDERS": 275,
      "QTD_RLSD_UNITS": 1998,
      "QTD_RLSD_ORDERS": 835,
      "HTD_RLSD_UNITS": 4667,
      "HTD_RLSD_ORDERS": 1757,
      "YTD_RLSD_UNITS": 4667,
      "YTD_RLSD_ORDERS": 1757,
      "L12_RLSD_UNITS": 10573,
      "L12_RLSD_ORDERS": 3757,
      "YST_RLSD_UNITS": 22,
      "YST_RLSD_ORDERS": 8,
      "LQ_RLSD_UNITS": 2669,
      "LQ_RLSD_ORDERS": 930,
      "LH_RLSD_UNITS": 4993,
      "LH_RLSD_ORDERS": 1868,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 0.8333333333333334,
      "LW_PCT_ON_TM": 0.9508196721311475,
      "LM_PCT_ON_TM": 0.8773946360153256,
      "MTD_PCT_ON_TM": 0.8068965517241379,
      "QTD_PCT_ON_TM": 0.8233333333333334,
      "HTD_PCT_ON_TM": 0.8399382398353062,
      "YTD_PCT_ON_TM": 0.8399382398353062,
      "L12_PCT_ON_TM": 0.7311004784688995,
      "YST_PCT_ON_TM": 1,
      "LQ_PCT_ON_TM": 0.8542665388302972,
      "LH_PCT_ON_TM": 0.6684544124817162,
      "WTD_AVG_MINS_TO_PICK": 188.875,
      "LW_AVG_MINS_TO_PICK": 55.704918032786885,
      "LM_AVG_MINS_TO_PICK": 109.59003831417625,
      "MTD_AVG_MINS_TO_PICK": 162.3655172413793,
      "QTD_AVG_MINS_TO_PICK": 141.46444444444427,
      "HTD_AVG_MINS_TO_PICK": 129.64642305712817,
      "YTD_AVG_MINS_TO_PICK": 129.64642305712817,
      "L12_AVG_MINS_TO_PICK": 175.577033492823,
      "YST_AVG_MINS_TO_PICK": 39.28571428571429,
      "LQ_AVG_MINS_TO_PICK": 119.44870565675933,
      "LH_AVG_MINS_TO_PICK": 209.29254022428077,
      "WTD_OVER_SLA_CNT": 4,
      "LW_OVER_SLA_CNT": 3,
      "LM_OVER_SLA_CNT": 32,
      "MTD_OVER_SLA_CNT": 56,
      "QTD_OVER_SLA_CNT": 159,
      "HTD_OVER_SLA_CNT": 311,
      "YTD_OVER_SLA_CNT": 311,
      "L12_OVER_SLA_CNT": 1124,
      "YST_OVER_SLA_CNT": 0,
      "LQ_OVER_SLA_CNT": 152,
      "LH_OVER_SLA_CNT": 680,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.9285714285714286,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.7592592592592593,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9259259259259259,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.8628571428571429,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.8735632183908046,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.8735632183908046,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8335724533715926,
      "L12_OF_APP": 0.999282468309017
    },
    {
      "STR_NBR": 6978,
      "DST_NBR": 1,
      "METRIC_LEVEL": "STR",
      "CHANNEL": "BOSS",
      "WTD_NET_SLS_AMT": 13028.599999999999,
      "WTD_LY_NET_SLS_AMT": 7018.539999999998,
      "LW_NET_SLS_AMT": 17598.759999999995,
      "LW_LY_NET_SLS_AMT": 18901.010000000002,
      "LM_NET_SLS_AMT": 60620.230000000025,
      "LM_LY_NET_SLS_AMT": 97375.88,
      "MTD_NET_SLS_AMT": 79329.32000000004,
      "MTD_LY_NET_SLS_AMT": 94278.67000000003,
      "QTD_NET_SLS_AMT": 218220.4999999999,
      "QTD_LY_NET_SLS_AMT": 285061.29,
      "HTD_NET_SLS_AMT": 469389.32999999996,
      "HTD_LY_NET_SLS_AMT": 581835.1199999986,
      "YTD_NET_SLS_AMT": 469389.32999999996,
      "YTD_LY_NET_SLS_AMT": 581835.1199999986,
      "L12_NET_SLS_AMT": 1057419.4699999997,
      "L12_LY_NET_SLS_AMT": 1246055.5499999984,
      "YST_NET_SLS_AMT": 8232.67,
      "YST_LY_NET_SLS_AMT": 2955.8600000000006,
      "LQ_NET_SLS_AMT": 251168.8300000003,
      "LQ_LY_NET_SLS_AMT": 296773.83000000025,
      "LH_NET_SLS_AMT": 560716.0299999993,
      "LH_LY_NET_SLS_AMT": 659474.369999999,
      "WTD_UNT_SLS_AMT": 131,
      "WTD_LY_UNT_SLS_AMT": 85,
      "LW_UNT_SLS_AMT": 146,
      "LW_LY_UNT_SLS_AMT": 94,
      "LM_UNT_SLS_AMT": 522,
      "LM_LY_UNT_SLS_AMT": 785,
      "MTD_UNT_SLS_AMT": 640,
      "MTD_LY_UNT_SLS_AMT": 661,
      "QTD_UNT_SLS_AMT": 1748,
      "QTD_LY_UNT_SLS_AMT": 2093,
      "HTD_UNT_SLS_AMT": 3946,
      "HTD_LY_UNT_SLS_AMT": 4898,
      "YTD_UNT_SLS_AMT": 3946,
      "YTD_LY_UNT_SLS_AMT": 4898,
      "L12_UNT_SLS_AMT": 8930,
      "L12_LY_UNT_SLS_AMT": 10258,
      "YST_UNT_SLS_AMT": 93,
      "YST_LY_UNT_SLS_AMT": 35,
      "LQ_UNT_SLS_AMT": 2198,
      "LQ_LY_UNT_SLS_AMT": 2805,
      "LH_UNT_SLS_AMT": 4877,
      "LH_LY_UNT_SLS_AMT": 5148,
      "WTD_TRANS_CNT": 47,
      "WTD_LY_TRANS_CNT": 37,
      "LW_TRANS_CNT": 79,
      "LW_LY_TRANS_CNT": 71,
      "LM_TRANS_CNT": 301,
      "LM_LY_TRANS_CNT": 395,
      "MTD_TRANS_CNT": 339,
      "MTD_LY_TRANS_CNT": 391,
      "QTD_TRANS_CNT": 906,
      "QTD_LY_TRANS_CNT": 1133,
      "HTD_TRANS_CNT": 1787,
      "HTD_LY_TRANS_CNT": 2251,
      "YTD_TRANS_CNT": 1787,
      "YTD_LY_TRANS_CNT": 2251,
      "L12_TRANS_CNT": 3913,
      "L12_LY_TRANS_CNT": 4523,
      "YST_TRANS_CNT": 29,
      "YST_LY_TRANS_CNT": 18,
      "LQ_TRANS_CNT": 881,
      "LQ_LY_TRANS_CNT": 1118,
      "LH_TRANS_CNT": 2020,
      "LH_LY_TRANS_CNT": 2179,
      "WTD_RLSD_UNITS": 47,
      "WTD_RLSD_ORDERS": 24,
      "LW_RLSD_UNITS": 156,
      "LW_RLSD_ORDERS": 66,
      "LM_RLSD_UNITS": 494,
      "LM_RLSD_ORDERS": 252,
      "MTD_RLSD_UNITS": 480,
      "MTD_RLSD_ORDERS": 246,
      "QTD_RLSD_UNITS": 1572,
      "QTD_RLSD_ORDERS": 741,
      "HTD_RLSD_UNITS": 3844,
      "HTD_RLSD_ORDERS": 1459,
      "YTD_RLSD_UNITS": 3844,
      "YTD_RLSD_ORDERS": 1459,
      "L12_RLSD_UNITS": 8926,
      "L12_RLSD_ORDERS": 3330,
      "YST_RLSD_UNITS": 36,
      "YST_RLSD_ORDERS": 17,
      "LQ_RLSD_UNITS": 2272,
      "LQ_RLSD_ORDERS": 721,
      "LH_RLSD_UNITS": 4710,
      "LH_RLSD_ORDERS": 1742,
      "WTD_ONLINE_SAT_SCORE": 0,
      "WTD_ONLINE_SURVEY_COUNT": 0,
      "LW_ONLINE_SAT_SCORE": 0,
      "LW_ONLINE_SURVEY_COUNT": 0,
      "LM_ONLINE_SAT_SCORE": 0,
      "LM_ONLINE_SURVEY_COUNT": 0,
      "MTD_ONLINE_SAT_SCORE": 0,
      "MTD_ONLINE_SURVEY_COUNT": 0,
      "QTD_ONLINE_SAT_SCORE": 0,
      "QTD_ONLINE_SURVEY_COUNT": 0,
      "HTD_ONLINE_SAT_SCORE": 0,
      "HTD_ONLINE_SURVEY_COUNT": 0,
      "YTD_ONLINE_SAT_SCORE": 0,
      "YTD_ONLINE_SURVEY_COUNT": 0,
      "L12_ONLINE_SAT_SCORE": 0,
      "L12_ONLINE_SURVEY_COUNT": 0,
      "YST_ONLINE_SAT_SCORE": 0,
      "YST_ONLINE_SURVEY_COUNT": 0,
      "LQ_ONLINE_SAT_SCORE": 0,
      "LQ_ONLINE_SURVEY_COUNT": 0,
      "LH_ONLINE_SAT_SCORE": 0,
      "LH_ONLINE_SURVEY_COUNT": 0,
      "WTD_PCT_ON_TM": 1,
      "LW_PCT_ON_TM": 0.9452054794520548,
      "LM_PCT_ON_TM": 0.9818181818181818,
      "MTD_PCT_ON_TM": 0.8932384341637011,
      "QTD_PCT_ON_TM": 0.9531442663378545,
      "HTD_PCT_ON_TM": 0.959409594095941,
      "YTD_PCT_ON_TM": 0.959409594095941,
      "L12_PCT_ON_TM": 0.8911973756150902,
      "YST_PCT_ON_TM": 1,
      "LQ_PCT_ON_TM": 0.9656441717791411,
      "LH_PCT_ON_TM": 0.8880084521922874,
      "WTD_AVG_MINS_TO_PICK": 12.791666666666666,
      "LW_AVG_MINS_TO_PICK": 79.90410958904108,
      "LM_AVG_MINS_TO_PICK": 28.0109090909091,
      "MTD_AVG_MINS_TO_PICK": 45.722419928825616,
      "QTD_AVG_MINS_TO_PICK": 31.261405672009893,
      "HTD_AVG_MINS_TO_PICK": 36.6309963099631,
      "YTD_AVG_MINS_TO_PICK": 36.6309963099631,
      "L12_AVG_MINS_TO_PICK": 69.76380535811916,
      "YST_AVG_MINS_TO_PICK": 5.375,
      "LQ_AVG_MINS_TO_PICK": 41.97423312883435,
      "LH_AVG_MINS_TO_PICK": 76.71262546222928,
      "WTD_OVER_SLA_CNT": 0,
      "LW_OVER_SLA_CNT": 4,
      "LM_OVER_SLA_CNT": 5,
      "MTD_OVER_SLA_CNT": 30,
      "QTD_OVER_SLA_CNT": 38,
      "HTD_OVER_SLA_CNT": 66,
      "YTD_OVER_SLA_CNT": 66,
      "L12_OVER_SLA_CNT": 398,
      "YST_OVER_SLA_CNT": 0,
      "LQ_OVER_SLA_CNT": 28,
      "LH_OVER_SLA_CNT": 212,
      "WTD_DS_COMP": 1,
      "WTD_OF_APP": 1,
      "LW_DS_COMP": 0.8,
      "LW_OF_APP": 1,
      "LM_DS_COMP": 0.9615384615384616,
      "LM_OF_APP": 1,
      "MTD_DS_COMP": 0.9230769230769231,
      "MTD_OF_APP": 1,
      "QTD_DS_COMP": 0.918918918918919,
      "QTD_OF_APP": 1,
      "HTD_DS_COMP": 0.8993288590604027,
      "HTD_OF_APP": 1,
      "YTD_DS_COMP": 0.8993288590604027,
      "YTD_OF_APP": 1,
      "L12_DS_COMP": 0.8306709265175719,
      "L12_OF_APP": 0.9948073244055753
    }
  ],
  DELIVERIES: [
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 7454.23,
      "LW_SLS_AMT": 13584.149999999998,
      "LM_SLS_AMT": 70144.87999999999,
      "MTD_SLS_AMT": 7454.23,
      "QTD_SLS_AMT": 159978.57,
      "HTD_SLS_AMT": 370765.6599999999,
      "YTD_SLS_AMT": 799352.1600000001,
      "L12_SLS_AMT": 841582.31,
      "YST_SLS_AMT": 639.48,
      "LQ_SLS_AMT": 210787.09,
      "LH_SLS_AMT": 428586.5,
      "WTD_FULFL_ORD": 5,
      "LW_FULFL_ORD": 32,
      "LM_FULFL_ORD": 201,
      "MTD_FULFL_ORD": 5,
      "QTD_FULFL_ORD": 379,
      "HTD_FULFL_ORD": 848,
      "YTD_FULFL_ORD": 1714,
      "L12_FULFL_ORD": 1802,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 469,
      "LH_FULFL_ORD": 866,
      "WTD_FULFL_ORD_COMP_PCT": 0,
      "LW_FULFL_ORD_COMP_PCT": -0.1578947368421053,
      "LM_FULFL_ORD_COMP_PCT": 0.2967741935483872,
      "MTD_FULFL_ORD_COMP_PCT": 0,
      "QTD_FULFL_ORD_COMP_PCT": 0.5220883534136547,
      "HTD_FULFL_ORD_COMP_PCT": 0.42760942760942755,
      "YTD_FULFL_ORD_COMP_PCT": 0.45008460236886627,
      "L12_FULFL_ORD_COMP_PCT": 0.4222573007103394,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.35942028985507246,
      "LH_FULFL_ORD_COMP_PCT": 0.4727891156462585,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 1,
      "LW_MISS_COUNT": 2,
      "LM_MISS_COUNT": 7,
      "MTD_MISS_COUNT": 1,
      "QTD_MISS_COUNT": 12,
      "HTD_MISS_COUNT": 21,
      "YTD_MISS_COUNT": 52,
      "L12_MISS_COUNT": 55,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 9,
      "LH_MISS_COUNT": 31
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 11017.65,
      "LW_SLS_AMT": 5721.87,
      "LM_SLS_AMT": 67072.87999999999,
      "MTD_SLS_AMT": 11017.65,
      "QTD_SLS_AMT": 164995.88000000003,
      "HTD_SLS_AMT": 339394.43999999994,
      "YTD_SLS_AMT": 538058.03,
      "L12_SLS_AMT": 543481.9800000002,
      "YST_SLS_AMT": 217.84,
      "LQ_SLS_AMT": 174398.56000000003,
      "LH_SLS_AMT": 198663.59,
      "WTD_FULFL_ORD": 19,
      "LW_FULFL_ORD": 53,
      "LM_FULFL_ORD": 251,
      "MTD_FULFL_ORD": 19,
      "QTD_FULFL_ORD": 518,
      "HTD_FULFL_ORD": 1187,
      "YTD_FULFL_ORD": 2514,
      "L12_FULFL_ORD": 2641,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 669,
      "LH_FULFL_ORD": 1327,
      "WTD_FULFL_ORD_COMP_PCT": 0.8999999999999999,
      "LW_FULFL_ORD_COMP_PCT": 0.65625,
      "LM_FULFL_ORD_COMP_PCT": 0.608974358974359,
      "MTD_FULFL_ORD_COMP_PCT": 0.8999999999999999,
      "QTD_FULFL_ORD_COMP_PCT": 0.5370919881305638,
      "HTD_FULFL_ORD_COMP_PCT": 0.4198564593301435,
      "YTD_FULFL_ORD_COMP_PCT": 0.31623036649214664,
      "L12_FULFL_ORD_COMP_PCT": 0.2600190839694656,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.3406813627254508,
      "LH_FULFL_ORD_COMP_PCT": 0.23556797020484166,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 0,
      "LW_MISS_COUNT": 2,
      "LM_MISS_COUNT": 5,
      "MTD_MISS_COUNT": 0,
      "QTD_MISS_COUNT": 9,
      "HTD_MISS_COUNT": 29,
      "YTD_MISS_COUNT": 73,
      "L12_MISS_COUNT": 79,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 20,
      "LH_MISS_COUNT": 44
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 2837.87,
      "LW_SLS_AMT": 3991.9900000000002,
      "LM_SLS_AMT": 35602.03,
      "MTD_SLS_AMT": 2837.87,
      "QTD_SLS_AMT": 73482.73000000001,
      "HTD_SLS_AMT": 221857.30000000002,
      "YTD_SLS_AMT": 487831.2600000001,
      "L12_SLS_AMT": 518965.29000000004,
      "YST_SLS_AMT": 366.82,
      "LQ_SLS_AMT": 148374.56999999995,
      "LH_SLS_AMT": 265973.95999999996,
      "WTD_FULFL_ORD": 14,
      "LW_FULFL_ORD": 45,
      "LM_FULFL_ORD": 239,
      "MTD_FULFL_ORD": 14,
      "QTD_FULFL_ORD": 481,
      "HTD_FULFL_ORD": 1080,
      "YTD_FULFL_ORD": 2448,
      "L12_FULFL_ORD": 2577,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 599,
      "LH_FULFL_ORD": 1368,
      "WTD_FULFL_ORD_COMP_PCT": 0,
      "LW_FULFL_ORD_COMP_PCT": 0.6071428571428572,
      "LM_FULFL_ORD_COMP_PCT": 0.943089430894309,
      "MTD_FULFL_ORD_COMP_PCT": 0,
      "QTD_FULFL_ORD_COMP_PCT": 0.625,
      "HTD_FULFL_ORD_COMP_PCT": 0.5340909090909092,
      "YTD_FULFL_ORD_COMP_PCT": 0.48633879781420775,
      "L12_FULFL_ORD_COMP_PCT": 0.4485666104553119,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.4681372549019607,
      "LH_FULFL_ORD_COMP_PCT": 0.4506892895015906,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 0,
      "LW_MISS_COUNT": 5,
      "LM_MISS_COUNT": 12,
      "MTD_MISS_COUNT": 0,
      "QTD_MISS_COUNT": 19,
      "HTD_MISS_COUNT": 60,
      "YTD_MISS_COUNT": 145,
      "L12_MISS_COUNT": 159,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 41,
      "LH_MISS_COUNT": 85
    },
    {
      "STR_NBR": 149,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 1580.42,
      "LW_SLS_AMT": 21627.260000000002,
      "LM_SLS_AMT": 95222.46,
      "MTD_SLS_AMT": 1580.42,
      "QTD_SLS_AMT": 189407.63,
      "HTD_SLS_AMT": 470857.38999999984,
      "YTD_SLS_AMT": 1092336.55,
      "L12_SLS_AMT": 1149783.65,
      "YST_SLS_AMT": 0,
      "LQ_SLS_AMT": 281449.76,
      "LH_SLS_AMT": 621479.16,
      "WTD_FULFL_ORD": 14,
      "LW_FULFL_ORD": 54,
      "LM_FULFL_ORD": 299,
      "MTD_FULFL_ORD": 14,
      "QTD_FULFL_ORD": 623,
      "HTD_FULFL_ORD": 1337,
      "YTD_FULFL_ORD": 3030,
      "L12_FULFL_ORD": 3218,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 714,
      "LH_FULFL_ORD": 1693,
      "WTD_FULFL_ORD_COMP_PCT": -0.26315789473684215,
      "LW_FULFL_ORD_COMP_PCT": 0.125,
      "LM_FULFL_ORD_COMP_PCT": 0.4444444444444444,
      "MTD_FULFL_ORD_COMP_PCT": -0.26315789473684215,
      "QTD_FULFL_ORD_COMP_PCT": 0.42237442922374435,
      "HTD_FULFL_ORD_COMP_PCT": 0.2637051039697542,
      "YTD_FULFL_ORD_COMP_PCT": 0.3654799459215863,
      "L12_FULFL_ORD_COMP_PCT": 0.34363256784968677,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.15161290322580645,
      "LH_FULFL_ORD_COMP_PCT": 0.4582256675279932,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 0,
      "LW_MISS_COUNT": 0,
      "LM_MISS_COUNT": 3,
      "MTD_MISS_COUNT": 0,
      "QTD_MISS_COUNT": 7,
      "HTD_MISS_COUNT": 18,
      "YTD_MISS_COUNT": 122,
      "L12_MISS_COUNT": 134,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 11,
      "LH_MISS_COUNT": 104
    },
    {
      "STR_NBR": 161,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 4177.06,
      "LW_SLS_AMT": 18226.100000000002,
      "LM_SLS_AMT": 95477.87,
      "MTD_SLS_AMT": 4177.06,
      "QTD_SLS_AMT": 229198.79,
      "HTD_SLS_AMT": 569549.5899999999,
      "YTD_SLS_AMT": 1441227.7399999995,
      "L12_SLS_AMT": 1503206.3499999996,
      "YST_SLS_AMT": 541.09,
      "LQ_SLS_AMT": 340350.80000000005,
      "LH_SLS_AMT": 871678.1499999999,
      "WTD_FULFL_ORD": 11,
      "LW_FULFL_ORD": 49,
      "LM_FULFL_ORD": 258,
      "MTD_FULFL_ORD": 11,
      "QTD_FULFL_ORD": 609,
      "HTD_FULFL_ORD": 1348,
      "YTD_FULFL_ORD": 3159,
      "L12_FULFL_ORD": 3312,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 739,
      "LH_FULFL_ORD": 1811,
      "WTD_FULFL_ORD_COMP_PCT": -0.2666666666666667,
      "LW_FULFL_ORD_COMP_PCT": -0.05769230769230771,
      "LM_FULFL_ORD_COMP_PCT": 0.5449101796407185,
      "MTD_FULFL_ORD_COMP_PCT": -0.2666666666666667,
      "QTD_FULFL_ORD_COMP_PCT": 0.7807017543859649,
      "HTD_FULFL_ORD_COMP_PCT": 0.5638051044083527,
      "YTD_FULFL_ORD_COMP_PCT": 0.6723133933298042,
      "L12_FULFL_ORD_COMP_PCT": 0.6412289395441031,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.4211538461538462,
      "LH_FULFL_ORD_COMP_PCT": 0.7633885102239533,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 1,
      "LW_MISS_COUNT": 1,
      "LM_MISS_COUNT": 9,
      "MTD_MISS_COUNT": 1,
      "QTD_MISS_COUNT": 13,
      "HTD_MISS_COUNT": 28,
      "YTD_MISS_COUNT": 83,
      "L12_MISS_COUNT": 82,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 15,
      "LH_MISS_COUNT": 55
    },
    {
      "STR_NBR": 175,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 1395.6200000000001,
      "LW_SLS_AMT": 8282.050000000001,
      "LM_SLS_AMT": 65008.03,
      "MTD_SLS_AMT": 1395.6200000000001,
      "QTD_SLS_AMT": 127944.73,
      "HTD_SLS_AMT": 282444.42999999993,
      "YTD_SLS_AMT": 661890.1800000002,
      "L12_SLS_AMT": 692247.68,
      "YST_SLS_AMT": 989.1600000000001,
      "LQ_SLS_AMT": 154499.7,
      "LH_SLS_AMT": 379445.7500000001,
      "WTD_FULFL_ORD": 10,
      "LW_FULFL_ORD": 41,
      "LM_FULFL_ORD": 228,
      "MTD_FULFL_ORD": 10,
      "QTD_FULFL_ORD": 462,
      "HTD_FULFL_ORD": 1149,
      "YTD_FULFL_ORD": 2625,
      "L12_FULFL_ORD": 2814,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 687,
      "LH_FULFL_ORD": 1476,
      "WTD_FULFL_ORD_COMP_PCT": 0.11111111111111116,
      "LW_FULFL_ORD_COMP_PCT": -0.12765957446808507,
      "LM_FULFL_ORD_COMP_PCT": 0.2596685082872927,
      "MTD_FULFL_ORD_COMP_PCT": 0.11111111111111116,
      "QTD_FULFL_ORD_COMP_PCT": 0.3237822349570201,
      "HTD_FULFL_ORD_COMP_PCT": 0.39441747572815533,
      "YTD_FULFL_ORD_COMP_PCT": 0.44468904788112273,
      "L12_FULFL_ORD_COMP_PCT": 0.442337262942081,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.4463157894736842,
      "LH_FULFL_ORD_COMP_PCT": 0.48640483383685806,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 1,
      "LW_MISS_COUNT": 0,
      "LM_MISS_COUNT": 2,
      "MTD_MISS_COUNT": 1,
      "QTD_MISS_COUNT": 9,
      "HTD_MISS_COUNT": 39,
      "YTD_MISS_COUNT": 106,
      "L12_MISS_COUNT": 114,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 30,
      "LH_MISS_COUNT": 67
    },
    {
      "STR_NBR": 1754,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 1266.58,
      "LW_SLS_AMT": 7939.69,
      "LM_SLS_AMT": 55005.17,
      "MTD_SLS_AMT": 1266.58,
      "QTD_SLS_AMT": 119045.62000000001,
      "HTD_SLS_AMT": 275488.97000000003,
      "YTD_SLS_AMT": 641553.3799999999,
      "L12_SLS_AMT": 682024.49,
      "YST_SLS_AMT": 396,
      "LQ_SLS_AMT": 156443.34999999998,
      "LH_SLS_AMT": 366064.41000000003,
      "WTD_FULFL_ORD": 17,
      "LW_FULFL_ORD": 43,
      "LM_FULFL_ORD": 230,
      "MTD_FULFL_ORD": 17,
      "QTD_FULFL_ORD": 454,
      "HTD_FULFL_ORD": 1050,
      "YTD_FULFL_ORD": 2318,
      "L12_FULFL_ORD": 2443,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 596,
      "LH_FULFL_ORD": 1268,
      "WTD_FULFL_ORD_COMP_PCT": 1.4285714285714284,
      "LW_FULFL_ORD_COMP_PCT": 0.2647058823529411,
      "LM_FULFL_ORD_COMP_PCT": 0.7164179104477613,
      "MTD_FULFL_ORD_COMP_PCT": 1.4285714285714284,
      "QTD_FULFL_ORD_COMP_PCT": 0.6814814814814816,
      "HTD_FULFL_ORD_COMP_PCT": 0.5129682997118155,
      "YTD_FULFL_ORD_COMP_PCT": 0.4878048780487805,
      "L12_FULFL_ORD_COMP_PCT": 0.4455621301775148,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.4056603773584906,
      "LH_FULFL_ORD_COMP_PCT": 0.46759259259259256,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 0,
      "LW_MISS_COUNT": 0,
      "LM_MISS_COUNT": 6,
      "MTD_MISS_COUNT": 0,
      "QTD_MISS_COUNT": 13,
      "HTD_MISS_COUNT": 27,
      "YTD_MISS_COUNT": 73,
      "L12_MISS_COUNT": 78,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 14,
      "LH_MISS_COUNT": 46
    },
    {
      "STR_NBR": 1755,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 8275.259999999998,
      "LW_SLS_AMT": 18151.37,
      "LM_SLS_AMT": 100300.78,
      "MTD_SLS_AMT": 8275.259999999998,
      "QTD_SLS_AMT": 227511.82,
      "HTD_SLS_AMT": 527711.42,
      "YTD_SLS_AMT": 1180581.48,
      "L12_SLS_AMT": 1243004.29,
      "YST_SLS_AMT": 978.96,
      "LQ_SLS_AMT": 300199.60000000003,
      "LH_SLS_AMT": 652870.0600000002,
      "WTD_FULFL_ORD": 13,
      "LW_FULFL_ORD": 56,
      "LM_FULFL_ORD": 279,
      "MTD_FULFL_ORD": 13,
      "QTD_FULFL_ORD": 554,
      "HTD_FULFL_ORD": 1243,
      "YTD_FULFL_ORD": 2552,
      "L12_FULFL_ORD": 2729,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 689,
      "LH_FULFL_ORD": 1309,
      "WTD_FULFL_ORD_COMP_PCT": -0.0714285714285714,
      "LW_FULFL_ORD_COMP_PCT": 0.8666666666666667,
      "LM_FULFL_ORD_COMP_PCT": 0.5942857142857143,
      "MTD_FULFL_ORD_COMP_PCT": -0.0714285714285714,
      "QTD_FULFL_ORD_COMP_PCT": 0.6011560693641618,
      "HTD_FULFL_ORD_COMP_PCT": 0.5774111675126903,
      "YTD_FULFL_ORD_COMP_PCT": 0.49327091866588657,
      "L12_FULFL_ORD_COMP_PCT": 0.47833152762730236,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.5588235294117647,
      "LH_FULFL_ORD_COMP_PCT": 0.42128121606948965,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 0,
      "LW_MISS_COUNT": 0,
      "LM_MISS_COUNT": 2,
      "MTD_MISS_COUNT": 0,
      "QTD_MISS_COUNT": 7,
      "HTD_MISS_COUNT": 29,
      "YTD_MISS_COUNT": 106,
      "L12_MISS_COUNT": 124,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 22,
      "LH_MISS_COUNT": 77
    },
    {
      "STR_NBR": 1856,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 2048.14,
      "LW_SLS_AMT": 7059.620000000001,
      "LM_SLS_AMT": 48032.15,
      "MTD_SLS_AMT": 2048.14,
      "QTD_SLS_AMT": 121308.77000000003,
      "HTD_SLS_AMT": 295791.9,
      "YTD_SLS_AMT": 720927.7999999998,
      "L12_SLS_AMT": 760597.3899999999,
      "YST_SLS_AMT": 0,
      "LQ_SLS_AMT": 174483.13,
      "LH_SLS_AMT": 425135.9,
      "WTD_FULFL_ORD": 12,
      "LW_FULFL_ORD": 44,
      "LM_FULFL_ORD": 175,
      "MTD_FULFL_ORD": 12,
      "QTD_FULFL_ORD": 384,
      "HTD_FULFL_ORD": 957,
      "YTD_FULFL_ORD": 2059,
      "L12_FULFL_ORD": 2194,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 573,
      "LH_FULFL_ORD": 1102,
      "WTD_FULFL_ORD_COMP_PCT": -0.33333333333333337,
      "LW_FULFL_ORD_COMP_PCT": 0.375,
      "LM_FULFL_ORD_COMP_PCT": 0.24113475177304955,
      "MTD_FULFL_ORD_COMP_PCT": -0.33333333333333337,
      "QTD_FULFL_ORD_COMP_PCT": 0.3426573426573427,
      "HTD_FULFL_ORD_COMP_PCT": 0.41358936484490405,
      "YTD_FULFL_ORD_COMP_PCT": 0.3181818181818181,
      "L12_FULFL_ORD_COMP_PCT": 0.3256797583081572,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.46547314578005117,
      "LH_FULFL_ORD_COMP_PCT": 0.24519774011299433,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 0,
      "LW_MISS_COUNT": 0,
      "LM_MISS_COUNT": 3,
      "MTD_MISS_COUNT": 0,
      "QTD_MISS_COUNT": 7,
      "HTD_MISS_COUNT": 37,
      "YTD_MISS_COUNT": 97,
      "L12_MISS_COUNT": 100,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 30,
      "LH_MISS_COUNT": 60
    },
    {
      "STR_NBR": 6978,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "TOTAL",
      "WTD_SLS_AMT": 0,
      "LW_SLS_AMT": -962.22,
      "LM_SLS_AMT": -1819.4099999999999,
      "MTD_SLS_AMT": 0,
      "QTD_SLS_AMT": -4031.2999999999997,
      "HTD_SLS_AMT": -14040.09,
      "YTD_SLS_AMT": -35425.58999999999,
      "L12_SLS_AMT": -38355.149999999994,
      "YST_SLS_AMT": 0,
      "LQ_SLS_AMT": -10008.79,
      "LH_SLS_AMT": -21385.499999999996,
      "WTD_FULFL_ORD": 15,
      "LW_FULFL_ORD": 61,
      "LM_FULFL_ORD": 280,
      "MTD_FULFL_ORD": 15,
      "QTD_FULFL_ORD": 578,
      "HTD_FULFL_ORD": 1279,
      "YTD_FULFL_ORD": 2796,
      "L12_FULFL_ORD": 2966,
      "YST_FULFL_ORD": 0,
      "LQ_FULFL_ORD": 701,
      "LH_FULFL_ORD": 1517,
      "WTD_FULFL_ORD_COMP_PCT": -0.0625,
      "LW_FULFL_ORD_COMP_PCT": 0.08928571428571419,
      "LM_FULFL_ORD_COMP_PCT": 0.36585365853658547,
      "MTD_FULFL_ORD_COMP_PCT": -0.0625,
      "QTD_FULFL_ORD_COMP_PCT": 0.5130890052356021,
      "HTD_FULFL_ORD_COMP_PCT": 0.4242761692650334,
      "YTD_FULFL_ORD_COMP_PCT": 0.34293948126801155,
      "L12_FULFL_ORD_COMP_PCT": 0.3282579489476041,
      "YST_FULFL_ORD_COMP_PCT": -1,
      "LQ_FULFL_ORD_COMP_PCT": 0.3585271317829457,
      "LH_FULFL_ORD_COMP_PCT": 0.28125,
      "WTD_SURVEY_COUNT": 0,
      "LW_SURVEY_COUNT": 0,
      "LM_SURVEY_COUNT": 0,
      "MTD_SURVEY_COUNT": 0,
      "QTD_SURVEY_COUNT": 0,
      "HTD_SURVEY_COUNT": 0,
      "YTD_SURVEY_COUNT": 0,
      "L12_SURVEY_COUNT": 0,
      "YST_SURVEY_COUNT": 0,
      "LQ_SURVEY_COUNT": 0,
      "LH_SURVEY_COUNT": 0,
      "WTD_CSAT": 0,
      "LW_CSAT": 0,
      "LM_CSAT": 0,
      "MTD_CSAT": 0,
      "QTD_CSAT": 0,
      "HTD_CSAT": 0,
      "YTD_CSAT": 0,
      "L12_CSAT": 0,
      "YST_CSAT": 0,
      "LQ_CSAT": 0,
      "LH_CSAT": 0,
      "WTD_MISS_COUNT": 0,
      "LW_MISS_COUNT": 3,
      "LM_MISS_COUNT": 5,
      "MTD_MISS_COUNT": 0,
      "QTD_MISS_COUNT": 8,
      "HTD_MISS_COUNT": 24,
      "YTD_MISS_COUNT": 95,
      "L12_MISS_COUNT": 102,
      "YST_MISS_COUNT": 0,
      "LQ_MISS_COUNT": 16,
      "LH_MISS_COUNT": 71
    }
  ],
  DELIVERY: [
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Box",
      "WTD_PCT_ON_TM": 0.9,
      "LW_PCT_ON_TM": 0.9,
      "LM_PCT_ON_TM": 0.9,
      "MTD_PCT_ON_TM": 0.9,
      "QTD_PCT_ON_TM": 0.9,
      "HTD_PCT_ON_TM": 0.9,
      "YTD_PCT_ON_TM": 0.9,
      "L12_PCT_ON_TM": 0.9,
      "YST_PCT_ON_TM": 0.9,
      "LQ_PCT_ON_TM": 0.9,
      "LH_PCT_ON_TM": 0.9,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Car",
      "WTD_PCT_ON_TM": 0.8,
      "LW_PCT_ON_TM": 0.8,
      "LM_PCT_ON_TM": 0.8,
      "MTD_PCT_ON_TM": 0.8,
      "QTD_PCT_ON_TM": 0.8,
      "HTD_PCT_ON_TM": 0.8,
      "YTD_PCT_ON_TM": 0.8,
      "L12_PCT_ON_TM": 0.8,
      "YST_PCT_ON_TM": 0.8,
      "LQ_PCT_ON_TM": 0.8,
      "LH_PCT_ON_TM": 0.8,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Flatbed",
      "WTD_PCT_ON_TM": 0.7,
      "LW_PCT_ON_TM": 0.7,
      "LM_PCT_ON_TM": 0.7,
      "MTD_PCT_ON_TM": 0.7,
      "QTD_PCT_ON_TM": 0.7,
      "HTD_PCT_ON_TM": 0.7,
      "YTD_PCT_ON_TM": 0.7,
      "L12_PCT_ON_TM": 0.7,
      "YST_PCT_ON_TM": 0.7,
      "LQ_PCT_ON_TM": 0.7,
      "LH_PCT_ON_TM": 0.7,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Van",
      "WTD_PCT_ON_TM": 0.6,
      "LW_PCT_ON_TM": 0.6,
      "LM_PCT_ON_TM": 0.6,
      "MTD_PCT_ON_TM": 0.6,
      "QTD_PCT_ON_TM": 0.6,
      "HTD_PCT_ON_TM": 0.6,
      "YTD_PCT_ON_TM": 0.6,
      "L12_PCT_ON_TM": 0.6,
      "YST_PCT_ON_TM": 0.6,
      "LQ_PCT_ON_TM": 0.6,
      "LH_PCT_ON_TM": 0.6,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Other",
      "WTD_PCT_ON_TM": 0.5,
      "LW_PCT_ON_TM": 0.5,
      "LM_PCT_ON_TM": 0.5,
      "MTD_PCT_ON_TM": 0.5,
      "QTD_PCT_ON_TM": 0.5,
      "HTD_PCT_ON_TM": 0.5,
      "YTD_PCT_ON_TM": 0.5,
      "L12_PCT_ON_TM": 0.5,
      "YST_PCT_ON_TM": 0.5,
      "LQ_PCT_ON_TM": 0.5,
      "LH_PCT_ON_TM": 0.5,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 147,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Total",
      "WTD_PCT_ON_TM": 0.4,
      "LW_PCT_ON_TM": 0.4,
      "LM_PCT_ON_TM": 0.4,
      "MTD_PCT_ON_TM": 0.4,
      "QTD_PCT_ON_TM": 0.4,
      "HTD_PCT_ON_TM": 0.4,
      "YTD_PCT_ON_TM": 0.4,
      "L12_PCT_ON_TM": 0.4,
      "YST_PCT_ON_TM": 0.4,
      "LQ_PCT_ON_TM": 0.4,
      "LH_PCT_ON_TM": 0.4,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 123,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Box",
      "WTD_PCT_ON_TM": 0.9,
      "LW_PCT_ON_TM": 0.9,
      "LM_PCT_ON_TM": 0.9,
      "MTD_PCT_ON_TM": 0.9,
      "QTD_PCT_ON_TM": 0.9,
      "HTD_PCT_ON_TM": 0.9,
      "YTD_PCT_ON_TM": 0.9,
      "L12_PCT_ON_TM": 0.9,
      "YST_PCT_ON_TM": 0.9,
      "LQ_PCT_ON_TM": 0.9,
      "LH_PCT_ON_TM": 0.9,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 123,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Car",
      "WTD_PCT_ON_TM": 0.8,
      "LW_PCT_ON_TM": 0.8,
      "LM_PCT_ON_TM": 0.8,
      "MTD_PCT_ON_TM": 0.8,
      "QTD_PCT_ON_TM": 0.8,
      "HTD_PCT_ON_TM": 0.8,
      "YTD_PCT_ON_TM": 0.8,
      "L12_PCT_ON_TM": 0.8,
      "YST_PCT_ON_TM": 0.8,
      "LQ_PCT_ON_TM": 0.8,
      "LH_PCT_ON_TM": 0.8,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 123,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Flatbed",
      "WTD_PCT_ON_TM": 0.7,
      "LW_PCT_ON_TM": 0.7,
      "LM_PCT_ON_TM": 0.7,
      "MTD_PCT_ON_TM": 0.7,
      "QTD_PCT_ON_TM": 0.7,
      "HTD_PCT_ON_TM": 0.7,
      "YTD_PCT_ON_TM": 0.7,
      "L12_PCT_ON_TM": 0.7,
      "YST_PCT_ON_TM": 0.7,
      "LQ_PCT_ON_TM": 0.7,
      "LH_PCT_ON_TM": 0.7,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 123,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Van",
      "WTD_PCT_ON_TM": 0.6,
      "LW_PCT_ON_TM": 0.6,
      "LM_PCT_ON_TM": 0.6,
      "MTD_PCT_ON_TM": 0.6,
      "QTD_PCT_ON_TM": 0.6,
      "HTD_PCT_ON_TM": 0.6,
      "YTD_PCT_ON_TM": 0.6,
      "L12_PCT_ON_TM": 0.6,
      "YST_PCT_ON_TM": 0.6,
      "LQ_PCT_ON_TM": 0.6,
      "LH_PCT_ON_TM": 0.6,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 123,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Other",
      "WTD_PCT_ON_TM": 0.5,
      "LW_PCT_ON_TM": 0.5,
      "LM_PCT_ON_TM": 0.5,
      "MTD_PCT_ON_TM": 0.5,
      "QTD_PCT_ON_TM": 0.5,
      "HTD_PCT_ON_TM": 0.5,
      "YTD_PCT_ON_TM": 0.5,
      "L12_PCT_ON_TM": 0.5,
      "YST_PCT_ON_TM": 0.5,
      "LQ_PCT_ON_TM": 0.5,
      "LH_PCT_ON_TM": 0.5,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 123,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Total",
      "WTD_PCT_ON_TM": 0.4,
      "LW_PCT_ON_TM": 0.4,
      "LM_PCT_ON_TM": 0.4,
      "MTD_PCT_ON_TM": 0.4,
      "QTD_PCT_ON_TM": 0.4,
      "HTD_PCT_ON_TM": 0.4,
      "YTD_PCT_ON_TM": 0.4,
      "L12_PCT_ON_TM": 0.4,
      "YST_PCT_ON_TM": 0.4,
      "LQ_PCT_ON_TM": 0.4,
      "LH_PCT_ON_TM": 0.4,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Box",
      "WTD_PCT_ON_TM": 0.9,
      "LW_PCT_ON_TM": 0.9,
      "LM_PCT_ON_TM": 0.9,
      "MTD_PCT_ON_TM": 0.9,
      "QTD_PCT_ON_TM": 0.9,
      "HTD_PCT_ON_TM": 0.9,
      "YTD_PCT_ON_TM": 0.9,
      "L12_PCT_ON_TM": 0.9,
      "YST_PCT_ON_TM": 0.9,
      "LQ_PCT_ON_TM": 0.9,
      "LH_PCT_ON_TM": 0.9,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Car",
      "WTD_PCT_ON_TM": 0.8,
      "LW_PCT_ON_TM": 0.8,
      "LM_PCT_ON_TM": 0.8,
      "MTD_PCT_ON_TM": 0.8,
      "QTD_PCT_ON_TM": 0.8,
      "HTD_PCT_ON_TM": 0.8,
      "YTD_PCT_ON_TM": 0.8,
      "L12_PCT_ON_TM": 0.8,
      "YST_PCT_ON_TM": 0.8,
      "LQ_PCT_ON_TM": 0.8,
      "LH_PCT_ON_TM": 0.8,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Flatbed",
      "WTD_PCT_ON_TM": 0.7,
      "LW_PCT_ON_TM": 0.7,
      "LM_PCT_ON_TM": 0.7,
      "MTD_PCT_ON_TM": 0.7,
      "QTD_PCT_ON_TM": 0.7,
      "HTD_PCT_ON_TM": 0.7,
      "YTD_PCT_ON_TM": 0.7,
      "L12_PCT_ON_TM": 0.7,
      "YST_PCT_ON_TM": 0.7,
      "LQ_PCT_ON_TM": 0.7,
      "LH_PCT_ON_TM": 0.7,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Van",
      "WTD_PCT_ON_TM": 0.6,
      "LW_PCT_ON_TM": 0.6,
      "LM_PCT_ON_TM": 0.6,
      "MTD_PCT_ON_TM": 0.6,
      "QTD_PCT_ON_TM": 0.6,
      "HTD_PCT_ON_TM": 0.6,
      "YTD_PCT_ON_TM": 0.6,
      "L12_PCT_ON_TM": 0.6,
      "YST_PCT_ON_TM": 0.6,
      "LQ_PCT_ON_TM": 0.6,
      "LH_PCT_ON_TM": 0.6,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Other",
      "WTD_PCT_ON_TM": 0.5,
      "LW_PCT_ON_TM": 0.5,
      "LM_PCT_ON_TM": 0.5,
      "MTD_PCT_ON_TM": 0.5,
      "QTD_PCT_ON_TM": 0.5,
      "HTD_PCT_ON_TM": 0.5,
      "YTD_PCT_ON_TM": 0.5,
      "L12_PCT_ON_TM": 0.5,
      "YST_PCT_ON_TM": 0.5,
      "LQ_PCT_ON_TM": 0.5,
      "LH_PCT_ON_TM": 0.5,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 105,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Total",
      "WTD_PCT_ON_TM": 0.4,
      "LW_PCT_ON_TM": 0.4,
      "LM_PCT_ON_TM": 0.4,
      "MTD_PCT_ON_TM": 0.4,
      "QTD_PCT_ON_TM": 0.4,
      "HTD_PCT_ON_TM": 0.4,
      "YTD_PCT_ON_TM": 0.4,
      "L12_PCT_ON_TM": 0.4,
      "YST_PCT_ON_TM": 0.4,
      "LQ_PCT_ON_TM": 0.4,
      "LH_PCT_ON_TM": 0.4,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Box",
      "WTD_PCT_ON_TM": 0.9,
      "LW_PCT_ON_TM": 0.9,
      "LM_PCT_ON_TM": 0.9,
      "MTD_PCT_ON_TM": 0.9,
      "QTD_PCT_ON_TM": 0.9,
      "HTD_PCT_ON_TM": 0.9,
      "YTD_PCT_ON_TM": 0.9,
      "L12_PCT_ON_TM": 0.9,
      "YST_PCT_ON_TM": 0.9,
      "LQ_PCT_ON_TM": 0.9,
      "LH_PCT_ON_TM": 0.9,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Car",
      "WTD_PCT_ON_TM": 0.8,
      "LW_PCT_ON_TM": 0.8,
      "LM_PCT_ON_TM": 0.8,
      "MTD_PCT_ON_TM": 0.8,
      "QTD_PCT_ON_TM": 0.8,
      "HTD_PCT_ON_TM": 0.8,
      "YTD_PCT_ON_TM": 0.8,
      "L12_PCT_ON_TM": 0.8,
      "YST_PCT_ON_TM": 0.8,
      "LQ_PCT_ON_TM": 0.8,
      "LH_PCT_ON_TM": 0.8,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Flatbed",
      "WTD_PCT_ON_TM": 0.7,
      "LW_PCT_ON_TM": 0.7,
      "LM_PCT_ON_TM": 0.7,
      "MTD_PCT_ON_TM": 0.7,
      "QTD_PCT_ON_TM": 0.7,
      "HTD_PCT_ON_TM": 0.7,
      "YTD_PCT_ON_TM": 0.7,
      "L12_PCT_ON_TM": 0.7,
      "YST_PCT_ON_TM": 0.7,
      "LQ_PCT_ON_TM": 0.7,
      "LH_PCT_ON_TM": 0.7,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Van",
      "WTD_PCT_ON_TM": 0.6,
      "LW_PCT_ON_TM": 0.6,
      "LM_PCT_ON_TM": 0.6,
      "MTD_PCT_ON_TM": 0.6,
      "QTD_PCT_ON_TM": 0.6,
      "HTD_PCT_ON_TM": 0.6,
      "YTD_PCT_ON_TM": 0.6,
      "L12_PCT_ON_TM": 0.6,
      "YST_PCT_ON_TM": 0.6,
      "LQ_PCT_ON_TM": 0.6,
      "LH_PCT_ON_TM": 0.6,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Other",
      "WTD_PCT_ON_TM": 0.5,
      "LW_PCT_ON_TM": 0.5,
      "LM_PCT_ON_TM": 0.5,
      "MTD_PCT_ON_TM": 0.5,
      "QTD_PCT_ON_TM": 0.5,
      "HTD_PCT_ON_TM": 0.5,
      "YTD_PCT_ON_TM": 0.5,
      "L12_PCT_ON_TM": 0.5,
      "YST_PCT_ON_TM": 0.5,
      "LQ_PCT_ON_TM": 0.5,
      "LH_PCT_ON_TM": 0.5,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
    {
      "STR_NBR": 131,
      "DST_NBR": 1,
      "TRNSFM_DT": "2023-12-26",
      "TRUCK_TYPE": "Total",
      "WTD_PCT_ON_TM": 0.4,
      "LW_PCT_ON_TM": 0.4,
      "LM_PCT_ON_TM": 0.4,
      "MTD_PCT_ON_TM": 0.4,
      "QTD_PCT_ON_TM": 0.4,
      "HTD_PCT_ON_TM": 0.4,
      "YTD_PCT_ON_TM": 0.4,
      "L12_PCT_ON_TM": 0.4,
      "YST_PCT_ON_TM": 0.4,
      "LQ_PCT_ON_TM": 0.4,
      "LH_PCT_ON_TM": 0.4,
      "WTD_DLV_MISSES": 10,
      "LW_DLV_MISSES": 9,
      "LM_DLV_MISSES": 8,
      "MTD_DLV_MISSES": 7,
      "QTD_DLV_MISSES": 6,
      "HTD_DLV_MISSES": 5,
      "YTD_DLV_MISSES": 4,
      "L12_DLV_MISSES": 3,
      "YST_DLV_MISSES": 2,
      "LQ_DLV_MISSES": 1,
      "LH_DLV_MISSES": 0,
      "WTD_DLV_MISSES_PCT": 0.1,
      "LW_DLV_MISSES_PCT": 0.1,
      "LM_DLV_MISSES_PCT": 0.1,
      "MTD_DLV_MISSES_PCT": 0.1,
      "QTD_DLV_MISSES_PCT": 0.1,
      "HTD_DLV_MISSES_PCT": 0.1,
      "YTD_DLV_MISSES_PCT": 0.1,
      "L12_DLV_MISSES_PCT": 0.1,
      "YST_DLV_MISSES_PCT": 0.1,
      "LQ_DLV_MISSES_PCT": 0.1,
      "LH_DLV_MISSES_PCT": 0.1,
    },
  ]
}