import React from 'react';
import { Header, Icon, Popup, Table } from 'semantic-ui-react';
import { getCompareStyle, getPercentString } from '../../../../../components/Common/TableCellUtils';
import { timeframes } from '../utils/constants';

// Utility function to get the score data for a specific timeframe
const getTimeframeData = (timeframe, scoresData) => {
  return scoresData.find(row => row && row['TIME_FRAME'] === timeframe);
};

export const RegionCoreExecScoreByTimeframe = ({ scoresData }) => {
  // Get the current day of the week (used to determine WTD or LW)
  const currentDay = new Date().getDay();
  const isMonday = currentDay === 1;

  return (
    <div>
      <Header textAlign='center'>Shrink Exec Score by Timeframe</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timeframe</Table.HeaderCell>
            <Table.HeaderCell>Total Score</Table.HeaderCell>
            <Table.HeaderCell>
              vLY{' '}
              <Popup
                on='click'
                position='bottom left'
                content='For the total Operational Shrink Execution Score, always try to perform better year-over-year.'
                trigger={<Icon name='info circle' color='blue' />}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {timeframes.map(({ label, display }) => {
            const currentData = getTimeframeData(label, scoresData);
            const lastYearData = getTimeframeData(label, scoresData);
            const currentScore = currentData ? currentData['SHRINK_EXEC_SCORE'] : 0;
            const lastYearScore = lastYearData ? lastYearData['LY_SHRINK_EXEC_SCORE'] : 0;
            const compareStyle = getCompareStyle(currentScore - lastYearScore);

            return (
              <Table.Row key={label}>
                <Table.Cell>{isMonday && label === 'WTD' ? 'LW' : display}</Table.Cell>
                <Table.Cell>{currentData ? getPercentString(currentScore) : '-'}</Table.Cell>
                <Table.Cell style={compareStyle}>
                  {currentData ? getPercentString(currentScore - lastYearScore) : '-'}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
