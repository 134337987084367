import useQueryApi from '../../../hooks/useQueryApi';

export const useShrinkDeptQueries = params => {
  const { level, locNbr, deptNbr, fpToggle } = params;
  const queryParams = { varPresent: true, fpToggle };

  const {
    data: shrinkActualsClsDeptData = [],
    isError: isErrorShrinkActualsClsDept,
    isLoading: isLoadingShrinkActualsClsDept
  } = useQueryApi(`query/queryShrinkActualsClsDept/${level}/${locNbr}/dept/${deptNbr}`, queryParams);

  const {
    data: shrinkActualsSkuDeptData = [],
    isError: isErrorShrinkActualsSkuDept,
    isLoading: isLoadingShrinkActualsSkuDept
  } = useQueryApi(`query/queryShrinkActualsSkuDept/${level}/${locNbr}/dept/${deptNbr}`, queryParams);

  const isError = isErrorShrinkActualsClsDept || isErrorShrinkActualsSkuDept;
  const isLoading = isLoadingShrinkActualsClsDept || isLoadingShrinkActualsSkuDept;

  return {
    isError,
    isLoading,
    shrinkActualsClsDeptData,
    shrinkActualsSkuDeptData
  };
};
