import { getAdjustableDollarString, getPercentString } from '../../../../../components/Common/TableCellUtils';

export const coreSummaryTableBuilder = (summaryData, isUnitNom) => {
  const summary = summaryData[0];

  return {
    headers: [
      { name: isUnitNom ? 'Shrink $' : 'Shrink %' },
      { name: 'vGoal' },
      { name: 'vLY' },
      { name: isUnitNom ? 'Ops Shrink $' : 'Ops Shrink %' }
    ],
    data: [
      [
        {
          stringValue: isUnitNom
            ? getAdjustableDollarString(summary?.TOTAL_SHRINK_AMT, 2)
            : getPercentString(summary?.TOTAL_SHRINK_AMT / summary?.TOTAL_SALES_AMT, 2, 2)
        },
        {
          stringValue: isUnitNom
            ? getAdjustableDollarString(
                summary?.TOTAL_SHRINK_AMT -
                  (summary?.LI_SHRINK_AMT_GOAL / summary?.LI_SALES_AMT_GOAL) * summary?.TOTAL_SALES_AMT,
                2
              )
            : getPercentString(
                summary?.TOTAL_SHRINK_AMT / summary?.TOTAL_SALES_AMT -
                  summary?.LI_SHRINK_AMT_GOAL / summary?.LI_SALES_AMT_GOAL,
                2,
                2
              )
        },
        {
          stringValue: isUnitNom
            ? getAdjustableDollarString(summary?.TOTAL_SHRINK_AMT - summary?.LY_TOTAL_SHRINK_AMT, 2)
            : getPercentString(
                summary?.TOTAL_SHRINK_AMT / summary?.TOTAL_SALES_AMT -
                  summary?.LY_TOTAL_SHRINK_AMT / summary?.LY_TOTAL_SALES_AMT,
                2,
                2
              )
        },
        {
          stringValue: isUnitNom
            ? getAdjustableDollarString(summary?.OPS_SHRINK_AMT, 2)
            : getPercentString(summary?.OPS_SHRINK_AMT / summary?.TOTAL_SALES_AMT, 2, 2)
        }
      ]
    ],
    sortable: false,
    pagination: false
  };
};
