import { useMemo, useState } from 'react';
import { useFetchLevelName } from '../../../hooks/useFetchLevelName';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { buildBaseShrinkUrl } from '../utils/url';
import { buildSkuTableHeaders, buildSkuTableRow } from '../utils/sku';
import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { Breadcrumb } from '../../Common/Breadcrumb';
import { DataView } from '../../Common/DataView';
import { Header, Loader } from 'semantic-ui-react';
import { SkuDetailsModal } from '../../Sku/SkuDetailsModal';
import { UnitMenu } from '../../Common/UnitMenu';
import { NOM } from '../../../utils/constants';

export const ShrinkSubclassPage = () => {
  const [skuData, setSkuData] = useState(null);
  const [unit, setUnit] = useState(NOM);
  const { fpToggle } = useShrinkContext();
  const {
    isError,
    isLoading,
    level,
    locNbr,
    results: [queryShrinkSkuBySubclass]
  } = useShrinkQueries({ fpToggle, queries: ['query/queryShrinkSkuBySubclass'] });
  const { currentLevelName, isLoadingName } = useFetchLevelName({ level, locNbr, fpToggle });
  const data = useMemo(() => {
    return queryShrinkSkuBySubclass.data?.map(row => buildSkuTableRow(row, unit, () => setSkuData(row))) || [];
  }, [queryShrinkSkuBySubclass.data, unit]);

  if (isLoading || isLoadingName) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const { CLASS_DESC, DEPT_NM, DEPT_NBR, EXT_CLASS_NBR, EXT_SUB_CLASS_NBR, SUB_CLASS_DESC } =
    queryShrinkSkuBySubclass.data?.[0] || {};
  const levelParam = getParamByLevel(level);
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const baseLevelParam = `${levelParam}=${locNbr}`;

  const breadcrumbItems = [
    { text: 'Shrink', to: `/${baseLevelShrinkUrl}?${baseLevelParam}` },
    { text: `${DEPT_NBR}-${DEPT_NM}`, to: `/${baseLevelShrinkUrl}/dept?${baseLevelParam}&deptNbr=${DEPT_NBR}` },
    {
      text: `${EXT_CLASS_NBR}-${CLASS_DESC}`,
      to: `/${baseLevelShrinkUrl}/class?${baseLevelParam}&classNbr=${EXT_CLASS_NBR}`
    },
    { text: `${EXT_SUB_CLASS_NBR}-${SUB_CLASS_DESC}` }
  ];

  const dataObj = {
    data,
    headers: buildSkuTableHeaders(unit),
    pagination: true,
    sortable: true,
    sortableDefault: 0
  };

  return (
    <section>
      <Breadcrumb items={breadcrumbItems} />

      <DataView>
        <DataView.Title className='mt-3'>
          {EXT_SUB_CLASS_NBR} - {SUB_CLASS_DESC?.toLowerCase()}, {level} {locNbr}, {currentLevelName}
        </DataView.Title>

        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </DataView.Controls>

        <DataView.DataTable data={dataObj} />
      </DataView>

      <SkuDetailsModal isOpen={!!skuData} onClose={() => setSkuData(null)} skuData={skuData} />
    </section>
  );
};
