import React from 'react';
import { Header, Loader, Segment } from 'semantic-ui-react';
import { Breadcrumb } from '../../../../../components/Common/Breadcrumb';
import { DataTable } from '../../../../../components/Common/DataTable';
import { DataView } from '../../../../../components/Common/DataView';
import { TimeframeDropdownShrink } from '../../../../../components/Common/TimeframeDropdownShrink';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { cullMarkdownsHeadersCore, shrinkCullMdBreadcrumbItemsCore, shrinkCullMdRowsCore } from '../utils/cullMarkdownsCore';

export const ShrinkCullMarkdownsCore = () => {
  const { fpToggle, timeframe, settimeframe } = useShrinkCoreContext();

  const defaultParams = {
    fpToggle,
    isCore: true
  };

  const {
    data: queryShrinkMetricsByLevel = [],
    isLoading: queryShrinkMetricsByLevelLoading,
    isError: queryShrinkMetricsByLevelError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', defaultParams);

  const {
    data: queryShrinkMetricsScoresByLevel = [],
    isLoading: queryShrinkMetricsScoresByLevelLoading,
    isError: queryShrinkMetricsScoresByLevelError
  } = useQueryApi('queryShrinkMetricsScoresCore', defaultParams);

  if (queryShrinkMetricsByLevelLoading || queryShrinkMetricsScoresByLevelLoading) return <Loader active>Loading...</Loader>;
  if (queryShrinkMetricsByLevelError || queryShrinkMetricsScoresByLevelError)
    return <Header textAlign='center'>Something went wrong.</Header>;

  const headers = cullMarkdownsHeadersCore();

  const metricsScoreDataFiltered = queryShrinkMetricsScoresByLevel.filter(row => row && row['TIME_FRAME'] === timeframe);

  const metricsData = queryShrinkMetricsByLevel.reduce((acc, row) => {
    if (acc[METRIC_CONSTANTS['core'].propToRender]) return acc;
    return { ...acc, [row[METRIC_CONSTANTS['core'].propToRender]]: row };
  }, {});

  const data = {
    sortable: true,
    sortableColumns: [0],
    sortableDefault: 0,
    headers,
    data: metricsScoreDataFiltered.map(row => shrinkCullMdRowsCore(row, metricsData, timeframe))
  };

  return (
    <Segment className='mb-4'>
      <Breadcrumb items={shrinkCullMdBreadcrumbItemsCore()} />

      <DataView className='my-4'>
        <section style={{ textAlign: 'center' }}>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </section>

        <DataView.Title className='mt-2' position='center'>
          Cull Markdowns - Core
          <Header.Subheader>Data as of {queryShrinkMetricsByLevel[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>

        <DataTable data={data} />
      </DataView>
    </Segment>
  );
};
