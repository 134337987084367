import { DataView } from '../../../../../components/Common/DataView';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { monitorMetricsDataCore } from '../utils/monitorMetricsBuilderCore';

export const MonitorMetricsCore = ({ shrinkMetricsData }) => {
  const { timeframe } = useShrinkCoreContext();

  return (
    <DataView className='mt-4'>
      <DataView.Title>Monitor Metrics</DataView.Title>
      <DataView.DataTable data={monitorMetricsDataCore({ shrinkMetricsData, timeframe })} />
    </DataView>
  );
};
