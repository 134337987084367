import { useMemo, useState } from 'react';
import { Header, Menu, Tab } from 'semantic-ui-react';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { DataTable } from '../../../../../components/Common/DataTable';
import { coreDepartmentSummaryBuilder } from '../utils/coreDepartmentSummaryBuilder';

export const CoreDepartmentSummary = ({ departmentData }) => {
  const [units, setUnits] = useState('NOM');

  const dataTableData = useMemo(() => {
    return coreDepartmentSummaryBuilder(departmentData, units);
  }, [departmentData, units]);

  return (
    <Tab.Pane className='mt-3'>
      <Header textAlign='center'>Department Summary</Header>
      <Menu compact size='small'>
        <UnitMenu unit={units} setUnit={setUnits} />
      </Menu>
      <DataTable data={dataTableData} />
    </Tab.Pane>
  );
};
