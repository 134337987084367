import { useState } from 'react';
import { Header, Tab } from 'semantic-ui-react';
import { capitalizeFirstLetter } from '../../../utils/string';
import { DataTable } from '../../Common/DataTable';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import {
  aggregateByDivNbr,
  salesRentalDataBuilder,
  salesRentalSubLevelDataBuilder,
  slsDepts
} from '../utils/salesRentalDataBuilder';
import { SalesProRentalLevelList } from './SalesProRentalLevelList';
import { UnitsAsComponent } from './UnitsAsComponent';

export const SalesProRental = ({ salesData, subLevelSalesData }) => {
  const { level, timeframe } = useSpecialtyProRentalContext();
  const slsLevel = capitalizeFirstLetter(level.levelName);
  const slsSubLevel = capitalizeFirstLetter(level.subLevel.levelName);
  const { subMetric, subLevel, levelName } = level;
  const { levelName: subLevelName } = subLevel;
  const [deptNmLbr, setDeptNmLbr] = useState('Total');
  const currentForCore = `${subMetric}_NBR`;
  const [unit, setUnit] = useState('NOM');
  const aggregatedData = aggregateByDivNbr(
    subLevelSalesData.filter(level => level['MER_DEPT_NM'] === deptNmLbr),
    currentForCore
  );
  const aggregatedDataArray = Object.values(aggregatedData);

  const { dataSalesRentalDataBuilder } = salesRentalDataBuilder(salesData, timeframe, subMetric, unit, levelName);

  const { dataSalesRentalSubLevelDataBuilder } = salesRentalSubLevelDataBuilder(
    subMetric,
    subLevelName,
    aggregatedDataArray,
    timeframe,
    unit,
    levelName
  );

  const slsDeptsFilter = slsDepts(salesData);

  const tabs = [
    { menuItem: slsLevel, render: () => <DataTable data={dataSalesRentalDataBuilder} /> },
    {
      menuItem: `${slsSubLevel} List`,
      render: () => (
        <SalesProRentalLevelList
          slsDepts={slsDeptsFilter}
          dataObj={dataSalesRentalSubLevelDataBuilder}
          setDeptNmLbr={setDeptNmLbr}
          deptNmLbr={deptNmLbr}
        />
      )
    }
  ];

  return (
    <>
      <Header textAlign='center'>Sales</Header>
      <UnitsAsComponent unit={unit} setUnit={setUnit} />
      <Tab className='mt-2' panes={tabs} />
      <p className='mt-2'>
        ** Pro Xtra Registered Tender & Services are not included in the total due to overlapping sales in the department
        totals.
      </p>
    </>
  );
};
