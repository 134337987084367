import React, { useContext } from 'react';
import { OrderFulfillmentContext } from '../../../context/OrderFulfillmentContext';
import { DataTable } from '../../../../../components/Common/DataTable';

const Summary = () => {
    const { orderFulfillmentPickupData, orderFulfillmentDeliveryData } = useContext(OrderFulfillmentContext);

    const pickupSummary = orderFulfillmentPickupData.reduce((acc, data) => {
        acc.PICK_ON_TIME += data.PICK_ON_TIME;
        acc.FULFIL_TY += data.FULFIL_TY;
        acc.TOTAL_ORDERS += data.TOTAL_ORDERS;
        acc.FULFIL_LY += data.FULFIL_LY;
        return acc;
    }, {
        PICK_ON_TIME: 0,
        FULFIL_TY: 0,
        TOTAL_ORDERS: 0,
        FULFIL_LY: 0
    });
    const deliverySummary = orderFulfillmentDeliveryData.reduce((acc, data) => {
        acc.PICK_ON_TIME_PCT += data.PICK_ON_TIME_PCT;
        acc.FULFIL_PCT += data.FULFIL_PCT;
        acc.FULFIL_TY += data.FULFIL_TY;
        acc.FULFIL_LY += data.FULFIL_LY;
        return acc;
    } , {
        PICK_ON_TIME_PCT: 0,
        FULFIL_PCT: 0,
        FULFIL_TY: 0,
        FULFIL_LY: 0
    });

    const dateObj = {
        headers: [
            { name: 'Method' }, 
            { name: 'Pick On Time %' }, 
            { name: 'Fulfill %' }, 
            { name: 'Fulfill TY' }, 
            { name: 'Fulfill LY' }
        ],
        data: [
            [
                { stringValue: 'Pickup' },
                { pctValue: pickupSummary.PICK_ON_TIME / pickupSummary.TOTAL_ORDERS },
                { pctValue: pickupSummary.FULFIL_TY / pickupSummary.TOTAL_ORDERS },
                { numValue: pickupSummary.FULFIL_TY },
                { numValue: pickupSummary.FULFIL_LY }
            ],
            [
                { stringValue: 'Delivery' },
                { pctValue: deliverySummary.PICK_ON_TIME_PCT },
                { pctValue: deliverySummary.FULFIL_PCT },
                { numValue: deliverySummary.FULFIL_TY },
                { numValue: deliverySummary.FULFIL_LY }
            ],
            [
                { stringValue: 'Total' },
                { pctValue: deliverySummary.PICK_ON_TIME_PCT },
                { pctValue: deliverySummary.FULFIL_PCT },
                { numValue: deliverySummary.FULFIL_TY },
                { numValue: deliverySummary.FULFIL_LY }
            ]
        ],
    }

    return (
        <div>
            <h2>Order Fulfillment Summary</h2>
            <DataTable
                data={dateObj}
            />
        </div>
    );
};

export default Summary;