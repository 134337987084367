import React from 'react';
import { Header, Tab } from 'semantic-ui-react';
import { CompletePickup } from './CompletePickup';
import { CompleteDelivery } from './CompleteDelivery';

export const Complete = () => {
    const tabs = [
        {
            menuItem: 'PICKUP',
            render: () => <CompletePickup />
        },
        {
            menuItem: 'DELIVERY',
            render: () => <CompleteDelivery />
        }
    ]

    return (<>
        <Header as='h1'>Order Picked on Time</Header>
        <Tab 
            menu={{ compact: true, size: 'small' }}
            panes={tabs} 
        />
    </>);
};
