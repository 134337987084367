import { OSA_SIDEKICK_PURGE_GOAL } from '../../../../../../constants/goals';

/**
 * Creates a new item with the given data.
 *
 * @param {string} entityField - The field representing the entity.
 * @param {Object} curr - The current data object.
 * @param {string} timeframe - The timeframe string.
 * @param {Object} sideExeData - The execution data object.
 * @param {string} ltf - The last timeframe string.
 * @returns {Object} The newly created item containing task counts, purge rates, and other metrics.
 */
export const createNewItem = (entityField, curr, timeframe, sideExeData, ltf) => {
  const totalTaskCount = curr[timeframe + '_TOTAL_TASK_COUNT']; // SIDEKICK_SKU_BAY DONE
  const completedTaskCount = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']; // SIDEKICK_EXECUTION_SCORES DONE
  const actualPurgeRate = completedTaskCount / totalTaskCount;

  const prevTotalTaskCount = curr[ltf + '_TOTAL_TASK_COUNT']; // SIDEKICK_SKU_BAY DONE
  const prevCompletedTaskCount = sideExeData[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT']; // SIDEKICK_EXECUTION_SCORES DONE
  const bps = prevTotalTaskCount !== 0 ? prevCompletedTaskCount / prevTotalTaskCount : '-';

  return {
    [entityField]: curr[entityField],
    [timeframe + '_TASK_COUNT']: totalTaskCount, // SIDEKICK_SKU_BAY
    [timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']: completedTaskCount, // SIDEKICK_SKU_BAY
    ACTUAL: actualPurgeRate,
    DIV_NBR: curr.DIV_NBR,
    [ltf + 's']: curr[ltf + '_STR_PURGE_TASK_COUNT'],
    [ltf + '_STR_PURGE_COMPLETED_TASK_COUNT']: prevCompletedTaskCount, // SIDEKICK_EXECUTION_SCORES
    BPS: bps
  };
};

export const updateItem = (item, curr, timeframe, sideExeData, ltf) => {
  const totalTaskCount = curr[timeframe + '_TOTAL_TASK_COUNT']; // SIDEKICK_SKU_BAY
  /**
   * Updates an existing item with the given data.
   *
   * @param {Object} item - The existing item to update.
   * @param {Object} curr - The current data object.
   * @param {string} timeframe - The timeframe string.
   * @param {Object} sideExeData - The execution data object.
   * @param {string} ltf - The last timeframe string.
   * @returns {Object} The updated item containing task counts, purge rates, and other metrics.
   */
  const completedTaskCount = sideExeData[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT']; // SIDEKICK_EXECUTION_SCORES
  const prevTotalTaskCount = curr[ltf + '_TOTAL_TASK_COUNT']; // SIDEKICK_SKU_BAY
  const prevCompletedTaskCount = sideExeData[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT']; // SIDEKICK_EXECUTION_SCORES

  item[timeframe + '_TASK_COUNT'] += totalTaskCount; // SIDEKICK_SKU_BAY
  item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] = completedTaskCount; // SIDEKICK_EXECUTION_SCORES
  item.ACTUAL = item[timeframe + '_STR_PURGE_COMPLETED_TASK_COUNT'] / item[timeframe + '_TOTAL_TASK_COUNT']; // SIDEKICK_EXECUTION_SCORES // SIDEKICK_SKU_BAY
  item.DIV_NBR = curr.DIV_NBR;
  item[ltf + '_LY_STR_PURGE_TASK_COUNT'] += curr[ltf + '_LY_STR_PURGE_TASK_COUNT'];
  item[ltf + '_STR_PURGE_COMPLETED_TASK_COUNT'] = prevCompletedTaskCount; // SIDEKICK_EXECUTION_SCORES

  item.BPS = prevTotalTaskCount !== 0 ? prevCompletedTaskCount / prevTotalTaskCount : '-';
};

export const NIGHT_CREW_DEPT = '38';
export const lastTimeframeMap = { WTD: 'LW', LW: null };

// NOTE: HELPERS FOR OSAPurge.jsx
export const filterPurgeData = data => {
  const purgeDataByNightCrewDept = data.filter(({ DEPT }) => DEPT === NIGHT_CREW_DEPT);
  return purgeDataByNightCrewDept.filter(row => row['TASK_TYPE'] === 'PURGE');
};

export const findItem = (acc, entityField, curr) => acc.find(item => item[entityField] === curr[entityField]);

// NOTE: HELPERS FOR PurgePackDownByDiv.jsx
export const reducePurgePackDown = (data, dataScores, entityField, timeframe, findItem) => {
  return data.reduce((acc, curr) => {
    const sideExeData = dataScores?.find(r => r[entityField] === curr[entityField]);
    if (!sideExeData) return acc;

    const item = findItem(acc, entityField, curr);

    if (item) {
      item[`${timeframe}_TOTAL_TASK_COUNT`] += curr[`${timeframe}_TOTAL_TASK_COUNT`];
      item[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`] = sideExeData[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`];
      item.RMNG = item[`${timeframe}_TOTAL_TASK_COUNT`] - item[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`];
      item.DIV_NBR = curr.DIV_NBR;
    } else {
      acc.push({
        [entityField]: curr[entityField],
        [`${timeframe}_TOTAL_TASK_COUNT`]: curr[`${timeframe}_TOTAL_TASK_COUNT`],
        [`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`]: sideExeData[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`],
        RMNG: curr[`${timeframe}_TOTAL_TASK_COUNT`] - sideExeData[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`],
        DIV_NBR: curr.DIV_NBR
      });
    }

    return acc;
  }, []);
};

export const createDataObjPurgePackDown = (purgePackDown, entityField, timeframe) => {
  return {
    headers: [{ name: 'Div' }, { name: 'Comp Tasks' }, { name: 'Remaining Sent #' }],
    data: purgePackDown.map(data => [
      {
        stringValue: data[entityField],
        link: {
          to: `/osa/Purge/division?divNbr=${data['DIV_NBR']}`
        }
      },
      {
        stringValue: data[`${timeframe}_STR_PURGE_COMPLETED_TASK_COUNT`].toLocaleString()
      },
      {
        stringValue: data.RMNG.toLocaleString()
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };
};

// NOTE: HELPERS FOR SidekickExecution.jsx

export const sideKickPurgeDataReduce = (data, dataScores, entityField, timeframe, findItem, ltf) => {
  return data.reduce((acc, curr) => {
    const sideExeData = dataScores?.find(r => r[entityField] === curr[entityField]);
    if (!sideExeData) return acc;
    let item = findItem(acc, entityField, curr);
    if (item) {
      updateItem(item, curr, timeframe, sideExeData, ltf);
    } else {
      acc.push(createNewItem(entityField, curr, timeframe, sideExeData, ltf));
    }

    return acc;
  }, []);
};

export const createDataObjSideKickExecution = (sideKickPurgeData, ltf) => {
  return {
    headers: [{ name: 'Div' }, { name: 'Actual' }, { name: 'Goal' }, { name: `v${ltf} bps` }],
    data: sideKickPurgeData.map(data => [
      {
        stringValue: data['DIV_NBR'],
        link: {
          to: `/osa/Purge/division?divNbr=${data['DIV_NBR']}`
        }
      },
      {
        pctValue: data.ACTUAL,
        customStyle: { color: data.ACTUAL < OSA_SIDEKICK_PURGE_GOAL ? 'red' : 'green' }
      },
      {
        pctValue: OSA_SIDEKICK_PURGE_GOAL
      },
      {
        numValue:
          data.BPS === '-' || isNaN(data.BPS)
            ? '-'
            : ((data.ACTUAL - data.BPS) * 10000).toLocaleString(undefined, { maximumFractionDigits: 0 })
      }
    ]),
    sortable: true,
    sortableDefault: 0
  };
};
