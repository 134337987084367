import { Grid, Header, Tab } from 'semantic-ui-react';
import { columnName, METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { TimeframeDropdownShrink } from '../../Common/TimeframeDropdownShrink';
import { useQueryKeys } from '../hooks/useQueryKeys';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { FocusMetrics } from './FocusMetrics';
import { LevelList } from './LevelList';
import { MonitorMetrics } from './MonitorMetrics';
import { Region } from './Region';
import { AccuracyAndReceiving } from './AccuracyAndReceiving';

export const DriversContainers = () => {
  const { timeframe, settimeframe, fpToggle } = useShrinkContext();
  const { locNbr, level } = useShrinkQueries({ fpToggle });
  const [shrinkMetricsData, scoresData, queryACCRCVByLevel] = useQueryKeys([
    `query/queryShrinkMetricsByLevel/${level}/${locNbr}`,
    `query/queryShrinkMetricsScoresByLevel/${level}/${locNbr}`,
    `query/queryACCRCVByLevel/${level}/${locNbr}`
  ]);

  const levelName = String(shrinkMetricsData[0][columnName[level]]).toLowerCase();
  const { levelName: subLevelname } = METRIC_CONSTANTS[level].subLevel;

  const panes = [
    {
      menuItem: level.toUpperCase(),
      render: () => <Region execScoreData={scoresData} />
    },
    {
      menuItem: `${subLevelname.toUpperCase()} LIST`,
      render: () => <LevelList />
    }
  ];

  return (
    <>
      <Header style={{ textTransform: 'capitalize' }}>
        Shrink Metrics - {level} {locNbr}, {levelName}
        <Header.Subheader>Data as of {shrinkMetricsData[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Tab panes={panes} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />
      <FocusMetrics scoresData={scoresData} shrinkMetricsData={shrinkMetricsData[0]} />
      <MonitorMetrics shrinkMetricsData={shrinkMetricsData[0]} />
      <AccuracyAndReceiving queryACCRCVByLevel={queryACCRCVByLevel} />
    </>
  );
};
