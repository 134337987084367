import { Link } from 'react-router-dom';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { getPrevAndNextElement } from '../../../../../../utils/array';
import { coreInventoryClassDrillBuilder } from '../../utils/inventory/CoreInventoryClassDrillBuilder';

export const CoreInventoryClass = ({ fpToggle }) => {
  const { deptNbr, classNbr } = useQueryParams();

  const { data: inventoryDrillData = [], isLoading: isInventoryDrillData } = useQueryApi(
    `queryInvClsByCoreDept/${deptNbr}`,
    {
      fpToggle,
      isCore: true
    }
  );

  const { data: drillClasses = [], isLoading: isDrillClasses } = useQueryApi(`queryInvSclsByCoreCls/${classNbr}`, {
    fpToggle,
    isCore: true
  });

  const deptDataSorted = inventoryDrillData.sort((left, right) => {
    const result = left.EXT_CLASS_NBR - right.EXT_CLASS_NBR;
    return result;
  });
  const currentIndex = deptDataSorted.findIndex(dept => dept['EXT_CLASS_NBR'] === classNbr);
  const [previousDept, nextDept] = getPrevAndNextElement(deptDataSorted, currentIndex);

  const { dataObj } = coreInventoryClassDrillBuilder(drillClasses);

  if (isInventoryDrillData || isDrillClasses) return <Loader active>Loading...</Loader>;

  return (
    <>
      <Header textAlign='center'>{deptDataSorted[currentIndex]?.CLASS_NM}</Header>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/core/inventory/dept/class?deptNbr=${deptNbr}&classNbr=${previousDept?.EXT_CLASS_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {previousDept?.CLASS_NM}
            </Link>
          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/core/inventory/dept/class?deptNbr=${deptNbr}&classNbr=${nextDept?.EXT_CLASS_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {nextDept?.CLASS_NM}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/core/inventory/dept?deptNbr=${deptNbr}`} data-test='b2o-link'>
                Back to Department
              </Link>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Inventory Detail</Header>

      <DataTable data={dataObj} />
    </>
  );
};
