import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { getCalloutStyle, getCompareStyle } from '../../Common/TableCellUtils';

export const buildFooter = (focusSix, totalData, timeframe, isFocusSix, pipelineFilter) => {
  if (!isFocusSix) return [];

  const { leadCount: focusLead, measCount: focusMeas } = extractRowData(focusSix, timeframe, pipelineFilter);
  const { leadCount: totalLead, measCount: totalMeas } = extractRowData(totalData, timeframe, pipelineFilter);

  const focusComp = calculateComp(focusSix, timeframe, pipelineFilter);
  const focusConv = calculateConv(focusSix, timeframe, pipelineFilter);

  const totalComp = calculateComp(totalData, timeframe, pipelineFilter);
  const totalConv = calculateConv(totalData, timeframe, pipelineFilter);

  return [
    [
      {
        name: 'total',
        stringValue: 'Total Focus 6 Programs'
      },
      {
        name: 'lead',
        numValue: focusLead
      },
      {
        name: 'meas',
        numValue: focusMeas
      },
      {
        name: 'comp',
        pctValue: focusComp
      },
      {
        name: 'conv',
        pctValue: focusConv
      }
    ],
    [
      {
        name: 'total_programs',
        stringValue: 'Total Programs'
      },
      {
        name: 'lead',
        numValue: totalLead
      },
      {
        name: 'meas',
        numValue: totalMeas
      },
      {
        name: 'comp',
        pctValue: totalComp
      },
      {
        name: 'conv',
        pctValue: totalConv
      }
    ]
  ];
};

export const buildHeaders = () => [
  { name: 'Program' },
  { name: 'Lead' },
  { name: 'Meas' },
  { name: 'Comp %' },
  { name: 'Conv %' }
];

export const buildRows = (data, isAllPrograms, pipelineFilter, level, locNbr, timeframe) => {
  const paramByLevel = getParamByLevel(level);

  return data.map(row => {
    const { leadConvNum, leadCount, lyLeadCount, lyMeasCount, measConvNum, measCount, programName } = extractRowData(
      row,
      timeframe,
      pipelineFilter,
      isAllPrograms
    );

    const comp = calculateComp(row, timeframe, pipelineFilter);
    const compStyle = leadCount + measCount - lyLeadCount - lyMeasCount;

    const conv = calculateConv(row, timeframe, pipelineFilter);
    const convStyle = leadConvNum + measConvNum;

    return [
      {
        link: {
          to: '/district/services/program',
          params: {
            [paramByLevel]: locNbr,
            smryPrgmNm: row.SUMMARY_PROGRAM_NAME
          }
        },
        stringValue: programName
      },
      {
        customStyle: getCalloutStyle(leadCount),
        numValue: leadCount.toLocaleString()
      },
      {
        customStyle: getCompareStyle(measCount),
        numValue: measCount.toLocaleString()
      },
      {
        customStyle: getCompareStyle(compStyle),
        pctValue: comp
      },
      {
        customStyle: getCompareStyle(convStyle),
        pctValue: conv
      }
    ];
  });
};

const calculateComp = (row, timeframe, pipelineFilter) => {
  const { leadCount, measCount, lyLeadCount, lyMeasCount } = extractRowData(row, timeframe, pipelineFilter);

  return (leadCount + measCount - lyLeadCount - lyMeasCount) / (lyLeadCount + lyMeasCount);
};

const calculateConv = (row, timeframe, pipelineFilter) => {
  const { leadConvDen, leadConvNum, measConvDen, measConvNum } = extractRowData(row, timeframe, pipelineFilter);

  return (leadConvNum + measConvNum) / (leadConvDen + measConvDen);
};

const extractRowData = (row, timeframe, pipelineFilter, isAllPrograms = false) => {
  const programName = isAllPrograms ? `${row.MER_DEPT_CD} - ${row.SUMMARY_PROGRAM_NAME}` : row.SUMMARY_PROGRAM_NAME;

  const leadCount = row[timeframe + '_LEAD_COUNT_' + pipelineFilter] || 0;
  const measCount = row[timeframe + '_MEASURE_COUNT_' + pipelineFilter] || 0;
  const lyLeadCount = row[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] || 0;
  const lyMeasCount = row[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter] || 0;
  const leadConvNum = row[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] || 0;
  const leadConvDen = row[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] || 0;
  const measConvNum = row[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter] || 0;
  const measConvDen = row[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter] || 0;

  return {
    leadCount,
    leadConvDen,
    leadConvNum,
    lyLeadCount,
    lyMeasCount,
    measCount,
    measConvDen,
    measConvNum,
    programName
  };
};
