import { useState } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import { DataTable } from '../../../../../../components/Common/DataTable';
import {
  buildHeaders,
  buildRows,
  buildFooter
} from '../../../../../../components/SpecialtyProRentalContainer/utils/servicesPipelineTable';

export const CoreFocus6PipelineTab = ({ focus6Data = [], timeframe }) => {
  const [pipelineFilter, setPipelineFilter] = useState('TOTAL');

  const filteredData = focus6Data.filter(row => row.TOP_6_CATEGORY <= 6);

  const headers = buildHeaders();
  const rows = buildRows(filteredData, false, pipelineFilter, 'CORE', '1234', timeframe);
  const footer = buildFooter(filteredData, focus6Data, timeframe, true, pipelineFilter);

  const coreFocus6PipelineData = {
    headers,
    data: rows,
    footer,
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title textAlign='center'>Focus 6</DataView.Title>
      <DataView.Controls>
        <select
          name='pipeline filter'
          onChange={e => {
            setPipelineFilter(e.target.value);
          }}
          value={pipelineFilter}
          style={{ padding: 10 }}>
          <option value='TOTAL'>All</option>
          <option value='STR'>Store</option>
          <option value='WEB'>Web</option>
          <option value='SP'>Service Provider</option>
          <option value='OTHER'>Other</option>
        </select>
      </DataView.Controls>
      <DataTable data={coreFocus6PipelineData} />
    </DataView>
  );
};
