import { capitalizeFirstLetter } from '../../../utils/string';
import { getCompareStyle } from '../../Common/TableCellUtils';
import { SPEC_PRO_RENTAL_PATH } from './consts';

const LEADS_VLY_PCT = 'LEADS_VLY_PCT';
const MEAS_VLY_PCT = 'MEAS_VLY_PCT';
const WALK_COMPLETE = 'WALK_COMPLETE';
const TOTAL_WALK = 'TOTAL_WALK';
const WALK_COMPLETE_PCT = 'WALK_COMPLETE_PCT';

export const headers = (level, locNbr) => {
  const params = level.levelName !== 'core' ? { [level.urlParam]: locNbr } : undefined;
  return [
    { name: `${capitalizeFirstLetter(level.urlLinkLevel)} # ` },
    {
      name: 'Lead Comp',
      link: {
        to: `/${level.levelName}/leads`,
        params
      }
    },
    {
      name: `Measure Comp`,
      link: {
        to: `/${level.levelName}/meas`,
        params
      }
    },
    { name: `DOTW Standards` }
  ];
};

export const footerTotal = (keyMetricsData, timeframe) => {
  const LEAD_COMP = `${timeframe}_${LEADS_VLY_PCT}`;
  const MEAS_COMP = `${timeframe}_${MEAS_VLY_PCT}`;
  const WALK_COMP_PCT = `${timeframe}_${WALK_COMPLETE_PCT}`;
  const NRO_ITEMS = keyMetricsData.length;

  return keyMetricsData.reduce(
    (acc, curr) => ({
      [LEAD_COMP]: (acc[LEAD_COMP] || 0) + curr[LEAD_COMP],
      [MEAS_COMP]: (acc[MEAS_COMP] || 0) + curr[MEAS_COMP],
      [WALK_COMP_PCT]: (acc[WALK_COMP_PCT] || 0) + curr[WALK_COMP_PCT],
      NRO_ITEMS
    }),
    {}
  );
};

export const keyMetricsRows = (keyMetricsData, timeframe, level) => {
  const LEAD_COMP = keyMetricsData[`${timeframe}_${LEADS_VLY_PCT}`];
  const MEAS_COMP = keyMetricsData[`${timeframe}_${MEAS_VLY_PCT}`];
  const WALK_COMP = keyMetricsData[`${timeframe}_${WALK_COMPLETE}`];
  const TOTAL_WALK_COMP = keyMetricsData[`${timeframe}_${TOTAL_WALK}`];
  const DOTW_STANDARDS = WALK_COMP / TOTAL_WALK_COMP;

  return [
    {
      stringValue: keyMetricsData[level.propToRender],
      link: {
        to: `/${level.urlLinkLevel}/${SPEC_PRO_RENTAL_PATH}`,
        params: {
          [level.urlSubLevelParam]: keyMetricsData[level.propToRender]
        }
      }
    },
    { pctValue: LEAD_COMP, customStyle: getCompareStyle(LEAD_COMP) },
    { pctValue: MEAS_COMP, customStyle: getCompareStyle(MEAS_COMP) },
    { pctValue: DOTW_STANDARDS, customStyle: getCompareStyle(DOTW_STANDARDS) }
  ];
};

export const keyMetricsFooterRow = (keyMetricsData, timeframe) => {
  const LEAD_COMP = keyMetricsData[`${timeframe}_${LEADS_VLY_PCT}`] / keyMetricsData.NRO_ITEMS;
  const MEAS_COMP = keyMetricsData[`${timeframe}_${MEAS_VLY_PCT}`] / keyMetricsData.NRO_ITEMS;
  const WALK_COMP_PCT = keyMetricsData[`${timeframe}_${WALK_COMPLETE_PCT}`] / keyMetricsData.NRO_ITEMS;

  return [{ stringValue: 'Total' }, { pctValue: LEAD_COMP }, { pctValue: MEAS_COMP }, { pctValue: WALK_COMP_PCT }];
};
