import { Breadcrumb } from '../../Common/Breadcrumb';
import { TabMenu } from '../../Common/TabMenu';
import { DataView } from '../../Common/DataView';
import { ShrinkDeptClassesTab } from '../components/tabs/ShrinkDeptClassesTab';
import { ShrinkDeptSkusTab } from '../components/tabs/ShrinkDeptSkusTab';
import { columnName as COLUMN_NAME } from '../../../utils/constantsByLevel';
import { DEPT_NM } from '../../../utils/constants';
import { getBreadcrumbItems } from '../utils/shrinkDept';
import { CLASSES, SKUS } from '../utils/constants';

const getPanes = ({ shrinkActualsClsDeptData, shrinkActualsSkuDeptData, level, locNbr }) => {
  return [
    {
      menuItem: CLASSES,
      render: () => (
        <ShrinkDeptClassesTab shrinkActualsClsDeptData={shrinkActualsClsDeptData} level={level} locNbr={locNbr} />
      )
    },
    {
      menuItem: SKUS,
      render: () => <ShrinkDeptSkusTab shrinkActualsSkuDeptData={shrinkActualsSkuDeptData} />
    }
  ];
};

export const ShrinkDept = props => {
  const { shrinkActualsClsDeptData, shrinkActualsSkuDeptData, level, locNbr = '', deptNbr } = props;

  const columnName = COLUMN_NAME[level];
  const [{ [DEPT_NM]: deptNm, [columnName]: levelNm }] = shrinkActualsClsDeptData;
  const levelName = Boolean(levelNm) && `, ${levelNm.toLowerCase()}`;

  const breadcrumbItems = getBreadcrumbItems({ level, locNbr, deptNbr, deptNm });
  const panes = getPanes({ shrinkActualsClsDeptData, shrinkActualsSkuDeptData, level, locNbr });

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='my-3'>
        <DataView.Title>
          {deptNbr} - {deptNm} - {level} {locNbr} {levelName}
        </DataView.Title>

        <TabMenu panes={panes} />
      </DataView>
    </>
  );
};
