import { useState } from 'react';
import { Header, Loader, Dropdown } from 'semantic-ui-react';
import { Breadcrumb } from '../Common/Breadcrumb';
import { DataView } from '../Common/DataView';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { getCalloutStyle, getDollarString } from '../Common/TableCellUtils';
import { UnitMenu } from '../Common/UnitMenu';
import { timeframeCode } from '../Common/timeframeCode';
import { SkuDetailsModal } from '../Sku/SkuDetailsModal';
import { useQueryParams } from '../../hooks/useQueryParams';
import useQueryApi from '../../hooks/useQueryApi';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { formatSku } from '../../utils/formatter';
import { capitalizeFirstLetter } from '../../utils/string';

const unitMap = { NOM: '#', PCT: '%' };
const unitMapDollar = { NOM: '$', PCT: '%' };

const QUERIES = {
  store: 'querySlsSkuByStrScls',
  district: 'querySlsSkuByDstScls',
  region: 'querySlsSkuByRgnScls',
  division: 'querySlsSkuByDivScls'
};

// breadcrumb

const getBreadcrumbItems = params => {
  const { levelName, subUrlParam, locNbr, deptNbr, deptNm, classNbr, classNm, subClassNm } = params;

  return [
    { to: `/${levelName}/overview?${subUrlParam}=${locNbr}`, text: 'Overview' },
    { to: `/${levelName}/sales/dept?${subUrlParam}=${locNbr}&deptNbr=${deptNbr}`, text: deptNm },
    { to: `/${levelName}/sales/class?${subUrlParam}=${locNbr}&classNbr=${classNbr}`, text: classNm },
    { text: subClassNm }
  ];
};

// DataTable

const getSubclassDrilldownTableHeadersByMetric = ({ metric, unit }) => {
  switch (metric) {
    case 'SLS':
      return [{ name: 'Sales Amt ($)' }, { name: `Sales vLY ${unitMapDollar[unit]}` }];
    case 'UNT':
      return [{ name: `Unit Sales #` }, { name: `Unit vLY ${unitMap[unit]}` }];
    case 'TXN':
      return [{ name: 'Trans Cnt #' }, { name: `Trans vLY ${unitMap[unit]}` }];
    default:
      return [{ name: 'Avg Ticket ($)' }, { name: `Avg Ticket vLY ${unitMapDollar[unit]}` }];
  }
};

const getSubclassDrilldownTableHeaders = params => {
  const { metric, unit } = params;
  return [{ name: 'SKU ' }, { name: 'SKU Description ' }, ...getSubclassDrilldownTableHeadersByMetric({ metric, unit })];
};

const propsByMetric = {
  SLS: ['TY_SLS_AMT', 'SLS_COMP_NOM', 'SLS_COMP_PCT'],
  UNT: ['TY_UNT_SLS', 'UNT_COMP_NOM', 'UNT_COMP_PCT'],
  TXN: ['TY_TXN_CNT', 'TXN_COMP_NOM', 'TXN_COMP_PCT'],
  AVG_TCKT: ['TY_AVG_TCKT', 'AVG_TCKT_COMP_NOM', 'AVG_TCKT_COMP_PCT']
};

const getSubclassDrilldownTableData = params => {
  const { data, setModalData, metric, unit } = params;

  return data.map(row => {
    return [
      {
        customStyle: { color: '#EE7125', cursor: 'pointer', textDecoration: 'underline' },
        stringValue: formatSku(row['SKU_NBR']),
        onClick: () => setModalData(row),
        orderBy: Number(row['SKU_NBR'])
      },
      {
        stringValue: row['SKU_DESC']
      },
      metric === 'SLS' || metric === 'AVG_TCKT'
        ? {
            decimals: 2,
            dollarValue: metric === 'SLS' || metric === 'AVG_TCKT' ? row[propsByMetric[metric][0]] : undefined,
            customStyle: getCalloutStyle(row[propsByMetric[metric][0]])
          }
        : {
            numValue: metric === 'TXN' || metric === 'UNT' ? row[propsByMetric[metric][0]] : undefined,
            customStyle: getCalloutStyle(row[propsByMetric[metric][0]])
          },
      unit === 'NOM'
        ? {
            stringValue:
              metric === 'SLS' || metric === 'AVG_TCKT'
                ? getDollarString(row[propsByMetric[metric][1]], 2)
                : row[propsByMetric[metric][1]],
            compareStyle: true,
            orderBy: Number(row[propsByMetric[metric][1]])
          }
        : {
            decimals: 2,
            pctValue: row[propsByMetric[metric][2]],
            compareStyle: true,
            orderBy: Number(row[propsByMetric[metric][2]])
          }
    ];
  });
};

const getSubclassDrilldownDataTable = params => {
  const { metric, unit, data, setModalData } = params;

  return {
    headers: getSubclassDrilldownTableHeaders({ metric, unit }),
    data: getSubclassDrilldownTableData({ data, setModalData, metric, unit }),
    sortable: true,
    sortableDefault: 0
  };
};

export const SubclassDrilldown = props => {
  const { timeframe, settimeframe, level, fpToggle } = props;
  const [metric, setMetric] = useState('SLS');
  const [unit, setUnit] = useState('NOM');
  const [modalData, setModalData] = useState(null);
  const {
    levelName,
    subLevel: { urlParam: subUrlParam }
  } = METRIC_CONSTANTS[level];
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0, subClassNbr = 0 } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr || 0;
  const { data, isLoading } = useQueryApi(QUERIES[levelName], { locNbr, subClassNbr, fpToggle });
  const { currentLevelName, isLoadingName } = useFetchLevelName({ level: levelName, locNbr, fpToggle });

  if (isLoading || isLoadingName) {
    return <Loader active>Loading...</Loader>;
  }

  const deptNbr = subClassNbr.toString().substring(1, 3);
  const deptNm = data.length > 0 ? `${deptNbr}-${data[0]['DEPT_NM']}` : deptNbr;
  const classNbr = subClassNbr.toString().substring(0, 7);
  const classNm = data.length > 0 ? `${classNbr}-${data[0]['CLASS_DESC']}` : classNbr;
  const subClassNm = data.length > 0 ? `${subClassNbr}-${data[0]['SUB_CLASS_DESC']}` : subClassNbr;

  const breadcrumbItems = getBreadcrumbItems({
    levelName,
    subUrlParam,
    locNbr,
    deptNbr,
    deptNm,
    classNbr,
    classNm,
    subClassNm
  });

  const allSkus = data.filter(row => {
    return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe];
  });

  const subclassDrilldownDataTable = getSubclassDrilldownDataTable({ metric, unit, data: allSkus, setModalData });

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <DataView className='my-3'>
        <DataView.Controls className='d-flex'>
          <div style={{ width: '50%' }}>
            <span style={{ display: 'block' }}>Metric:</span>
            <Dropdown
              defaultValue={metric}
              selection
              scrolling
              compact
              size='small'
              options={[
                { key: 'SLS', text: 'Sales', value: 'SLS' },
                { key: 'UNT', text: 'Units', value: 'UNT' },
                { key: 'TXN', text: 'Trans', value: 'TXN' },
                { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' }
              ]}
              onChange={(_, { value }) => {
                setMetric(value);
              }}
            />
          </div>
          <div style={{ width: '50%' }}>
            <span style={{ display: 'block' }}>Timeframe:</span>
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </div>
        </DataView.Controls>

        <DataView.Title>
          Overview - Sales - Skus - {capitalizeFirstLetter(levelName)} {locNbr}, {currentLevelName}
          <Header.Subheader>Data as of {allSkus[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls>
          <span style={{ display: 'block' }}>Units:</span>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </DataView.Controls>

        <DataView.DataTable data={subclassDrilldownDataTable} />
      </DataView>

      <SkuDetailsModal isOpen={!!modalData} onClose={() => setModalData(null)} skuData={modalData} />
    </>
  );
};
