import React, { useContext, useState } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';

export const Curbside = () => {
  const { assocOnTimePickupData } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const showCustomTimeframe = ['# Missed SLA']

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Pick On Time' },
            { name: '# Orders Missed' },
            { name: 'Avg Mins of Pick' },
            { name: 'DS Compliance %' }
          ],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.TOTAL_ORDERS },
            { numValue: data.MISSED_SLA },
            { numValue: data.MINS_TO_PICK },
            { pctValue: data.DS_COMPLIANCE }
          ])
        };
      case '# Missed SLA':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Order Date' },
            { name: 'Avg Customer Wait Time' },
          ],
          data: assocOnTimePickupData.map(data => [
            { stringValue: formatAssociateName(data.ASSOCIATE) },
            { numValue: data.WC_ORDER_NOM },
            { numValue: data.WC_ORDER_DATE },
            { numValue: data.CS_COSTUMER_WAIT_TIME },
          ])
        }
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' },
        ]}
      />
      <DataTable
        data={getData()}
      />
    </>
  );
};
