import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Tab } from 'semantic-ui-react';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { getThousandDollarString } from '../../../../../../../components/Common/TableCellUtils';
import { UnitMenu } from '../../../../../../../components/Common/UnitMenu';
import { useCoreOverviewContext } from '../../../../../hooks/useCoreOverviewContext';

export const InventoryCoreTab = () => {
  const { InvDepByCore } = useCoreOverviewContext();
  const [unit, setUnit] = useState('NOM');
  const isNOM = unit === 'NOM';
  const isPCT = unit === 'PCT';

  const dataObj = {
    data: InvDepByCore.map(row => [
      {
        link: {
          to: `/core/inventory/dept?deptNbr=${row['DEPT_NBR']}`
        },
        stringValue: row['DEPT_NM']
      },
      {
        stringValue: getThousandDollarString(row['INV_AMT']),
        orderBy: row['INV_AMT']
      },
      {
        compareStyle: true,
        stringValue: isNOM ? getThousandDollarString(row['INV_VP']) : undefined,
        pctValue: isPCT ? row['INV_VP_PCT'] : undefined,
        orderBy: isNOM ? row['INV_VP'] : row['INV_VP_PCT']
      },
      {
        stringValue: getThousandDollarString(row['ASW']),
        orderBy: row['ASW']
      },
      {
        decimals: 1,
        numValue: row['WOS'],
        orderBy: row['WOS']
      },
      {
        stringValue: getThousandDollarString(row['ICE_INV_AMT']),
        orderBy: row['ICE_INV_AMT']
      }
    ]),
    headers: [
      { name: 'Dept ' },
      { name: 'Inv $ ' },
      { name: `vP ${isPCT ? '% ' : ''}` },
      { name: 'ASW ' },
      { name: 'WOS ' },
      { name: 'ICE $ ' }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Column>

        <Grid.Column style={{ alignItems: 'center', display: 'flex' }} width={8}>
          <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/core/shrink`}>
            Click for Shrink Metrics
          </Link>
        </Grid.Column>
      </Grid>

      <Grid style={{ paddingBottom: '1rem' }}>
        <Grid.Column>
          <Grid.Row>
            <DataTable data={dataObj} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
};
