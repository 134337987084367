import { useQueryParams } from '../../../hooks/useQueryParams';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { Breadcrumb } from '../../Common/Breadcrumb';
import { buildBaseShrinkUrl } from '../utils/url';
import { getParamByLevel } from '../../../utils/timeFrameUtils';
import { TabMenu } from '../../Common/TabMenu';
import { Header, Loader } from 'semantic-ui-react';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { ShrinkSubClassesTab } from '../components/tabs/ShrinkSubClassesTab';
import { ShrinkSkusTab } from '../components/tabs/ShrinkSkusTab';
import { useFetchLevelName } from '../../../hooks/useFetchLevelName';

export const ShrinkClassPage = () => {
  const { fpToggle } = useShrinkContext();
  const { classNbr } = useQueryParams();
  const {
    isError,
    isLoading,
    level,
    locNbr,
    results: [queryShrinkSclsCls]
  } = useShrinkQueries({ fpToggle, queries: ['query/queryShrinkSclsCls', 'query/queryShrinkSkuByCls'] });
  const { currentLevelName, isLoadingName } = useFetchLevelName({ level, locNbr, fpToggle });

  if (isLoading || isLoadingName) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const classDesc = queryShrinkSclsCls.data?.[0]?.CLASS_DESC;
  const deptName = queryShrinkSclsCls.data?.[0]?.DEPT_NM;
  const deptNbr = queryShrinkSclsCls.data?.[0]?.DEPT_NBR;
  const levelParam = getParamByLevel(level);
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);

  const breadcrumbItems = [
    { text: 'Shrink', to: `/${baseLevelShrinkUrl}?${levelParam}=${locNbr}` },
    { text: `${deptNbr}-${deptName}`, to: `/${baseLevelShrinkUrl}/dept?${levelParam}=${locNbr}&deptNbr=${deptNbr}` },
    { text: `${classNbr}-${classDesc}` }
  ];

  const panes = [
    {
      menuItem: 'SUBCLASSES',
      render: () => <ShrinkSubClassesTab />
    },
    {
      menuItem: 'SKUS',
      render: () => <ShrinkSkusTab />
    }
  ];

  return (
    <section>
      <Breadcrumb items={breadcrumbItems} />

      <Header className='text-capitalize'>
        {classNbr} - {classDesc?.toLowerCase()}, {level} {locNbr}, {currentLevelName}
      </Header>

      <TabMenu panes={panes} />
    </section>
  );
};
