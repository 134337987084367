import { formatSku } from '../../utils/formatter';
import { iconsByUnitDollar, iconsByUnitHashtag } from '../../utils/timeFrameUtils';
import { DataView } from '../Common/DataView';
import { Grid } from 'semantic-ui-react';
import { UnitMenu } from '../Common/UnitMenu';
import { useMemo, useState } from 'react';
import { SkuDetailsModal } from '../Sku/SkuDetailsModal';

export const SkuTable = ({ metric, skuList }) => {
  const [unit, setUnit] = useState('NOM');
  const [modalData, setModalData] = useState(null);
  const isModalOpen = !!modalData;

  const dataObj = useMemo(
    () => ({
      headers: headersByMetric(metric, unit),
      data: skuList.map(data => [
        {
          onClick: () => setModalData(data),
          stringValue: formatSku(data['SKU_NBR']),
          customStyle: { color: '#EE7125', textDecoration: 'underline' }
        },
        {
          stringValue: data['SKU_DESC']
        },
        ...getColumnsByMetric(data, metric, unit)
      ]),
      pagination: true,
      sortable: true,
      sortableDefault: 0
    }),
    [metric, unit, skuList]
  );

  const onCloseModal = () => setModalData(null);

  return (
    <>
      <DataView>
        <DataView.Controls>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} textAlign='justified'>
                <Grid.Row>Units:</Grid.Row>
                <UnitMenu unit={unit} setUnit={setUnit} />
              </Grid.Column>
              <Grid.Column width={8} />
            </Grid.Row>
          </Grid>
        </DataView.Controls>
        <DataView.DataTable data={dataObj} />
      </DataView>

      <SkuDetailsModal isOpen={isModalOpen} onClose={onCloseModal} skuData={modalData} />
    </>
  );
};

const headersByMetric = (metric, unit) => {
  switch (metric) {
    case 'SLS':
      return [
        { name: 'SKU ' },
        { name: 'SKU Description ' },
        { name: 'Sales Amt ($) ' },
        { name: `Sales vLY ${iconsByUnitDollar[unit]} ` }
      ];
    case 'UNT':
      return [
        { name: 'SKU ' },
        { name: 'SKU Description ' },
        { name: 'Unit Sales (#) ' },
        { name: `Units vLY ${iconsByUnitHashtag[unit]} ` }
      ];
    case 'TXN':
      return [
        { name: 'SKU ' },
        { name: 'SKU Description ' },
        { name: 'Trans Cnt (#) ' },
        { name: `Trans vLY ${iconsByUnitHashtag[unit]} ` }
      ];
    case 'AVG_TCKT':
      return [
        { name: 'SKU ' },
        { name: 'SKU Description ' },
        { name: 'Avg Ticket ($) ' },
        { name: `Avg Ticket vLY ${iconsByUnitDollar[unit]} ` }
      ];
    default:
      return [];
  }
};

function getColumnsByMetric(data, metric, unit) {
  switch (metric) {
    case 'SLS':
      return [
        {
          dollarValue: data['TY_SLS_AMT'],
          getCalloutStyle: data['TY_SLS_AMT']
        },
        {
          compareStyle: true,
          pctValue: unit === 'PCT' ? data['SLS_COMP_PCT'] : undefined,
          dollarValue: unit === 'NOM' ? data['SLS_COMP_NOM'] : undefined
        }
      ];
    case 'UNT':
      return [
        {
          stringValue: parseFloat(data['TY_UNT_SLS']),
          getCalloutStyle: data['TY_UNT_SLS']
        },
        {
          compareStyle: true,
          pctValue: unit === 'PCT' ? data['UNT_COMP_PCT'] : undefined,
          stringValue: unit === 'NOM' ? parseFloat(data['UNT_COMP_NOM']) : undefined
        }
      ];
    case 'TXN':
      return [
        {
          numValue: data['TY_TXN_CNT'],
          getCalloutStyle: data['TY_TXN_CNT']
        },
        {
          compareStyle: true,
          pctValue: unit === 'PCT' ? data['TXN_COMP_PCT'] : undefined,
          numValue: unit === 'NOM' ? data['TXN_COMP_NOM'] : undefined
        }
      ];
    case 'AVG_TCKT':
      return [
        {
          dollarValue: data['TY_AVG_TCKT'],
          getCalloutStyle: data['TY_AVG_TCKT']
        },
        {
          compareStyle: true,
          pctValue: unit === 'PCT' ? data['AVG_TCKT_COMP_PCT'] : undefined,
          dollarValue: unit === 'NOM' ? data['AVG_TCKT_COMP_NOM'] : undefined
        }
      ];
    default:
      return [];
  }
}
