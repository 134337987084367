
// This style is for comparison values, usually vPlan or vLY. 
// If it's above the target (positive), color green. Below target (negative) is red, and matching is black.
export const getCompareStyle = (value) => {
    return { color: value == null ? 'black' : value > 0 ? 'green' : value < 0 ? 'red' : 'black' }
}

export const getSmdCompareStyle = (value) => {
    return { color: value == null ? 'black' : value > 0 ? 'red' : value < 0 ? 'green' : 'black' }
}

// This style is for calling out bad values. Generally for Sales or similar metrics, getting less than 0 is bad.
export const getCalloutStyle = (value) => {
    return { color: value == null ? 'black' : value < 0 ? 'red' : 'black' }
}

export const formatColor = (value, valueToCompare) => ({
    color: value >= valueToCompare ? 'green' : 'red'
});  

// Returns a string for dollar values.
// 0 decimal places, and negative numebers have the - sign in front of the $ sigh



// export const getProjectedOverTime=(hours)=>{
//     const hr=hours.toFixed(2)
//     const roundedHours=Math.floor(hr);
//     const minutes=Math.round((hr-roundedHours)*60)
//     return `${hr} hrs /${roundedHours} hrs and ${minutes} min`

// }
export const getHoursToHrsAndMin =(hours)=>{
    if(!hours){
        return `-`
    }
    const hr=hours.toFixed(2)
    const roundedHours=Math.floor(hr);
    const minutes=Math.round((hr-roundedHours)*60)
    return `${hr} hrs /${roundedHours} hrs and ${minutes} min`
}
export const getRoundToDecimalString = (value, decimals) => {
    let decimalPlaces = 0
    if (decimals) {
        decimalPlaces = decimals
    }
    if (value == null) {
        return '-'
    } 
        return  value.toLocaleString(undefined, { maximumFractionDigits: decimalPlaces })
    
}

export const getDollarString = (value, decimals) => {
    let decimalPlaces = 0
    if (decimals) {
        decimalPlaces = decimals
    }
    if (value == null) {
        return '--'
    } else {
        return value.toLocaleString(undefined, { style: 'currency', currency: 'USD', maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces });
    }
}

// Returns a string for dollar values rounded to the nearest thousand dolars.
// 0 decimal places, and negative numebers have the - sign in front of the $ sigh
export const getThousandDollarString = (value, decimals) => {
    let decimalPlaces = 0
    if (decimals) {
        decimalPlaces = decimals
    }
    if (value == null) {
        return '--'
    } else if (value >= 1000) {
        return '$' + (value / 1000).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces }) + 'k'
    } else if (value >= 0) {
        return '$' + value.toLocaleString(undefined, { maximumFractionDigits: 0 })
    } else if (value > -1000) {
        return '-$' + (-1 * value).toLocaleString(undefined, { maximumFractionDigits: 0 })
    } else {
        return '-$' + (-1 * value / 1000).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces }) + 'k'
    }
}

// Percent values are 0-1.0, so this multiplies them by 100, adds a % symbol, and limits them to 1 (or configurable) decimal place
export const getPercentString = (value, decimals, minDecimals) => {
    let decimalPlaces = 1
    let minDecimalPlaces = 0
    if (decimals !== undefined) {
        decimalPlaces = decimals
    }
    if (minDecimals) {
        minDecimalPlaces = minDecimals
    }
    if (value == null || isNaN(value) || value === Infinity || value === -Infinity) {
        return '--'
    } else {
        return (100 * value).toLocaleString(undefined, { minimumFractionDigits: minDecimalPlaces, maximumFractionDigits: decimalPlaces }) + '%'
    }
}

export const getAdjustableDollarString = (value, decimals) => {
    let decimalPlaces = 0
    if (decimals) {
        decimalPlaces = decimals
    }

    if (value == null) {
        return '--'
    } else if (value >= 1000000000){ // Over 1 billion
        return '$' + (value / 1000000000).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces }) + 'B'
    } else if (value >= 1000000){ // Over 1 million
        return '$' + (value / 1000000).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces }) + 'M'
    } else if (value >= 1000 )  { // Over 1 thousand
        return '$' + (value / 1000).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces}) + 'K'
    }else if (value >= 0) { // Over 0
        return '$' + value.toLocaleString(undefined, { maximumFractionDigits: 2 })
        // Because negative numbers flip directions, we now divide by one tier up. So if > 1 million, divide by 1 thousand, etc.
    }else if (value > -1000) { // Over negative 1 thousand
        return '-$' + (-1 * value).toLocaleString(undefined, { maximumFractionDigits: 2})  
     } else if (value > -1000000) { // Over negative 1 million
        return '-$' + (-1 * value / 1000).toLocaleString(undefined, { maximumFractionDigits:decimalPlaces }) + 'K'   
    } else if (value > -1000000000 ) { // Over negative 1 billion
        return '-$' + (-1 * value / 1000000).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces }) + 'M'
    }  else { // assume under negative 1 billion
        return '-$' + (-1 * value / 1000000000).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces }) + 'B'
    }  
}

export const getBpsString = (value) => {

    if (value == null) {
        return '-'
    } else if (value >= 0) {
        return (10000 * value).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
    } else {
        return '(' + (-10000 * value).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps)'
    }
}