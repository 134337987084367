import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';

export const ClearanceNoHomesCoreTable = ({ dataAsOf, dataObject }) => {
  return (
    <DataView className='mt-4'>
      <DataView.Title>
        Clearance No-Homes - Core
        <Header.Subheader>Data as Of {dataAsOf}</Header.Subheader>
      </DataView.Title>
      <DataView.DataTable data={dataObject} />
    </DataView>
  );
};
