import { createContext, useState } from 'react';

export const LaborContext = createContext();

const LaborContextProvider = ({ children, timeframe, settimeframe, fpToggle, userEmplCtgryNm, level }) => {
  const [days, setDays] = useState('Total');

  const value = {
    days,
    setDays,
    timeframe,
    settimeframe,
    fpToggle,
    isHourlyEmployee: userEmplCtgryNm.toUpperCase().includes('HOURLY'),
    level
  };

  return <LaborContext.Provider value={value}>{children}</LaborContext.Provider>;
};

export default LaborContextProvider;
