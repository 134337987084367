import { Header, Loader } from 'semantic-ui-react';
import { useShrinkDeptQueries } from '../hooks/useShrinkDeptQueries';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { ShrinkDept } from '../../../../../components/ShrinkContainer/components/ShrinkDept';
import { CORE } from '../../../../../utils/constantsByLevel';

const { levelName: level } = CORE;

export const CoreShrinkDeptPage = props => {
  const { fpToggle } = props;
  const { deptNbr = 0 } = useQueryParams();

  const { isError, isLoading, shrinkActualsClsDeptData, shrinkActualsSkuDeptData } = useShrinkDeptQueries({
    level,
    deptNbr,
    fpToggle
  });

  if (isError) return <Header textAlign='center'>Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;
  if (!shrinkActualsClsDeptData.length) return <Header textAlign='center'>Invalid Store Number</Header>;

  return (
    <ShrinkDept
      shrinkActualsClsDeptData={shrinkActualsClsDeptData}
      shrinkActualsSkuDeptData={shrinkActualsSkuDeptData}
      level={level}
      deptNbr={deptNbr}
    />
  );
};
