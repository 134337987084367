import { Grid, Header, Loader, Segment, Tab } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../../../../components/Common/TimeframeDropdown';
import { useFetchLevelName } from '../../../../../hooks/useFetchLevelName';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import StoreOrderFulfillmentContextProvider from '../../../context/StoreOrderFulfillmentContext';
import { Complete } from '../components/complete/Complete';
import { OnTime } from '../components/ontime/OnTime';
import Summary from '../components/Summary';

export const StoreOrderFulfillmentPage = ({ timeframe, settimeframe, fpToggle, ...props }) => {
  const { strNbr = 0 } = useQueryParams();
  const { currentLevelName, isLoadingName} = useFetchLevelName({ fpToggle, level: 'store', locNbr: strNbr })

  const date = '2021-01-01';

  const panes = [
      {
          menuItem: 'ON TIME',
          render: () => <OnTime />
      },
      {
          menuItem: 'COMPLETE',
          render: () => <Complete />
      }
  ];

  if (isLoadingName) {
    return <Loader active>Loading...</Loader>;
  }

  return (
    <StoreOrderFulfillmentContextProvider {...props}>
      <Segment fluid>
        <Header textAlign='left' style={{ marginTop: '1rem' }}>
          Order Fulfillment - Store {strNbr}, {currentLevelName}
          <Header.Subheader>Data as of {date}</Header.Subheader>
        </Header>

        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              Timeframe:
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Summary */}
        <Summary />

        {/* On Time and Complete Tabs component. This might hold all the other components?*/}
        {/* If we structure everything to be in the On Time and Complete components, then the rest of this would be in those */}
        <Tab menu={{ secondary: true, pointing: true, color: 'orange' }} panes={panes} />

        {/* ON TIME */}
        {/* Order Picked on Time header */}
        {/* PICKUP DELIVERY tabs */}

        {/* PICKUP */}
        {/* Pickup Details by Method */}
        {/* Pickup Details for Curbside */}
        {/* More Details */}
        
        
        
      </Segment>
    </StoreOrderFulfillmentContextProvider>
  );
};
