import classNames from "classnames";

export const Select = ({ label, name, onChange, value, items, className }) => {
  const classes = classNames('select-section', className);
  return (
    <section className={classes}>
      <label>{label}</label>
      <select name={name} onChange={event => onChange(event.target.value)} value={value} style={{ padding: 10 }}>
        {items.map(({ value, text }, index) => (
          <option key={index} value={value}>
            {text}
          </option>
        ))}
      </select>
    </section>
  );
};
