import { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { coreSkuDrillBuilder } from '../utils/coreSkuDrillBuilder';
import { DataView } from '../../../../../components/Common/DataView';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { SkuDetailsModal } from '../../../../../components/Sku/SkuDetailsModal';

export const CoreSkuDrill = ({ data }) => {
  const [unit, setUnit] = useState('NOM');
  const [modalData, setModalData] = useState(null);
  const isUnitNom = unit === 'NOM';

  const dataTableData = coreSkuDrillBuilder(data, isUnitNom, unit, setModalData);

  return (
    <Tab.Pane attached={false}>
      <DataView>
        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </DataView.Controls>

        <DataView.DataTable data={dataTableData} />

        <SkuDetailsModal isOpen={!!modalData} onClose={() => setModalData(null)} skuData={modalData} />
      </DataView>
    </Tab.Pane>
  );
};
