import { useMemo, useState } from 'react';
import { Dropdown, Tab } from 'semantic-ui-react';
import { uniqueValues } from '../../../../../utils/array';
import { formatOptions } from '../../../../../utils/dropdown';
import { DataView } from '../../../../../components/Common/DataView';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { coreClassesSummaryBuilder } from '../utils/coreClassesSummaryBuilder';

export const CoreClassesSummary = ({ classesData = [] }) => {
  const [unit, setUnit] = useState('NOM');
  const [deptNbr, setDeptNbr] = useState('All');
  const isUnitNom = unit === 'NOM';

  const departmentOptions = useMemo(() => {
    const departmentNumbers = uniqueValues(classesData?.map(dept => dept.DEPT_NBR)).sort();
    return formatOptions(['All', ...departmentNumbers]);
  }, [classesData]);

  const dataTableData = useMemo(
    () => coreClassesSummaryBuilder(classesData, isUnitNom, unit, deptNbr),
    [classesData, isUnitNom, unit, deptNbr]
  );

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Title textAlign='center'>Classes Summary</DataView.Title>

        <DataView.Controls>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
            <UnitMenu unit={unit} setUnit={setUnit} />
            <Dropdown
              compact
              selection
              value={deptNbr}
              className='mx-3'
              placeholder='Dept'
              options={departmentOptions}
              onChange={(_, { value }) => setDeptNbr(value)}
            />
          </div>
        </DataView.Controls>

        <DataView.DataTable data={dataTableData} />
      </DataView>
    </Tab.Pane>
  );
};
