import { Tab } from 'semantic-ui-react';
import { useMemo, useState } from 'react';
import { DataView } from '../../../../../components/Common/DataView';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { coreSubClassesDrillBuilder } from '../utils/coreSubClassesDrillBuilder';

export const CoreSubClassesDrill = ({ data }) => {
  const [unit, setUnit] = useState('NOM');
  const isUnitNom = unit === 'NOM';

  const dataTableData = useMemo(() => coreSubClassesDrillBuilder(data, isUnitNom, unit), [data, isUnitNom, unit]);

  return (
    <Tab.Pane attached={false}>
      <DataView>
        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </DataView.Controls>

        <DataView.DataTable data={dataTableData} />
      </DataView>
    </Tab.Pane>
  );
};
