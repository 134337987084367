import { useMemo, useState } from 'react';
import { NOM } from '../../../../../utils/constants';
import { Grid, Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { DataView } from '../../../../../components/Common/DataView';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { UnitMenu } from '../../../../../components/Common/UnitMenu';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { Breadcrumb } from '../../../../../components/Common/Breadcrumb';
import { SkuDetailsModal } from '../../../../../components/Sku/SkuDetailsModal';
import { coreShrinkSubClassPageBuilder } from '../utils/coreShrinkSubClassPageBuilder';
import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';

export const CoreShrinkSubClassPage = () => {
  const [unit, setUnit] = useState(NOM);
  const { fpToggle } = useShrinkCoreContext();
  const [modalData, setModalData] = useState(null);
  const isUnitNom = unit === NOM;
  const { subClassNbr = 0 } = useQueryParams();

  const { data, isLoading, isError } = useQueryApi('queryShrinkSubclassToSkuDrillAtCore', {
    subClassNbr,
    fpToggle,
    isCore: true
  });

  const dataTableData = useMemo(
    () => coreShrinkSubClassPageBuilder(data, unit, isUnitNom, setModalData),
    [data, unit, isUnitNom]
  );

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong getting SKU data</Header>;

  const subClassDesc = data[0]?.SUB_CLASS_DESC;
  const deptNbr = subClassNbr.toString().substring(1, 3); // TODO: Get DEPT_NBR from data
  const classNbr = subClassNbr.toString().substring(0, 7); // TODO: Get CLASS_NBR from data
  const deptNm = data.length > 0 ? data[0]?.DEPT_NM : deptNbr;
  const classDesc = data.length > 0 ? data[0]?.CLASS_DESC : classNbr;
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');

  const breadcrumbItems = [
    { to: `/${baseLevelShrinkUrl}`, text: 'Shrink' },
    { to: `/${baseLevelShrinkUrl}/dept?deptNbr=${deptNbr}`, text: `${deptNbr}-${deptNm}` },
    { to: `/${baseLevelShrinkUrl}/class?classNbr=${classNbr}`, text: `${classNbr}-${classDesc}` },
    { text: `${subClassNbr}-${subClassDesc}` }
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='mb-4'>
        <DataView.Title>
          {subClassNbr}-{subClassDesc}
        </DataView.Title>
        <DataView.Controls>
          <Grid.Row>Units:</Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </DataView.Controls>

        <DataView.DataTable data={dataTableData} />
      </DataView>

      <SkuDetailsModal isOpen={!!modalData} onClose={() => setModalData(null)} skuData={modalData} />
    </>
  );
};
