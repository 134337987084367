import { useContext, useState } from "react";
import { DataView } from "../../../../../../components/Common/DataView";
import { OrderFulfillmentContext } from "../../../../context/OrderFulfillmentContext";
import { Dropdown } from "semantic-ui-react";
import { METRIC_CONSTANTS } from "../../../../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from "../../../../../../utils/string";
import { getPercentString } from "../../../../../../components/Common/TableCellUtils";

export const CompleteDelivery = () => {

  const { orderFulfillmentOnTimePickupData, level, timeframe } = useContext(OrderFulfillmentContext)

  const [view, setView] = useState('Box')
  const getData = () => {
    switch (view) {
      case 'Total':
      case 'Van':
      case 'Other':
      case 'Flatbed':
      case 'Car':
      case 'Box':
        return {
          sortable: true,
          headers: [
            { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + " # " },
            { name: 'Fulfillment %' },
            { name: 'Push %' },
            { name: 'Truck Changes' },
            { name: 'Unfulfilled Units' },
          ],
          data: orderFulfillmentOnTimePickupData.BOSS.map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            {
              stringValue: getPercentString(data[timeframe + '_PCT_ON_TM']),
              orderBy: data[timeframe + '_PCT_ON_TM']
            },
            {
              numValue: data[timeframe + '_TRANS_CNT'],
              orderBy: data[timeframe + '_TRANS_CNT']
            },
            {
              numValue: data[timeframe + '_OVER_SLA_CNT'],
              orderBy: data[timeframe + '_OVER_SLA_CNT']
            }
          ])
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <DataView className='mt-2'>
      <DataView.Controls>
        <div>Select Truck Type</div>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={[
            { key: 'Car', text: 'Car', value: 'Car' },
            { key: 'Flatbed', text: 'Flatbed', value: 'Flatbed' },
            { key: 'Van', text: 'Van', value: 'Van' },
            { key: 'Other', text: 'Other', value: 'Other' },
            { key: 'Total', text: 'Total', value: 'Total' },
            { key: 'Box', text: 'Box', value: 'Box' },
          ]}
          onChange={(_, { value }) => {
            setView(value);
          }}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Delivery Details by {view}</DataView.Title>
      <DataView.DataTable data={getData()} />
    </DataView>
  );
}