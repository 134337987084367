import { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { UnitMenu } from '../../../Common/UnitMenu';
import { DataTable } from '../../../Common/DataTable';
import { DEPARTMENTS_HEADER_TABS, departmentsSummaryRowBuilder } from '../../utils/overview';
import { DataView } from '../../../Common/DataView';
import { useShrinkContext } from '../../hooks/useShrinkContext';
import { useShrinkQueries } from '../../hooks/useShrinkQueries';
import { METRIC_CONSTANTS } from '../../../../utils/constantsByLevel';

export const DepartmentsSummaryTab = () => {
  const [unit, setUnit] = useState('NOM');
  const { fpToggle } = useShrinkContext();
  const { results, level } = useShrinkQueries({
    fpToggle,
    queries: ['query/queryShrinkActualsDept']
  });
  const [queryShrinkActualsDept] = results;
  const newLevel = METRIC_CONSTANTS[level];

  const dataTable = {
    sortable: true,
    sortableDefault: 0,
    direction: 'ascending',
    headers: DEPARTMENTS_HEADER_TABS(unit),
    data:
      queryShrinkActualsDept?.data
        .filter(row => row.DEPT_NBR)
        .map(row => departmentsSummaryRowBuilder(row, unit, newLevel)) || []
  };

  return (
    <Tab.Pane className='mt-3'>
      <DataView>
        <DataView.Title className='mt-4' position='center'>
          Department Summary
        </DataView.Title>
        <p>Units:</p>
        <UnitMenu unit={unit} setUnit={setUnit} />
        <DataTable data={dataTable} />
      </DataView>
    </Tab.Pane>
  );
};
