import useQueryApi from '../../../hooks/useQueryApi';
import { CORE } from '../../../utils/constantsByLevel';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';

// NOTE: At the moment we don't have all the columns for this queries, so, we are not using this yet but the implementation is done

const { levelName: coreLevelName } = CORE;

export const useSpecialtyProRentalQueries = () => {
  const { fpToggle, level, locNbr } = useSpecialtyProRentalContext();
  const { levelName } = level;
  const queryParamsByLevel = levelName === coreLevelName ? { isCore: true } : { locNbr };
  const queryParams = { ...queryParamsByLevel, fpToggle };

  const {
    data: quotesAggData,
    isLoading: quotesAggIsLoading,
    isError: quotesAggIsError
  } = useQueryApi(`query/queryQuotesAgg/${levelName}`, queryParams);

  const {
    data: proSpecTrSlsData,
    isLoading: proSpecTrSlsIsLoading,
    isError: proSpecTrSlsIsError
  } = useQueryApi(`query/queryProSpecTrSls/${levelName}`, queryParams);

  const {
    data: proSpecTrLbrData,
    isLoading: proSpecTrLbrIsLoading,
    isError: proSpecTrLbrIsError
  } = useQueryApi(`query/queryProSpecTrLbr/${levelName}`, queryParams);

  const {
    data: queryProSpecTrLbrPlusOneData,
    isLoading: queryProSpecTrLbrPlusOneIsLoading,
    isError: queryProSpecTrLbrPlusOneIsError
  } = useQueryApi(`query/queryProSpecTrLbrPlusOne/${levelName}`, queryParams);

  const {
    data: specOpsStrData,
    isLoading: specOpsStrIsLoading,
    isError: specOpsStrIsError
  } = useQueryApi(`query/querySpecOpsStr/${levelName}`, queryParams);

  const {
    data: specOpsDstData,
    isLoading: specOpsDstIsLoading,
    isError: specOpsDstIsError
  } = useQueryApi(`query/querySpecOpsDst/${levelName}`, queryParams);

  const {
    data: specOpsPlusOneData,
    isLoading: specOpsPlusOneIsLoading,
    isError: specOpsPlusOneIsError
  } = useQueryApi(`query/querySpecOpsPlusOne/${levelName}`, queryParams);

  const {
    data: proOpsData,
    isLoading: proOpsIsLoading,
    isError: proOpsIsError
  } = useQueryApi(`query/queryProOps/${levelName}`, queryParams);

  const {
    data: queryProOpsPlusOneData,
    isLoading: queryProOpsPlusOneIsLoading,
    isError: queryProOpsPlusOneIsError
  } = useQueryApi(`query/queryProOpsPlusOne/${levelName}`, queryParams);

  const {
    data: trOpsData,
    isLoading: trOpsIsLoading,
    isError: trOpsIsError
  } = useQueryApi(`query/queryTrOps/${levelName}`, queryParams);

  const {
    data: salesData,
    isLoading: salesDataIsLoading,
    isError: salesDataIsError
  } = useQueryApi(`query/querySales/${levelName}`, queryParams);
  const {
    data: subLevelSalesData,
    isLoading: subLevelSalesIsLoading,
    isError: subLevelSalesIsError
  } = useQueryApi(`query/querySubLevelSales/${levelName}`, queryParams);

  const isLoading =
    quotesAggIsLoading ||
    proSpecTrSlsIsLoading ||
    proSpecTrLbrIsLoading ||
    specOpsStrIsLoading ||
    specOpsDstIsLoading ||
    proOpsIsLoading ||
    trOpsIsLoading ||
    queryProSpecTrLbrPlusOneIsLoading ||
    specOpsPlusOneIsLoading ||
    queryProOpsPlusOneIsLoading ||
    salesDataIsLoading ||
    subLevelSalesIsLoading;

  const isError =
    quotesAggIsError ||
    proSpecTrSlsIsError ||
    proSpecTrLbrIsError ||
    specOpsStrIsError ||
    specOpsDstIsError ||
    proOpsIsError ||
    trOpsIsError ||
    queryProSpecTrLbrPlusOneIsError ||
    specOpsPlusOneIsError ||
    queryProOpsPlusOneIsError ||
    salesDataIsError ||
    subLevelSalesIsError;

  return {
    isLoading,
    isError,
    quotesAggData,
    proSpecTrSlsData,
    proSpecTrLbrData,
    specOpsStrData,
    specOpsDstData,
    proOpsData,
    trOpsData,
    queryProSpecTrLbrPlusOneData,
    specOpsPlusOneData,
    queryProOpsPlusOneData,
    salesData,
    subLevelSalesData
  };
};
