import { useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';
import {
  buildFooter,
  buildHeaders,
  buildRows
} from '../../../../../../components/SpecialtyProRentalContainer/utils/servicesPipelineTable';
import { aggregateObjectKeys } from '../../../../../../utils/array';

const PIPELINE_OPTIONS = [
  { key: 'TOTAL', text: 'Total', value: 'TOTAL' },
  { key: 'STR', text: 'Store', value: 'STR' },
  { key: 'WEB', text: 'Web', value: 'WEB' },
  { key: 'SP', text: 'Service Provider', value: 'SP' },
  { key: 'OTHER', text: 'Other', value: 'OTHER' }
];

export const CoreAllProgramPipeline = ({ allProgramPipelineData, onChangePipeline, pipelineFilter, timeframe }) => {
  const dataTopCategoryMemo = useMemo(
    () => allProgramPipelineData?.filter(row => row.TOP_6_CATEGORY <= 6),
    [allProgramPipelineData]
  );

  const focusSixTotal = useMemo(() => aggregateObjectKeys(dataTopCategoryMemo), [dataTopCategoryMemo]);

  const totalData = useMemo(() => aggregateObjectKeys(allProgramPipelineData), [allProgramPipelineData]);

  const dataPipelineObj = {
    data: buildRows(allProgramPipelineData || [], true, pipelineFilter, 'core', '', timeframe),
    headers: buildHeaders(),
    footer: buildFooter(focusSixTotal, totalData, timeframe, false, pipelineFilter),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.Controls className='d-flex justify-content-end'>
        <Dropdown selection options={PIPELINE_OPTIONS} onChange={onChangePipeline} value={pipelineFilter} />
      </DataView.Controls>

      <DataView.Title position='center'>All Programs</DataView.Title>
      <DataView.DataTable data={dataPipelineObj} />
    </DataView>
  );
};
