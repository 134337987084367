import { Dropdown, Grid, Header } from 'semantic-ui-react';
import { VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../../constants/goals';
import { useOverviewContext } from '../../../hooks/useOverviewContext';
import { DataView } from '../../Common/DataView';
import { getCompareStyle, getDollarString, getPercentString } from '../../Common/TableCellUtils';

const OPTIONS = [
  { key: 'sales', text: 'Sales', value: 'sales' },
  { key: 'units', text: 'Units', value: 'units' },
  { key: 'transactions', text: 'Transactions', value: 'transactions' },
  { key: 'average_ticket', text: 'Average Ticket', value: 'average_ticket' },
  { key: 'units_per_basket', text: 'Units per Basket', value: 'units_per_basket' },
  { key: 'days_safe', text: 'Days Safe', value: 'days_safe' },
  { key: 'smd', text: 'SMD', value: 'smd' },
  { key: 'labor', text: 'Labor', value: 'labor' },
  { key: 'get', text: 'GET', value: 'get' },
  { key: 'ltsa', text: 'LTSA', value: 'ltsa' },
  { key: 'ltpa', text: 'LTPA', value: 'ltpa' },
  { key: 'leads', text: 'Leads', value: 'leads' },
  { key: 'measures', text: 'Measures', value: 'measures' },
  { key: 'HDPP - MA UA', text: 'HDPP - MA UA', value: 'HDPP - MA UA' },
  { key: 'HDPP - GM UA', text: 'HDPP - GM UA', value: 'HDPP - GM UA' }
];

const LEVEL_CONSTANTS = {
  region: {
    sufix: 'DST',
    columnHeader: 'District',
    subQueryParam: 'dstNbr',
    subLevel: 'district',
    rowValue: 'DST_NBR'
  },
  division: {
    sufix: 'RGN',
    columnHeader: 'Region',
    subQueryParam: 'rgnNbr',
    subLevel: 'region',
    rowValue: 'RGN_NBR'
  }
};

const headersList = (metric, unit, level) => {
  if (metric === 'days_safe') {
    return [{ name: `${LEVEL_CONSTANTS[level]['columnHeader']} # ` }, { name: 'Days Safe ' }];
  }
  return [
    { name: `${LEVEL_CONSTANTS[level]['columnHeader']} # ` },
    { name: `Actual ` },
    { name: `vP/vF  ${unit === 'PCT' ? '% ' : ''}` },
    { name: `vLY  ${unit === 'PCT' ? '% ' : ''}` }
  ];
};

const bodyRows = ({ timeframe, metric, row, unit, level }) => {
  const listOfRecords = [
    {
      stringValue: `${LEVEL_CONSTANTS[level]['sufix']} ${row[LEVEL_CONSTANTS[level]['rowValue']]}`,
      orderBy: parseInt(row[LEVEL_CONSTANTS[level]['rowValue']]),
      link: {
        to: `/${LEVEL_CONSTANTS[level]['subLevel']}/overview`,
        params: {
          showList: true,
          [LEVEL_CONSTANTS[level]['subQueryParam']]: String(row[LEVEL_CONSTANTS[level]['rowValue']]).padStart(4, '0')
        }
      }
    }
  ];
  if (metric === 'days_safe') {
    listOfRecords.push({
      stringValue: row['DAYS_SAFE'],
      orderBy: row['DAYS_SAFE']
    });
  }
  if (metric === 'sales') {
    const currentList = [
      {
        stringValue: getDollarString(row[timeframe + propsByMetric[metric]['actual']]),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue:
          unit !== 'PCT'
            ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VP']])
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VP']], 2),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VP']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VP']]
      },
      {
        stringValue:
          unit !== 'PCT'
            ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'units') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              })
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'transactions') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              })
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'average_ticket') {
    const currentList = [
      {
        stringValue: getDollarString(row[timeframe + propsByMetric[metric]['actual']], 2),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2)
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'units_per_basket') {
    const unitsPerBasketByNOM =
      row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
      row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT'];
    const unitsPerBasketByPCT =
      (row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
        row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']) /
      (row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']);
    const currentList = [
      {
        stringValue: (row['TY_' + timeframe + '_UNT_SLS'] / row['TY_' + timeframe + '_TXN_CNT']).toLocaleString(undefined, {
          maximumFractionDigits: 2
        }),
        orderBy: row['TY_' + timeframe + '_UNT_SLS'] / row['TY_' + timeframe + '_TXN_CNT']
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'PCT'
            ? unitsPerBasketByNOM.toLocaleString(undefined, { maximumFractionDigits: 2 })
            : getPercentString(unitsPerBasketByPCT, 2),
        customStyle: getCompareStyle(unitsPerBasketByNOM),
        orderBy: unit !== 'PCT' ? unitsPerBasketByNOM : unitsPerBasketByPCT
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'smd') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + '_SMD_ACT'], 2),
        orderBy: row[timeframe + '_SMD_ACT']
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + '_SMD_VP']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
            : '-',
        customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VP']),
        orderBy: unit !== 'PCT' ? row[timeframe + '_SMD_VP'] : undefined
      },
      {
        stringValue:
          unit !== 'PCT'
            ? (+row[timeframe + '_SMD_VLY']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
            : '-',
        customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VLY']),
        orderBy: unit !== 'PCT' ? row[timeframe + '_SMD_VLY'] : undefined
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'labor') {
    const currentList = [
      {
        stringValue: (+row[timeframe + '_LBR_ACT']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs',
        orderBy: row[timeframe + '_LBR_ACT']
      },
      {
        stringValue:
          unit === 'NOM'
            ? (+row[timeframe + propsByMetric[metric][unit + '_VP']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }) + ' hrs'
            : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VP']]),
        customStyle: unit === 'PCT' ? getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VP']]) : undefined,
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VP']]
      },
      {
        stringValue: '-'
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'get') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
        customStyle: {
          color: +row[timeframe + propsByMetric[metric]['actual']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue: unit === 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
        customStyle: {
          color: +row[timeframe + propsByMetric[metric][unit + '_VLY']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'ltsa') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
        customStyle: {
          color: +row[timeframe + propsByMetric[metric]['actual']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue: unit === 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
        customStyle: {
          color: +row[timeframe + propsByMetric[metric][unit + '_VLY']] >= VOC_LTSA_GOAL ? 'green' : 'red'
        },
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'ltpa') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
        customStyle: {
          color:
            +row[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL
              ? 'green'
              : +row[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL
                ? 'red'
                : 'black'
        },
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue: unit !== 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: unit === 'NOM' ? row[timeframe + propsByMetric[metric][unit + '_VLY']] : undefined
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'leads') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'NOM'
            ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
            : (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'measures') {
    const currentList = [
      {
        stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }),
        orderBy: row[timeframe + propsByMetric[metric]['actual']]
      },
      {
        stringValue: '-'
      },
      {
        stringValue:
          unit !== 'NOM'
            ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
            : (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, {
                maximumFractionDigits: 0
              }),
        customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
        orderBy: row[timeframe + propsByMetric[metric][unit + '_VLY']]
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'HDPP - MA UA') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + '_MA_HDPP']),
        orderBy: row[timeframe + '_MA_HDPP']
      },
      {
        stringValue: getPercentString(row[timeframe + '_MA_HDPP_VG']),
        customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VG']),
        orderBy: row[timeframe + '_MA_HDPP_VG']
      },
      {
        stringValue: getPercentString(row[timeframe + '_MA_HDPP_VLY']),
        customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VLY']),
        orderBy: row[timeframe + '_MA_HDPP_VLY']
      }
    ];
    listOfRecords.push(...currentList);
  }
  if (metric === 'HDPP - GM UA') {
    const currentList = [
      {
        stringValue: getPercentString(row[timeframe + '_GM_HDPP']),
        orderBy: row[timeframe + '_GM_HDPP']
      },
      {
        stringValue: getPercentString(row[timeframe + '_GM_HDPP_VG']),
        customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VG']),
        orderBy: row[timeframe + '_GM_HDPP_VG']
      },
      {
        stringValue: getPercentString(row[timeframe + '_GM_HDPP_VLY']),
        customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VLY']),
        orderBy: row[timeframe + '_GM_HDPP_VLY']
      }
    ];
    listOfRecords.push(...currentList);
  }

  return listOfRecords;
};

export const SalesBySubLevelList = ({
  timeframe,
  metricsByDistrict,
  unit,
  levelNumber,
  SafetyIncOshaLtGlbiByRgnData,
  dataAsOf,
  level,
  levelName
}) => {
  const { metric, setMetric } = useOverviewContext();
  const listByDaysSafe = SafetyIncOshaLtGlbiByRgnData.filter(
    row => row.METRIC_LEVEL === LEVEL_CONSTANTS[level]['sufix']
  ).map(row => bodyRows({ timeframe, row, metric, unit, level }));

  const districtListDataObj = {
    headers: headersList(metric, unit, level),
    data:
      metric === 'days_safe'
        ? listByDaysSafe
        : metricsByDistrict.map(row => bodyRows({ timeframe, row, metric, unit, level })),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <>
      <Grid>
        <Grid.Column width={8} />
        <Grid.Column width={8}>
          Metric:
          <br></br>
          <Dropdown
            compact
            defaultValue={metric}
            selection
            size='tiny'
            options={OPTIONS}
            onChange={(_, { value }) => setMetric(value)}
          />
        </Grid.Column>
      </Grid>
      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        {metric.replaceAll('_', ' ')} - {level} {levelNumber}, {levelName}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>

      <DataView testId={'salesByDistrict'}>
        <DataView.DataTable data={districtListDataObj} />
      </DataView>
    </>
  );
};

const propsByMetric = {
  sales: {
    NOM_VP: '_SLS_VP',
    PCT_VP: '_SLS_AMT_VP_PCT',
    NOM_VLY: '_SLS_AMT_VLY',
    PCT_VLY: '_SLS_AMT_VLY_PCT',
    actual: '_SLS_AMT_ACT'
  },
  units: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_UNTS_VLY',
    PCT_VLY: '_UNTS_VLY_PCT',
    actual: '_UNTS_ACT'
  },
  transactions: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_TRNS_VLY',
    PCT_VLY: '_TRNS_VLY_PCT',
    actual: '_TRNS_ACT'
  },
  average_ticket: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_AVG_TCKT_VLY',
    PCT_VLY: '_AVG_TCKT_VLY_PCT',
    actual: '_AVG_TCKT_ACT'
  },
  smd: {
    NOM_VP: '_SMD_VP',
    PCT_VP: null,
    NOM_VLY: '_SMD_VLY',
    PCT_VLY: null,
    actual: '_SMD_ACT'
  },
  labor: {
    NOM_VP: '_LBR_VF',
    PCT_VP: '_LBR_VF_PCT',
    NOM_VLY: null,
    PCT_VLY: null,
    actual: '_LBR_ACT'
  },
  get: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_GET_VLY',
    PCT_VLY: null,
    actual: '_GET_ACT'
  },
  ltsa: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_LTSA_VLY',
    PCT_VLY: null,
    actual: '_LTSA_ACT'
  },
  ltpa: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_ONLINE_SAT_VLY',
    PCT_VLY: null,
    actual: '_ONLINE_SAT_ACT'
  },
  leads: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_LEADS_VLY',
    PCT_VLY: '_LEADS_VLY_PCT',
    actual: '_LEADS_ACT'
  },
  measures: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_MEAS_VLY',
    PCT_VLY: '_MEAS_VLY_PCT',
    actual: '_MEAS_ACT'
  }
};
