import React, { useContext, useState } from 'react';
import { Dropdown } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { CustomDropDown } from '../CustomDropdown';

export default function OnTimeAverage() {
  const [view, setView] = useState('Associate View');
  const showCustomTimeframe = ['# Missed SLA']
  const { OnTimeAverageData } = useContext(StoreOrderFulfillmentContext);

  const isAssoc = view === 'Associate View';

  const dataAssoc = {
    headers: [
      { name: 'Associate' },
      { name: 'Pick On Time %' },
      { name: '# of Orders Missed' },
    ],
    data: OnTimeAverageData.map(data => [
      { stringValue: data.ASSOCIATE },
      { pctValue: data.PICK_ON_TIME },
      { numValue: data.DLV_MISSES },
    ])
  }

  const dataMissedSLA = {
    headers: [
      { name: 'Truck Type' },
      { name: 'Associate Name' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'FulFill Date' },
    ],
    data: OnTimeAverageData.map(data => [
      { stringValue: data.TRUCK },
      { stringValue: data.ASSOCIATE },
      { numValue: data.ORDER_NOM },
      { numValue: data.WRK_ORDER_NOM },
      { stringValue: data.FULFIL_DATE },
    ])
  }

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return dataAssoc;
      case '# Missed SLA':
        return dataMissedSLA;
    }
  }

  return (
    <div>
      <br />
      {isAssoc ? <Header as='h3'>Delivery: Associate Report</Header> : <Header as='h3'>Average Min to Pick</Header>}
      <CustomDropDown
        view={view}
        setView={setView}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'MTD', text: 'MTD', value: 'MTD' },
        ]}
      />
      <DataTable data={getData()} />
    </div>
  );
}
