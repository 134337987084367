import { DataView } from "../../Common/DataView";
import { useShrinkContext } from "../hooks/useShrinkContext";
import { useShrinkQueries } from "../hooks/useShrinkQueries";
import { focusMetricsData } from "../utils/dataObjectBuilder";

export const FocusMetrics = ({ shrinkMetricsData, scoresData }) => {
  const { timeframe, fpToggle } = useShrinkContext();
  const { locNbr, level } = useShrinkQueries({ fpToggle })

  const focusMetricsObject = focusMetricsData({ level, locNbr, timeframe, scoresData, shrinkMetricsData })

  return (
    <DataView>
      <DataView.Title>
        Focus Metrics
      </DataView.Title>
      <DataView.DataTable data={focusMetricsObject} />
    </DataView>
  );
}
