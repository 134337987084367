import { useState } from 'react';
import { useShrinkContext } from '../../hooks/useShrinkContext';
import { useShrinkQueries } from '../../hooks/useShrinkQueries';
import { UNIT_CHAR } from '../../utils/overview';
import { buildBaseShrinkUrl } from '../../utils/url';
import { getParamByLevel } from '../../../../utils/timeFrameUtils';
import { DataTable } from '../../../Common/DataTable';
import { UnitMenu } from '../../../Common/UnitMenu';
import { Tab } from 'semantic-ui-react';

export const ShrinkSubClassesTab = () => {
  const [unit, setUnit] = useState('NOM');
  const { fpToggle } = useShrinkContext();
  const {
    level,
    locNbr,
    results: [queryShrinkSclsCls]
  } = useShrinkQueries({ fpToggle, queries: ['query/queryShrinkSclsCls'] });

  const levelParam = getParamByLevel(level);
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const url = `/${baseLevelShrinkUrl}/subclass`;
  const isUnitNom = unit === 'NOM';

  const dataObj = {
    data: queryShrinkSclsCls.data?.map(row => {
      const shrinkDollar = row.TOTAL_SHRINK_AMT;
      const shrinkPct = row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT;
      const shrinkValue = isUnitNom ? shrinkDollar : shrinkPct;

      const vlyDollar = row.TOTAL_SHRINK_AMT - row.LY_TOTAL_SHRINK_AMT;
      const vlyPct = row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT - row.LY_TOTAL_SHRINK_AMT / row.LY_TOTAL_SALES_AMT;
      const vlyValue = isUnitNom ? vlyDollar : vlyPct;

      const openShrinkDollar = row.OPS_SHRINK_AMT;
      const openShrinkPct = row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT;
      const openShrinkValue = isUnitNom ? openShrinkDollar : openShrinkPct;

      return [
        {
          link: {
            to: url,
            params: {
              [levelParam]: locNbr,
              subClassNbr: row.EXT_SUB_CLASS_NBR
            }
          },
          stringValue: `${row.EXT_SUB_CLASS_NBR} - ${row.SUB_CLASS_DESC.replaceAll('/', '/\u200b')}`
        },
        {
          decimals: !isUnitNom ? 2 : undefined,
          dollarValue: isUnitNom ? shrinkDollar : undefined,
          orderBy: shrinkValue,
          pctValue: !isUnitNom ? shrinkPct : undefined
        },
        {
          decimals: !isUnitNom ? 2 : undefined,
          dollarValue: isUnitNom ? vlyDollar : undefined,
          orderBy: vlyValue,
          pctValue: !isUnitNom ? vlyPct : undefined
        },
        {
          decimals: !isUnitNom ? 2 : undefined,
          dollarValue: isUnitNom ? openShrinkDollar : undefined,
          orderBy: openShrinkValue,
          pctValue: !isUnitNom ? openShrinkPct : undefined
        }
      ];
    }),
    headers: [
      { name: 'Sub-Class' },
      { name: `Shrink ${UNIT_CHAR[unit]}` },
      { name: `vLY ${UNIT_CHAR[unit]}` },
      { name: `Open Shrink ${UNIT_CHAR[unit]}` }
    ],
    pagination: true,
    sortable: true,
    sortableDefault: 0
  };

  return (
    <Tab.Pane attached={false}>
      <UnitMenu unit={unit} setUnit={setUnit} />

      <DataTable data={dataObj} />
    </Tab.Pane>
  );
};
