import { UNIT_CHAR } from '../../../utils/skus';
import { formatSku } from '../../../../../utils/formatter';

export const coreShrinkSubClassPageBuilder = (data, unit, isUnitNom, setModalData) => {
  return {
    data: data?.map(row => [
      {
        onClick: () => setModalData(row),
        stringValue: formatSku(row['SKU_NBR']),
        customStyle: { color: '#EE7125', cursor: 'pointer', textDecoration: 'underline' }
      },
      {
        stringValue: row.SKU_DESC?.replaceAll('/', '/\u200b')
      },
      {
        dollarValue: isUnitNom ? row.TOTAL_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT
      },
      {
        dollarValue: isUnitNom ? row.TOTAL_SHRINK_AMT - row.LY_TOTAL_SHRINK_AMT : undefined,
        pctValue: isUnitNom
          ? undefined
          : row.TOTAL_SHRINK_AMT / row.TOTAL_SALES_AMT - row.LY_TOTAL_SHRINK_AMT / row.LY_TOTAL_SALES_AMT
      },
      {
        dollarValue: isUnitNom ? row.OPS_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT
      }
    ]),
    headers: [
      { name: 'SKU # ' },
      { name: 'SKU Desc ' },
      { name: `Shrink ${UNIT_CHAR[unit]}` },
      { name: `vLY ${UNIT_CHAR[unit]}` },
      { name: `Ops Shrink ${UNIT_CHAR[unit]}` }
    ],
    sortable: true,
    pagination: true,
    sortableDefault: 0
  };
};
