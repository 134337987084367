import React from 'react'
import { Header, Tab } from 'semantic-ui-react';
import { CompletePickUp } from './CompletePickUp';
import { CompleteDelivery } from './CompleteDelivery';

export default function Complete() {
  const tabs = [
    {
      menuItem: 'PICKUP',
      render: () => <CompletePickUp />
    },
    {
      menuItem: 'DELIVERY',
      render: () => <CompleteDelivery />
    }
  ]

  return (
    <Tab
      menu={{ compact: true, size: 'small' }}
      panes={tabs}
    />
  );
}
