import { Grid } from 'semantic-ui-react';
import { UnitMenu } from '../../Common/UnitMenu';

export const UnitsAsComponent = ({ unit, setUnit }) => {
  return (
    <Grid.Column width={8} textAlign='left'>
      <Grid.Row>Units:</Grid.Row>
      <Grid.Row>
        <UnitMenu unit={unit} setUnit={setUnit} />
      </Grid.Row>
    </Grid.Column>
  );
};
