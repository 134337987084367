import { Header, Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useShrinkDeptQueries } from '../hooks/useShrinkDeptQueries';
import { ShrinkDept } from '../components/ShrinkDept';

export const ShrinkDeptPage = props => {
  const { fpToggle } = props;
  const { level } = useParams();
  const { divNbr = 0, rgnNbr = 0, dstNbr = 0, deptNbr = 0 } = useQueryParams();
  const locNbr = divNbr || rgnNbr || dstNbr;

  const { isError, isLoading, shrinkActualsClsDeptData, shrinkActualsSkuDeptData } = useShrinkDeptQueries({
    level,
    locNbr,
    deptNbr,
    fpToggle
  });

  if (isError) return <Header textAlign='center'>Something went wrong</Header>;
  if (isLoading) return <Loader active>Loading</Loader>;
  if (!shrinkActualsClsDeptData.length) return <Header textAlign='center'>Invalid Store Number</Header>;

  return (
    <ShrinkDept
      shrinkActualsClsDeptData={shrinkActualsClsDeptData}
      shrinkActualsSkuDeptData={shrinkActualsSkuDeptData}
      level={level}
      locNbr={locNbr}
      deptNbr={deptNbr}
    />
  );
};
