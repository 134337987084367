import { UNIT_CHAR } from '../../../utils/skus';
import { formatSku } from '../../../../../utils/formatter';

export const coreTop40SkuBuilder = (top40SkuData, isUnitNom, unit, setModalData) => {
  const dataFiltered = top40SkuData.filter(
    row => row && (isUnitNom ? row['SHRINK_AMT_vLY_RANK_DST'] <= 40 : row['SHRINK_RATE_vLY_RANK_DST'] <= 40)
  );

  return {
    data: dataFiltered?.map(row => [
      {
        numValue: isUnitNom ? row.SHRINK_AMT_vLY_RANK_DST : undefined,
        stringValue: isUnitNom ? undefined : row.SHRINK_RATE_vLY_RANK_DST
      },
      {
        onClick: () => setModalData(row),
        stringValue: formatSku(row['SKU_NBR']),
        customStyle: { color: '#EE7125', cursor: 'pointer', textDecoration: 'underline' }
      },
      {
        stringValue: row.SKU_DESC?.replaceAll('/', '/\u200b')
      },
      {
        stringValue: row.DEPT_NBR
      },
      {
        dollarValue: isUnitNom ? row.TOTAL_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.SHRINK_RATE
      },
      {
        dollarValue: isUnitNom ? row.SHRINK_AMT_vLY : undefined,
        pctValue: isUnitNom ? undefined : row.SHRINK_RATE_vLY
      },
      {
        dollarValue: isUnitNom ? row.OPS_SHRINK_AMT : undefined,
        pctValue: isUnitNom ? undefined : row.OPS_SHRINK_AMT / row.TOTAL_SALES_AMT
      }
    ]),
    headers: [
      { name: 'Rank * ' },
      { name: 'SKU ' },
      { name: 'SKU Desc ' },
      { name: 'Dept ' },
      { name: `Shrink ${UNIT_CHAR[unit]}` },
      { name: `vLY ${UNIT_CHAR[unit]}` },
      { name: `Ops Shrink ${UNIT_CHAR[unit]}` }
    ],
    sortable: true,
    sortableDefault: 0
  };
};
