import useQueryApi from '../../../../../hooks/useQueryApi';

export const useShrinkACCRCVQuries = (fpToggle) => {
  const defaultParams = { fpToggle, isCore: true };

  const {
    data: queryACCRCVByCore,
    isError: isErrorACCRCVByCore,
    isLoading: isACCRCVByCoreLoading
  } = useQueryApi('queryACCRCVByCore',defaultParams);

  const isError = isErrorACCRCVByCore ;
  const isLoading = isACCRCVByCoreLoading ;

  return {
    isError,
    isLoading,
    queryACCRCVByCore,
  };
};
