import React from 'react';
import { Tab } from 'semantic-ui-react';
import { Boss } from './assoc/Boss';
import { WillCall } from './assoc/WillCall';
import { BopisAssoc } from './assoc/BopisAssoc';
import { Curbside } from './assoc/Curbside';

export const OnTimePickupAssoc = () => {
    const tabs = [
        {
            menuItem: 'BOPIS',
            render: () => <BopisAssoc />
        },
        {
            menuItem: 'BOSS',
            render: () => <Boss />
        },
        {
            menuItem: 'WILL CALL',
            render: () => <WillCall />
        },
        {
            menuItem: 'CURBSIDE',
            render: () => <Curbside />
        }
    ]
    return (
        <Tab
            menu={{ compact: true, size: 'small' }}
            panes={tabs}
        />
    );
};
