import { useMemo } from "react";
import { useEnhancedQueries } from "../../../hooks/useEnhancedQueries";
import { useSpecialtyProRentalServicesContext } from "./useSpecialtyProRentalServicesContext";
import { FOCUS_6 } from "../utils/specialtyProRentalServices";
import { aggregateObjectKeys } from "../../../utils/array";

export const useServiceDtlData = focus6AllPrograms => {
  const { fpToggle } = useSpecialtyProRentalServicesContext();
  const { results: [{ data = [] }] } = useEnhancedQueries(['queryServicesDtlByMetricLevel'], fpToggle);

  const dataTopCategoryMemo = useMemo(() => data.filter(row => row.TOP_6_CATEGORY <= 6), [data])

  const dataFilteredMemo = useMemo(() => {
    if (focus6AllPrograms !== FOCUS_6) return data;

    return dataTopCategoryMemo;
  }, [data, dataTopCategoryMemo, focus6AllPrograms]);

  const focusSixTotal = useMemo(() => aggregateObjectKeys(dataTopCategoryMemo), [dataTopCategoryMemo]);

  const totalData = useMemo(() => aggregateObjectKeys(data), [data])

  return { data: dataFilteredMemo, focusSixTotal, totalData };
}
