import { Header } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { DataView } from '../../Common/DataView';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { getDataAsOf } from '../../../utils/getDataAsOf';

export const SpecialtyProRental = props => {
  const { children, title, data } = props;
  const { timeframe, setTimeframe } = useSpecialtyProRentalContext();

  const dataAsOf = getDataAsOf({ data });

  return (
    <>
      <DataView className='my-3'>
        <DataView.Title position='center'>
          {title}
          <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='d-flex justify-content-center'>
          <TimeframeDropdown timeframe={timeframe} settimeframe={setTimeframe} />
        </DataView.Controls>
      </DataView>
      {children}
    </>
  );
};
