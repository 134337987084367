export const leadsMeasuresBuilder = (data = [], timeframe = '') => {
  const formattedData = data.map(row => {
    const compPercent =
      (row[`${timeframe}_LEAD_COUNT_TOTAL`] + row[`${timeframe}_MEASURE_COUNT_TOTAL`] || 0) /
      (row[`${timeframe}_LEAD_COUNT_TOTAL`] || 1);

    const convPercent =
      (row[`${timeframe}_LEAD_CONV_NUM_TOTAL`] + row[`${timeframe}_MEASURE_CONV_NUM_TOTAL`] || 0) /
      (row[`${timeframe}_LEAD_CONV_DEN_TOTAL`] + row[`${timeframe}_MEASURE_CONV_DEN_TOTAL`] || 1);

    return [
      {
        link: {
          to: '/division/services',
          params: { divNbr: row.DIV_NBR }
        },
        stringValue: row.DIV_NBR
      },
      { numValue: row[`${timeframe}_LEAD_COUNT_TOTAL`] },
      { stringValue: row[`${timeframe}_MEASURE_COUNT_TOTAL`].toLocaleString() },
      { pctValue: isNaN(compPercent) ? '--' : compPercent },
      { pctValue: isNaN(convPercent) ? '--' : convPercent }
    ];
  });

  // Calcular los totales para el footer
  const totals = data.reduce(
    (acc, row) => {
      acc.leads += row[`${timeframe}_LEAD_COUNT_TOTAL`] || 0;
      acc.meas += row[`${timeframe}_MEASURE_COUNT_TOTAL`] || 0;
      acc.leadConvNum += row[`${timeframe}_LEAD_CONV_NUM_TOTAL`] || 0;
      acc.leadConvDen += row[`${timeframe}_LEAD_CONV_DEN_TOTAL`] || 0;
      acc.measureConvNum += row[`${timeframe}_MEASURE_CONV_NUM_TOTAL`] || 0;
      acc.measureConvDen += row[`${timeframe}_MEASURE_CONV_DEN_TOTAL`] || 0;
      return acc;
    },
    {
      leads: 0,
      meas: 0,
      leadConvNum: 0,
      leadConvDen: 0,
      measureConvNum: 0,
      measureConvDen: 0
    }
  );

  const totalCompPercent = (totals.leads + totals.meas) / (totals.leads + totals.meas || 1);
  const totalConvPercent = (totals.leadConvNum + totals.measureConvNum) / (totals.leadConvDen + totals.measureConvDen || 1);

  const footerData = [
    [
      { stringValue: 'Total' },
      { numValue: totals.leads },
      { numValue: totals.meas.toLocaleString() },
      { pctValue: isNaN(totalCompPercent) ? '--' : totalCompPercent },
      { pctValue: isNaN(totalConvPercent) ? '--' : totalConvPercent }
    ]
  ];

  return {
    headers: [{ name: 'Division' }, { name: 'Leads' }, { name: 'Meas' }, { name: 'Comp %' }, { name: 'Conv %' }],
    data: formattedData,
    sortable: true,
    sortableDefault: 0,
    footer: footerData
  };
};
