import { Header, Loader } from 'semantic-ui-react';
import { MonitorMetricsDrill } from '../components/MonitorMetricsDrill';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { columnName } from '../../../utils/constantsByLevel';
import { shrinkMonitorMetricsDrillBreadcrumbItems, getShrinkBossCancelDataTable } from '../utils/shrinkMonitorMetrics';
import { TRNSFM_DT } from '../../../utils/constants';

export const ShrinkBossCancel = () => {
  const { fpToggle, timeframe } = useShrinkContext();

  const queryBuilder = { fpToggle, queries: ['query/queryShrinkMetricsByLevelPlusOne'] };
  const { results, locNbr, level, isError, isLoading } = useShrinkQueries(queryBuilder);
  const [{ data: shrinkMetricsByLevelData }] = results;

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const levelName = shrinkMetricsByLevelData?.[0]?.[columnName[level]];
  const titleSingle = 'Boss RTV %';

  const breadcrumb = shrinkMonitorMetricsDrillBreadcrumbItems(level, locNbr, titleSingle);
  const title = `${titleSingle} - ${level} ${locNbr}, ${levelName?.toLowerCase()}`;
  const dataAsOf = shrinkMetricsByLevelData?.[0]?.[TRNSFM_DT];
  const dataTable = getShrinkBossCancelDataTable({ level, shrinkMetricsByLevelData, timeframe });

  return <MonitorMetricsDrill breadcrumb={breadcrumb} title={title} dataAsOf={dataAsOf} data={dataTable} />;
};
