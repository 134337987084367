import { Loader, Tab } from 'semantic-ui-react';
import { useOverviewContext } from '../../../hooks/useOverviewContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { withOverviewHeader } from '../../HOC/withHeader';
import { OverviewTabs } from './OverviewTabs';
import { SalesBySubLevelList } from './SalesBySubLevelList';

const RegionBody = withOverviewHeader(OverviewTabs, 'Region');
const DistrictBody = withOverviewHeader(SalesBySubLevelList, 'District-List');
const DivisionBody = withOverviewHeader(OverviewTabs, 'Division');
const RegionListBody = withOverviewHeader(SalesBySubLevelList, 'Region-List');

const tabsMenu = {
  region: [
    {
      menuItem: 'Region',
      render: () => <RegionBody />
    },
    {
      menuItem: 'District List',
      render: () => <DistrictBody />
    }
  ],
  division: [
    {
      menuItem: 'Division',
      render: () => <DivisionBody />
    },
    {
      menuItem: 'Region List',
      render: () => <RegionListBody />
    }
  ]
};

export const OverviewMenu = () => {
  const { isError, isOverviewTabsLoading, level } = useOverviewContext();
  const { showList } = useQueryParams();
  if (isError) return <h1>Something went wrong</h1>;
  if (isOverviewTabsLoading) return <Loader active>Loading</Loader>;
  return (
    <Tab
      panes={tabsMenu[level]}
      menu={{ secondary: true, pointing: true }}
      style={{ marginBottom: '1em' }}
      defaultActiveIndex={showList ? 1 : 0}
    />
  );
};
