import { useMemo } from 'react';
import { DataView } from '../../../../../../components/Common/DataView';
import {
  aggregateMetricsFromLevels,
  buildFooter,
  buildRows
} from '../../../../../../components/SpecialtyProRentalContainer/utils/leadsMeasuresTable';
import { FOCUS_6 } from '../../../../../../components/SpecialtyProRentalContainer/utils/specialtyProRentalServices';

export const LeadsAndMeasuresPerformanceTable = ({
  allProgramLeadsMeasureData,
  focus6AllPrograms,
  timeframe,
  pipelineFilter
}) => {
  const dataFilteredLMMemo = useMemo(() => {
    if (focus6AllPrograms !== FOCUS_6) return allProgramLeadsMeasureData;

    return allProgramLeadsMeasureData.filter(row => row.TOP_6_CATEGORY <= 6);
  }, [allProgramLeadsMeasureData, focus6AllPrograms]);

  const dataReducedMemo = useMemo(() => {
    return aggregateMetricsFromLevels(dataFilteredLMMemo, 'core', timeframe, pipelineFilter);
  }, [dataFilteredLMMemo, 'core', pipelineFilter, timeframe]);

  const dataObjLM = {
    data: buildRows(dataReducedMemo, 'core', pipelineFilter, timeframe),
    footer: buildFooter(dataReducedMemo, timeframe, pipelineFilter),
    headers: [{ name: 'Division' }, { name: 'Lead' }, { name: 'Meas' }, { name: 'Comp %' }, { name: 'Conv %' }],
    sortable: true,
    sortableDefault: 0
  };
  return (
    <DataView>
      <DataView.Title position='center'>Leads & Measures Performance</DataView.Title>
      <DataView.DataTable data={dataObjLM} />
    </DataView>
  );
};
