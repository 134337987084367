import { Loader } from 'semantic-ui-react';
import { SalesProRental } from '../components/SalesProRental';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { useSpecialtyProRentalQueries } from '../hooks/useSpecialtyProRentalQueries';
import { SpecialtyProRental } from '../components/SpecialtyProRental';
import { Quotes } from '../components/Quotes';
import { RentalKeyMetrics } from '../components/RentalKeyMetrics';
import { getTitle } from '../utils/specialtyProRental';
import { SpecialtyProRentalLabor } from '../components/SpecialtyProRentalLabor';
import { SpecialtyProRentalKeyMetrics } from '../components/SpecialtyProRentalKeyMetrics';
import { SpecialtyProRentalProKeyMetrics } from '../components/SpecialtyProRentalProKeyMetrics';

export const SpecialtyProRentalPage = () => {
  const { level, locNbr, currentLevelName, isLoadingCurrentLevelName } = useSpecialtyProRentalContext();
  const {
    isLoading: queriesIsLoading,
    isError,
    quotesAggData,
    proSpecTrSlsData,
    proSpecTrLbrData,
    queryProSpecTrLbrPlusOneData,
    specOpsStrData,
    specOpsDstData,
    proOpsData,
    trOpsData,
    specOpsPlusOneData,
    queryProOpsPlusOneData,
    salesData,
    subLevelSalesData
  } = useSpecialtyProRentalQueries();

  const isLoading = isLoadingCurrentLevelName || queriesIsLoading;

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong</p>;

  const title = getTitle({ level, locNbr, currentLevelName });

  return (
    <SpecialtyProRental title={title} data={quotesAggData}>
      {/* Quotes */}
      <Quotes quotesData={quotesAggData} />

      <SalesProRental salesData={salesData} subLevelSalesData={subLevelSalesData} />

      <SpecialtyProRentalLabor
        proSpecTrLbrData={proSpecTrLbrData}
        queryProSpecTrLbrPlusOneData={queryProSpecTrLbrPlusOneData}
      />

      <SpecialtyProRentalKeyMetrics specOpsPlusOneData={specOpsPlusOneData} />

      {/* Specialty Key Metrics Cont. */}

      <SpecialtyProRentalProKeyMetrics queryProOpsPlusOneData={queryProOpsPlusOneData} proOpsData={proOpsData} />

      {/* Rental Key Metrics */}
      <RentalKeyMetrics rentalKeyMetricsData={trOpsData} />
    </SpecialtyProRental>
  );
};
